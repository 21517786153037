import React, { useState } from "react";
import IconButton from "../../../../uiComponents/IconButton/IconButton";
import Modal from "../../../../uiComponents/Modal/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import { resetModals, setModal } from "../../../../redux/modal/modal.actions";
import Loader from "react-loader-spinner";
import { APIPost } from "../../../../Services/authenticated";
import { connect } from "react-redux";

import "./styles.css";

const NewfolderModal = ({ resetModals, setModal, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [folderName, setFolderName] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  const onSaveFolder = async (newFolderName) => {
    setIsLoading(true);
    const payload = { folderName: newFolderName };
    try {
      const accessToken = await getAccessTokenSilently();
      await APIPost("/folders/create-folder", accessToken, payload);
      if (!data.document) {
        window.location.reload();
      }
      if (data.document) {
        setModal({
          folderModal: {
            show: true,
            document: data.document,
          },
          newFolderModal: {
            show: false,
            document: null,
          },
        });
      }
    } catch (e) {
      setIsLoading(false);
      alert("Error, intentelo nuevamente");
    }
  };

  return (
    <Modal>
      <div className="new-folder-modal-body">
        <i
          className="far fa-window-close new-folder-modal-close"
          onClick={() => resetModals()}
        ></i>
        <p className="bold new-folder-modal-title">Nueva Carpeta</p>
        {isLoading && (
          <div>
            <Loader
              type="Bars"
              color="#e8eeff"
              height={40}
              width={40}
              className="new-folder-modal-loader"
            />
            <span>Cargando ...</span>
          </div>
        )}
        {!isLoading && (
          <div>
            <p className="new-folder-modal-input-label primary">
              Nombre de la carpeta:
            </p>
            <input
              type="text"
              className="new-folder-modal-input"
              maxlength="40"
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
            />
            <IconButton
              label="Crear Carpeta"
              icon="fas fa-plus"
              className="new-folder-modal-create"
              color="secondary"
              onClick={() => onSaveFolder(folderName)}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.modal.newFolderModal,
  };
};

const mapDispatchToProps = {
  resetModals,
  setModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewfolderModal);
