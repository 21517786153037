import React from "react";

import { Box, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { colorPallette } from "../../../Common/theme";

type TutorialPdfViewerProps = {
  title: string;
  icon: any;
  src: string;
  message: string;
};

const TutorialPdfViewer: React.FC<TutorialPdfViewerProps> = ({
  title,
  icon,
  src,
  message,
}) => {
  return (
    <Stack flex={1} sx={{ alignItems: "flex-start" }}>
      <Typography variant="h6">
        <FontAwesomeIcon
          icon={icon}
          style={{ color: colorPallette.primary }}
          className="mr1"
        />
        {title}
      </Typography>
      <Box mt={2}>{message}</Box>
      <Box width="100%" mt={5}>
        <iframe
          title="Firma Electrónica"
          src={src}
          width="100%"
          height="600px"
        />
      </Box>
    </Stack>
  );
};

export default TutorialPdfViewer;
