import React from "react";
import { useSelector } from "react-redux";
import TableUser2 from "../../uiComponents/AndesTable/TableEntries/TableUser2.component";
import LogOutButton from "../LogOut";
import "./styles.css";

const Profile = () => {
  const { user, company } = useSelector((state) => state.masterData);

  return (
    <div className="profile">
      <div className="">
        <div className="profile-card center b-white">
          <div className="profile-photo-user">
            <TableUser2
              hideName
              photo={
                localStorage.getItem("currentUserPhoto") ||
                user?.photoUrl ||
                null
              }
              name={
                localStorage.getItem("currentUserName") || user?.name || "A D"
              }
            />
          </div>
          {user?.name ? (
            <p className="profile-name">{user?.name}</p>
          ) : (
            <p className="profile-name">{user?.name}</p>
          )}
          {user?.email && (
            <p className="profile-email primary">{user?.email}</p>
          )}
          <div className="profile-line" />
          <div className="profile-entry">
            <div>Empresa:</div>{" "}
            <div className="primary">
              {company?.companyName ? (
                <span className="primary">{company?.companyName}</span>
              ) : (
                "No Asignada"
              )}
            </div>
          </div>
          <div className="profile-entry">
            Rol:{" "}
            <span className="primary">
              {user?.role ? (
                <span className="primary">{user?.role}</span>
              ) : (
                "No Asignado"
              )}{" "}
            </span>
          </div>
          <div className="profile-entry">
            Suscripcion:
            <span className="primary">
              <span className="green2">
                <i className="far fa-check-circle"></i> Activa
              </span>
            </span>
          </div>
          <LogOutButton className="profile-logout b-primary b-primary-hover" />
        </div>
      </div>
    </div>
  );
};

export default Profile;
