import React, { useEffect, useState } from "react";
import "../styles.css";
import "./styles.css";
import Text from "../../../uiComponents/Text";

const Card = ({ item, handleClick, currentAnswer }) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    // this bit of code checks whether the item was already selected to mark it as so.
    if (currentAnswer === item) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [currentAnswer]);
  return (
    <div
      onClick={() => {
        setSelected((prev) => !prev);
        if (!selected) {
          handleClick(item);
        } else {
          handleClick("");
        }
      }}
      className={` multiple-options-option-card purple multiple-options-option-card-${selected}`}
    >
      {!selected ? (
        <i className="far fa-square multiple-options-square primary"></i>
      ) : (
        <i className="far fa-check-square multiple-options-check primary"></i>
      )}
      {
        <input
          type="checkbox"
          className="big-options-checkbox"
          checked={selected}
          value={selected}
          style={{ marginRight: 10 }}
          onChange={() => {
            if (!selected) {
              handleClick(item);
            } else {
              handleClick("");
            }
          }}
        />
      }
      {item}
    </div>
  );
};

const BigOptions = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  // this sets redux and session state answers
  const reduxAnswerSetter = (newAnswer) => {
    onChange({
      [sectionId]: {
        ...currentSectionAnswers,
        [question.id]: {
          question: question.question,
          answer: newAnswer,
        },
      },
    });
  };

  //this method handles clicks

  const handleClick = (newItem) => {
    reduxAnswerSetter(newItem);
  };
  return (
    <div>
      <Text
        size="small"
        className=" question-format-info"
        style={{ marginTop: 10 }}
      >
        *Seleccionar solo 1 opción
      </Text>
      {question.options.map((e) => {
        return (
          <Card
            item={e}
            handleClick={handleClick}
            key={e}
            currentAnswer={currentAnswer}
          />
        );
      })}
      <input
        required
        type="text"
        value={currentAnswer}
        className="big-options-invisible"
        maxLength="350"
        tabIndex={-1}
      />
    </div>
  );
};

export default BigOptions;
