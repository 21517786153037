import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export const BarElement = ({ element, index, selected, setSelected }) => {
  const { internalUser: isInternalUser, role: isAdminOrSuperAdmin } =
    useSelector((state) => state.masterData.user);

  const isSelected = index === selected;

  const history = useHistory();

  if (!isInternalUser && element.name === "Editor") {
    return null;
  }

  if (
    isAdminOrSuperAdmin === "user" &&
    element.name === "Control de Usuarios"
  ) {
    return null;
  }

  return (
    <div
      className={`left-bar-element left-bar-element-selected-${isSelected} ${
        isSelected && "b-primary"
      }`}
      onClick={() => {
        setSelected(index);
        history.push(element.to);
      }}
    >
      <div
        className={`left-bar-element-content left-bar-element-content-selected-${isSelected}`}
      >
        <div className="left-bar-element-icon-box">
          <i class={`${element.icon} ${isSelected ? "white" : "primary"}`} />
        </div>
      </div>
      <div className="left-bar-name">{element.name}</div>
    </div>
  );
};
