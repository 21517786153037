export type QuestionType =
  | "options"
  | "question"
  | "date"
  | "number"
  | "numberOf"
  | "multipleOptions"
  | "textarea"
  | "money"
  | "pesos"
  | "catastro"
  | "percentage"
  | "sheetsDataBase";
// | "moneyAndDates"
// | "moneyAndDatesWithIVA"
// | "searchServerOptions"
// | "advancedOptions"
// | "documentName";

export const questions = "questions";

export const questionTypes: QuestionType[] = [
  "options",
  "question",
  "date",
  "number",
  "numberOf",
  "multipleOptions",
  "textarea",
  "money",
  "pesos",
  "catastro",
  "percentage",
  "sheetsDataBase",
  // "moneyAndDates",
  // "moneyAndDatesWithIVA",
  // "searchServerOptions",
  // "advancedOptions",
  // "documentName",
];

export const questionTypeOptions = [
  { label: "Opciones", value: "options" },
  { label: "Pregunta", value: "question" },
  { label: "Fecha", value: "date" },
  { label: "Número", value: "number" },
  { label: "Número De", value: "numberOf" },
  { label: "Opciones Múltiples", value: "multipleOptions" },
  { label: "Área De Texto", value: "textarea" },
  { label: "Dinero", value: "money" },
  { label: "Pesos", value: "pesos" },
  { label: "Porcentaje", value: "percentage" },
  { label: "Base de Datos de Sheets", value: "sheetsDataBase" },
  { label: "Nomenclatura Catastral", value: "catastro" },
];

export type DependencyType =
  | "contain all"
  | "contain any"
  | "exact"
  | "exclude";

export const dependencyTypes: DependencyType[] = [
  "contain all",
  "contain any",
  "exact",
  "exclude",
];

export type DependencyOption = {
  value: DependencyType;
  label: string;
};

export const dependencyOptions: DependencyOption[] = [
  { value: "exact", label: "Debe ser exactamente el siguiente valor" },
  {
    value: "contain all",
    label: "Debe contener todos los valores a continuación",
  },
  {
    value: "contain any",
    label: "Debe contener al menos uno de los valores a continuación",
  },
  {
    value: "exclude",
    label: "No debe incluir ninguno de los siguientes valores",
  },
];

export type Dependency = {
  id: number;
  type: DependencyType;
  values: string;
};

export type Question = {
  id: number | string;
  question: string;
  info?: string;
  type: QuestionType;
  options?: string;
  section: string;
  fixedValue?: string;
  fixedValueSingular?: string;
  sheetId?: string;
  dependencies?: Dependency[];
};

export const ExampleQuestions: Question[] = [
  {
    id: 1,
    question: "What is your name?",
    info: "Choose how you would like to be called; Example: Peter",
    type: "question",
    section: "1",
    dependencies: [
      {
        id: 2,
        type: "exact",
        values: "Pedro",
      },
    ],
    options: "juan;pedro;pablo",
  },
  {
    id: 2,
    question: "What is your surname?",
    info: "Choose how you would like to be called; Example: Peter",
    type: "options",
    options: "juan;pedro;pablo",
    section: "1",
    fixedValue: "pesos",
    fixedValueSingular: "peso",
    sheetId: "",
    dependencies: [
      {
        id: 2,
        type: "exact",
        values: "Pedro",
      },
      {
        id: 2,
        type: "contain all",
        values: "Pedro;Pablo",
      },
      {
        id: 2,
        type: "exclude",
        values: "Pedro;Pablo",
      },
    ],
  },
];
