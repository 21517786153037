import React, { useEffect } from "react";

function Pago() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://sdk.mercadopago.com/js/v2";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  var orderData = {
    quantity: 1,
    description: "contrato",
    price: 199,
  };

  const scriptCreator = (preferenceId) => {
    var newScript = document.createElement("script");
    var inlineScript = document.createTextNode(` 
    const mp = new MercadoPago("TEST-e2da7a79-2de3-48f7-a9f0-42ee0d818f5b", {
      locale: "es-AR",
    });
          const checkout = mp.checkout({
              preference: {
                  id: '${preferenceId}'
              }
          });
          checkout.render({
              container: '.cho-container',
              label: 'Pagar'
          });
  `);
    newScript.appendChild(inlineScript);
    document.body.appendChild(newScript);
  };

  const fetcher = async () => {
    const data = await fetch("http://localhost:8000/pago", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(orderData),
    });
    const preference = await data.json();
    scriptCreator(preference.id);
  };

  return (
    <div>
      <div onClick={fetcher}>esta es la seccion de pago</div>
      <div id="button-checkout">pagar</div>
      <div className="cho-container"></div>
    </div>
  );
}

export default Pago;
