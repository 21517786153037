import React from "react";
import { useSelector } from "react-redux";
import Folder from "./Folder/Folder";
import "./styles.css";

type Props = {
  documents: any[];
  isSignatureScreen?: boolean;
  setEsignDocumentData?: () => void;
};

const FoldersPage: React.FC<Props> = ({
  documents,
  isSignatureScreen,
  setEsignDocumentData,
}) => {
  const { folders } = useSelector((state: any) => state.masterData);

  return (
    <div style={{ marginTop: 20 }}>
      {folders &&
        folders.map((folder: any) => {
          return (
            <Folder
              name={folder.folderName}
              documents={documents}
              id={folder.folderId}
              isSignatureScreen={isSignatureScreen}
              setEsignDocumentData={setEsignDocumentData}
            />
          );
        })}
      {documents && (
        <Folder
          name="General (sin asignar)"
          documents={documents}
          id={null}
          isSignatureScreen={isSignatureScreen}
          setEsignDocumentData={setEsignDocumentData}
        />
      )}
    </div>
  );
};

export default FoldersPage;
