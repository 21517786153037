import { useEffect, useState } from "react";
import { Box, Button, Stack } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Question } from "../../Types/Question.types";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faBolt,
  faPlay,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { colorPallette, theme } from "../../Common/theme";
import { setAppError, setLoading } from "../../Utils/modalMethods";
import { processAnswer } from "../../Utils/TextProcessor";
import { generateAndDownloadWord } from "../../Utils/wordGeneration";
import {
  APIGetWithError,
  APIPostFile,
  APIPostWithError,
} from "../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { set } from "date-fns";
import { setEditorDocument } from "../../redux/slices/EditorDataSlice";
import { useDispatch } from "react-redux";

type Props = {
  documentQuestions: Question[];
  setIsReview: any;
};

const ReviewSection: React.FC<Props> = ({ documentQuestions, setIsReview }) => {
  const [documentAnswers, setDocumentAnswers] = useState<any>({});

  const { getAccessTokenSilently } = useAuth0();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    const data2 = localStorage.getItem("generator-form-answers");
    if (data2) {
      setDocumentAnswers(JSON.parse(data2));
      return;
    } else {
      setAppError();
    }
  }, [history]);

  const [companyId, setCompanyId] = useState<string | undefined>(undefined);

  const getCompany = async () => {
    setCompanyId(undefined);
    try {
      const accessToken = await getAccessTokenSilently();
      const company = await APIGetWithError(
        "/companies/get-company",
        accessToken
      );
      if (company && company.companyId) {
        setCompanyId(company.companyId);
      }
    } catch {
      setCompanyId("ERROR");
    }
  };

  useEffect(() => {
    getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPrevious = () => {
    setIsReview(false);
    window.scrollTo(0, 0);
  };

  const getFileDataAndLoadDocumentFromServer = async (fileId: string) => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();

      const testRes = await APIPostWithError(
        "/doc-ref/get-one-doc",
        accessToken,
        {
          documentId: fileId,
        }
      );
      if (!testRes.document && testRes.document) {
        setLoading(false);
        return;
      }
      dispatch(setEditorDocument(testRes.document));
      history.push("/docx-editor");
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const saveDocument = async (file: any, docName: string) => {
    const now = Date.now();
    try {
      const accesToken = await getAccessTokenSilently();

      let newDocName = docName;

      const params = {
        data: file,
        docName: `${newDocName}_${now}.docx`,
        documentType: "Word",
        fileNameNoExtension: newDocName,
        versionId: now,
        versionNumber: 1,
        date: now,
        size: file.size,
        updateDate: now,
        format: "word",
      };

      const docRef = await APIPostFile(
        "/doc-ref/create-doc-ref",
        accesToken,
        params
      );

      if (docRef.documentSaved) {
        dispatch(setEditorDocument(null));
        getFileDataAndLoadDocumentFromServer(String(now));
      }
    } catch (error) {
      setAppError(
        "No se pudo guardar el documento en la nuve",
        "El documento se descargo en su computadora pero no se pudo guardar en la nube"
      );
    }
  };

  const onSubmit = async () => {
    const isInternalTesting = window.location.href.includes("/testing");

    if (isInternalTesting) {
      const data2 = localStorage.getItem("generator-form-document");
      if (data2) {
        const parsedData = JSON.parse(data2);

        if (
          parsedData.document &&
          parsedData.document[0] &&
          !parsedData.document[0].text
        ) {
          setAppError(
            "Aun no se agrego el documento",
            "Primero debe crear un documento para poder generarlo"
          );
          return;
        }

        return generateAndDownloadWord(
          parsedData,
          companyId,
          `Testing-${documentAnswers?.documentName || ""}`
        );
      } else
        setAppError(
          "Aun no se agrego el documento",
          "Primero debe crear un documento para poder generarlo"
        );
      return;
    }

    try {
      setLoading(true);
      if (!companyId) {
        return;
      }
      if (companyId === "ERROR") {
        getCompany();
        return;
      }
      window.scrollTo(0, 0);
      const data2 = localStorage.getItem("generator-form-document");
      if (data2) {
        const file = await generateAndDownloadWord(
          JSON.parse(data2),
          companyId,
          documentAnswers?.documentName || "Documento Andes Docs"
        );
        await saveDocument(
          file,
          documentAnswers?.documentName || "Documento Andes Docs"
        );
      } else {
        setAppError(
          "No hay documento para generar",
          "Deberá crear un documento para descargarlo"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box width={"100%"}>
      <Stack minHeight="100vh" alignItems="center" className="b-tertiary" p={4}>
        <Stack
          direction="row"
          width="100%"
          textAlign="left"
          maxWidth={1000}
          my={5}
          pl={2}
          fontWeight="Bold"
          fontSize={23}
          alignItems="center"
        >
          <FontAwesomeIcon
            icon={faPlayCircle}
            style={{
              color: colorPallette.primary,
              marginRight: 10,
            }}
          />
          <Stack sx={{ transform: "translateY(-8px)" }}>
            <Box fontWeight={400} fontSize={14} mb={"-2px"}>
              Sección Final:
            </Box>
            Revisa tus respuestas
          </Stack>
        </Stack>
        <Stack
          bgcolor="white"
          width={"100%"}
          maxWidth="1000px"
          textAlign="left"
          mb={5}
          sx={{
            boxShadow: "0px 27px 26px -25px rgba(0, 0, 0, 0.13)",
            borderRadius: "4px",
          }}
          py={2}
          px={"18px"}
        >
          <Box fontWeight="bold" color={theme.palette.primary.main} mb={2}>
            Respuestas:
          </Box>
          {documentQuestions
            .filter((question) =>
              Object.keys(documentAnswers).includes(String(question.id))
            )
            // .filter((question) => checkDependencies(question, getValues))
            .map((question, index) => (
              <Stack
                pb={1}
                my={1}
                sx={{ borderBottom: "solid 1px #e8e8e8" }}
                key={question.id}
              >
                <Box fontWeight="bold">
                  <FontAwesomeIcon
                    icon={faPlay}
                    color={theme.palette.primary.main}
                    fontSize={12}
                    style={{ marginRight: 2, transform: "translateY(-1px)" }}
                  />{" "}
                  {question.question}
                </Box>
                <Box mb={"2px"} mt={1} pl={2}>
                  {documentAnswers?.[question.id] === "______________" ? (
                    <Box color={"#e8e8e8"}>SIN RESPONDER</Box>
                  ) : (
                    processAnswer(
                      documentAnswers?.[question.id] || "SIN RESPONDER"
                    )
                  )}
                </Box>
              </Stack>
            ))}
        </Stack>
        <Stack
          direction="row"
          width="100%"
          maxWidth="1000px"
          justifyContent="space-between"
          my={2}
          mb={"200px"}
        >
          <Button
            onClick={() => {
              onPrevious();
            }}
            sx={{
              width: 250,
              mr: 2,
              backgroundColor: colorPallette.lightRed,
            }}
            variant="contained"
          >
            <Box component="span" mr={1}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Box>
            Anterior
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={!companyId}
            onClick={onSubmit}
          >
            Generar Documento
            <Box component="span" ml={1}>
              <FontAwesomeIcon icon={faBolt} />
            </Box>
          </LoadingButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ReviewSection;
