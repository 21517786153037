import React from "react";
import Text from "../../../../../../uiComponents/Text";
import XsButton from "../../../../../../uiComponents/XsButton/XsButton";

import "./styles.css";

const IntroMobile = ({ data, currentSection, setCurrentSection, setHelp }) => {
  return (
    <div className="intro-mobile">
      <div className="main-title-div">
        <Text size="medium" align="left" className="main-title black">
          <i className="fas fa-play main-title-icon"></i>
          {/* <Image name="docicon.png" className="bottom-section-icon" /> */}
          {data.name}
        </Text>
      </div>
      <div className="intro-mobile-after-title">
        <div>
          <Text
            roboto
            size="xmedium"
            className="fourth margin-b"
            align="center"
          >
            {data.question}
          </Text>
          {data.definition.map((e) => (
            <p className="margin-v secondary f-1">{e}</p>
          ))}
          <XsButton
            label={`Comenzar!`}
            color="green"
            onClick={() => setCurrentSection(currentSection + 1)}
            shadow={false}
            className="center margin-v-l"
          />
        </div>
        {data.video && (
          <div>
            <Text
              roboto
              size="xmedium"
              className="fourth margin-b"
              align="center"
            >
              Mira el video explicativo
            </Text>
            <p className="margin-v secondary f-1">
              Despeja todas tus dudas sobre este contrato con en el video
              explicativo de nuestros abogados! Si dudás si este es el contrato
              que necesitás hacé click y enterate.
            </p>
            <XsButton
              label={`Video!`}
              color="secondary"
              onClick={() => {
                window.open(
                  "https://www.youtube.com/channel/UC5OED6ncslubtyhp4r0N_1w"
                );
              }}
              shadow={false}
              className="center margin-v-l"
            />
          </div>
        )}
      </div>
      <Text roboto size="xmedium" className="fourth margin-b" align="center">
        Casos de uso:
      </Text>

      {data.useCases &&
        data.useCases.good.map((e) => {
          return (
            <div className="mobile-use-case">
              <i className="fas fa-check-circle green2 use-case-icon-mobile"></i>
              {e}
            </div>
          );
        })}
    </div>
  );
};

export default IntroMobile;
