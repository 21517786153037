import { Box } from "@mui/material";
import React from "react";
import { keyframes } from "@mui/system";
import { colorPallette } from "../../Common/theme";

type Props = {
  onClick: () => void;
  icon: any;
  label: string;
};

const SvgButton: React.FC<Props> = ({ onClick, icon, label }) => {
  const slideIn = keyframes`
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(-110%);
      opacity: 1;
    }
  `;

  const labelLeft = {
    pointerEvents: "none",
    position: "absolute",
    transform: "translateX(-110%)",
    top: 4,
    fontSize: 14,
    width: "fit-content",
    whiteSpace: "nowrap",
    display: "none",
    animation: `${slideIn} 0.4s ease-out`,
    color: colorPallette.primary,
  };
  return (
    <Box
      component="button"
      sx={{
        border: "none",
        background: "none",
        cursor: "pointer",
        position: "relative",
        px: 1,
        ":hover img": { transform: "scale(0.9)" },
        ":hover div": { display: "block" },
      }}
      onClick={onClick}
    >
      <Box sx={labelLeft}>{label}</Box>
      <img
        style={{
          width: "30px",
          height: "30px",
          cursor: "pointer",
          right: "10px",
          top: "10px",
          transition: "all 0.15s",
        }}
        src={icon}
        alt="chat icon"
        title="Icono de chat"
      />
    </Box>
  );
};

export default SvgButton;
