import Text from "../../../../uiComponents/Text";
import QuestionsSection from "./components/QuestionsSection";
import "./styles.css";
import Introduction from "./Introduction";
import CheckSection from "./CheckSection";

import XsButton from "../../../../uiComponents/XsButton/XsButton";

const Main = ({
  data,
  currentSection,
  setCurrentSection,
  resetDocumentToStart,
}) => {
  const questionsSections =
    currentSection > 0 && currentSection < data.sections.length + 1;
  const checkSection = currentSection > data.sections.length;
  return (
    <div className="main-section-wrapper">
      <div className="main-section">
        {!currentSection && (
          <Introduction
            data={data}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
          />
        )}
        {checkSection && (
          <CheckSection
            data={data}
            setCurrentSection={setCurrentSection}
            resetDocumentToStart={resetDocumentToStart}
          />
        )}
        {questionsSections && (
          <div className="main-section-questions">
            <div className="main-title-div">
              <Text size="medium" align="left" className="main-title">
                <i className="fas fa-play main-title-icon primary"></i>
                {data.name}
              </Text>
              <div className="flex main-section-help-div">
                <XsButton
                  label="Reiniciar"
                  color="red"
                  onClick={() => {
                    resetDocumentToStart();
                    window.location.reload();
                  }}
                  className="main-section-mobile-hide"
                />
              </div>
            </div>
            <div className="main-subtitle-div">
              <div className="flex-align main-subtitle-text">
                <div className="main-section-circle">
                  <Text size="large">{currentSection} </Text>
                </div>
                <Text align="left" className="main-subtitle" blue>
                  {data.sections[currentSection - 1].title}
                </Text>
              </div>
              {/* <DocumentSaver className="main-saver" /> */}
            </div>
            <div className="main-sections-questions-div">
              <QuestionsSection
                data={data}
                currentSection={currentSection}
                setCurrentSection={setCurrentSection}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
