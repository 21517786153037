import {
  setDocuments,
  setElectronicSignatures,
} from "../redux/slices/masterData";
import store from "../redux/store";
import { APIPostWithError } from "../Services/authenticated";
import { setAppError, setLoading } from "./modalMethods";

// redux methods

export const deleteReduxDocument = (document: any) => {
  const id = document.documentId;

  const getDocuments = store.getState().masterData.documents;
  if (getDocuments) {
    let prevDocuments: any = [...getDocuments];
    const docToDeleteIndex = prevDocuments.findIndex(
      (doc: any) => doc.documentId === id
    );
    if (docToDeleteIndex !== -1) {
      prevDocuments.splice(docToDeleteIndex, 1);
      store.dispatch(setDocuments([...prevDocuments]));
    }
  }
};

export const editReduxDocument = (document: any) => {
  const id = document.documentId;

  const getDocuments = store.getState().masterData.documents;
  if (getDocuments) {
    let prevDocuments: any = [...getDocuments];
    const docToEdit = prevDocuments.findIndex(
      (doc: any) => doc.documentId === id
    );
    if (docToEdit !== -1) {
      const newObject = {
        ...document,
      };
      prevDocuments[docToEdit] = newObject;
      store.dispatch(setDocuments([...prevDocuments]));
    }
  }
};

export const addNewReduxDocument = (document: any) => {
  const getDocuments = store.getState().masterData.documents;
  if (getDocuments) {
    let newDocuments: any = [...getDocuments, document];
    store.dispatch(setDocuments([...newDocuments]));
  }
};

export const editReduxDocumentAlarm = (document: any, date: string) => {
  const id = document.documentId;

  const getDocuments = store.getState().masterData.documents;
  if (getDocuments) {
    let prevDocuments: any = [...getDocuments];
    const docToEditAlarmIndex = prevDocuments.findIndex(
      (doc: any) => doc.documentId === id
    );
    if (docToEditAlarmIndex !== -1) {
      const newObject = {
        ...prevDocuments[docToEditAlarmIndex],
        expirationDate: date,
        isExpirationHandled: false,
      };
      prevDocuments[docToEditAlarmIndex] = newObject;
      store.dispatch(setDocuments([...prevDocuments]));
    }
  }
};

export const deleteReduxDocumentAlarm = (document: any) => {
  const id = document.documentId;

  const getDocuments = store.getState().masterData.documents;
  if (getDocuments) {
    let prevDocuments: any = [...getDocuments];
    const docToDeleteAlarmIndex = prevDocuments.findIndex(
      (doc: any) => doc.documentId === id
    );
    if (docToDeleteAlarmIndex !== -1) {
      const newObject = {
        ...prevDocuments[docToDeleteAlarmIndex],
        expirationDate: null,
      };
      prevDocuments[docToDeleteAlarmIndex] = newObject;
      store.dispatch(setDocuments([...prevDocuments]));
    }
  }
};

export const markReduxDocumentAlarmAsHandled = (document: any) => {
  const id = document.documentId;

  const getDocuments = store.getState().masterData.documents;
  if (getDocuments) {
    let prevDocuments: any = [...getDocuments];
    const docToHandleAlarmIndex = prevDocuments.findIndex(
      (doc: any) => doc.documentId === id
    );
    if (docToHandleAlarmIndex !== -1) {
      const newObject = {
        ...prevDocuments[docToHandleAlarmIndex],
        isExpirationHandled: true,
      };
      prevDocuments[docToHandleAlarmIndex] = newObject;
      store.dispatch(setDocuments([...prevDocuments]));
    }
  }
};

export const deleteReduxSignature = (signature: any) => {
  const id = signature.signatureId;

  const getSignatures = store.getState().masterData.electronicSignatures;
  if (getSignatures) {
    let prevSignatures: any[] = [...getSignatures];
    const sigToDeleteIndex = prevSignatures.findIndex(
      (sig: any) => sig.signatureId === id
    );
    if (sigToDeleteIndex !== -1) {
      prevSignatures.splice(sigToDeleteIndex, 1);
      store.dispatch(setElectronicSignatures([...prevSignatures]));
    }
  }
};

// server methods

export const editDocument = async (
  document: any,
  accessToken: any,
  callback?: (document: any) => void
) => {
  try {
    setLoading(true);
    const res = await APIPostWithError(
      "/doc-ref/edit-document",
      accessToken,
      document
    );
    if (res && callback) {
      callback(document);
      return true;
    }
  } catch (error) {
    setAppError();
    return false;
  } finally {
    setLoading(false);
  }
};

export const deleteDocument = async (document: any, accessToken: any) => {
  setLoading(true);
  try {
    const deleteDocument = await APIPostWithError(
      "/doc-ref/delete-document",
      accessToken,
      {
        ...document,
      }
    );
    if (deleteDocument.documentDeleted) {
      deleteReduxDocument(document);
    }
  } catch (e) {
    setAppError();
  } finally {
    setLoading(false);
  }
};

export const deleteDBSignature = async (signature: any, accessToken: any) => {
  setLoading(true);
  try {
    const deleteSignature = await APIPostWithError(
      "/electronic-signature/delete-signature",
      accessToken,
      { token: signature.documentToken }
    );
    if (deleteSignature.signatureDeleted) {
      deleteReduxSignature(signature);
    }
  } catch (e) {
    setAppError();
  } finally {
    setLoading(false);
  }
};
