import { useState } from "react";
import { Box, Stack } from "@mui/material";
import {
  faCloudArrowUp,
  faComments,
  faFileWord,
  faPaperPlane,
  faSave,
  faTools,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { colorPallette } from "../../Common/theme";
import ConfirmationModal from "../../uiComponents/Modal/ConfirmationModal/ConfirmationModal";
import { deleteAndRestart } from "../../Utils/DocumentGeneratorMethods";
import { ClickableIcon } from "../../uiComponents/ClickableIcon/ClickableIcon";

type Props = {
  onSave: (callback?: any) => void;
};

const InternalGeneratorTaskBar: React.FC<Props> = ({ onSave }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const actionsLeft = [
    {
      icon: faComments,
      onClick: () => {
        window.open("/internal-generator", "_blank");
      },
      isSelected: window.location.href.includes("/internal-generator"),
      label: "Editor de Preguntas",
      // style: { color: colorPallette.primary },
    },
    {
      icon: faPaperPlane,
      onClick: () => {
        window.open("/generator-form/testing", "_blank");
      },
      label: "Probar formulario",
      // style: { color: colorPallette.primary },
    },
    {
      icon: faFileWord,
      onClick: () => {
        window.open("/internal-document-generator", "_blank");
      },
      label: "Editor de Documento",
      isSelected: window.location.href.includes("/internal-document-generator"),
      // style: { color: colorPallette.primary },
    },
    {
      icon: faTrash,
      onClick: () => {
        setShowDeleteModal(true);
      },
      color: colorPallette.primary,
      label: "Eliminar y Reiniciar",
    },
  ];

  const actionsRight = [
    {
      icon: faSave,
      onClick: () => {
        onSave();
      },
      label: "Guardar progreso actual",
      // style: { color: colorPallette.primary },
    },
    {
      icon: faCloudArrowUp,
      onClick: () => {
        onSave(() => {
          window.open("/backoffice-company-documnent-uploader", "_blank");
        });
      },
      label: "Cargar documento a la nube",
      // style: { color: colorPallette.primary },
    },
    {
      icon: faTools,
      onClick: () => {
        onSave(() => {
          window.open("/backoffice-company-documnent-updater", "_blank");
        });
      },
      label: "Actualizar document en la nube",
      // style: { color: colorPallette.primary },
    },
  ];

  return (
    <Box sx={{ zIndex: 100 }}>
      <Stack
        sx={{
          // boxShadow: "0px -2px 40px 2px rgba(0, 0, 0, 0.03)",
          // background: "rgba(0, 0, 0, 0.01)",
          // backdropFilter: "blur(6px)",
          "&:hover #taskbar": {
            bgcolor: "white",
            transform: "translateY(0)",
          },
        }}
        width="100vw"
        position="fixed"
        bottom={0}
        py={2}
        justifyContent="center"
        direction="row"
      >
        <Stack
          sx={{
            boxShadow: "0px -2px 12px 2px rgba(0, 0, 0, 0.04)",
            border: "2px solid rgba(0, 0, 0, 0.03)",
            bgcolor: "white",
            backdropFilter: "blur(6px)",
            transition: "all 0.1s ease-in-out",
            transform: "translateY(50px)",
            "&:hover": {
              bgcolor: "white",
            },
          }}
          justifyContent="space-between"
          width={"900px"}
          maxWidth="80%"
          flexDirection="row"
          alignItems="center"
          borderRadius={2}
          px={2}
          py={1}
          id="taskbar"
        >
          <Stack direction="row">
            {actionsLeft.map((action) => (
              <ClickableIcon
                key={action.label}
                icon={action.icon}
                onClick={action.onClick}
                color={action.color}
                isSelected={action.isSelected}
                label={action.label}
              />
            ))}
          </Stack>
          <Stack direction="row">
            {actionsRight.map((action) => (
              <ClickableIcon
                key={action.label}
                icon={action.icon}
                onClick={action.onClick}
                label={action.label}
                // style={action.style}
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      {showDeleteModal && (
        <ConfirmationModal
          message={"¿Está seguro que desea borrar y reiniciar?"}
          subMessage={
            "Se borrará tanto el formulario como el documento que hayas generado hasta el momento."
          }
          onClose={() => setShowDeleteModal(false)}
          onConfirm={deleteAndRestart}
        />
      )}
    </Box>
  );
};

export default InternalGeneratorTaskBar;
