import React, { useEffect } from "react";
import Loader from "react-loader-spinner";

import "./styles.css";

const LoadingModal = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  return (
    <div className="loading-modal-background">
      <div className="loading-modal-background-children">
        <div className="a-center" style={{ marginTop: 120, marginBottom: 120 }}>
          <Loader
            type="Bars"
            color="#e8eeff"
            height={90}
            width={90}
            className="margin-v-l"
          />
          <span className="f-medium primary margin-v">Cargando ...</span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { data: state.modal.errorModal };
};

export default LoadingModal;
