export const text = "text";
export const bold = "bold";
export const title = "title";
export const bullet = "bullet";
export const subtitle = "subtitle";
export const firma = "firma";
export const number = "number";
export const person = "person";
export const pathQuestion = "pathQuestion";
export const textArray = "textArray";
export const boldRight = "boldRight";
