import { Box } from "@mui/material";
import React from "react";
import InputForm from "../../uiComponents/Form/FormInput";
import styles from "./ParagraphGeneratorForm.module.css";
import FormSelect from "../../uiComponents/Form/FormSelect";
import { dependencyOptions } from "../../Types/Question.types";
import DeleteIcon from "../../uiComponents/DeleteIcon/DeleteIcon";

type Props = {
  id: number;
  control: any;
  remove: any;
};

const DocumentDependencyForm: React.FC<Props> = ({ control, id, remove }) => {
  return (
    <Box
      flexDirection={"column"}
      py={3}
      px={3}
      my={4}
      sx={{ backgroundColor: "#554f9e" }}
      borderRadius={3}
      display="flex"
      width={"97%"}
      color="white"
    >
      <Box className={styles.text} mb={4}>
        <i
          className="fas fa-chevron-circle-right primary"
          style={{ marginRight: 10 }}
        ></i>
        Dependencia {id + 1}
      </Box>
      <InputForm
        control={control}
        name={`dependencies.${id}.id`}
        label="¿De qué pregunta depende?"
        required
        info={["Escribir el ID de la pregunta", "EJ: nombreInquilino"]}
        rules={{
          validate: (value: string) =>
            !String(value).includes(" ") || "No se permiten espacios en blanco",
        }}
      />
      <FormSelect
        options={dependencyOptions}
        control={control}
        name={`dependencies.${id}.type`}
        label="¿Cuál es la condición de la respuesta para que el texto sea visible?"
        required
      />{" "}
      <InputForm
        control={control}
        name={`dependencies.${id}.values`}
        label="¿Cuáles deben ser los valores de la respuesta? (si son mas de uno separarlos con punto y coma)"
        required
        info={[
          "Escribir los valores de la respuesta",
          "EJ: 'pedro;pablo;juan",
          "Si eligió 'Debe ser exactamente el siguiente valor' solo se tomará el primer valor",
        ]}
        maxLength={1000}
      />
      {/* <Button
        onClick={() => {
          remove(id);
        }}
        color="error"
        variant="contained"
        sx={{ mt: 2, alignSelf: "end" }}
      >
        Eliminar
      </Button> */}
      <DeleteIcon
        label="Eliminar"
        sx={{ alignSelf: "end", mt: 2 }}
        onClick={() => {
          remove(id);
        }}
      />
    </Box>
  );
};

export default DocumentDependencyForm;
