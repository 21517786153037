import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  Paragraph,
  TextRun,
} from "docx";

import guimatLogo from "../../Assets/CompanyLogos/guimat-logo.png";
import guimatIso from "../../Assets/CompanyLogos/guimat-iso.png";

export const styles_guimat = {
  companyId: ["12404"],
  companyName: "Guimat",

  font: "Arial",
  titleSize: 20,
  textSize: 20,
  lineSpacing: 280,
  marginRight: 780,
  marginLeft: 780,
  beforeParagraph: 160,
  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 550,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new ImageRun({
            data: guimatLogo,
            transformation: {
              width: 230,
              height: 97.26,
            },
          }),
        ],
      }),
    ],
  }),
  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "GUIMAT PROPIEDADES   –   Junín 1144 (1113) CABA   –   www.guimat.com",
            size: 15,
            font: "Arial",
            color: "#e21b1a",
            bold: true,
          }),
        ],
      }),
      new Paragraph({
        spacing: {
          before: 60,
          after: 260,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "CUCICBA Mat 197 / 6933  –  CMCPSI Mat 7060  –  Tel: 4821-1010   –   e-mail: info@guimat.com",
            size: 15,
            font: "Arial",
            color: "#e21b1a",
            bold: true,
          }),
        ],
      }),
      new Paragraph({
        spacing: {
          before: 0,
          after: 0,
        },
        alignment: AlignmentType.RIGHT,
        children: [
          new ImageRun({
            data: guimatIso,
            transformation: {
              width: 35,
              height: 35,
            },
          }),
        ],
      }),
    ],
  }),
};
