import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import LogInButton from "../../Authentication/LogIn";
import LogOutButton from "../../Authentication/LogOut";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import TableUser2 from "../../uiComponents/AndesTable/TableEntries/TableUser2.component";
import MenuDrawer from "../MenuDrawer/MenuDrawer";
import { colorPallette, gradients } from "../../Common/theme";
import CompanyChanger from "./CompanyChanger";

import "./Navbar.css";

export default function Navbar() {
  const history = useHistory();
  const [photo, setPhoto] = useState<any>(
    "https://andes-photos.s3.us-east-2.amazonaws.com/Avatar+H1.png"
  );

  const reduxUser = useSelector((state: any) => state.masterData.user);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { user, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (localStorage.getItem("currentUserPhoto")) {
      setPhoto(localStorage.getItem("currentUserPhoto"));
    } else if (isAuthenticated && reduxUser && reduxUser.photoUrl) {
      setPhoto(reduxUser.photoUrl);
    } else {
      setPhoto(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxUser]);

  function handleDrawer() {
    setDrawerOpen((prev) => !prev);
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".animated-box");
    hiddenElements.forEach((el) => observer.observe(el));
  }, [isAuthenticated]);

  const darkRoutes = ["/", "/contacto", "/suscripcion", "/productTour"];

  return (
    <div
      className="header b-white"
      id="header"
      style={
        darkRoutes.includes(window?.location?.pathname)
          ? {
              background: gradients.dark,
              boxShadow: "none",
            }
          : {}
      }
    >
      <div className="navbar-dual-box">
        <img
          src={process.env.PUBLIC_URL + "/images/andesdocs.png"}
          alt="logo-word"
          title="Logo Andes"
          className={`logo-word ${
            window.location.pathname === "/" ? "hidden" : ""
          }`}
          onClick={() => history.push("/")}
        ></img>

        {isAuthenticated &&
          localStorage.getItem("currentCompanyLogo") &&
          !darkRoutes.includes(window?.location?.pathname) && (
            <div className={`navbar-company-divide-wrapper animated-box`}>
              <div className="navbar-company-divide b-primary" />
              <div className="navbar-company-divide-2" />
            </div>
          )}
        {isAuthenticated &&
          localStorage.getItem("currentCompanyLogo") &&
          !darkRoutes.includes(window?.location?.pathname) && (
            <img
              src={
                localStorage.getItem("currentCompanyLogo") ||
                "default-image-url.png"
              }
              alt="logo-word"
              title="Logo Andes 2"
              className="navbar-logo-company animated-box"
              onClick={() => history.push("/")}
            ></img>
          )}
      </div>
      {isAuthenticated &&
        user &&
        reduxUser &&
        reduxUser.internalUser &&
        !darkRoutes.includes(window?.location?.pathname) && <CompanyChanger />}
      <div className="navbar">
        {!isAuthenticated && (
          <>
            <div
              onClick={() => history.push("/contacto")}
              className="nav-element"
            >
              Contacto
            </div>
            <div
              onClick={() => history.push("/suscripcion")}
              className="nav-element"
            >
              Planes
            </div>
            <LogInButton
              className="nav-element-login mobile-disapear"
              label="Registrarse"
              style={{
                backgroundColor: colorPallette.dark,
                color: colorPallette.white,
              }}
            />
            <LogInButton
              className="nav-element-login b-primary mobile-disapear"
              label="Iniciar Sesión"
            />
          </>
        )}

        {isAuthenticated && (
          <>
            <div
              className="nav-element"
              onClick={() => history.push("/dashboard/finished")}
            >
              Dashboard
            </div>
            <div
              onClick={() => {
                if (!isAuthenticated) {
                  history.push("/document-selector");
                } else {
                  history.push("/dashboard/documents");
                }
              }}
              className="nav-element"
            >
              Documentos
            </div>
            <LogOutButton className="nav-element-login b-primary mobile-disapear b-primary-hover" />
            <div
              onClick={() => history.push("/dashboard/profile")}
              className="mobile-disapear"
            >
              {/* <img src={photo} className="nav-user" alt="" /> */}

              <div className="nav-user">
                <TableUser2
                  hideName
                  photo={photo}
                  name={localStorage.getItem("currentUserName") || "A D"}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <MenuDrawer
        isOpen={drawerOpen}
        toggleDrawer={handleDrawer}
        isAuthenticated={isAuthenticated}
      />

      <i onClick={handleDrawer} className="fas fa-bars"></i>
    </div>
  );
}
