import {
  DETAIL_SETTER,
  DETAIL_RESETTER,
  DETAIL_FULL_SETTER,
} from './fileDetail.types';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DETAIL_SETTER:
      return {
        ...state,
        ...action.payload,
      };
    case DETAIL_RESETTER:
      return {};
    case DETAIL_FULL_SETTER:
      return { ...action.payload };
    default:
      return state;
  }
};
export default reducer;
