/* eslint no-template-curly-in-string: 0 */

import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  question,
  textArray,
} from "./types.js";

export const ContratoDeTrabajoAPlazoFijoDoc = [
  [
    {
      type: title,
      text: "CONTRATO DE TRABAJO A PLAZO FIJO",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: false,
    },
    {
      type: bold,
      text: "Contrato de Trabajo a Plazo Fijo ",
      depend: false,
      notBe: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text:
        "(en adelante, el “Contrato”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: bold,
      text: "Contrato de Trabajo a Plazo Fijo ",
      depend: false,
    },
    {
      type: text,
      text:
        '(en adelante, el "Contrato") se celebra en la ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:',
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Empleadora",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[4].answer}, ${answers.section2[5].answer} N° ${answers.section2[6].answer}, con domicilio sito en ${answers.section2[7].answer}, ${answers.section2[8].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Empleadora",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[9].answer}, ${answers.section2[10].answer} N° ${answers.section2[11].answer}, con domicilio sito en ${answers.section2[12].answer}, ${answers.section2[13].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Trabajadora",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[14].answer}, ${answers.section3[15].answer} N° ${answers.section3[16].answer}, ${answers.section3[17].answer} N° ${answers.section3[18].answer}, con domicilio sito en ${answers.section3[19].answer}, ${answers.section3[20].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Empleadora y la Trabajadora podrán ser denominadas conjuntamente como las “Partes” e individualmente como la “Parte”.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes acuerdan que el presente Contrato se regirá por los siguientes términos y condiciones:",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA PRIMERA: Definiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "“CCT”: significa el convenio colectivo de trabajo N° ${answers.section4[24].answer} junto con sus complementos y modificaciones.",
      depend: true,
      question: {
        section: "section4",
        number: 23,
        answer: "Sí, aplica un Convenio Colectivo",
      },
    },
  ],
  [
    {
      type: text,
      text:
        '"Domicilio": la respectiva dirección de cada Parte indicada en el encabezamiento del presente Contrato o aquella otra dirección en la República Argentina que la respectiva Parte informare a la otra Parte de conformidad a la Cláusula Sexta.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Tareas": significan las siguientes tareas: ${answers.section4[21].answer}.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Jornada Laboral": significa el período de tiempo que se detalla a continuación: ${answers.section6[34].answer}.',
      depend: true,
      question: {
        section: "section6",
        number: 33,
        answer: "Variable, con días y horarios distintos",
      },
    },
  ],
  [
    {
      type: text,
      text:
        '"Jornada Laboral": significa el período de tiempo entre las ${answers.section6[36].answer} horas y las ${answers.section6[37].answer} horas los días ',
      depend: true,
      question: {
        section: "section6",
        number: 33,
        answer: "Fijo, con días y horarios iguales",
      },
    },
    {
      type: textArray,
      text: "{{array}}. ",
      question: { section: "section6", number: 35 },
      stopRemoval: true,
    },
    {
      type: text,
      text:
        "La Jornada Laboral contempla una pausa entre las ${answers.section6[39].answer} horas y las ${answers.section6[40].answer} horas, los días indicados.",
      depend: true,
      question: {
        section: "section6",
        number: 38,
        answer: "Sí, habrá una pausa",
      },
      stopRemoval: true,
    },
  ],
  [
    {
      type: text,
      text:
        "“Vigencia”: se le asigna el significado definido en la Cláusula Tercera.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "“Ley de Contrato de Trabajo”: Ley Nacional N° 20.744 con su reglamentación y sus modificaciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "“Laudo”: significa el laudo N° ${answers.section4[25].answer} junto con sus complementos y modificaciones.",
      depend: true,
      question: {
        section: "section4",
        number: 23,
        answer: "Sí, aplica un Laudo",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "“Remuneración Bruta”: es la suma de ${answers.section4[27].answer} brutos por ${answers.section4[26].answer}. La Remuneración Bruta es previo a cualquier tipo de descuento legal aplicable.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEGUNDA: Objeto.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Empleadora contrata a la Trabajadora para realizar las Tareas en la Jornada Laboral durante la Vigencia del presente Contrato y de conformidad con la Ley de Contrato de Trabajo.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Tareas a realizar por la Trabajadora durante la Jornada Laboral deberán desarrollarse en el siguiente domicilio: ${answers.section4[22].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA TERCERA: Vigencia.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La vigencia del presente Contrato se extenderá por ${answers.section5[30].answer} comenzando el ${answers.section1[3].answer}(en adelante, la “Vigencia”).",
      depend: true,
      question: {
        section: "section5",
        number: 31,
        answer: "Misma fecha que se firma el Contrato",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "La vigencia del presente Contrato se extenderá por ${answers.section5[30].answer} comenzando el ${answers.section5[32].answer}(en adelante, la “Vigencia”).",
      depend: true,
      question: {
        section: "section5",
        number: 31,
        answer: "Otra posterior",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA CUARTA: Remuneración.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Empleadora deberá pagar a la Trabajadora, en su cuenta sueldo, la Remuneración Bruta establecida en el presente Contrato dentro del plazo de ley.",
      depend: true,
      question: {
        section: "section4",
        number: 28,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "La Empleadora deberá pagar a la Trabajadora la Remuneración Bruta establecida en el presente Contrato dentro del plazo de ley.",
      depend: true,
      question: {
        section: "section4",
        number: 28,
        answer: "No",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA: Deberes. ",
      depend: false,
    },
    {
      type: bold,
      text: "Preaviso.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 29,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "La Trabajadora se compromete expresamente a cumplir con los reglamentos internos de  la  Empleadora, las  normas sobre  asistencia y puntualidad y el correcto trato hacia superiores y compañeros de tareas. Expresamente se considerará falta grave la divulgación de secretos comerciales y/o industriales y/o toda violación al deber de fidelidad que tiene con la Empleadora.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En el caso que la Trabajadora no cumpliera adecuadamente sus obligaciones y/o lo establecido en el párrafo anterior y/o incurriera en faltas, actitudes o conductas perjudiciales a la Empleadora de cualquier índole, esta podrá rescindir el presente Contrato con justa causa, antes de la fecha estipulada en la Vigencia sin devengar ninguna obligación indemnizatoria.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Si la Empleadora decidiera extinguir el presente Contrato deberá notificar fehacientemente por escrito su decisión a la Trabajadora, en su Domicilio, con un preaviso no menor de un (1) mes ni mayor a dos (2) meses.",
      depend: true,
      question: {
        section: "section5",
        number: 29,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA BIS: Convenio Colectivo de Trabajo.",
      depend: true,
      question: {
        section: "section4",
        number: 23,
        answer: "Sí, aplica un Convenio Colectivo",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "La Empleadora y la Trabajadora establecen que el CCT es aplicable a la contratación formalizada en el presente Contrato.",
      depend: true,
      question: {
        section: "section4",
        number: 23,
        answer: "Sí, aplica un Convenio Colectivo",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA BIS: Laudo.",
      depend: true,
      question: {
        section: "section4",
        number: 23,
        answer: "Sí, aplica un Laudo",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "La Empleadora y la Trabajadora establecen que el Laudo es aplicable a la contratación formalizada en el presente Contrato.",
      depend: true,
      question: {
        section: "section4",
        number: 23,
        answer: "Sí, aplica un Laudo",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEXTA: Domicilios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Cada Parte constituye domicilio en su Domicilio exclusivamente a los fines del presente Contrato. La Parte que cambiare su Domicilio informará la nueva dirección, que deberá encontrarse en la República Argentina, a la otra Parte mediante una notificación fehaciente dirigida al Domicilio de esta última.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las notificaciones que se realicen entre las Partes, así como otras comunicaciones y notificaciones prejudiciales y/o judiciales derivadas de este Contrato, se tendrán por válidamente cursadas al Domicilio de la Parte destinataria hasta que la Parte remitente sea informada del nuevo Domicilio de la Parte destinataria mediante una notificación fehaciente dirigida al Domicilio de la Parte remitente.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA: Interpretación.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Títulos. Los títulos temáticos utilizados en este Contrato se incluyen para fines de conveniencia únicamente y no serán interpretados de forma que se les asigne importancia significativa o se los considere indicativos de que todas las disposiciones de este Contrato referidas a un tema en particular se encuentran en una única Cláusula;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Singular y Plural. La referencia a la forma singular incluye la referencia a la forma plural y viceversa;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Género. La referencia a uno de los géneros incluye la referencia a todos los demás géneros;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "Incluye. “Incluye” e “incluyendo” significará que incluyen sin limitar la generalidad de la descripción que precede a dichos términos, y se utilizan en el presente en un sentido ilustrativo y no limitativo;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text:
        'Las expresiones "este Contrato", "el presente Contrato", refieren al presente Contrato y todos los Anexos y todas las modificaciones que tuviere en conjunto;',
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vi)",
      text:
        "Las palabras en mayúscula utilizadas en este Contrato tienen el significado que se les asigna en el mismo;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vii)",
      text:
        "Las referencias a Cláusulas o Anexos utilizadas en este Contrato sin indicar su fuente son a Cláusulas o Anexos de este Contrato exclusivamente; y",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(viii)",
      text:
        "Todos los plazos contractuales se computan en días calendarios corridos salvo cuando en este Contrato expresamente se dispone de otra forma.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA OCTAVA: Ley Aplicable. Tribunales Judiciales Competentes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Este Contrato se regirá e interpretará de conformidad con las leyes de la República Argentina. Las Partes acuerdan que cualquier controversia o reclamo que se derive del presente Acuerdo, será resuelto exclusivamente por los tribunales laborales competentes, por razón de la materia, con asiento en el Domicilio de la Trabajadora, con expresa renuncia a cualquier otro fuero o jurisdicción.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En prueba de conformidad de lo establecido en el presente Contrato, las Partes firman dos (2) ejemplares del mismo tenor y a un solo efecto en el lugar y fecha indicados en el encabezamiento.",
      depend: false,
    },
  ],
];
