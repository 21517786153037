/* eslint-disable no-template-curly-in-string */
import { title, text, boldRight } from "./types.js";

export const ReciboDoc = [
  [
    {
      type: title,
      text: "RECIBO DE PAGO",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: "En ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina, al ${answers.section1[3].answer}, ",
      notBe: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text: "En la ${answers.section1[1].answer}, República Argentina, al ${answers.section1[3].answer}, ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text: "$$^^RECIBÍ^^$$ ",
      depend: false,
    },
    {
      type: text,
      text: "de ${answers.section3[7].answer}, ${answers.section3[8].answer} ${answers.section3[9].answer}, ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section3",
        number: "person",
        answer: "Persona Física",
      },
    },
    {
      type: text,
      text: "de ${answers.section3[71].answer}, ${answers.section3[81].answer} ${answers.section3[91].answer}, ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section3",
        number: "person",
        answer: "Persona Jurídica",
      },
    },
    {
      type: text,
      text: "la suma en efectivo de ${answers.section4[13].answer} ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section4",
        number: 12,
        answer: "Dinero en efectivo",
      },
    },
    {
      type: text,
      text: "un (1) cheque bancario emitido por el ${answers.section4[14].answer}, por una suma equivalente a ${answers.section4[15].answer} y con número de cheque: ${answers.section4[16].answer}, ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section4",
        number: 12,
        answer: "Un (1) Cheque",
      },
    },
    {
      type: text,
      text: "${answers.section4[14].answer} cheques bancarios emitidos por ${answers.section4[14].answer}, por una suma acumulada de ${answers.section4[14].answer}, con los siguentes números de cheques: ${answers.section4[14].answer}, ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section4",
        number: 12,
        answer: "Varios Cheques",
      },
    },
    {
      type: text,
      text: "en concepto de ${answers.section4[20].answer}.",
      stopRemoval: true,
    },
  ],
  [
    {
      type: boldRight,
      text: "Firma, Aclaración y Documento",
      depend: false,
    },
  ],
];
