/* eslint no-template-curly-in-string: 0 */

import { title, text, bold, bullet, subtitle, firma } from "./types.js";

export const ndaDoc = [
  [
    {
      type: title,
      text: "Acuerdo de Confidencialidad",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: false,
    },
    {
      type: bold,
      text: "Acuerdo de Confidencialidad ",
      depend: false,
      notBe: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text:
        "(en adelante, el “Acuerdo”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: bold,
      text: "Acuerdo de Confidencialidad ",
      depend: false,
    },
    {
      type: text,
      text:
        '(en adelante, el "Acuerdo") se celebra en la ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:',
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Parte A",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[4].answer}, ${answers.section2[5].answer} N° ${answers.section2[6].answer}, con domicilio sito en ${answers.section2[7].answer}, ${answers.section2[8].answer} (en adelante la “Parte A”); y",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Parte A",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[9].answer}, ${answers.section2[10].answer} N° ${answers.section2[11].answer}, con domicilio sito en ${answers.section2[12].answer}, ${answers.section2[13].answer} (en adelante la “Parte A”); y",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Parte B",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[14].answer}, ${answers.section3[15].answer} N° ${answers.section3[16].answer}, con domicilio sito en ${answers.section3[17].answer}, ${answers.section3[18].answer} (en adelante, la “Parte B”).",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Parte B",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[19].answer}, ${answers.section3[20].answer} N° ${answers.section3[21].answer}, con domicilio sito en ${answers.section3[22].answer}, ${answers.section3[23].answer} (en adelante, la “Parte B”).",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Parte A y la Parte B podrán ser denominadas conjuntamente como las “Partes”, e individualmente como la “Parte”.  ",
      depend: false,
    },
  ],
  [
    {
      type: subtitle,
      text: "LAS PARTES ACUERDAN:",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA PRIMERA: Definiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Autoridad Pública: significa cualquier órgano judicial, ejecutivo y/o legislativo de carácter nacional, provincial y/o municipal. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "CCyCN: Código Civil y Comercial de la Nación.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Información Confidencial: en general, cualquier dato, conocimiento, ideas, conceptos, planos, cuadros, diagramas, informes, reportes, “know-how”, oportunidades de inversión, técnicas y/o cualquier otro tipo de datos, información y/o documentación, como así también las políticas y/o cualquier otra información vinculada con sus funciones y/o el giro comercial, que cumple con los siguientes requisitos: (i) es propiedad de la Informante que está divulgado a la Informada, (ii) se encuentra contenida y es accesible en soporte papel y/o electrónico y/o digital y/o es expresado oralmente; y (iii) no es de conocimiento público;",
      depend: false,
    },
    {
      type: text,
      text:
        " y en particular, lo detallado en el Anexo A del presente Acuerdo.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section4",
        number: 25,
        answer: "En un Anexo del Contrato",
      },
    },
    {
      type: text,
      text:
        " y en particular, lo que se detalla a continuación: ${answers.section4[26].answer}.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section4",
        number: 25,
        answer: "En el Texto del Contrato",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Multa: en caso de incumplimiento por la Parte Receptora de cualquier término y/o condición establecido en el presente Acuerdo, se establece una multa equivalente a ${answers.section6[30].answer}.",
      depend: true,
      question: { section: "section6", number: 29, answer: "Sí" },
    },
  ],
  [
    {
      type: text,
      text:
        "Parte Receptora: La Parte A respecto de la Información Confidencial recibida de la Parte B y la Parte B respecto de la Información Confidencial recibida de la Parte A.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Parte Divulgadora: La Parte A respecto de la Información Confidencial transmitida a la Parte B y la Parte B respecto de la Información Confidencial transmitida a la Parte A. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Proyecto: significa el siguiente emprendimiento/iniciativa a ser ejecutado con la Información Confidencial: ${answers.section4[24].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Representantes: significa los directores, socios, representantes, empleados, agentes, contratistas, consultores y/o afiliadas de la Parte A o Parte B, según sea el caso.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Vigencia: tiene el significado asignado en la Cláusula Séptima.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEGUNDA: Objeto. Deber de Reserva.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "De conformidad con los términos y condiciones establecidos en el presente Acuerdo, la Parte Divulgadora transmite a la Parte Receptora la Información Confidencial objeto del presente Acuerdo en el marco del Proyecto que une a las Partes. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "La Parte Receptora, durante la Vigencia del Acuerdo, se obliga a:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      text:
        "no divulgar, ni revelar a terceros la Información Confidencial obtenida con motivo de su relación, ya sea intencionalmente o por falta de cuidado adecuado en su manejo, en forma personal, ó bien, a través de sus Representantes;",
      depend: false,
      bullet: "(i)",
    },
  ],
  [
    {
      type: bullet,
      text:
        "manejar la Información Confidencial de la misma manera que maneja la información propia de carácter confidencial, la cual bajo ninguna circunstancia podrá estar por debajo de las estándares aceptables de la debida diligencia y prudencia; y ",
      depend: false,
      bullet: "(ii)",
    },
  ],
  [
    {
      type: bullet,
      text:
        "que con el consentimiento previo y por escrito de la Parte Divulgadora, podrá divulgar la Información Confidencial objeto del presente Acuerdo a terceros, siempre que el tercero involucrado suscriba un acuerdo de confidencialidad en términos y condiciones semejantes a los establecidos por el presente Acuerdo. ",
      depend: false,
      bullet: "(iii)",
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA TERCERA: Excepciones al Deber de Reserva.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Acuerdo no impone a la Parte Receptora ninguna obligación de reserva, según lo establecido en la anterior Cláusula Primera, con respecto a la Información Confidencial que: ",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "era del dominio de la Parte Receptora previamente a ser comunicada por la Parte Divulgadora, según lo demuestren los libros y/o registros preexistentes de la Parte Receptora;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "es, o eventualmente pase a ser, del dominio público, siempre y cuando no sea como consecuencia del incumplimiento del presente Acuerdo o de cualquier obligación de confidencialidad hacia la Parte Divulgadora;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "sea debidamente transmitida por un tercero, en favor de la Parte Receptora, siempre y cuando el tercero no se encuentre sujeto a un deber de confidencialidad con la Parte Divulgadora; o ",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "sea desarrollada independientemente por la Parte Receptora y sin uso de Información Confidencial que haya sido intercambiada en virtud de este Acuerdo.  ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Parte Receptora podrá divulgar Información Confidencial en caso de ser requerido por (i) orden judicial, (ii) a pedido de una Autoridad Pública, y/o (iii) si fuera emplazado en virtud de un procedimiento legal y/o administrativo. Si la ley no lo prohíbe, la Parte Receptora deberá notificar a la Parte Divulgadora, con al menos diez (10) días previos al vencimiento de dicha obligación de divulgación, de la notificación recibida a fin de brindar, a la Parte Divulgadora, la oportunidad de solicitar orden de protección y/u otras medidas.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA CUARTA: Divulgación en Incumplimiento. Responsabilidad.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Si la Parte Receptora divulgaré la Información Confidencial en violación a los términos y condiciones establecidos en el presente Acuerdo, la Parte Receptora deberá:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "poner en conocimiento de la Parte Divulgadora la Información Confidencial que fue divulgada a terceros en violación del presente Acuerdo dentro de las veinticuatro (24) horas de su divulgación indebida;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "En conjunto con la Parte Divulgadora, adoptar todas las medidas necesarias para hacer cesar de inmediato, o mitigar cuando sea imposible lo primero, la divulgación de la Información Confidencial; y",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Cumplir con las medidas necesarias que la Parte Divulgadora le comunicase, para hacer cesar la divulgación de la Información Confidencial. La Parte Divulgadora tendrá total derecho de intervenir en el presente procedimiento.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Una vez configurado la divulgación en incumplimiento por la Parte Receptora establecido en la presente Cláusula, la Parte Divulgadora quedará facultada para avanzar de conformidad con la Cláusula Décima Primera. ",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA QUINTA: Devolución y/o Destrucción de la Información Confidencial.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Parte Receptora se compromete a la devolución y/o destrucción de todo documento o material de cualquier tipo (incluyendo pero no limitado a archivos electrónicos, bases de datos, e-mails, copias, apuntes, reportes, anotaciones, faxes, etc.) que estuviera a su disposición y que contenga la Información Confidencial y/o las copias que de los mismos hubieran obtenido:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "al finalizar la Vigencia del presente Acuerdo;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "al cesar las causas por las cuales le fueron entregados; o ",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "a solicitud de la Parte Divulgadora, una vez vencido el presente Acuerdo, lo que ocurra en primer término.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Parte Divulgadora deberá solicitar la devolución o la destrucción de la Información Confidencial en la primera oportunidad que tuviese de conformidad con el párrafo anterior.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEXTA: Divulgación a Representantes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Información Confidencial podrá ser divulgada a los Representantes de la Parte Receptora que reciba la Información Confidencial, siempre y cuando dicha divulgación sea necesaria para que pueda llevar a cabo el propósito del Proyecto. Por este motivo, las obligaciones asumidas en el presente Acuerdo se hacen extensivas a los Representantes que reciban la Información Confidencial, respondiendo la Parte Receptora por cualquier incumplimiento realizado por aquellos.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA: Vigencia. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Acuerdo se extenderá por ${answers.section5[27].answer} contado/s desde la fecha indicada en el encabezado (en adelante, la “Vigencia”). Las Partes establecen que las obligaciones de confidencialidad establecidas en el presente Acuerdo permanecerán en pleno vigor y efecto por la totalidad de la Vigencia.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA OCTAVA: Extensión de Carácter Confidencial de la Información.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En el supuesto de que previamente a la celebración del presente Acuerdo las Partes hubieran intercambiado información, la misma será considerada a todos los efectos previstos en el presente documento, como Información Confidencial, siempre y cuando se haya notificado por escrito al momento de la entrega que dicha información era confidencial (o la información entregada así lo aclare).",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA NOVENA: Domicilios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes fijan como domicilios especiales los arriba indicados, donde se tendrán por válidas todas las notificaciones que se cursaren con relación al presente Acuerdo.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMA: Prohibición de Cesión.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Ninguna Parte podrá ceder el presente Acuerdo sin el previo consentimiento por escrito de la otra Parte.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: `CLÁUSULA DÉCIMA PRIMERA: Resolución Anticipada. Indemnización.`,
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Parte Divulgadora podrá resolver el presente Acuerdo si la Parte Receptora, incluyendo sus Representantes, incumple o viola cualquiera de los términos y condiciones establecidos en el presente Acuerdo, con especial observancia a la Cláusula Cuarta. La Parte Divulgadora deberá notificar fehacientemente por escrito de la presente resolución al domicilio de la Parte Receptora de conformidad con la Cláusula Novena. Una vez resuelto el Acuerdo, la Parte Divulgadora quedará facultado para solicitar las medidas indemnizatorias, a saber: (i) solicitar a la Parte Receptora indemnización por los daños y perjuicios generados en un todo de acuerdo con el art. 922 del CCyCN; y (ii) exigir a la Parte Receptora que abone la totalidad de la Multa en un plazo no mayor de diez (10) contados desde el incumplimiento de alguno de los términos y/ condiciones establecidos en el presente Acuerdo; todo ello sin perjuicio de la eventual responsabilidad penal que pudiera corresponder.",
      depend: true,
      question: {
        section: "section6",
        number: 29,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "La Parte Divulgadora podrá resolver el presente Acuerdo si la Parte Receptora, incluyendo sus Representantes, incumple o viola cualquiera de los términos y condiciones establecidos en el presente Acuerdo, con especial observancia a la Cláusula Cuarta. La Parte Divulgadora deberá notificar fehacientemente por escrito de la presente resolución al domicilio de la Parte Receptora de conformidad con la Cláusula Novena. Una vez resuelto el Acuerdo, la Parte Divulgadora quedará facultado para solicitar, a la Parte Receptora, indemnización por los daños y perjuicios generados en un todo de acuerdo con el art. 922 del CCyCN.",
      depend: true,
      question: {
        section: "section6",
        number: 29,
        answer: "No",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMA SEGUNDA: Gastos.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Los gastos y/o erogaciones en los que las Partes hayan incurrido para celebrar y/o ejecutar los términos del presente Acuerdo, serán soportados exclusivamente por la Parte que haya incurrido en los mismos. ",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMA TERCERA: Disposiciones Finales.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En el caso de que una o más provisiones de este Acuerdo llegasen a perder total o parcialmente su validez, legalidad o su vigencia en cualquier respecto, bajo la ley aplicable, la validez, la legalidad y la vigencia de las restantes provisiones de este Acuerdo no se verán afectadas o anuladas en ningún caso. Cualquier provisión que, total o parcialmente, haya perdido su validez, legalidad o vigencia deberá ser reemplazada por una provisión que se ajuste de la mejor manera al propósito de la provisión reemplazada; lo mismo se aplicará en el caso de una omisión. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En el caso de que una o más provisiones de este Acuerdo llegasen a perder total o parcialmente su validez, legalidad o su vigencia en cualquier respecto, bajo la ley aplicable, la validez, la legalidad y la vigencia de las restantes provisiones de este Acuerdo no se verán afectadas o anuladas en ningún caso. Cualquier provisión que, total o parcialmente, haya perdido su validez, legalidad o vigencia deberá ser reemplazada por una provisión que se ajuste de la mejor manera al propósito de la provisión reemplazada; lo mismo se aplicará en el caso de una omisión. ",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA DÉCIMA CUARTA: Ley Aplicable. Tribunales Judiciales Competentes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Este Acuerdo se regirá e interpretará de conformidad con las leyes de la República Argentina. Las Partes acuerdan que cualquier controversia o reclamo que se derive del presente Acuerdo, será resuelto exclusivamente por los tribunales ordinarios competentes, por razón de la materia, con asiento en ${answers.section5[28].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMA QUINTA: Integridad del Acuerdo.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: `El presente Acuerdo representa el acuerdo completo e íntegro entre las Partes con respecto al objeto del mismo. Las Partes prescinden expresamente de todas las disposiciones contenidas en cualquier otro acuerdo o correspondencia anteriores que nieguen, limiten, extiendan o entren en conflicto con las disposiciones del presente Acuerdo. Ningún término de este Acuerdo será modificado por ninguna declaración anterior o posterior, conducta o acto de cualquier Parte, a menos que las Partes hayan autorizado tal modificación en un documento escrito y ratificado por ambas Partes. Las Partes firman dos (2) ejemplares de un mismo tenor y a un solo efecto.`,
      depend: false,
    },
  ],
];
