import React, { useEffect, useState } from "react";
import "../styles.css";
import numbersToLetters from "../../../Utils/numbersToLetters";
import "./styles.css";

const Percentage = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
  currentAnswerObject,
}) => {
  const [currentFull, setCurrentFull] = useState("");
  const [currentDecimals, setCurrentDecimals] = useState("");

  const answerNormalizer = (value) => {
    let valueNormalized = typeof value === "string" ? value.split("") : [];
    let newValue = valueNormalized.filter((val) => /[\d|]/.test(val)).join("");
    return newValue;
  };

  const addLetters = (num, thisCurrency = null) => {
    const parsedNum = parseFloat(num);
    const letters = numbersToLetters(parsedNum);
    const numberWithComma = num.replace(".", ",");
    let final = `${letters} POR CIENTO (${numberWithComma}%)`;

    return final;
  };

  useEffect(() => {
    if (
      currentAnswerObject &&
      currentAnswerObject.currentFull &&
      currentAnswerObject.currentFull !== ""
    ) {
      setCurrentFull(currentAnswerObject.currentFull);
    }

    if (
      currentAnswerObject &&
      currentAnswerObject.currentDecimals &&
      currentAnswerObject.currentDecimals !== ""
    ) {
      setCurrentDecimals(currentAnswerObject.currentDecimals);
    }
  }, [currentAnswer, currentAnswerObject]);

  return (
    <div className="question-format-form percentage-wrapper">
      <input
        required
        type="text"
        value={answerNormalizer(currentFull)}
        className="question-format-input percentage-input-full"
        placeholder="Numero Entero"
        onChange={(e) => {
          let valueNormalized =
            typeof e.target.value === "string" ? e.target.value.split("") : [];
          let newValue = valueNormalized
            .filter((val) => /[\d]/.test(val))
            .join("");
          if (newValue.length > 9) {
            const oldValue = newValue;
            newValue = currentFull;
          }
          setCurrentFull(newValue);
          if (newValue === "") {
            onChange({
              [sectionId]: {
                ...currentSectionAnswers,
                [question.id]: {
                  question: question.question,
                  answer: null,
                  currentFull: newValue,
                  currentDecimals: currentDecimals,
                },
              },
            });
            return;
          }
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: addLetters(`${newValue}${currentDecimals}`),
                currentFull: newValue,
                currentDecimals: currentDecimals,
              },
            },
          });
        }}
      ></input>
      <input
        className="question-format-input percentage-decimals"
        placeholder="Decimales"
        value={answerNormalizer(currentDecimals)}
        onChange={(e) => {
          let valueNormalized =
            typeof e.target.value === "string" ? e.target.value.split("") : [];
          let newValue = valueNormalized
            .filter((val) => /[\d]/.test(val))
            .join("");
          if (newValue.length > 0) {
            const oldValue = newValue;
            newValue = `.${newValue}`;
          }
          if (newValue.length > 3) {
            const oldValue = newValue;
            newValue = currentDecimals;
          }
          setCurrentDecimals(newValue);
          if (currentFull === "") {
            onChange({
              [sectionId]: {
                ...currentSectionAnswers,
                [question.id]: {
                  question: question.question,
                  answer: null,
                  currentFull: "",
                  currentDecimals: newValue,
                },
              },
            });
            return;
          }
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: addLetters(`${currentFull}${newValue}`),
                currentFull: currentFull,
                currentDecimals: newValue,
              },
            },
          });
        }}
      ></input>
      % (Por Ciento)
    </div>
  );
};

export default Percentage;
