import React, { useEffect, useState } from "react";
import { Question } from "../../Types/Question.types";
import { Box } from "@mui/material";
import InputForm from "../../uiComponents/Form/FormInput";
import FormSelect from "../../uiComponents/Form/FormSelect";
import { checkDependencies } from "../../Utils/FormMethods";
import styles from "./GeneratorForm.module.css";
import FormDate from "../../uiComponents/Form/FormDate";
import FormNumber from "../../uiComponents/Form/FormNumber";
import FormNumberOf from "../../uiComponents/Form/FormNumbeOf";
import FormTextarea from "../../uiComponents/Form/FormTextarea";
import FormMoney from "../../uiComponents/Form/FormMoney";
import FormPercentage from "../../uiComponents/Form/FormPercentage";
import FormMultipleOptions from "../../uiComponents/Form/FormMultipleOptions";
import chatIcon from "../../Assets/svg/chat.svg";
import SvgButton from "../../uiComponents/SvgButton/SvgButton";
import FormLabel from "../../uiComponents/Form/FormLabel";
import AnswerDisplay from "./AnswerDisplay";
import { questionDefaultValues } from "./defaultValues";
import FormServerOptions from "../../uiComponents/Form/FormServerOptions";
import FormCatastro from "../../uiComponents/Form/FormCatastro";

type Props = {
  question: Question;
  control: any;
  watch: any;
  setValue: any;
};

const getRenderType = (question: Question, control: any) => {
  if (question.type === "question") {
    return (
      <InputForm
        name={String(question.id)}
        label={question.question}
        control={control}
        required
        info={question.info && question.info.split(";")}
      />
    );
  }
  if (question.type === "number") {
    return (
      <FormNumber
        name={String(question.id)}
        label={question.question}
        control={control}
        required
        info={question.info && question.info.split(";")}
      />
    );
  }
  if (question.type === "numberOf") {
    return (
      <FormNumberOf
        name={String(question.id)}
        label={question.question}
        control={control}
        required
        question={question}
      />
    );
  }
  if (question.type === "date") {
    return (
      <FormDate
        name={String(question.id)}
        label={question.question}
        control={control}
        required
      />
    );
  }
  if (question.type === "options") {
    return (
      <FormSelect
        name={String(question.id)}
        label={question.question}
        control={control}
        required
        options={
          question.options
            ?.split(";")
            .filter((option) => option !== "")
            .map((option) => ({ value: option, label: option })) || []
        }
      />
    );
  }
  if (question.type === "textarea") {
    return (
      <FormTextarea
        name={String(question.id)}
        label={question.question}
        control={control}
        required
        info={question.info && question.info.split(";")}
      />
    );
  }
  if (question.type === "money") {
    return (
      <>
        <FormMoney
          name={String(question.id)}
          required
          label={question.question}
          control={control}
          info={question.info && question.info.split(";")}
        />
      </>
    );
  }
  if (question.type === "pesos") {
    return (
      <FormMoney
        name={String(question.id)}
        required
        label={question.question}
        control={control}
        pesosOnly
        info={question.info && question.info.split(";")}
      />
    );
  }
  if (question.type === "catastro") {
    return (
      <FormCatastro
        name={String(question.id)}
        required
        label={question.question}
        control={control}
        info={question.info && question.info.split(";")}
      />
    );
  }
  if (question.type === "percentage") {
    return (
      <FormPercentage
        name={String(question.id)}
        required
        label={question.question}
        control={control}
        info={question.info && question.info.split(";")}
      />
    );
  }
  if (question.type === "multipleOptions") {
    return (
      <FormMultipleOptions
        name={String(question.id)}
        label={question.question}
        control={control}
        options={
          question.options
            ?.split(";")
            .filter((option) => option !== "")
            .map((option) => ({ value: option, label: option })) || []
        }
        info={question.info && question.info.split(";")}
      />
    );
  }
  if (question.type === "sheetsDataBase") {
    return (
      <FormServerOptions
        name={String(question.id)}
        label={question.question}
        control={control}
        sheetId={question.sheetId}
        required
      />
    );
  }

  return null;
};

const GeneratorQuestion: React.FC<Props> = ({
  question,
  control,
  watch,
  setValue,
}) => {
  const [shouldRender, setShouldRender] = useState(false);

  const formWatch = watch();

  useEffect(() => {
    if (!question.dependencies) {
      setShouldRender(true);
    } else {
      const check = checkDependencies(question, formWatch);
      setShouldRender(check);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formWatch]);

  if (!shouldRender) return null;

  const hanleNoAnswer = () => {
    setValue(String(question.id), "______________");
  };

  const handleAllowAnswer = () => {
    setValue(
      String(question.id),
      questionDefaultValues.find((q) => q.type === question.type)?.value || ""
    );
  };

  if (formWatch[question.id] === "______________") {
    return (
      <Box className={styles.card} maxWidth="1000px" position="relative">
        <Box
          sx={{
            position: "absolute",
            top: 7,
            right: 15,
            transform: "scale(0.9)",
          }}
        >
          <SvgButton
            icon={chatIcon}
            label="Responder"
            onClick={handleAllowAnswer}
          />
        </Box>
        <FormLabel label={question.question} isFocused={false} />
        <Box my={2}>Sin Responder</Box>
        <Box my={2} fontSize={12} color={"lightgray"}>
          *En el documento veras un espacio para completar
        </Box>
      </Box>
    );
  }

  return (
    <Box className={styles.card} maxWidth="1000px" position="relative">
      {question.type !== "multipleOptions" && (
        <Box
          sx={{
            position: "absolute",
            top: 7,
            right: 15,
            transform: "scale(0.9)",
          }}
        >
          <SvgButton
            icon={chatIcon}
            label="Aun no tengo esta respuesta"
            onClick={hanleNoAnswer}
          />
        </Box>
      )}
      {getRenderType(question, control)}
      <AnswerDisplay
        watch={formWatch}
        name={String(question.id)}
        question={question}
      />
    </Box>
  );
};

export default GeneratorQuestion;
