import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CurrentDocumentDataState {
  expirationDate: null | number;
  alternativeLogo: null;
}

const initialState = {
  expirationDate: null,
  alternativeLogo: null,
};

export const currentDocumentDataSlice = createSlice({
  name: "currentDocumentData",
  initialState,
  reducers: {
    setCurrentDocumentExpirationDate: (state, action: PayloadAction<any>) => {
      state.expirationDate = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentDocumentExpirationDate } =
  currentDocumentDataSlice.actions;

export default currentDocumentDataSlice.reducer;
