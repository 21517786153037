import React, { useEffect } from "react";
import Loader from "react-loader-spinner";

const LoaderComponent = () => {
  return (
    <div>
      <div className="a-center" style={{ marginTop: 120, marginBottom: 120 }}>
        <Loader
          type="Bars"
          color="#e8eeff"
          height={60}
          width={60}
          className="margin-v"
        />
        <span className="f-medium primary margin-v">Cargando ...</span>
      </div>
    </div>
  );
};

export default LoaderComponent;
