import { useAuth0 } from "@auth0/auth0-react";
import { APIPostWithError } from "../../Services/authenticated";
import { setAppError, setLoading } from "../../Utils/modalMethods";
import { useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import AutomaticForm, { Field } from "../../uiComponents/Form/AutomaticForm";

const CompanyDocumentUpdater = () => {
  const documentUpdateData = JSON.parse(
    localStorage.getItem("document-update-data") || "[]"
  );

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      documentName: documentUpdateData.documentName || "",
      documentName1: documentUpdateData.documentName1 || "",
      documentName2: documentUpdateData.documentName2 || "",
      removeLogo:
        documentUpdateData.removeLogo !== undefined
          ? documentUpdateData.removeLogo
          : false,
      password: "",
    },
  });

  const fields: Field[] = [
    {
      name: "documentName",
      label: "Document Name",
      type: "text",
      required: true,
    },
    {
      name: "documentName1",
      label: "Document Name 1",
      type: "text",
      required: true,
    },
    {
      name: "documentName2",
      label: "Document Name 2",
      type: "text",
    },
    {
      name: "removeLogo",
      label: "Desea quitar el logo?",
      type: "options",
      options: [
        {
          value: true,
          label: "Si",
        },
        {
          value: false,
          label: "No",
        },
      ],
    },
    {
      name: "password",
      label: "Password",
      type: "number",
      required: true,
    },
  ];

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      const finalQuestionData = JSON.parse(
        localStorage.getItem("generator-form-data") || "[]"
      );

      const finalDocumentData = JSON.parse(
        localStorage.getItem("generator-form-document") || "[]"
      );

      const finalSectionData = JSON.parse(
        localStorage.getItem("generator-form-sections") || "{}"
      );

      if (
        finalQuestionData.length < 1 ||
        finalDocumentData.length < 1 ||
        Object.keys(finalSectionData).length < 1
      ) {
        setAppError("No se encontraron datos para actualizar");
        return;
      }
      const now = Date.now();

      const {
        documentName,
        documentName1,
        documentName2,
        removeLogo,
        password,
      } = data;

      const params = {
        documentName,
        documentName1,
        documentName2: documentName2 || "",
        removeLogo,
        questionData: finalQuestionData,
        documentData: finalDocumentData,
        sectionData: finalSectionData,
        date: now.toString(),
        password,
        generatorVersion: "2.0",
        dataPath: documentUpdateData.dataPath,
        companyName: documentUpdateData.companyName,
        documentId: documentUpdateData.documentId,
        companyId: documentUpdateData.companyId,
        sheetId: documentUpdateData.sheetId,
      };

      const accesToken = await getAccessTokenSilently();
      const uploadData = await APIPostWithError(
        "/company-docs/update-company-document-2",
        accesToken,
        params
      );

      if (uploadData.saved) {
        alert("guardado correctamente");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack alignItems="center" width="100vw" pt={4} mb={5}>
      <h2>Actualizar el documento actual</h2>
      <Box mt={2}>* Recordá revisar las preguntas y el documento</Box>
      {isAuthenticated && (
        <Stack
          width={800}
          maxWidth={"90vw"}
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            p: 4,
            my: 4,
            borderRadius: 1,
          }}
        >
          <AutomaticForm control={control} fields={fields} />
          <Button
            onClick={() => handleSubmit(onSubmit)()}
            sx={{ mt: 2 }}
            variant="contained"
          >
            Cargar
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CompanyDocumentUpdater;
