const currency = [
  "Pesos Argentinos",
  "Dólares Estadounidenses",
  "Euros",
  "Libras Esterlinas",
  "Bitcoin",
  "Ethereum",
];

export default currency;
