import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./TableEntries.styles.css";

type Props = {
  date: number;
  isHandled: boolean;
};

const DaysToExpiration: React.FC<Props> = ({ date, isHandled }) => {
  if (isNaN(date)) {
    return <>-</>;
  }

  const days = Math.floor(date / 86400000) + 1;

  if (isHandled) {
    return (
      <div>
        {days} Días{" "}
        <span className="bold table-green icon-margin-l">
          {" "}
          <FontAwesomeIcon icon={faCheckCircle} /> Renovado
        </span>
      </div>
    );
  }

  if (days === 0) {
    return (
      <div>
        <span className="bold light-red">
          {" "}
          <FontAwesomeIcon icon={faExclamationTriangle} /> Vence Hoy
        </span>
      </div>
    );
  }

  if (days < 0) {
    return (
      <div>
        {days} Días{" "}
        <span className="bold light-red icon-margin-l">
          <FontAwesomeIcon icon={faTimesCircle} /> Vencido
        </span>
      </div>
    );
  }

  if (days < 7) {
    return (
      <div>
        {days} Días{" "}
        <span className="bold primary icon-margin-l">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Vencimiento Próximo
        </span>
      </div>
    );
  }

  return (
    <div>
      {days} Días <span className="icon-margin-l primary bold">Por Vencer</span>
    </div>
  );
};

export default DaysToExpiration;
