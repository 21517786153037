import React, { useEffect, useState } from "react";

import "./styles.css";
import NewElectronicSignatureScreen from "./NewElectronicSignatureScreen";
import ElectronicSignatureForm from "./ElectronicSignatureForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { setShouldESign } from "../../../redux/slices/EditorDataSlice";

const ElectronicSignature = ({ documents, searchDocuments, company }) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [currentSignDocument, setCurrentSignDocument] = useState(null);

  const setEsignDocumentData = (document) => {
    setCurrentSignDocument(document);
    setCurrentSection(1);
  };
  const dispatch = useDispatch();
  const { document, shouldESign } = useSelector((state) => state.editorData);

  useEffect(() => {
    if (shouldESign) {
      dispatch(setShouldESign(false));
      setEsignDocumentData(document);
    }
  }, []);

  return (
    <div className="electronic-signature-wrapper">
      {currentSection === 0 && (
        <NewElectronicSignatureScreen
          documents={documents}
          searchDocuments={searchDocuments}
          setEsignDocumentData={setEsignDocumentData}
          company={company}
        />
      )}
      {currentSection === 1 && (
        <div
          className="electronic-signature-cancel b-light-red"
          onClick={() => {
            setCurrentSection(0);
            setCurrentSignDocument(null);
          }}
          style={{ cursor: "pointer", marginTop: 40 }}
        >
          <FontAwesomeIcon
            icon={faArrowAltCircleLeft}
            className="margin-icon"
          />
          Cancelar
        </div>
      )}
      {currentSection === 1 && (
        <ElectronicSignatureForm
          document={currentSignDocument}
          company={company}
        />
      )}
    </div>
  );
};

export default ElectronicSignature;
