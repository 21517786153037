import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const ContratoDePrestamodeDineroSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Contrato de Préstamo de Dinero",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.",
      ],
    },
    questions: [
      {
        id: 1,
        question:
          "¿En qué Provincia se celebra el Contrato de Préstamo de Dinero?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question:
          "¿En qué Ciudad/Localidad se celebra el Contrato de Préstamo de Dinero?",
        info:
          "Si arriba elegistes Ciudad Autónoma de Buenos Aires, respondé lo mismo en esta pregunta.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se celebra el Contrato?",
        type: date,
      },
    ],
  },
  {
    id: "section2",
    section: "Acreedor",
    title: "Indicar los datos del Acreedor que prestará su dinero al Deudor.",
    type: person,
    explanation: {
      question: "¿Quién es el Acreedor del Contrato de Préstamo de Dinero?",
      answer: [
        "El Acreedor es la persona física, o jurídica, que presta su dinero a otra persona (la Deudora).",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 4,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 5,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 6,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 151,
              question: "Tipo de Identificación Tributaria:",
              type: options,
              options: ["CUIL", "CUIT"],
            },
            {
              id: 152,
              question: "Número de Identificación Tributaria:",
              type: question,
            },
            {
              id: 7,
              question: "Domicilio Completo:",
              info:
                "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 8,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 9,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 10,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 11,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 12,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info:
                "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 13,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section3",
    section: "Deudor",
    title: "Indicar los datos del Deudor que tomará prestado el dinero.",
    type: person,
    explanation: {
      question: "¿Quién es el Deudor del Contrato de Préstamo de Dinero?",
      answer: [
        "El Deudor es quien tomará prestado el dinero del Acreedor sujeto a las condiciones que se establezcan en el Contrato de Préstamo de Dinero.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person2",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 14,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              question: "Tipo de Documento:",
              id: 15,
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 16,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 161,
              question: "Tipo de Identificación Tributaria:",
              type: options,
              options: ["CUIL", "CUIT"],
            },
            {
              id: 162,
              question: "Número de Identificación Tributaria:",
              type: question,
            },
            {
              id: 17,
              question: "Domicilio Completo:",
              info:
                "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 18,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 19,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 20,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 21,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 22,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info:
                "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 23,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },

  {
    id: "section4",
    section: "Préstamo. Capital",
    title:
      "Detallar las aspectos principales del Préstamo que otorgará el Acreedor.",
    type: questions,
    explanation: {
      question: "¿Qué es un Préstamo?",
      answer: [
        "Un préstamo es una operación financiera por la cual una persona (Acreedor) otorga mediante un contrato dinero (capital) a otra persona (Deudora), a cambio de un interés que pactan las partes.",
      ],
    },
    questions: [
      {
        id: 24,
        question:
          "¿Cuál es el Capital que el Acreedor otorgará al Deudor en Préstamo?",
        answer: [
          "Se deberá determinar cúal es la suma total del capital que se dará en préstamo.",
          "En la sección siguiente se establecerán los intereses.",
        ],
        type: money,
      },
      {
        id: 25,
        question: "¿Cómo se otorgará el Capital?",
        answer: [
          "Indicar si el Capital se dará en efectivo en mano o si se depositará o transferirá a una cuenta bancaria del Deudor.",
        ],
        type: options,
        options: [
          "Se otorgará en efectivo en mano",
          "Se depositará o transferirá a una cuenta bancaria del Deudor",
        ],
      },
      {
        id: 26,
        question:
          "Del Deudor. Detallar los datos completos de la Cuenta Bancaria donde recibirá el Préstamo",
        answer: [
          "Detallar N° de cuenta, CBU, Alias y Banco del Deudor",
          'Por ej.: Caja de Ahorro N° 40015878, CBU N° 031584623598700000155, Alias: "Andes.Docs.Deudor" del Banco Patagonia.',
        ],
        type: question,
        depend: {
          id: 25,
          answer:
            "Se depositará o transferirá a una cuenta bancaria del Deudor",
        },
      },
      {
        id: 27,
        question:
          "¿Cuándo transferirá/depositará el Acreedor el Capital al Deudor?",
        answer: [
          "Indicar en que momento se transferirá/depositará el Capital del Préstamo al Deudor.",
        ],
        type: options,
        options: ["Misma fecha en que se firma el Contrato", "Otra posterior"],
        depend: {
          id: 25,
          answer:
            "Se depositará o transferirá a una cuenta bancaria del Deudor",
        },
      },

      {
        id: 28,
        question: "¿En qué fecha se otorgará?",
        type: date,
        doubleDepend: [
          {
            id: 25,
            answer:
              "Se depositará o transferirá a una cuenta bancaria del Deudor",
          },
          {
            id: 27,
            answer: "Otra posterior",
          },
        ],
      },
      {
        id: 29,
        question:
          "¿Cómo deberá el Deudor devolver el capital al Acreedor? (Los intereses se establecen en la sección siguiente)",
        answer: [
          "Indicar la manera en que se devolverá el Capital. La práctica indica que se devuelve en único pago al vencimiento del Préstamo.",
          "Los intereses se establecen en la sección siguiente",
        ],
        type: options,
        options: [
          "En un único pago al finalizar el Préstamo",
          "En dos (2) pagos. El primero a la mitad del Préstamo, el segundo al finalizar",
          "Según cronograma de pagos distinto de las opciones anteriores (Ud. deberá detallar el cronograma)",
        ],
      },
      {
        id: 30,
        question: "¿Cuándo terminará/vencerá el Préstamo?",
        answer: [
          "Indicar en que fecha finalizará el Préstamo.",
          'En caso que en la opción anterior hayas elegido "En un único pago al finalizar el Préstamo", entonces en la fecha que eliga ahora también se deberá devolver el Capital.',
          "En caso que hayas elegido la otra opción, esta fecha indicará cuando se finaliza el Préstamo y el vencimiento de la última cuota.",
        ],
        type: date,
      },
      {
        id: 31,
        question:
          "Establecer el cronograma de pagos con la cantidad de cuotas y su frecuencia de pago.",
        answer: [
          "Considerando la duración del Préstamo, indicar la manera en que se devolverá el Capital.",
          "Detallar cantidad de cuotas y frecuencia de pago.",
          "Por ej.: Para un préstamo de un (1) año: 12 cuotas iguales mensuales ó 2 pagos semestrales, el primero del 60% del Capital y el segundo del 40% restante, etc.",
        ],
        type: textarea,
        depend: {
          id: 29,
          answer:
            "Según cronograma de pagos distinto de las opciones anteriores (Ud. deberá detallar el cronograma)",
        },
      },
      {
        id: 32,
        question:
          "¿Dónde deberá el Deudor realizar los pagos del Préstamo al Acreedor?",
        answer: [
          "Indicar el lugar/manera donde deberá el Deudor realizar los pagos del Préstamo",
        ],
        type: options,
        options: [
          "En la cuenta bancaria del Acreedor",
          "En el domicilio del Acreedor",
          "Otro domicilio",
        ],
      },
      {
        id: 33,
        question:
          "Del Acreedor. Detallar los datos completos de la Cuenta Bancaria de donde se realizarán los pagos del Préstamo",
        answer: [
          "Detallar N° de cuenta, CBU, Alias y Banco del Acreedor, en donde el Deudor deberá pagar el Préstamo.",
          'Por ej.: Caja de Ahorro N° 40015878, CBU N° 031584623598700000155, Alias: "Andes.Docs.Acreedor" del Banco Francés',
        ],
        type: question,
        depend: {
          id: 32,
          answer: "En la cuenta bancaria del Acreedor",
        },
      },
      {
        id: 34,
        question:
          "Detallar el domicilio donde el Deudor deberá realizar sus pagos",
        info:
          "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad) en donde el Deudor deberá pagar sus obligaciones establecidas en el Contrato.",
        type: question,
        depend: {
          id: 32,
          answer: "Otro domicilio",
        },
      },
    ],
  },

  {
    id: "section5",
    section: "Intereses",
    title:
      "Detallar los aspectos relevantes de los intereses que serán aplicable al Préstamo.",
    type: questions,
    explanation: {
      question: "¿Qué es un Interés?",
      answer: [
        "Además de devolver el capital, la deudora debe pagar un monto de dinero adicional en concepto de intereses. Ese monto depende de la tasa de interés que se determine en el contrato de préstamo.",
      ],
    },
    questions: [
      {
        id: 35,
        question:
          "¿De qué manera se calculará la tasa de interés del Préstamo?",
        answer: [
          "Establecer si la tasa de interés se calculará en forma mensual o anual",
        ],
        type: options,
        options: ["tasa mensual", "tasa anual"],
      },
      {
        id: 36,
        question: "¿Cuál es el la tasa de interés del Préstamo?",
        answer: [
          "Se deberá determinar EN NÚMEROS cúal es la tasa de interés que aplicará al Capital del Préstamo.",
          " Escribir solo el número, SIN el signo %. AndesDocs lo incorporará automáticamente en el Contrato",
        ],
        type: number,
      },
      {
        id: 37,
        question: "¿Cómo se devolverán los intereses?",
        answer: ["Indicar la manera en que se devolverán los Intereses."],
        type: options,
        options: [
          "En un único pago al finalizar el Préstamo",
          "En dos (2) pagos. El primero a la mitad del Préstamo, el segundo al finalizar",
          "Según cronograma de pagos distinto de las opciones anteriores (Ud. deberá detallar el cronograma)",
        ],
      },
      {
        id: 38,
        question:
          "Establecer el cronograma de pagos con la cantidad de cuotas y su frecuencia de pago.",
        answer: [
          "Considerando la duración del Préstamo, indicar la manera en que se devolverán los intereses.",
          "Detallar cantidad de cuotas y frecuencia de pago.",
          "Por ej.: Para un préstamo de un (1) año: 12 cuotas iguales mensuales ó 2 pagos semestrales, el primero del 60% del Interes y el segundo del 40% restante, etc.",
        ],
        type: textarea,
        depend: {
          id: 37,
          answer:
            "Según cronograma de pagos distinto de las opciones anteriores (Ud. deberá detallar el cronograma)",
        },
      },
    ],
  },

  {
    id: "section6",
    section: "Garante",
    title: "En caso de exisistir, definir el Garante del Préstamo.",
    type: questions,
    explanation: {
      question: "¿Qué es un Garante?",
      answer: [
        "Es quien garantiza una obligación. El Garante es la persona (física o jurídica) que se ve obligada a responder por otra persona cuando ella no cumpla con sus obligaciones de pago.",
      ],
    },
    questions: [
      {
        id: 51,
        question:
          "¿Va a haber un Garante (una tercera persona) que garantize que el Préstamo que se va a cumplir?",
        answer: [
          'El Garante (una tercera persona) garantizará las obligaciones de pago que tiene el Deudor en el Contrato. En caso que se opté por "Sí", deberá indicar los datos de esta persona.',
        ],
        type: options,
        options: ["Sí", "No"],
      },
      {
        id: 511,
        question:
          "Detallar los datos del Garante ¿Es una persona física o una persona jurídica?",
        type: options,
        options: ["Persona Física", "Persona Jurídica"],
        depend: {
          id: 51,
          answer: "Sí",
        },
      },
      {
        id: 512,
        question: "Nombres y Apellidos Completos:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 513,
        question: "Tipo de Documento:",
        type: options,
        options: ["DNI", "Pasaporte", "Cédula de Identidad"],
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 514,
        question: "Número de Documento:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 515,
        question: "Domicilio Completo:",
        info:
          "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 516,
        question: "Provincia:",
        type: options,
        options: provincias,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 517,
        question: "Denominación Social (incluyendo tipo societario):",
        info: "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 518,
        question: "Tipo de identificacion:",
        type: options,
        options: ["CUIT"],
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 519,
        question: "Número de CUIT:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 520,
        question: "Domicilio Completo de la sede principal de la entidad:",
        info:
          "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 521,
        question: "Provincia:",
        type: options,
        options: provincias,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
    ],
  },

  {
    id: "section7",
    section: "Condiciones Particulares",
    title: "Jurisdicción. Escribano Público y Pago Anticipado",
    type: questions,
    explanation: {
      question: "¿Qué vamos a definir?",
      answer: [
        "Que jurisdicción es aplicable al contrato.",
        "Si las Partes optarán por certificar sus firmas ante Escribano Público",
        "Y, si el Deudor podrá pre-cancelar el préstamo.",
      ],
    },
    questions: [
      {
        id: 39,
        question:
          "En caso de conflicto ¿En qué jurisdicción se deberá resolver?",
        answer: [
          "En caso de conflicto, las Partes deberán resolverlo ante un Juez. Definir en que provincia se encontrará el Juez que resolveran el conflicto. (Se suele elegir la misma provincia donde se celebra el Contrato)",
        ],
        type: options,
        options: provincias,
      },
      {
        id: 40,
        question:
          "¿Las Partes utilizarán un Escribano Público para certificar sus firmas?",
        answer: [
          "Las Partes pueden establecer que sus respectivas firmas sean certificadas por un escribano público en la fecha de firma del Contrato.",
        ],
        type: options,
        options: ["Sí", "No"],
      },
      {
        id: 41,
        question:
          "Pre-Cancelación ¿Podrá el Deudor pagar anticipadamente el capital de manera parcial o total?",
        answer: [
          "Indicar si el Deudor podrá pagar anticipadamente, en forma parcial o total, el capital adeudado al momento de su precancelación.",
        ],
        type: options,
        options: [
          "Sí, podrá pre-cancelar el préstamos",
          "No, no podrá pre-cancelar el préstamo",
        ],
      },
    ],
  },
];

export const ContratoDePrestamoDeDinero = {
  name: "Contrato de Prestamo de dinero",
  codeName: "contratoDePrestamo",
  question: "¿Qué es un Contrato de prestamo de dinero?",
  definition: [
    "El Contrato de Préstamo de dinero es un contrato en virtud del cual una persona -prestamista- presta a otra -deudora- un dinero a cambio de un interés por un tiempo determinado. Puede ser usado como una alternativa a un Pagaré y puede ser pactado en Pesos Argentinos o moneda extranjera. El monto del dinero prestado es denominado “Capital”. A cambio del Capital el deudor deberá pagar un interés al Prestamista. Se debe establecer que tasa de interés se acuerda entre las partes.",
  ],
  price: 999.99,
  firmas: [
    [
      { id: 4, section: "section2" },
      { id: 9, section: "section2" },
    ],
    [
      { id: 14, section: "section3" },
      { id: 19, section: "section3" },
    ],
    [
      { id: 512, section: "section6" },
      { id: 517, section: "section6" },
    ],
  ],
  size: "9-12 Páginas",
  lastRevision: "Agosto 2021",
  sections: ContratoDePrestamodeDineroSections,
  url: "contrato-de-prestamo-de-dinero",
  video: null,
  useCases: {
    good: [
      "Documentar un préstamo de dinero",
      "Establecer de qué manera y plazos se devolverá el dinero prestado",
      "Tener un documento exigible en caso que el Deudor no devuelva el dinero",
    ],
    bad: [
      "Documentar una donación de dinero (entrega gratuita de dinero)",
      "Documentar un préstamo de inmueble",
      "Documentar una relación laboral",
    ],
  },
};
