import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const ContratoDeTrabajoPlazoFijoSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Contrato",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.",
      ],
    },
    questions: [
      {
        id: 1,
        question:
          "¿En qué Provincia se celebra el Contrato de Trabajo a Plazo Fijo?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question: "¿En que Ciudad/Localidad se celebra el Contrato?",
        info: "Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se celebra el Contrato?",
        type: date,
      },
    ],
  },
  {
    id: "section2",
    section: "Empleadora",
    title:
      "Indicar los datos de la Empleadora que contratará a la parte Trabajadora.",
    type: person,
    explanation: {
      question:
        "¿Quién es la Empleadora en el Contrato de Trabajo a Plazo Fijo?",
      answer: [
        "Es toda entidad o toda persona laboralmente capaz puede firmar un contrato de trabajo a plazo fijo como empleadora.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 4,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 5,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 6,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 7,
              question: "Domicilio Completo:",
              info:
                "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 8,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 9,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 10,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 11,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 12,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info:
                "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 13,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section3",
    section: "Trabajadora",
    title:
      "Indicar los datos de la Trabajadora que firmará el presente Contrato.",
    type: questions,
    explanation: {
      question:
        "¿Quién es es la parte Trabajadora del Contrato de Trabajo a Plazo Fijo?",
      answer: [
        "La parte Trabajadora es quien realizará una o más tareas en relación de dependencia con la Empleadora.",
      ],
    },
    questions: [
      {
        id: 14,
        question: "Nombres y Apellidos Completos:",
        type: question,
      },
      {
        question: "Tipo de Documento:",
        id: 15,
        type: options,
        options: ["DNI", "Pasaporte", "Cédula de Identidad"],
      },
      {
        id: 16,
        question: "Número de Documento:",
        type: question,
      },
      {
        id: 17,
        question: "Tipo de Identificación Tributaria:",
        type: options,
        options: ["CUIL", "CUIT"],
      },
      {
        id: 18,
        question: "Número de Identificación Tributaria:",
        type: question,
      },
      {
        id: 19,
        question: "Domicilio Completo:",
        info:
          "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad)",
        type: question,
      },
      {
        id: 20,
        question: "Provincia:",
        type: options,
        options: provincias,
      },
    ],
  },
  {
    id: "section4",
    section: "Trabajo. Remuneración",
    title:
      "Detallar las tareas que prestará la Trabajadora y la remuneración a cambio.",
    type: questions,
    explanation: {
      question: "¿Cúal es la Tarea que realiza la Trabajadora?",
      answer: [
        "Es la función/trabajo que deberá que cumplir la Trabajadora por indicación de la Empleadora. ",
        "La Remuneración Bruta a percibir por la trabajadora no podrá ser inferior a la proporcional que le corresponda a un trabajador a tiempo completo establecido por ley o por Convenio Colectivo de Trabajo de la actividad o en defecto el Salario Mínimo Vital y Móvil por igual jornada, además el empleador garantizará la dación de trabajo suficiente y de la misma categoría o puesto de trabajo contratado.",
      ],
    },
    questions: [
      {
        id: 21,
        question: "¿Cúal es la Trabajo/Tarea que prestará la Trabajadora?",
        answer: [
          "Se deberá determinar, con detalle, cada tarea que tendrá que cumplir la Trabajadora. Por ejemplo: Repositor de estanterias en supermercado; Desarrollar el marketing de la empresa; Ser operario en una fábrica, etc.",
        ],
        type: question,
      },
      {
        id: 22,
        question:
          "¿En que lugar/dirección deberá la Trabajadora desarrollar su Trabajo/Tarea?",
        answer: [
          "Detallar la Dirección exacta donde la Trabajadora desarrollará su trabajo (incorporar la Ciudad/Localidad también).",
          'Por ejemplo: Sargento Diaz 350, PB "F", Localidad de Tigre,',
          "Galería Florida Local 21, Calle Tucuman 537, CABA, etc.",
        ],
        type: question,
      },
      {
        id: 23,
        question:
          "¿Se aplica algún Convenio Colectivo de Trabajo o Laudo en la actividad que se contrata a la Trabajadora?",
        answer: [
          "Si a la actividad en la que se ocupará a la Empleada se aplica un convenio colectivo de trabajo o laudo legalmente obligatorio, entonces el contrato automáticamente se rige por las disposiciones de dicho convenio o laudo.",
        ],
        type: options,
        options: [
          "No, no aplica ninguno",
          "Sí, aplica un Convenio Colectivo",
          "Sí, aplica un Laudo",
        ],
      },
      {
        id: 24,
        question:
          "Favor de detallar el número y año del Convenio Colectivo de Trabajo",
        answer: ["Por ejemplo: 57/2019, 86/2021"],
        type: question,
        depend: {
          id: 23,
          answer: "Sí, aplica un Convenio Colectivo",
        },
      },
      {
        id: 25,
        question: "Favor de detallar el número y año del Laudo",
        answer: ["Por ejemplo: 157/2019, 186/2021"],
        type: question,
        depend: {
          id: 23,
          answer: "Sí, aplica un Laudo",
        },
      },
      {
        id: 26,
        question:
          "Definir la manera en que se pagará la remuneración a la Trabajadora ¿Será por día, semana, quincena o mes calendario?",
        answer: [
          "Por favor seleccione el período de tiempo por el que se calculará la remuneración de la Empleada.",
        ],
        type: options,
        options: ["mes calendario", "quincena", "semana", "día"],
      },
      {
        id: 27,
        question:
          "¿Cúal será la remuneración Bruta que se le pagará a la Trabajadora?",
        answer: [
          "Indicar el monto de la remuneración total antes de hacer los descuentos obligatorios correspondientes y sin sumar ningún importe en concepto de Sueldo Anual Complementario, horas extras o asignaciones familiares.",
        ],
        type: money,
      },
      {
        id: 28,
        question: "¿La Remuneración se pagará en una Cuenta Sueldo?",
        answer: [
          "La Remuneración podrá depositarse/transferirse en una Cuenta Sueldo. En caso contrario, las partes podrás hacer, por ejemplo, con pago en efectivo.",
        ],
        type: options,
        options: ["Sí", "No"],
      },
    ],
  },
  {
    id: "section5",
    section: "Vigencia del Contrato",
    title: "Duración y Vigencia del vínculo laboral.",
    type: questions,
    explanation: {
      question: "Duración y Vigencia",
      answer: [
        "Por ley el Contrato de Trabajo a Plazo Fijo no puede durar más de 5 años. Se deberá determinar a partir de que fecha inicia la vigencia del Contrato",
      ],
    },
    questions: [
      {
        id: 29,
        question: "¿El Contrato de Trabajo durará más de un (1) mes?",
        answer: [
          "Si el Contrato dura menos de un (1) mes, la ley establece condiciones distintas en caso que las partes quieran terminar el Contrato antes de su terminación. Andes Docs contemplará esta variable según su respuesta.",
        ],
        type: options,
        options: ["Sí", "No"],
      },
      {
        id: 30,
        question: "¿Cúanto tiempo durará el Contrato de Trabajo?",
        answer: [
          "Por ejemplo: 6 meses, 2 años, 45 días, etc.",
          "Detallar en días, meses o años cuanto tiempo durará el Contrato de Trabajo entre la Empleadora y la Trabajadora.",
          "Recordar que el máximo legal para este tipo de contrato es de cinco (5) años.",
        ],
        type: question,
      },
      {
        id: 31,
        question:
          "¿A partir de qué fecha comenzará la vigencia Contrato de Trabajo?",
        answer: [
          "Indicar a partir de que momento inciará el Contrato entre el Empleador y la Trabajadora.",
          "Indicar si empieza el mismo día que se firma el Contrato ó si empieza en otra fecha posterior.",
          "En caso que sea otra fecha posterior, deberá indicar cuál.",
        ],
        type: options,
        options: ["Misma fecha que se firma el Contrato", "Otra posterior"],
      },
      {
        id: 32,
        question: "¿En que fecha empieza?",
        type: date,
        depend: {
          id: 31,
          answer: "Otra posterior",
        },
      },
    ],
  },
  {
    id: "section6",
    section: "Jornada Laboral",
    title: "Extensión de la Jornada Laboral. Pausas. ",
    type: questions,
    explanation: {
      question: "Jornada Laboral y Duración.",
      answer: [
        "Por ley la jornada laboral no puede extenderse por más de 8 horas diarias y de 48 horas semanales. Si se superan esos límites entonces la empleadora debe remunerar a la empleada el tiempo adicional trabajado por un valor horario mayor.",
      ],
    },
    questions: [
      {
        id: 33,
        question:
          "Jornada Laboral ¿Cómo será el horario de la Trabajadora para cumplir con su trabajo?",
        answer: [
          "El horario de la Empleada para cumplir las tareas puede ser fijo todos los días laborables o variable según el día laborable de que se trate.",
          "Indicar si se desarrollará de manera fija o variable.",
        ],
        type: options,
        options: [
          "Fijo, con días y horarios iguales",
          "Variable, con días y horarios distintos",
        ],
      },
      {
        id: 34,
        question:
          "Jornada Variable ¿Cúales serán los días y horarios en que la Trabajadora deberá cumplir con su Trabajo? (Contemplar pausas, como de almuerzo, de existir)",
        answer: [
          "Detallar los días, horarios y pausas que aplican a la Trabajadora.",
          "Por ejemplo: Lunes de 09.00hs a 16.00 hs con almuerzo de 13.00 hs a 14.00 hs y Martes a Viernes de 10.00 hs a 17.00hs con identico horario de almuerzo.",
        ],
        type: textarea,
        depend: {
          id: 33,
          answer: "Variable, con días y horarios distintos",
        },
      },
      {
        id: 35,
        question:
          "Jornada Fija ¿Qué días de la semana deberá la Trabajadora cumplir con su Trabajo?",
        type: multipleOptions,
        options: [
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
          "Domingo",
        ],
        depend: { id: 33, answer: "Fijo, con días y horarios iguales" },
      },
      {
        id: 36,
        question:
          "Jornada Fija ¿A qué hora comienza el horario de Trabajo de la Trabajadora?",
        type: number,
        answer: ["8, 08.00, 13.00, 14.30, etc."],
        depend: { id: 33, answer: "Fijo, con días y horarios iguales" },
      },
      {
        id: 37,
        question:
          "Jornada Fija ¿A qué hora finaliza el horario de Trabajo de la Trabajadora?",
        type: number,
        answer: ["18, 18.30, 19.15, etc."],
        depend: { id: 33, answer: "Fijo, con días y horarios iguales" },
      },
      {
        id: 38,
        question:
          "¿Durante la Jornada Laboral indicada anteriormente, la Trabajadora tendrá algún tipo de pausa?",
        answer: [
          "Durante la Jornada Laboral se puede establecer una pausa en favor de la Trabajadora, como puede ser horario de almuerzo o alguna otro tipo de pausa.",
          "Indicar si habrá o no una pausa durante la Jornada Laboral indicada",
        ],
        type: options,
        options: [
          "Sí, habrá una pausa",
          "No, no habrá o ya se contempla en la Jornada indicada anteriormente.",
        ],
        depend: { id: 33, answer: "Fijo, con días y horarios iguales" },
      },
      {
        id: 39,
        question: "¿Indicar a qué hora comienza la pausa.",
        answer: [
          "Indicar el horario en que inicaia la pausa.",
          "Por ejemplo: 13, 14.30, 11.45, etc.",
        ],
        type: number,
        depend: { id: 38, answer: "Sí, habrá una pausa" },
      },
      {
        id: 40,
        question: "¿Indicar a qué hora finaliza la pausa.",
        answer: [
          "Indicar el horario en que inicaia la pausa.",
          "Por ejemplo: 14, 15.30, 20.15, etc.",
        ],
        type: number,
        depend: { id: 38, answer: "Sí, habrá una pausa" },
      },
    ],
  },
];

export const ContratoDeTrabajoAPlazoFijo = {
  name: "Contrato de Trabajo a Plazo Fijo",
  codeName: "contratoDeTrabajo",
  question: "¿Qué es un Contrato de Trabajo a Plazo Fijo?",
  definition: [
    "Este Contrato de Trabajo a Plazo Fijo puede ser utilizado por una persona o entidad (Empleadora) para contratar a otra persona (Trabajadora) documentando las condiciones básicas de una contratación en relación de dependencia por un tiempo determinado no mayor a 5 años. Este contrato cumple los requisitos de la Ley de Contrato de Trabajo (N° 20.744).",
  ],
  firmas: [
    [
      { id: 4, section: "section2" },
      { id: 9, section: "section2" },
    ],
    [{ id: 14, section: "section3" }],
  ],
  price: 999.99,
  size: "4-6 Páginas",
  lastRevision: "Julio 2021",
  sections: ContratoDeTrabajoPlazoFijoSections,
  url: "contrato-de-trabajo",
  video: null,
  useCases: {
    good: [
      "Contratar a una persona por un máximo de 5 años",
      "Formalizar una contratacion en relación de dependencia",
      "Contratar para modalidad de trabajo presencial",
    ],
    bad: [
      "Contratar a una persona por más de 5 años",
      "Contratar para modalidad de trabajo remoto",
    ],
  },
};
