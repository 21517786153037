import React, { useState } from "react";
import {
  faPencil,
  faLink,
  faShare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ActionButton from "../../../../../uiComponents/ActionButton/ActionButton";
import "./SignerActions.styles.css";
import {
  closeModal,
  modalOpen,
  setAppError,
  setLoading,
} from "../../../../../Utils/modalMethods";
import { APIPostWithError } from "../../../../../Services/authenticated";

type Props = {
  data: any;
  signerCount: number;
};

export const deleteOneSigner = async (data: any, accessToken: any) => {
  try {
    setLoading(true);
    const res = await APIPostWithError(
      "/electronic-signature/delete-signer",
      accessToken,
      {
        signerToken: data.token,
      }
    );
    if (res.signerDeleted === true) {
      setTimeout(() => {
        modalOpen({
          successModal: {
            show: true,
            message: "Firmante Eliminado Exitosamente",
            submessage: "Ya no aparecerá en el documento.",
            disclaimer: "",
            shouldReload: false,
          },
        });
      }, 500);
      closeModal();
    } else {
      setTimeout(() => {
        setAppError();
      }, 50);
      closeModal();
    }
  } finally {
    setLoading(false);
  }
};

const SignerActions: React.FC<Props> = ({ data, signerCount }) => {
  const [showToast, setShowToast] = useState<boolean>(false);

  const openSignerEmailModal = () => {
    closeModal();
    modalOpen({
      signerEmailModal: {
        show: true,
        data: data,
      },
    });
  };

  const copySignLink = () => {
    // get the container
    const body = document.querySelector("#root");
    const signatureUrl = data.sign_url;
    // Create a fake `textarea` and set the contents to the text
    // you want to copy

    if (body) {
      const storage = document.createElement("textarea");
      storage.value = signatureUrl;
      body.appendChild(storage);

      // Copy the text in the fake `textarea` and remove the `textarea`
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand("copy");
      body.removeChild(storage);
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  };

  const openDeleteConfirmModal = () => {
    closeModal();
    modalOpen({
      confirmModal: {
        show: true,
        message: "Confirme eliminación",
        submessage: "¿Seguro desea eliminar a este firmante?",
        disclaimer: "*No se podrá deshacer",
        isAsync: true,
        confirmMethodName: "deleteOneSigner",
        data: data,
      },
    });
  };

  return (
    <div className="signer-actions-wrapper">
      <ActionButton
        icon={faPencil}
        label="Editar email"
        onClick={openSignerEmailModal}
      />
      <ActionButton
        icon={faLink}
        label="Copiar link para firmar"
        onClick={copySignLink}
      />
      {/* <ActionButton
        icon={faShare}
        label="Compartir link para firmar"
        onClick={copySignLink}
      /> */}
      {showToast && (
        <div className="toast b-primary white bold">
          Link para firmar copiado! Envíelo al firmante y podrá firmar
          accediendo al link.
        </div>
      )}
      {signerCount > 1 && data.status !== "signed" && (
        <ActionButton
          icon={faTrash}
          label="Eliminar firmante"
          className="light-red "
          onClick={openDeleteConfirmModal}
        />
      )}
    </div>
  );
};

export default SignerActions;
