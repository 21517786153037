import React, { useEffect, useState } from "react";
import "../styles.css";
import "./styles.css";

const Card: React.FC<any> = ({ item, handleClick, currentAnswer, value }) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    // this bit of code checks whether the item was already selected to mark it as so.
    if (currentAnswer === value) {
      setSelected(true);
    } else {
      setSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnswer]);
  return (
    <div
      onClick={() => {
        setSelected((prev) => !prev);
        if (!selected) {
          handleClick(item, value);
        } else {
          handleClick("");
        }
      }}
      className={` multiple-options-option-card purple multiple-options-option-card-${selected}`}
    >
      {!selected ? (
        <i className="far fa-square multiple-options-square primary"></i>
      ) : (
        <i className="far fa-check-square multiple-options-check primary"></i>
      )}
      {
        <input
          type="checkbox"
          className="big-options-checkbox"
          checked={selected}
          // value={selected}
          style={{ marginRight: 10 }}
          onChange={() => {
            if (!selected) {
              handleClick(item, value);
            } else {
              handleClick("");
            }
          }}
        />
      }
      {item}
    </div>
  );
};

const BigAdvancedOptions: React.FC<any> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  // this sets redux and session state answers
  const reduxAnswerSetter = (newAnswer: any, value: any) => {
    onChange({
      [sectionId]: {
        ...currentSectionAnswers,
        [question.id]: {
          question: question.question,
          answer: value,
        },
      },
    });
  };

  //this method handles clicks

  const handleClick = (newItem: any, value: any) => {
    reduxAnswerSetter(newItem, value);
  };
  return (
    <div>
      <p className=" question-format-info" style={{ marginTop: 10 }}>
        *Seleccionar solo 1 opción
      </p>
      {question.options.map((e: any) => {
        return (
          <Card
            item={e.label}
            value={e.value}
            handleClick={handleClick}
            key={e}
            currentAnswer={currentAnswer}
          />
        );
      })}
      <input
        required
        type="text"
        value={currentAnswer}
        className="big-options-invisible"
        maxLength={350}
        tabIndex={-1}
      />
    </div>
  );
};

export default BigAdvancedOptions;
