import {
  ANSWER_SETTER,
  ANSWER_RESETTER,
  ANSWER_FULL_SETTER,
} from './answers.types';

const INITIAL_STATE = {};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ANSWER_SETTER:
      return {
        ...state,
        ...action.payload,
      };
    case ANSWER_RESETTER:
      return {};
    case ANSWER_FULL_SETTER:
      return { ...action.payload };
    default:
      return state;
  }
};
export default reducer;
