import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faRecycle,
  faSignature,
  faWandSparkles,
} from "@fortawesome/free-solid-svg-icons";
import Logo from "../../uiComponents/Logo/Logo";
import pattern from "../../Assets/svg/pattern.svg";
import wand from "../../Assets/svg/wand.svg";
import Image from "../../uiComponents/Image";

interface Step {
  title: string;
  description: string[];
  icon: any;
}

type Props = {
  steps: Step[];
};

const ContractLifecycleMobile: React.FC<Props> = ({ steps }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        my: "20px",
        py: "100px",
        position: "relative",
        display: {
          xs: "flex",
          sm: "flex",
          md: "none",
        },
      }}
      alignItems="center"
      //   bgcolor={colorPallette.secondary}
    >
      <Box
        component={"img"}
        src={pattern}
        sx={{
          position: "absolute",
          top: "800px",
          width: "auto",
          opacity: 0.4,
          height: "100vw",
          maxHeight: "500px",
          objectFit: "cover",

          transform: "rotate(90deg)",
        }}
      />
      <Stack
        alignItems="center"
        sx={{
          width: "100%",
          py: 10,
          borderRadius: "6px",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          fontWeight="bold"
          sx={{ width: "90%", maxWidth: "1200px" }}
          mb={3}
        >
          Optimizá tus
          <span style={{ color: colorPallette.primary }}> procesos</span>{" "}
          contractuales de{" "}
          <span style={{ color: colorPallette.primary }}>principio a fin</span>
        </Typography>
        <Typography
          fontSize="1.1rem"
          sx={{ width: "90%", maxWidth: "1200px" }}
          textAlign="center"
          mb="120px"
        >
          Andes Docs te acompaña en cada paso del proceso de creación de
          contratos. Desde la creación del borrador hasta la firma y
          distribución, Andes Docs te ayuda a optimizar tus procesos
          contractuales.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          {steps.map((step, index) => (
            <Box
              key={index}
              sx={{
                textAlign: "center",
                position: "relative",
                px: 4,
                pt: 4,
                width: "320px",
                minHeight: "400px",
                borderRadius: "12px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                boxShadow: "0px 0px 80px rgba(0, 0, 0, 0.03)",
                backgroundColor: colorPallette.veryDark,
              }}
              mt={6}
            >
              <FontAwesomeIcon
                icon={step.icon}
                style={{
                  position: "absolute",
                  right: 20,
                  color: colorPallette.dark,
                }}
              />
              <Typography width="100%" fontSize={12} textAlign="left">
                Etapa {index + 1}
              </Typography>
              <Typography
                variant="h6"
                fontWeight="700"
                gutterBottom
                mb={2}
                mt={0}
                width="100%"
                textAlign="left"
                color={colorPallette.primary}
              >
                {step.title}
              </Typography>

              <Box
                sx={{
                  height: 2,
                  width: 70,
                  bgcolor: colorPallette.secondary,
                  transform: "translateY(-5px)",
                }}
              />
              {step.description.map((desc, descIndex) => (
                <Typography
                  key={descIndex}
                  variant="body1"
                  gutterBottom
                  width="100%"
                  textAlign="left"
                  my={2}
                  // pl={1}
                >
                  <FontAwesomeIcon
                    icon={faBolt}
                    color={colorPallette.primary}
                    className="mr1"
                  />
                  {desc}
                </Typography>
              ))}
            </Box>
          ))}
        </Box>
      </Stack>
    </Stack>
  );
};

export default ContractLifecycleMobile;
