import React, { useEffect } from "react";
import "./styles.css";

const LegalDisclaimer = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policy-wrapper">
      <div>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "42.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "center",
          }}
        >
          <strong>
            <span
              style={{
                fontSize: "29px",
                fontFamily: '"Arial",sans-serif',
                color: "black",
              }}
            >
              LEGAL DISCLAIMER
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#555555" }}>
            AndesDocs DOES NOT provide any legal advice and users of this web
            site should consult with their own lawyer for legal advice.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#555555" }}>
            This web site (the "Site") is a general service that provides legal
            information over the Internet. We are not a law firm and our
            employees are not acting as your attorney. The information contained
            in the Site is general legal information and should not be construed
            as legal advice to be applied to any specific factual situation. If
            you are unsure whether your particular situation requires that a
            document be changed, you should consult a lawyer. Any use of the
            Site DOES NOT create or constitute a lawyer-client relationship
            between AndesDocs or any employee of or other person associated with
            AndesDocs and a user of the Site. As the law differs in each legal
            jurisdiction and may be interpreted or applied differently depending
            on your location or situation, the information or use of documents
            on the Site is not a substitute for the advice of a lawyer.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#555555" }}>
            Any guarantee on the Site is subject to our Terms and Conditions.
            For the most part, our Terms and Conditions specify that there is no
            guarantee or warranty and that we are not responsible for any loss,
            injury, claim, liability, or damage ("damages") related to your use
            of the Site, whether from errors or omissions in the content of the
            Site or any other linked sites. We are not responsible for any
            damages from the Site being inaccessible to the user and use of the
            Site is at your own risk. While we have worked to make our Site and
            all the features on our Site compatible with the most commonly used
            browsers, we cannot represent or guarantee that every feature will
            work with your browser.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#555555" }}>
            AndesDocs does not endorse any content provided by any linked sites,
            nor does it assume any responsibility for the interpretation or
            application of any information originating from such content. In
            addition, AndesDocs does not endorse any content contained in any
            advertising on the Site, nor does it assume any responsibility for
            the quality or integrity of such work.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#555555" }}>
            AndesDocs may offer the USER the possibility of speaking with a
            lawyer. AndesDocs only connects the USER with the lawyer, but in no
            case does it advise the USER in any way whatsoever. The WEBSITE does
            not guarantee the validity and accuracy of the information submitted
            by the lawyers, nor their work, nor their advisement. The WEBSITE
            shall not be held liable for any legal advice or information
            provided by the lawyer. Use of our Site is governed by our Terms and
            Conditions; refer to this document for more information.
          </span>
        </p>
      </div>
    </div>
  );
};

export default LegalDisclaimer;
