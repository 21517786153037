import React from "react";
import Text from "../../../uiComponents/Text";
import "../styles.css";
import { isSafari } from "react-device-detect";
import "./styles.css";

const mes = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

const Date = ({ onChange, type, label }) => {
  const dateFormater = (e) => {
    let dateFormat = `${new window.Date(e.target.value).getUTCDate()} de ${
      mes[new window.Date(e.target.value).getUTCMonth()]
    } del ${new window.Date(e.target.value).getUTCFullYear()}`;
    return dateFormat;
  };

  return (
    <div>
      {isSafari && (
        <Text blue className="margin-v question-format-info">
          Introducir la fecha con el siguiente formato: 23 del agosto de 2021
        </Text>
      )}
      <p className="m-dates-label">{label}</p>
      {!isSafari && (
        <input
          required
          className="question-format-input m-dates-date-input"
          onChange={(e) => {
            onChange(dateFormater(e), type);
          }}
          type="date"
        ></input>
      )}
      {isSafari && (
        <input
          required
          className="question-format-input"
          onChange={(e) => {
            onChange(e.target.value);
          }}
        ></input>
      )}
    </div>
  );
};

export default Date;
