import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
} from './types';

import currency from './currency';
import { provincias } from './provincias';

export const TestSections = [
  {
    id: 'section1',
    section: 'Tiempo y Lugar',
    title: 'Tiempo y Lugar de Celebración del Acuerdo',
    type: questions,
    explanation: {
      question: '¿Que es el tiempo y el lugar?',
      answer: [
        'El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Acuerdo.',
      ],
    },
    questions: [
      {
        id: 1,
        question: '¿En qué provincia se celebra el Acuerdo?',
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question: '¿En que ciudad se celebra el Acuerdo?',
        info:
          'Si arriba elegistes Ciudad de Buenos Aires, respondé lo mismo en esta pregunta.',
        type: textarea,
        notBe: {
          id: 1,
          answer: 'Ciudad Autónoma de Buenos Aires',
        },
      },
      {
        id: 3,
        question: '¿En que fecha se celebra el Acuerdo?',
        type: date,
      },
      {
        id: 45,
        question: '¿Firma 1?',
        info: 'Esta debiera ser la primera firma',
        type: question,
      },
      {
        id: 46,
        question: '¿Firma 2?',
        info: 'Esta debiera ser la segunda firma',
        type: question,
      },
      {
        id: 27,
        question: '¿El inmueble incluye cochera y/o baulera?',
        type: multipleOptions,
        options: ['Cochera', 'Baulera', 'Mobiliario'],
      },
      {
        id: 5,
        question: '¿Esta depende de que arriba pongan mobiliario?',
        type: date,
        dependInclude: {
          id: 27,
          answer: 'Mobiliario',
        },
      },
      {
        id: 8,
        question: '¿Cuanta plata?',
        type: money,
      },
    ],
  },
];

export const Test6 = {
  name: 'Test Section 6 - NOT FOR USERS',
  codeName: 'Test6',
  question: '¿Qué es un acuerdo de TEST?',
  definition: [
    'El acuerdo de confidencialidad es un contrato por medio del cual las partes se comprometen a no revelar la información de carácter confidencial que les es suministrada.',
  ],
  price: 199.99,
  firmas: [
    [{ id: 45, section: 'section1' }],
    [{ id: 46, section: 'section1' }],
  ],
  size: '3 Páginas',
  lastRevision: 'May 2020',
  sections: TestSections,
  url: 'test6',
  video: {
    image: 'Nda.jpg',
  },
  useCases: {
    good: [
      'Compartir información confidencial sobre un proyecto',
      'Solicitar reserva sobre cierta información a alguien',
      'Asegurar la confidencialidad de cierto bloque de datos',
    ],
    bad: [
      'Generar reserva sobre información de publico conocimiento',
      'Generar reserva sobre información que no es propia',
    ],
  },
};
