import React from 'react';
import Image from '../../../../../uiComponents/Image';
import { setDetails } from '../../../../../redux/fileDetail/fileDetail.actions';
import { connect } from 'react-redux';
import { truncate } from '../../../../../Utils/textTruncate';

import './styles.css';

const NameField = ({ field, fieldName, edit, setDetails, details, format }) => {
  const getImageName = () => {
    if (format === 'pdf') {
      return 'pdf2-icon.png';
    }
    if (format === 'xls' || format === 'xlsx') {
      return 'excel-icon.png';
    }
    if (format === 'ppt' || format === 'pptx') {
      return 'ppt-icon.png';
    }
    if (
      format === 'png' ||
      format === 'jpg' ||
      format === 'jpeg' ||
      format === 'heic'
    ) {
      return 'pic-icon.png';
    }
    return 'word-icon.png';
  };
  return (
    <div className='name-field-wrapper'>
      <Image name={getImageName()} className='name-field-word-icon' />
      <div className='name-field-text-wrapper'>
        <p className='name-field-title'>Nombre del Documeto</p>
        {!edit && (
          <div className='bold name-field'>
            {truncate(field, 37)}
            {field.length > 36 && (
              <div className='name-field-tooltip b-secondary'>{field}</div>
            )}
          </div>
        )}
        {edit && (
          <input
            type='text'
            className='name-field-input'
            maxlength='50'
            defaultValue={details.fileName ? details.fileName : field}
            onChange={(e) => {
              setDetails({ fileName: e.target.value });
            }}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    details: state.details,
  };
};

const mapDispatchToProps = {
  setDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(NameField);
