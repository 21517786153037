import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSignature,
  faChevronCircleRight,
  faCameraRetro,
  faIdCard,
  faMailBulk,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { setAppError } from "../../../../Utils/modalMethods";
import "./styles.css";
import { AndesTooltip } from "../../../../uiComponents/AndesTooltip/AndesTooltip";

const numbersArray = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];

const SignerDataForm = ({
  numberOfSigners,
  setEsignersData,
  setCurrentSection,
}) => {
  const { handleSubmit, getValues, register } = useForm();

  const onSubmit = (data) => {
    let emailsMatch = true;
    let fieldWithEmptySpaces = false;

    numbersArray.forEach((num) => {
      if (num <= numberOfSigners) {
        if (data[`email${num}`].trim() !== data[`confirmEmail${num}`].trim()) {
          emailsMatch = false;
        }
      }
    });

    Object.keys(data).forEach((key) => {
      if (typeof data[key] === "string" && !data[key].trim()) {
        fieldWithEmptySpaces = true;
      }
    });

    if (fieldWithEmptySpaces) {
      setAppError(
        "Campos sin completar",
        "Por favor revise todos los emails campos y completelos"
      );
      return;
    }

    if (!emailsMatch) {
      setAppError(
        "Emails no coinciden",
        "Por favor revise todos los emails y confirmaciones para que coincidan"
      );
      return;
    }

    const eSignerData = [];

    numbersArray.forEach((num) => {
      if (num <= numberOfSigners) {
        const newSigner = {
          name: data[`name${num}`],
          email: data[`email${num}`],
          photo: data[`photo${num}`],
          idCard: data[`idCard${num}`],
          emailValidation: data[`emailValidation${num}`],
          screenSignature: true,
        };
        eSignerData.push(newSigner);
      }
    });

    setEsignersData(eSignerData);
    setCurrentSection(2);
  };
  const onError = () => {
    setAppError(
      "Campos sin completar",
      "Por favor revise todos los emails campos y completelos"
    );
    return;
  };

  const ESignCard = ({ question, children }) => {
    return (
      <div
        className="e-sign-form-card b-white
      "
      >
        <p className="font-l roboto bold margin-b">
          {/* <FontAwesomeIcon
            icon={faChevronCircleRight}
            className="margin-icon primary"
          /> */}
          {question}
        </p>
        {children}
      </div>
    );
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        {numbersArray.map((num) => {
          if (numberOfSigners >= num)
            return (
              <ESignCard question={`Firmante ${num}`}>
                <label>
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="margin-icon primary"
                  />
                  Nombre y apellido completos:
                </label>
                <input
                  {...register(`name${num}`, { required: true })}
                  maxLength={80}
                  className="e-sign-form-input"
                />
                <label>
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="margin-icon primary"
                  />
                  E-mail:
                </label>
                <input
                  {...register(`email${num}`, { required: true })}
                  maxLength={80}
                  className="e-sign-form-input"
                />
                <label>
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="margin-icon primary"
                  />
                  Confirmar E-mail:
                </label>
                <input
                  {...register(`confirmEmail${num}`, { required: true })}
                  maxLength={80}
                  className="e-sign-form-input"
                />
                <div>
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="margin-icon primary"
                  />
                  Requerir al firmante:
                </div>
                <div className="e-sign-form-checkbox-wrapper">
                  <div className="e-sign-form-checkbox">
                    <AndesTooltip
                      title="Firma en pantalla"
                      description="Se requerirá al firmante que firme con su dedo o mouse (Móvil/PC)."
                      icon={faInfoCircle}
                      placement="top"
                    />
                    <input
                      type="checkbox"
                      name={`screenSignature${num}`}
                      defaultChecked
                      disabled
                      {...register(`screenSignature${num}`)}
                      style={{ opacity: 1 }}
                    />
                    <label style={{ opacity: 0.4 }}>
                      <FontAwesomeIcon
                        icon={faSignature}
                        className="margin-icon primary"
                      />
                      Firma en Pantalla
                    </label>
                  </div>
                  <div className="e-sign-form-checkbox">
                    <AndesTooltip
                      title="Identificación con Auto Foto"
                      description="Se requerirá al firmante que se tome una autofoto (Selfie) del rostro para identificar que es la persona que dice ser."
                      icon={faInfoCircle}
                      placement="top"
                    />
                    <input
                      type="checkbox"
                      {...register(`photo${num}`)}
                      defaultChecked
                    />
                    <label>
                      <FontAwesomeIcon
                        icon={faCameraRetro}
                        className="margin-icon primary"
                      />
                      Auto Foto
                    </label>
                  </div>
                  <div className="e-sign-form-checkbox">
                    <AndesTooltip
                      title="Documento de Identidad"
                      description="Se requerirá al firmante que saque una foto de su DNI."
                      icon={faInfoCircle}
                      placement="top"
                    />
                    <input
                      type="checkbox"
                      {...register(`idCard${num}`)}
                      defaultChecked
                    />
                    <label>
                      <FontAwesomeIcon
                        icon={faIdCard}
                        className="margin-icon primary"
                      />
                      Documento de Identidad
                    </label>
                  </div>
                  <div className="e-sign-form-checkbox">
                    <AndesTooltip
                      title="Validación de Email"
                      description="Se requerirá al firmante que valide su email con un código."
                      icon={faInfoCircle}
                      placement="top"
                    />
                    <input
                      type="checkbox"
                      {...register(`emailValidation${num}`)}
                      defaultChecked
                    />
                    <label>
                      <FontAwesomeIcon
                        icon={faMailBulk}
                        className="margin-icon primary"
                      />
                      Solicitar Validacion de Email via Token
                    </label>
                  </div>
                </div>
              </ESignCard>
            );
        })}
        <div className="width100 flex-space-between margin-v-l">
          <div></div>
          <input
            type="submit"
            className="b-primary b-primary-hover e-sign-form-submit"
            value="Siguiente"
          />
        </div>
      </form>
    </div>
  );
};

export default SignerDataForm;
