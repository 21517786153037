import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
  pesos,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const ContratoDeAlquilerSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Contrato",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.",
      ],
    },
    questions: [
      {
        id: 1,
        question: "¿En qué Provincia se celebra el Contrato de Alquiler?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question:
          "¿En que Ciudad/Localidad se celebra el Contrato de Alquiler?",
        info:
          "Si arriba elegistes Ciudad Autónoma de Buenos Aires, respondé lo mismo en esta pregunta.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se celebra el Contrato?",
        type: date,
      },
    ],
  },
  {
    id: "section2",
    section: "Propietario",
    title:
      "Indicar los datos del Propietario que dará en Alquiler su Inmueble.",
    type: person,
    explanation: {
      question: "¿Quién es Propietario del Contrato de Alquiler?",
      answer: [
        "El Propietario es el dueño del Inmueble que lo da en alquiler y recibe a cambio un monto en dinero a cambio.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 4,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 5,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 6,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 7,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 8,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 9,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 10,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 11,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 12,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 13,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section3",
    section: "Inquilino",
    title: "Indicar los datos del Inquilino que alquilará el Inmueble.",
    type: person,
    explanation: {
      question: "¿Quién es el Inquilino del Contrato de Alquiler?",
      answer: [
        "El Inquilino es quien alquilará el Inmueble del Propietario y pagará un alquiler a cambio.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person2",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 14,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              question: "Tipo de Documento:",
              id: 15,
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 16,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 17,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 18,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 19,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 20,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 21,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 22,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 23,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section4",
    section: "Inmueble",
    title: "Detallar el Inmueble que se alquilará",
    type: questions,
    explanation: {
      question: "¿Qué es el Inmueble?",
      answer: [
        "El inmueble puede ser de cualquier tipo apto para ser usado como vivienda (departamento, PH, casa en un barrio privado o abierto, country, etc.) y de cualquier tamaño, condición (nuevo o usado) con o sin cochera y/o amoblado o desamoblado.",
      ],
    },
    questions: [
      {
        id: 24,
        question: "¿En qué Provincia se ubica el Inmueble?:",
        type: options,
        options: provincias,
      },
      {
        id: 25,
        question: "¿En qué Ciudad/Localidad se encuentra el Inmueble?",
        answer: ["Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc."],
        type: question,
        notBe: {
          id: 24,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },

      {
        id: 26,
        question:
          "¿Cuál es la úbicación exacta del Inmueble? (Calle, Número, Departamento, Piso y Unidad)",
        answer: [
          'Si es un Departamento o PH, por ej: Beruti 3574, Piso 3, dpto "A"',
          "Si es una Casa, Lote, etc., por ej: Ramallo 3571, Beccar; ó Blanco Encalada 1200, Barrio Laguna de la Luna, Lote 3525",
        ],
        type: question,
      },
      {
        id: 125,
        question: "¿Con que servicios cuenta el inmueble?",
        type: multipleOptions,
        info:
          "Esto NO implica que vayan a ser pagados por el dueño del inmueble",
        options: [
          "Agua corriente",
          "Electricidad",
          "Gas de red",
          "Telefonía fija",
          "Televisión por cable",
          "Internet",
        ],
      },
      {
        id: 27,
        question: "¿El inmueble incluye cochera y/o mobiliario y/o baulera?",
        type: multipleOptions,
        options: ["Cochera", "Baulera", "Mobiliario"],
      },
      {
        id: 28,
        question: "¿Desea agregar un inventario?",
        type: options,
        dependInclude: {
          id: 27,
          answer: "Mobiliario",
        },
        options: ["Sí", "No"],
      },
      {
        id: 29,
        question: "Detalle los muebles que se van a incluir (inventario)",
        type: textarea,
        dependIncludeDouble: [
          {
            id: 27,
            answer: "Mobiliario",
          },
          {
            id: 28,
            answer: "Sí",
          },
        ],
      },
    ],
  },
  {
    id: "section5",
    section: "Precio, Pago y Duración",
    title: "Precio, Forma de Pago y Duración del Alquiler",
    type: questions,
    explanation: {
      question: "¿Qué es el Precio, la Forma de Pago y la Duración?",
      answer: [
        "El Precio del Alquiler es el dinero que el Inquilino le pagará al Propietario.",
        "La Forma de Pago es la manera que se pagará el Precio del Alquiler.",
        "El Propietario y el Inquilino acuerdan libremente la duración (o plazo) del Contrato. Sin embargo, la Ley no permite que sean menos de 3 años, ni más de 20 años.",
        "Recordamos que el Precio del Alquiler solo puede ser ajustado por indexación, es decir, según lo establezca un Índice que elabora el Banco Central de la Républica Argentina. Este punto ya esta contemplado en el Contrato.",
      ],
    },
    questions: [
      {
        id: 31,
        question: "¿Cúal es el precio del alquiler mensual?",
        answer: [
          "Este contrato de alquiler solo se puede determinar en Pesos Argentinos. ",
          "Recordamos que el Precio del Alquiler solo puede ser ajustado por indexación, es decir, según lo establezca un Índice que elabora el Banco Central de la Républica Argentina. Este punto se encuentra contemplado por Andes Docs en el Contrato.",
        ],
        type: pesos,
      },
      {
        id: 32,
        question: "Forma de Pago ¿De qué manera se pagará el Precio?",
        answer: [
          "Elegir la manera en que se pagará el Precio.",
          "Mensualmente: Se pagará todos los meses.",
          "Anualmente: Se pagará una vez al año.",
        ],
        type: options,
        options: ["Mensualmente", "Anualmente"],
      },
      {
        id: 33,
        question:
          "¿Cuánto tiempo durará el alquiler en Meses? Recordar que el minimo legal son 36 Meses.",
        answer: ["El minimo legal son 36 meses"],
        type: numberOf,
        fixedValue: "meses",
        fixedValueSingular: "mes",
      },
      {
        id: 34,
        question: "¿A partir de qué fecha comenzará el alquiler?",
        answer: [
          "Indicar a partir de que momento inciará el alquiler entre el Propietario y el Inquilino.",
          "Indicar si empieza el mismo día que se firma el Contrato ó si empieza en otra fecha posterior.",
          "En caso que sea otra fecha posterior, deberá indicar cuál.",
        ],
        type: options,
        options: ["Misma fecha que se firma el Contrato", "Otra posterior"],
      },
      {
        id: 35,
        question: "¿En que fecha empieza?",
        type: date,
        depend: {
          id: 34,
          answer: "Otra posterior",
        },
      },
    ],
  },
  {
    id: "section6",
    section: "Condiciones Generales",
    title: "Cláusulas Generales y Jurisdicción",
    type: questions,
    explanation: {
      question: "¿Que vamos a definir?",
      answer: [
        "Vamos a definir la Jurisdicción y ciertas cláusulas generales del Contrato",
      ],
    },
    questions: [
      {
        id: 36,
        question:
          "En caso de conflicto ¿En qué jurisdicción se deberá resolver?",
        answer: [
          "En caso de conflicto, las Partes deberán resolverlo ante un Juez. Definir en que provincia se encontrará el Juez que resolveran el conflicto. (Se suele elegir la misma provincia donde se celebra el Contrato)",
        ],
        type: options,
        options: provincias,
      },
      {
        id: 37,
        question: "¿Desea prohibir alguna de estas actividades en el inmueble?",
        type: multipleOptions,
        options: ["Fumar dentro del inmueble", "Tener Mascotas"],
      },
      {
        id: 38,
        question:
          "¿Las Partes utilizarán un Escribano Público para certificar sus firmas?",
        answer: [
          "Es práctica generalizada que la Propietaria y el Inquilino acuerden que sus respectivas firmas en el contrato de locación sean certificadas por un escribano público en la fecha de firma del contrato de alquiler.",
        ],
        type: options,
        options: ["Sí", "No"],
      },
    ],
  },
  {
    id: "section7",
    section: "Depósito en Garantía",
    title: "En caso de haber, definir la Garantía que se utilice.",
    type: questions,
    explanation: {
      question: [
        "¿Qué es un Depósito en Garantía?",
        "¿Qué es una Garantía? ¿Qué tipo de Garantías hay?",
      ],
      answer: [
        "Depósito en Garantía: Si bien no es obligatorio por ley, es práctica generalizada que al firmarse el Contrato el Inquilino entregue un depósito de garantía a la locadora. La ley establece que su monto en Pesos no debe ser mayor que el primer alquiler. En la fecha cuando termina el contrato por la causa que sea la locadora debe devolverlo a la locataria pero por un monto en Pesos igual al último alquiler ajustado antes de la fecha de terminación del Contrato.",
        "Garantía: Una Garantía es instrumento jurídico que se emplea para asegurar el cumplimiento de una obligación, en este caso para el cumplimiento de las obligaciones del Alquiler.",
        "La Ley prevé distintos tipos de Garantías para los Contratos de Locación, a saber: Título de propiedad inmueble; Aval bancario; Seguro de caución; Garantía de fianza o fiador solidario; y/o Garantía personal del locatario, (Recibo de sueldo, Certificado de ingresos o cualquier otro medio fehaciente).",
      ],
    },
    questions: [
      {
        id: 40,
        question: "¿Se va a dejar un Depósito en Garantía?",
        answer: [
          'En caso que se opté por "Sí", Andes Docs incorporará automáticamente el máximo del Depósito en Garantía que es el equivalente a un (1) Alquiler Mensual según lo establecido por Ley.',
          'En caso que opte por "No", no lo incorporaremos al Contrato.',
        ],
        type: options,
        options: [
          "Sí (El equivalente al primer mes de alquiler)",
          "Si (Otro)",
          "No",
        ],
      },
      {
        id: 141,
        question: "¿Cuál será el monto del depósito en garantía?",
        type: money,
        depend: {
          id: 40,
          answer: "Si (Otro)",
        },
      },
    ],
  },
  {
    id: "section8",
    section: "Garante",
    title: "En caso de haber, definir la Garantía que se utilice.",
    type: questions,
    explanation: {
      question: [
        "¿Qué es un Depósito en Garantía?",
        "¿Qué es una Garantía? ¿Qué tipo de Garantías hay?",
      ],
      answer: [
        "Depósito en Garantía: Si bien no es obligatorio por ley, es práctica generalizada que al firmarse el Contrato el Inquilino entregue un depósito de garantía a la locadora. La ley establece que su monto en Pesos no debe ser mayor que el primer alquiler. En la fecha cuando termina el contrato por la causa que sea la locadora debe devolverlo a la locataria pero por un monto en Pesos igual al último alquiler ajustado antes de la fecha de terminación del Contrato.",
        "Garantía: Una Garantía es instrumento jurídico que se emplea para asegurar el cumplimiento de una obligación, en este caso para el cumplimiento de las obligaciones del Alquiler.",
        "La Ley prevé distintos tipos de Garantías para los Contratos de Locación, a saber: Título de propiedad inmueble; Aval bancario; Seguro de caución; Garantía de fianza o fiador solidario; y/o Garantía personal del locatario, (Recibo de sueldo, Certificado de ingresos o cualquier otro medio fehaciente).",
      ],
    },
    questions: [
      {
        id: 51,
        question:
          "¿Va a haber un Garante (una tercera persona) que garantize que el Contrato se va a cumplir?",
        answer: [
          'El Garante (una tercera persona) garantizará las obligaciones que tiene el Inquilino en el Contrato. En caso que se opté por "Sí", deberá indicar los datos de esta persona.',
        ],
        type: options,
        options: ["Sí", "No"],
      },
      {
        id: 511,
        question:
          "Detallar los datos del Garante ¿Es una persona física o una persona jurídica?",
        type: options,
        options: ["Persona Física", "Persona Jurídica"],
        depend: {
          id: 51,
          answer: "Sí",
        },
      },
      {
        id: 512,
        question: "Nombres y Apellidos Completos:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 513,
        question: "Tipo de Documento:",
        type: options,
        options: ["DNI", "Pasaporte", "Cédula de Identidad"],
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 514,
        question: "Número de Documento:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 515,
        question: "Domicilio Completo:",
        info: "(Calle, Piso, Departamento, Ciudad)",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 516,
        question: "Provincia:",
        type: options,
        options: provincias,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 517,
        question: "Denominación Social (incluyendo tipo societario):",
        info: "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 518,
        question: "Tipo de identificacion:",
        type: options,
        options: ["CUIT"],
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 519,
        question: "Número de CUIT:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 520,
        question: "Domicilio Completo de la sede principal de la entidad:",
        info: "(Calle, Piso, Departamento, Ciudad)",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 521,
        question: "Provincia:",
        type: options,
        options: provincias,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 522,
        question:
          "¿El Garante va a establecer una Garantía para asegurar el Contrato?",
        answer: [
          "La Garantía deberá ser del Garante.",
          'En caso que se opté por "Sí", deberá elegir que garantía presentará entre las opciones que le daremos.',
          'En caso que opté por "No", no lo agregaremos al Contrato.',
        ],
        type: options,
        options: ["Sí", "No"],
        depend: {
          id: 51,
          answer: "Sí",
        },
      },
      {
        id: 523,
        question: "¿Que Garantía se va a dar?",
        answer: [
          "Elegir entre las siguientes opciones. Una vez impreso el Contrato Ud. deberá acompañar una copia de la Garantia elegida al final del Contrato.",
        ],
        type: options,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí",
          },
          {
            id: 522,
            answer: "Sí",
          },
        ],
        options: [
          "Título de propiedad Inmueble",
          "Aval bancario",
          "Seguro de Caución",
          "Recibo de sueldo",
          "Certificado de Ingresos",
          "Pagaré",
        ],
      },
      {
        id: 52,
        question:
          "¿Va a ver una Garantía del Inquilino para asegurar el Contrato?",
        answer: [
          'La Garantía deberá ser del Inquilino. En caso que se opté por "Sí", deberá elegir cuál presenta entre las opciones que le daremos.',
          'En caso que opté por "No", no lo agregaremos al Contrato.',
        ],
        type: options,
        options: ["Sí", "No"],
        depend: {
          id: 51,
          answer: "No",
        },
      },
      {
        id: 53,
        question: "¿Que Garantía se va a dar?",
        answer: [
          "Elegir entre las siguientes opciones. Una vez impreso el Contrato Ud. deberá acompañar una copia de la Garantia elegida al final del Contrato.",
        ],
        type: options,
        doubleDepend: [
          {
            id: 51,
            answer: "No",
          },
          {
            id: 52,
            answer: "Sí",
          },
        ],
        options: [
          "Título de propiedad Inmueble",
          "Aval bancario",
          "Seguro de Caución",
          "Recibo de sueldo",
          "Certificado de Ingresos",
          "Pagaré",
        ],
      },
    ],
  },
];

export const ContratoDeAlquiler = {
  name: "Contrato de Alquiler de Vivienda (Nueva Ley)",
  codeName: "contratoDeAlquier",
  question: "¿Qué es un Contrato de Alquiler de Vivienda?",
  definition: [
    "El Contrato de Alquiler de Vivienda se utiliza para documentar la locación, permanente o temporaria, de un inmueble urbano, con o sin amueblamiento, exclusivamente para vivienda. Este documento cumple los requisitos del Código Civil y Comercial de la Nación y de la Ley N° 27.551.",
    "El Contrato de Alquiler de Vivienda no puede firmarse por menos de tres (3) años (plazo mínimo legal establecido por ley). Según lo establece la Ley N° 27.551, el precio del alquiler se podrá ajustar una (1) vez al año según el índice de actualización que indique el Banco Central de la República Argentina (se encuentra contemplado por Andes Docs en este Contrato).",
  ],
  price: 999.99,
  firmas: [
    [
      { id: 4, section: "section2" },
      { id: 9, section: "section2" },
    ],
    [
      { id: 14, section: "section3" },
      { id: 19, section: "section3" },
    ],
    [
      { id: 512, section: "section8" },
      { id: 517, section: "section8" },
    ],
  ],
  size: "9-12 Páginas",
  lastRevision: "Junio 2020",
  sections: ContratoDeAlquilerSections,
  url: "contrato-de-alquiler",
  video: {
    image: "Alquiler.jpg",
  },
  useCases: {
    good: [
      "Alquiler de un departamento por un plazo mayor a tres (3) años",
      "Alquiler de casa, sea en barrio cerrado o abierto, en country o no, para vivienda",
      "Alquilar un terreno, complejo a largo plazo para vivienda",
    ],
    bad: [
      "Alquilar una casa/departamento para turismo temporal",
      "Alquilar un inmueble por aplicaciones como Airbnb o similar",
      "Alquilar una propiedad para oficina o uso comercial",
    ],
  },
};
