import React from 'react';
import './styles.css';
import { setDetails } from '../../../../../redux/fileDetail/fileDetail.actions';
import { connect } from 'react-redux';

const TextAreaField = ({
  field,
  fieldName,
  edit,
  editable,
  setDetails,
  logicalName,
  details,
}) => {
  return (
    <div>
      {(edit || !field) && (
        <div
          className='text-area-field-wrapper'
          style={!field && !edit ? { marginTop: 50 } : { marginTop: 15 }}
        >
          <i className='far fa-play-circle secondary margin-r'></i>
          <div>
            {fieldName}:{' '}
            {!edit && (
              <span>
                {!field && (
                  <span className='text-area-field-undefined'>Sin Definir</span>
                )}
              </span>
            )}
          </div>
        </div>
      )}
      {field && !edit && (
        <div className='text-area-field-text b-primary'>
          <p className='text-area-field-text-title'>{fieldName}</p>
          <p>{field}</p>
        </div>
      )}
      {edit && editable && (
        <textarea
          type='text'
          className='text-area-field-input'
          maxlength='500'
          onChange={(e) => {
            setDetails({ [logicalName]: e.target.value });
          }}
          defaultValue={details[logicalName] ? details[logicalName] : field}
        />
      )}
      {edit && !editable && (
        <p className='bold text-area-field-not-editable'>
          {field}{' '}
          <span className='text-area-field-not-remark'>- No editable </span>
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    details: state.details,
  };
};

const mapDispatchToProps = {
  setDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextAreaField);
