// src/form-component/FormInputText.tsx
import styles from "./InputForm.module.css";
import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faLightbulb } from "@fortawesome/free-solid-svg-icons";

type Props = {
  label?: string;
  smLabel?: boolean;
  info?: string[] | "";
  isFocused: boolean;
  required?: boolean;
};

const FormLabel: React.FC<Props> = ({
  label,
  smLabel,
  info,
  isFocused,
  required,
}) => {
  return (
    <>
      {label && !smLabel && (
        <Box className={styles.text} id="andes-label">
          <FontAwesomeIcon
            icon={faBolt}
            style={{
              marginRight: 4,
              color: isFocused ? "#b3fda8" : "#a8bbfd",
              transition: "all 0.2s ease",
            }}
            className="primary"
          />
          {label}{" "}
          {!required && (
            <span
              style={{
                color: "#bfbebd",
                fontWeight: 400,
                fontSize: "0.9rem",
                fontStyle: "italic",
              }}
            >
              *Opcional
            </span>
          )}
        </Box>
      )}

      {label && smLabel && (
        <Box className={styles.textSm} mb={-1}>
          {label}
        </Box>
      )}

      {info &&
        info.map((inf: string, index) => (
          <Box
            className={styles.info}
            sx={{
              transform: isFocused ? "translateX(-6px)" : "translateX(-10px)",
              transition: "all 0.2s ease",
            }}
            key={index}
          >
            <FontAwesomeIcon
              icon={faLightbulb}
              style={{
                marginRight: 6,
                color: isFocused ? "#b3fda8" : "#a8bbfd",
                transform: isFocused ? "scale(1)" : "scale(0.8)",
                transition: "all 0.2s ease",
              }}
              className="primary"
            />
            <span style={{ opacity: 0.8 }}>{inf}</span>
          </Box>
        ))}
      {!info && <Box my={0.2} />}
    </>
  );
};

export default FormLabel;
