import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  APIGetWithError,
  APIPostWithError,
} from "../../Services/authenticated";
import { setAppError, setLoading } from "../../Utils/modalMethods";
import { useForm } from "react-hook-form";
import { Box, Button, Stack } from "@mui/material";
import AutomaticForm, { Field } from "../../uiComponents/Form/AutomaticForm";

const CompanyDocumentUploader = () => {
  const [companies, setCompanies] = useState([]);

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const { control, handleSubmit } = useForm();

  const getAllCompanies = async () => {
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const allCompanies = await APIGetWithError(
        "/companies/get-all-companies",
        accesToken
      );
      if (allCompanies && allCompanies.length > 0) {
        setCompanies(allCompanies);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fields: Field[] = [
    {
      name: "documentName",
      label: "Document Name",
      type: "text",
      required: true,
    },
    {
      name: "documentName1",
      label: "Document Name 1",
      type: "text",
      required: true,
    },
    {
      name: "documentName2",
      label: "Document Name 2",
      type: "text",
    },
    {
      name: "company",
      label: "Company",
      type: "options",
      required: true,
      options: companies.map((com: any) => ({
        label: com.companyName,
        value: com.companyId,
      })),
    },
    {
      name: "removeLogo",
      label: "Desea quitar el logo?",
      type: "options",
      options: [
        {
          value: true,
          label: "Si",
        },
        {
          value: false,
          label: "No",
        },
      ],
    },
    {
      name: "password",
      label: "Password",
      type: "number",
      required: true,
    },
  ];

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const finalQuestionData = JSON.parse(
        localStorage.getItem("generator-form-data") || "[]"
      );

      const finalDocumentData = JSON.parse(
        localStorage.getItem("generator-form-document") || "[]"
      );

      const finalSectionData = JSON.parse(
        localStorage.getItem("generator-form-sections") || "{}"
      );

      if (
        finalQuestionData.length < 1 ||
        finalDocumentData.length < 1 ||
        Object.keys(finalSectionData).length < 1
      ) {
        setAppError(
          "Faltan datos para cargar el documento",
          "Debe haber tanto preguntas como documentos"
        );
        return;
      }

      const now = Date.now();

      const {
        documentName,
        documentName1,
        documentName2,
        company,
        removeLogo,
        password,
      } = data;

      const currentCompany: any = companies.find(
        (com: any) => com.companyId === company
      );

      if (!currentCompany) {
        setAppError("No se encontró la empresa seleccionada");
        return;
      }

      const params = {
        documentName,
        documentName1,
        documentName2: documentName2 || "",
        questionData: finalQuestionData,
        documentData: finalDocumentData,
        sectionData: finalSectionData,
        date: now.toString(),
        companyId: currentCompany?.companyId,
        companyName: currentCompany?.companyName,
        removeLogo,
        password,
        generatorVersion: "2.0",
        dataPath: finalDocumentData.dataPath,
      };

      const accesToken = await getAccessTokenSilently();
      const uploadData = await APIPostWithError(
        "/company-docs/create-company-document",
        accesToken,
        params
      );

      if (uploadData.saved) {
        alert("guardado correctamente");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack alignItems="center" width="100vw" pt={4} mb={5}>
      <h2>Cargar documento nuevo</h2>
      <Box mt={2}>* Recordá revisar las preguntas y el documento</Box>
      {isAuthenticated && (
        <Stack
          width={800}
          maxWidth={"90vw"}
          sx={{
            backgroundColor: "#fff",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
            p: 4,
            my: 4,
            borderRadius: 1,
          }}
        >
          <AutomaticForm control={control} fields={fields} />
          <Button
            onClick={() => handleSubmit(onSubmit)()}
            sx={{ mt: 2 }}
            variant="contained"
          >
            Cargar
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default CompanyDocumentUploader;
