import React from "react";
import Image from "../Image";
import "./styles.css";

const SelectorBox = ({
  title,
  description,
  image,
  onClick,
  className,
  style,
  backgroundColor,
  selected,
}) => {
  return (
    <div
      style={style}
      className={`selector-box white selector-box-${selected} ${className} `}
      onClick={onClick}
    >
      {image && (
        <div className="flex-center">
          <Image name={image} className="selector-box-image" />
        </div>
      )}
      <div className="flex-center-v">
        <div className="selector-box-title">{title}</div>
        <div className="selector-box-desc">
          {description && <div>{description}</div>}
        </div>
      </div>
    </div>
  );
};

export default SelectorBox;
