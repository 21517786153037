import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BarElement } from "../../../uiComponents/BarElement/BarElement";

import "./styles.css";

const elements = [
  {
    name: "Documentos Finalizados",
    icon: "fas fa-cloud-download-alt",
    to: "/dashboard/finished",
  },
  // {
  //   name: "Clientes",
  //   icon: "fas fa-users",
  //   to: "/dashboard/alarms",
  // },
  // {
  //   name: "Comenzados",
  //   icon: "far fa-save",
  //   to: "/dashboard/started",
  // },
  {
    name: "Crear nuevo",
    icon: "far fa-file-word",
    to: "/dashboard/documents",
  },
  {
    name: "Firma Electrónica",
    icon: "fas fa-signature",
    to: "/dashboard/esignature",
  },
  {
    name: "Alarmas / Vencimientos",
    icon: "fas fa-stopwatch-20",
    to: "/dashboard/alarms",
  },
  // { name: "Recargas", icon: "far fa-plus-square", to: "/dashboard/recharge" },
  { name: "Mi Perfil", icon: "far fa-address-card", to: "/dashboard/profile" },
  {
    name: "Editor",
    icon: "fas fa-pencil-alt",
    to: "/dashboard/editor",
  },
  {
    name: "Tutoriales",
    icon: "fas fa-graduation-cap",
    to: "/dashboard/tutorials",
  },
  {
    name: "Control de Usuarios",
    icon: "fas fa-users",
    to: "/dashboard/usersManagement",
  },
];

const LeftBar = () => {
  const location = useLocation();
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    if (window.location.href.includes("editor")) {
      setSelected(5);
    }
    if (window.location.href.includes("profile")) {
      setSelected(4);
    }
    if (window.location.href.includes("esignature")) {
      setSelected(2);
    }
    if (window.location.href.includes("finished")) {
      setSelected(0);
    }

    if (window.location.href.includes("documents")) {
      setSelected(1);
    }
    if (window.location.href.includes("alarm")) {
      setSelected(3);
    }
    if (window.location.href.includes("tutorials")) {
      setSelected(6);
    }
    if (window.location.href.includes("usersManagement")) {
      setSelected(7);
    }
  }, [location]);

  return (
    <div className="left-bar b-white">
      {elements.map((element, index) => {
        return (
          <BarElement
            element={element}
            index={index}
            selected={selected}
            setSelected={setSelected}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default LeftBar;
