import React, { useEffect, useState } from "react";
import { APIGet } from "../../../../Services/authenticated";
import IconButton from "../../../../uiComponents/IconButton/IconButton";
import Modal from "../../../../uiComponents/Modal/Modal";
import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import { resetModals, setModal } from "../../../../redux/modal/modal.actions";
import Loader from "react-loader-spinner";
import { APIPost } from "../../../../Services/authenticated";

import "./styles.css";

const Folder = ({ name, onClick }) => {
  return (
    <div
      className="folder-modal-folder b-secondary b-secondary-hover"
      onClick={onClick}
    >
      <i className="far fa-folder-open folder-modal-folder-icon"></i> {name}
    </div>
  );
};

const FolderModal = ({ resetModals, data, setModal }) => {
  const [folders, setFolders] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { getAccessTokenSilently } = useAuth0();

  const getFolders = async () => {
    try {
      const accesToken = await getAccessTokenSilently();
      const allFolders = await APIGet(
        "/folders/get-company-folders",
        accesToken
      );

      if (allFolders.folders && allFolders.folders.length > 0) {
        const sortedArray = allFolders.folders.sort((a, b) => {
          return b.folderId - a.folderId;
        });
        setFolders(sortedArray);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      throw e;
    }
  };

  const onChangeFolder = async (newFolderId) => {
    setIsLoading(true);
    const payload = { ...data.document };
    payload.folder = newFolderId;
    try {
      const accessToken = await getAccessTokenSilently();
      await APIPost("/doc-ref/edit-document", accessToken, payload);
      window.location.reload();
    } catch (e) {
      setIsLoading(false);
      alert("Error, intentelo nuevamente");
    }
  };

  useEffect(() => {
    getFolders();
  }, []);

  return (
    <Modal>
      <div className="folder-modal-body">
        <i
          className="far fa-window-close folder-modal-close"
          onClick={() => resetModals()}
        ></i>
        <p className="bold folder-modal-title">Mover Documento</p>
        <p className="folder-modal-name primary">{data.document.fileName}</p>
        {isLoading && (
          <div>
            <Loader
              type="Bars"
              color="#e8eeff"
              height={60}
              width={60}
              className="folder-modal-loader"
            />
            <span>Cargando ...</span>
          </div>
        )}
        {!isLoading && (
          <div>
            <IconButton
              label="Nueva carpeta"
              icon="fas fa-plus"
              className="folder-modal-new-button"
              color="secondary"
              onClick={() => {
                setModal({
                  folderModal: {
                    show: false,
                    document: null,
                  },
                  newFolderModal: {
                    show: true,
                    document: data.document,
                  },
                });
              }}
            />
            <p>Mover a:</p>
            <div className="folder-modal-container">
              {folders &&
                folders.map((folder) => {
                  return (
                    <Folder
                      name={folder.folderName}
                      onClick={() => {
                        onChangeFolder(folder.folderId);
                      }}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

const mapDispatchToProps = {
  resetModals,
  setModal,
};

const mapStateToProps = (state) => {
  return {
    data: state.modal.folderModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FolderModal);
