import React, { useState } from "react";
import Modal from "../Modal/Modal";
import IconButton from "../IconButton/IconButton";
import "./styles.css";
import Loader from "react-loader-spinner";
import { closeModal } from "../../Utils/modalMethods";
import { useAuth0 } from "@auth0/auth0-react";
import {
  deleteDocumentAlarm,
  markDocumentAlarmAsHandled,
} from "../../Utils/commonTableActions";

import * as tableActions from "../../Utils/commonTableActions";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { deleteOneSigner } from "../../screens/Dashboard/ElectronicSignature/SignatureStatusTable/SignatureRow/SignerActions";

const ConfirmModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();
  const {
    document,
    isAsync,
    confirmMethodName,
    message,
    submessage,
    disclaimer,
    fileName,
    data,
  } = useSelector((state: RootState) => state.modal.confirmModal);
  const onConfirm = async () => {
    if (!confirmMethodName) {
      return;
    }
    if (isAsync) {
      setIsLoading(true);
      const accessToken = await getAccessTokenSilently();
      if (confirmMethodName === "deleteDocumentAlarm" && document) {
        deleteDocumentAlarm(document, accessToken);
      }
      if (confirmMethodName === "markDocumentAlarmAsHandled" && document) {
        markDocumentAlarmAsHandled(document, accessToken);
      }
      if (confirmMethodName === "deleteDocumentAndReference" && document) {
        tableActions.deleteDocumentAndReference(document, accessToken);
      }
      if (confirmMethodName === "deleteSignature" && document) {
        tableActions.deleteSignature(document, accessToken);
      }
      if (confirmMethodName === "deleteOneSigner" && data) {
        deleteOneSigner(data, accessToken);
        closeModal();
      }
    }
  };

  return (
    <Modal>
      <div className="confirmation-modal">
        <i
          className="far fa-window-close confirmation-modal-close"
          onClick={closeModal}
        ></i>
        <p className="confimation-modal-message">
          {message || "¿Está seguro?"}
        </p>
        <p className="confimation-modal-sub-message">
          {submessage || "Confirme si desea proceder con la acción"}
        </p>
        {fileName && <p className="primary bold margin-b">{fileName}</p>}
        <p className="confimation-modal-disclaimer">
          {disclaimer || "*Esta acción no podrá deshacerse"}
        </p>
        {!isLoading && (
          <div className="confirmation-modal-button-box">
            <IconButton
              label="No"
              color="secondary"
              icon="far fa-times-circle"
              onClick={closeModal}
              className={undefined}
              type={undefined}
            />
            <IconButton
              label="Si"
              color="secondary"
              icon="far fa-check-circle"
              onClick={onConfirm}
              className={undefined}
              type={undefined}
            />
          </div>
        )}{" "}
        {isLoading && (
          <Loader type="Bars" color="#e8eeff" height={60} width={60} />
        )}
      </div>
    </Modal>
  );
};

export default ConfirmModal;
