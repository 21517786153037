import React, { useEffect } from "react";
import "./styles.css";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policy-wrapper">
      <div>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "center",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              &nbsp;
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "center",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              &nbsp;
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "19.0pt",
            marginRight: "0cm",
            marginBottom: "29.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "23px",
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              TERMS CONDITIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            This website, www.andesdocs.com (hereinafter "the WEBSITE"),
            provides users with the service of an automatic writing system for
            documents which may be sold individually or through a subscription
            on the WEBSITE, depending on the country for which the USER is
            requesting the documents. The WEBSITE also provide an optional
            service of putting users in touch with lawyers. The services of the
            WEBSITE will hereinafter collectively be referred to as "SERVICES."
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The following provisions define the contractual rights and
            obligations of the operator of the WEBSITE, Southern Legal Tech LLC
            (hereinafter, "SLT" and/or indistinctly “AndesDocs”), and the USERS
            (as hereinafter defined) of the WEBSITE. USER or USERS shall be
            defined as all individuals that access the WEBSITE, whether by
            surfing, reading, viewing, using, and/or purchasing the documents
            and/or subscribing to the services offered on the WEBSITE. The home
            page of the WEBSITE is located at the following URL:
            www.andesdocs.com
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Using the SERVICES of the WEBSITE constitutes full acceptance of
            these Terms and Conditions.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The AndesDocs, and the WEBSITE, do not provide any legal advice.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The design of the documents on the WEBSITE is entirely automated and
            is not the result of any work of writing, editing or modification by
            the director or the employees of the company AndesDocs, the
            company's partners or any other person.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 1 - ACCESS, NAVIGATION AND CONTACT
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Any access, use, subscriptions and/or purchases on the WEBSITE are
            available only for legal entities and natural persons of full age
            and capacity.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs reserves the right to request proof of age from the USER.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs may, at any time, at its sole and exclusive discretion,
            suspend, limit or discontinue the WEBSITE in order to update it or
            change its content.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER hereby agrees to use the WEBSITE solely for personal use
            and, in any event, not to use the WEBSITE, the SERVICES or the
            content of the WEBSITE for any illegal purpose, commercial purpose,
            advertising or spam.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            For any questions or information about the products presented on the
            WEBSITE or the WEBSITE itself, or to report illegal content or
            activities, the USER may send an email to: help@andesdocs.com.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 2 - LIABILITY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs's sole and exclusive responsibility shall be in the
            specific content AndesDocs provides to USER through the WEBSITE and
            SERVICES. Any inaccurate, incomplete, or otherwise incorrect
            information added by the USER or incorrect usage of the WEBSITE and
            SERVICES (including, but not limited to, incorrect usage of
            documents purchased on the WEBSITE) shall be the exclusive
            responsibility of the USER, with no liability on the part of
            AndesDocs.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs shall not be liable in the following cases:
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Technical or computer failure. The WEBSITE's incompatibility with
            any hardware or software shall not trigger any liability on the part
            of AndesDocs.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Internet connection failure.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Direct or indirect, material or consequential, foreseeable or
            unforeseeable damage to the USER arising from the use or difficulty
            to use the WEBSITE or its SERVICES.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The circulation of any inaccurate, non-secure or unreliable
            information on the Internet.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Illegal use of the WEBSITE.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs does not guarantee the effectiveness, completeness,
            accuracy, result and/or effectiveness of the documents that the USER
            may create on the WEBSITE.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs shall not be held liable for any error, omission or
            inaccuracy of the proposed templates and any damage suffered by the
            USER who has created a document on the website.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER is responsible for:
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Protecting its equipment and data;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The use made of the WEBSITE or SERVICES;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The observance of the Legal Disclaimer;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Any breach of these Terms And Conditions.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER specifically acknowledges that USER bears the entire
            responsibility for the selection, use, interpretation and
            personalization of the document USER has created using the service
            provided by the WEBSITE.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER acknowledges that the WEBSITE does not provide any legal
            advice, that is not a law firm and therefore that the use of the
            WEBSITE cannot be considered a legal opinion or replace one.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Therefore, for any legal matter, the USER must contact competent
            legal professionals, who are duly authorized by the law in their
            respective jurisdictions to conduct legal consulting.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 3 - INTELLECTUAL PROPERTY
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            All of the WEBSITE's elements (including ergonomics, texts, graphs,
            images, sounds, videos, programming elements, database - this list
            shall not be considered exhaustive), together or separately, are the
            exclusive property of AndesDocs, protected pursuant to the moral and
            patrimonial rights belonging to their author(s).
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Access to the WEBSITE does not represent the recognition of a right,
            and does not confer any rights, including any intellectual property
            rights relating to any of the elements of the WEBSITE, which remain
            AndesDocs´s exclusive property.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Any representation, reproduction, adaptation or partial or total
            exploitation of the contents, trademarks, and services provided by
            the WEBSITE by any means whatsoever without the prior express and
            written consent of AndesDocs is strictly prohibited.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER is forbidden to enter data on the WEBSITE that would likely
            alter its content or appearance.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 4 - SUBSCRIPTION
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            USERS have the option to access the SERVICES by using a subscription
            in order to benefit from the access to the catalog of templates and
            to create documents in accordance to the type of subscription
            chosen.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            If subscription service is not provided in the country for which you
            are requesting documents, the terms in this provision are not
            relevant.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            This subscription is completed by creating an account, in which the
            USER must choose a valid method of payment. The frequency of the
            subscription is selected by the USER according to the options
            offered by the WEBSITE. The subscription to the WEBSITE is
            automatically renewed before the end of each subscription period
            until termination. The USER may receive a free trial period if the
            WEBSITE offers one. After this trial period, the USER is
            automatically debited the amount specified on the WEBSITE. This
            amount may vary. The USER also has the option to terminate the
            subscription at any time. However, any termination during the trial
            period prevents the USER from being entitled to benefit from one
            again. Thus, if the USER wishes to benefit again from the SERVICES,
            no new trial period will be granted. If the USER wishes to terminate
            the subscription before the renewal date, the USER will continue to
            access the SERVICES offered by the WEBSITE until the end of the
            current billing period. There will be no refund granted for periods
            of partial use of the SERVICES. The USER may choose to reactivate
            their account after termination if desired.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 5 - PRICE
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            5.1 Purchase of individual documents
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The WEBSITE provides USERS with a catalog of templates to fill out
            to create documents. Prices to buy the templates are indicated in
            the catalog. AndesDocs reserves the right to change its prices at
            any time by posting them online. The only prices applicable to USER
            transactions are the ones in effect at the time the purchase is
            made. The prices are quoted in the official currency of the country
            where the SERVICES are made available. In the Argentina Republic
            (hereinafter "ARG"), the prices are quoted in Argentine Peso, all
            taxes included.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The prices include the taxes applicable on the date of the purchase
            and any change in the rate of these taxes will be reflected in the
            price of the templates in the catalog. The total amount of the
            purchase, including all taxes, is indicated before the final
            confirmation of the purchase order. The payment of the full price
            must be made when the purchase is made.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            5.2 Subscription
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The WEBSITE may provide subscription services to USERS in certain
            nations. If subscription services are provided, the terms outlined
            in this subsection will apply to govern the subscription services.
            If subscription services are not provided to USERS in your nation,
            the terms in this provision will not be relevant. The WEBSITE may
            provide USERS with the ability to subscribe in order to benefit from
            the proposed SERVICES and therefore have unlimited access to the
            WEBSITE catalog. The subscription enables the USER to allow
            AndesDocs to periodically debit a financial account that the USER
            has provided, depending on the subscription the USER has
            chosen.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The price of the subscription is indicated in the official currency
            of the country where the subscription services are made available,
            all taxes included. The price of the subscription will be the one in
            effect at the time of the USER's initial subscription. The WEBSITE
            reserves the right to change the offer and the price of the
            subscription, after notifying the USER.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            When the USER chooses to subscribe, a free trial period may be
            available, if the WEBSITE chooses to offer one. After this trial
            period, the USER will be charged with the amount corresponding to
            the cost of the subscription, unless the USER decides to terminate
            its subscription before the end of the free trial period.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 6 - PAYMENT
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            6.1 Purchase of individual documents
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            When the USER chooses to buy an individual document, the USER makes
            the payment at the time of the final confirmation of the purchase.
            Upon receipt of confirmation of the purchase and payment by the
            USER, the latter will receive an electronic invoice to the email
            address personally specified upon requested.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            6.2 Subscription
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The terms in this subpart only apply if subscription services are
            available in the country where the USER is accessing the SERVICES.
            At the time of the USER's initial choice to subscribe, the USER
            gives his banking and/or credit or debit card information for the
            direct debit which is conducted at each subscription period. The
            subscription fee to the SERVICES will be charged periodically
            according the starting date of the first subscription period. The
            billing schedule may nevertheless be subject to changes. The USER
            may choose to change the payment method by requesting AndesDocs,
            whom will not unreasonably deny it.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            If a payment fails because of the expiration of the USER's card,
            insufficient funds or for any other reason, the WEBSITE reserves the
            right to suspend the USER's access to the proposed SERVICES, until
            obtaining a valid payment method.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            6.3 Financial permissions
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER guarantees to AndesDocs that the USER has the necessary
            permissions to use the payment method chosen and recognizes that the
            transmission to AndesDocs of the USER's banking information is
            evidence of its ability and consent to the sale, and to the payment
            due pursuant to the purchase. AndesDocs does not save the details of
            the USER's credit cards, but the proof of the transaction is
            archived. Should the accredited bodies refuse to authorize the
            payment by credit card or in case of non-payment, AndesDocs reserves
            the right to suspend or cancel the purchase and its delivery.
            AndesDocs also reserves the right to refuse a purchase made by a
            USER who has not fully paid its purchase or has not fully paid a
            previous purchase or with whom a dispute is unsettled. AndesDocs is
            not liable for payment problems related to the operation of the
            SERVICES. The USER acknowledges sole responsibility for any problems
            related to the payment method chosen to access the SERVICES.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 7 – OPTIONAL LEGAL ADVICE&nbsp;
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The WEBSITE may offer the USER the possibility of speaking with a
            lawyer. The WEBSITE only connects the USER with the lawyer, but in
            no case does it advise the USER in any way whatsoever.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Under these Terms And Conditions, this service of connecting with a
            lawyer enables the USER to get a verbal advice of a lawyer for a
            period of 30 (thirty) minutes. This service is optional, and must be
            deliberately chosen by the USER if the USER wishes to benefit from
            it. Beyond the allotted 30 (thirty) minutes, the USER may choose to
            continue to benefit from the advice of the lawyer without going
            through the service offered by the WEBSITE, but instead directly
            through the lawyer.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER acknowledges that any personal information given by the
            USER to the lawyer is willingly provided, and the WEBSITE is not
            liable or responsible for such information disclosure. The WEBSITE
            does not guarantee the validity and accuracy of the information
            submitted by the lawyers, nor their work, nor their legal advice.
            The WEBSITE shall not be held liable for any legal advice or
            information provided by the lawyer.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER and lawyers guarantee to protect and indemnify the WEBSITE
            and AndesDocs against all claims, demands, losses, actions, suits,
            judgments, costs, expenses, and damages of any nature whatsoever
            arising directly or indirectly from the USER's utilization of the
            WEBSITE SERVICES which allow the USER to contact a lawyer.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 8 - RIGHT OF WITHDRAWAL
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER may not assert a right of withdrawal once the document has
            been made available to the USER.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 9 – USER ACCOUNT
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The registration on the WEBSITE is free and open to any legal entity
            or natural person of full age and capacity, with a valid and active
            email address. All the information provided by the USER must be
            accurate and each USER can open only one account. A USER wishing to
            register on the WEBSITE must choose a password which is strictly
            confidential and personal. It is up to the latter to keep it
            absolutely secret. The USER may close its account automatically by
            sending a message to the following address:&nbsp;
          </span>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            <a href="mailto:help@andesdocs.com">help@andesdocs.com</a>
          </span>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            . AndesDocs reserves the discretionary right to delete a USER
            account for any reason.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 10 - PERSONAL DATA
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            10.1 Personal data collected&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs will securely collect the following personal data when a
            USER purchases a document or monthly subscription:
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • A proof of purchase for the item selected;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Internal invoices used by AndesDocs for tax purposes;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The USER’s email address and cellphone;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Any document ordered or saved by the USER.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Please note that if a USER selects a monthly subscription, AndesDocs
            will store information related to the sale with internal accounting
            records.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs collects and stores the personal data that we do so that
            we can continue offering the SERVICES on our WEBSITE.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            When initially creating a USER account, the following information
            may be collected and stored:
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The USER’s first and last name;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The USER’s mailing address.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            If a USER chooses not to provide this information, access to the
            SERVICES offered by AndesDocs will still be permitted. The
            information noted above is not required.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            10.2 Web Hosting&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs´ web host is Vercel Inc., 340 S Lemon Ave #4133, Walnut
            CA, 91789. Email: &nbsp;
          </span>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            <a href="mailto:support@vercel.com">
              <span style={{ color: "#343843", textDecoration: "none" }}>
                support@vercel.com
              </span>
            </a>
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Personal data collected by AndesDocs is exclusively hosted and
            stored in Mongo DB Atlas.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            10.3 Personal data passed to third parties
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            No personal data collected by AndesDocs is ever shared with any
            third parties.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            10.4 Data Controller
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The Data Controller at AndesDocs is SLT. They can be contacted as
            indicated below:&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • E-mail address: help@andesdocs.com;&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • Registered mail: 201 Alhambra Circle, Coral Gables, FL
            33134;&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The&nbsp;
          </span>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            <a href="https://www.wonder.legal/mx/pages/ecrivez-nous.php">
              <span style={{ color: "#343843", textDecoration: "none" }}>
                contact form
              </span>
            </a>
          </span>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            &nbsp;available at AndesDocs (this is the preferred means of
            contact).&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The Data Controller is responsible for any and all decision-making
            regarding the collection of personal data, such as the means and
            purposes of processing, as well as the storage and protection of the
            personal data.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            10.5 Duties of the&nbsp;Data Controller
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The&nbsp;Data Controller is committed to the protection and security
            of all USERS’ personal data. This includes overseeing all personal
            data and ensuring that it is never shared with third parties without
            a USER’s valid, freely given, unambiguous consent. The Data
            Controller’s responsibilities also include ensuring that all USERS’
            personal data is used explicitly as described in this policy.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The Data Controller is also the party responsible for ensuring that
            personal USER data is properly adjusted or deleted when a USER
            requests, unless doing so would lead to a disproportionately large
            cost or effort.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            If the integrity, confidentiality or security of personal USER data
            are compromised, the Data Controller will be the party responsible
            for informing all USERS.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            10.6 USER’s rights in relation to personal data collected
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Under relevant laws regarding data collection and processing, USERS
            have the following rights:&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The right to obtain, access, and/or rectify personal data
            collected by AndesDocs;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The right to erasure of personal data collected by AndesDocs;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The right to the restriction of personal data processing by
            AndesDocs;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The right to personal data portability;&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The right to object to automated individual decision-making;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            • The right to decide the fate of the collected personal data after
            a USER’s death.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            If there are questions about a USER’s rights, that USER may contact
            the Data Controller using the email address utilized for the
            purchase of a document or subscription. The&nbsp;Data Controller
            will respond to the USER in a reasonable time frame, but in no event
            later than 30 days.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The USER may also bring complaints regarding the processing of
            personal data to a court of competent jurisdiction or the relevant
            data protection authority. This includes if a USER believes any
            rights have been infringed.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            10.7 Cookies
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs may use cookies. A cookie is a small file stored on the
            USER’s computer by AndesDocs while the USER is using the
            WEBSITE.&nbsp;Cookies are used to improve the browsing experience of
            the USER. The cookies AndesDocs uses are “session cookies” and do
            not require USER consent. Cookies used by AndesDocs are deleted once
            the USER closes the browser that was used to access the WEBSITE.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 11 - SEVERABILITY OF THE PROVISIONS
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            The invalidity of one or more of these provisions does not
            invalidate any other provision within these Terms And Conditions.
            The remainder of the provisions, which are still valid, shall remain
            in full and force and effect.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 12 – WAIVER
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            Should AndesDocs fail to enforce one or more of the provisions of
            this document, it shall not entitle the USER not to comply with that
            provision or any other provision of these Terms And Conditions, and
            shall not constitute or be deemed a final waiver of the assertion of
            AndesDocs´ rights.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 13 - AMENDMENT TO THE GENERAL TERMS AND CONDITION OF USE
              AND SALE
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs has a right to unilaterally amend the Terms And Conditions
            and to post an updated or edited version of the Terms And Conditions
            on the WEBSITE. The USER hereby agrees to read the Terms And
            Conditions each time the USER accesses the WEBSITE. AndesDocs
            undertakes no obligation to specifically inform the USER about any
            changes to the Terms And Conditions. Continued use of the WEBSITE by
            the USER constitutes a continued acceptance of these Terms And
            Conditions. The conditions applicable are those stated on the
            WEBSITE when the USER uses any of the SERVICES of the WEBSITE or the
            WEBSITE itself.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              ARTICLE 14 - APPLICABLE LAW, LANGUAGE AND THE COMPETENT
              JURISDICTION PROVISION
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            AndesDocs is a company registered in Florida, USA.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            USER hereby submits to the jurisdiction of Argentina and
            acknowledges fully that these Terms And Conditions are governed by
            the laws of the nation of Argentina (without regard to conflict of
            law provisions) and that no other foreign or international laws
            shall apply, including: (i) the United Nations Convention on
            Contracts for the International Sale of Goods; (ii) the 1974
            Convention on the Limitation Period in the International Sale of
            Goods; and (iii) the Protocol amending the 1974 Convention, done at
            Vienna April 11, 1980.
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            USER further acknowledges that any dispute resolution required,
            including arbitration, litigation or another method, shall
            exclusively be done in English. Specifically, any litigation under
            these Terms And Conditions will be exclusively before a court of
            competent jurisdiction in Argentina.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "0cm",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            To the fullest extent permitted by law and especially in case of any
            dispute between the parties, the controlling language for these
            Terms And Conditions is English.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
            textAlign: "justify",

            verticalAlign: "baseline",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "#343843" }}>
            In case of any dispute or claim, however, before undertaking any
            method involving a third party, USER hereby agrees to first contact
            AndesDocs directly so that the parties may come to an amicable
            solution.
          </span>
        </p>
        <p
          style={{
            marginTop: "0cm",
            marginRight: "0cm",
            marginBottom: "8.0pt",
            marginLeft: "0cm",
            lineHeight: "115%",
            fontSize: "15px",
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif' }}>&nbsp;</span>
        </p>
      </div>{" "}
    </div>
  );
};

export default TermsAndConditions;
