import React from "react";
import { Box, Checkbox, Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import { colorPallette } from "../../Common/theme";

const FromCheckboxOption: React.FC<any> = ({
  name,
  control,
  label,
  setIsParentFocused,
}) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        // defaultValue={defaultValue}
        render={({
          field: { onChange, value },
          fieldState: { error },
          formState,
        }) => (
          <Stack
            direction="row"
            alignItems="center"
            bgcolor={value ? colorPallette.secondary : colorPallette.tertiary}
            my={2}
            py={1}
            borderRadius={2}
            onClick={(e) => {
              onChange(!value);
            }}
            sx={{
              cursor: "pointer",
              transition: "0.12s",
              ":hover": {
                backgroundColor: "#cbfdb4",
                transform: "scale(1.02)",
              },
            }}
          >
            <Checkbox
              value={value}
              onChange={onChange}
              checked={value ? true : false}
              onFocus={() => setIsParentFocused(true)}
              onBlur={() => setIsParentFocused(false)}
              sx={{
                svg: { color: colorPallette.primary },
              }}
            />
            <Box>{label}</Box>
          </Stack>
        )}
      />
    </div>
  );
};

export default FromCheckboxOption;
