import { NDA } from './NdaQuestions';
import { ContratoDeServicios } from './ContratoServicioQuestions';
import { ContratoDeAlquiler } from './ContratoDeAlquilerQuestions';
import { ContratoDeTrabajoAPlazoFijo } from './ContratoTrabajoPlazoFijoQuestions';
import { ContratoDePrestamoDeDinero } from './ContratoPrestamodeDineroQuestions';
import * as testQuestions from './TestQuestions';
import * as testQuestions1 from './TestQuestions1';
import * as testQuestions2 from './TestQuestions2';
import * as testQuestions3 from './TestQuestions3';
import * as testQuestions4 from './TestQuestions4';
import * as testQuestions5 from './TestQuestions5';
import * as testQuestions6 from './TestQuestions6';
import * as testQuestions7 from './TestQuestions7';
import { ContratoDeAlquilerTemporal } from './ContratoAlqTemporalQuestions';
import { Comodato } from './ComodatoQuestions';
import { Pagare } from './PagareQuestion';
import { ReservaDeLocacion } from './ReservadeLocacionQuestions';
import { Recibo } from './ReciboQuestions';
import { ContratoDeAlquilerNormal } from './ContratoDeAlquilerNormalQuestions';

// it is important that the variable is exported with the same name as definied in codeName property
// of the original import

export const nda = NDA;
export const contratoDeServicios = ContratoDeServicios;
export const contratoDeAlquier = ContratoDeAlquiler;
export const contratoDeTrabajo = ContratoDeTrabajoAPlazoFijo;
export const contratoDePrestamo = ContratoDePrestamoDeDinero;
export const contratoDeAlquilerTemporal = ContratoDeAlquilerTemporal;
export const Test = testQuestions.Test;
export const Test1 = testQuestions1.Test1;
export const Test2 = testQuestions2.Test2;
export const Test3 = testQuestions3.Test3;
export const Test4 = testQuestions4.Test4;
export const Test5 = testQuestions5.Test5;
export const Test6 = testQuestions6.Test6;
export const Test7 = testQuestions7.Test7;
export const comodato = Comodato;
export const pagare = Pagare;
export const reservaDeLocacion = ReservaDeLocacion;
export const recibo = Recibo;
export const contratoDeAlquilerNormal = ContratoDeAlquilerNormal;
