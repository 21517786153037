import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { colorPallette } from "../../Common/theme";

type Document = {
  id: string;
  fileName: string;
  expirationDate: number | string;
  daysToExpiration?: number;
};

type Props = {
  open: boolean;
  onClose: () => void;
};

const filterAlarmDocuments = (documents: Document[], range = 7) => {
  const today = Date.now();

  return documents.filter((doc) => {
    let expDate = doc.expirationDate;
    if (typeof expDate === "string") {
      expDate = parseInt(expDate);
    }
    const daysToExpiration = Math.floor(
      (expDate - today) / (1000 * 60 * 60 * 24)
    ); // Convert to days

    return daysToExpiration <= range && daysToExpiration >= -range;
  });
};

export const AlarmsModal: React.FC<Props> = ({ open, onClose }) => {
  const history = useHistory();
  const { documents } = useSelector((state: any) => state.masterData);
  const [alarmDocuments, setAlarmDocuments] = useState<Document[]>([]);

  useEffect(() => {
    if (documents && documents.length) {
      const filteredDocuments = filterAlarmDocuments(documents, 7);
      const today = Date.now();

      const documentsWithDays = filteredDocuments
        .map((doc: any) => {
          let expDate = doc.expirationDate;
          if (typeof expDate === "string") {
            expDate = parseInt(expDate);
          }
          const daysToExpiration = Math.floor(
            (expDate - today) / (1000 * 60 * 60 * 24)
          ); // Convert to days
          const days = daysToExpiration + 1;

          return { ...doc, daysToExpiration: days };
        })
        .sort(
          (a: Document, b: Document) =>
            (a.daysToExpiration ?? 0) - (b.daysToExpiration ?? 0)
        );

      setAlarmDocuments(documentsWithDays);
    }
  }, [documents]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          height: 500,
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: "10px",
        }}
      >
        <i
          className="far fa-window-close confirmation-modal-close"
          onClick={onClose}
        ></i>
        <Typography
          id="modal-modal-title"
          variant="h5"
          fontWeight="bold"
          component="h2"
        >
          {alarmDocuments.length > 0
            ? "Aviso de vencimientos recientes"
            : "No tienes vencimientos próximos pendientes"}
        </Typography>
        <Box sx={{ mt: 2, pt: 6, overflowY: "auto", maxHeight: "60vh" }}>
          {alarmDocuments.length > 0 ? (
            alarmDocuments.map((doc) => (
              <Box
                key={doc.id}
                display="flex"
                justifyContent="space-between"
                sx={{
                  py: 2,
                  borderBottom: "solid 1px #e4eaf5",
                }}
                gap={10}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "350px",
                    textAlign: "left",
                  }}
                >
                  {doc.fileName}
                </Typography>
                {doc.daysToExpiration !== undefined && (
                  <>
                    <Typography
                      fontWeight="bold"
                      color={doc.daysToExpiration <= 0 ? "error" : "inherit"}
                    >
                      {doc.daysToExpiration <= 0
                        ? `Hace ${-doc.daysToExpiration} días vencido`
                        : `Vence en ${doc.daysToExpiration} días`}
                    </Typography>
                  </>
                )}
              </Box>
            ))
          ) : (
            <Typography
              sx={{ color: colorPallette.primary, fontWeight: "bold" }}
            >
              No tienes vencimientos próximos pendientes
            </Typography>
          )}
        </Box>
        <Button
          onClick={() => {
            history.push("/dashboard/alarms");
            onClose();
          }}
          sx={{ px: 10, mt: 5, width: "100%" }}
          variant="contained"
        >
          Ver vencimientos
        </Button>
      </Box>
    </Modal>
  );
};
