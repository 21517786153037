import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
} from "./types.js";

export const ContratoDePrestamoDoc = [
  [
    {
      type: title,
      text: "CONTRATO DE PRÉSTAMO DE DINERO - MUTUO",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: false,
    },
    {
      type: bold,
      text: "Contrato de Préstamo de Dinero ",
      depend: false,
      notBe: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text:
        "(en adelante, el “Contrato”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: bold,
      text: "Contrato de Préstamo de Dinero ",
      depend: false,
    },
    {
      type: text,
      text:
        '(en adelante, el "Contrato") se celebra en la ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:',
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Acreedor",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[4].answer}, ${answers.section2[5].answer} N° ${answers.section2[6].answer}, ${answers.section2[151].answer} N° ${answers.section2[152].answer}, con domicilio sito en ${answers.section2[7].answer}, ${answers.section2[8].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Acreedor",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[9].answer}, ${answers.section2[10].answer} N° ${answers.section2[11].answer}, con domicilio sito en ${answers.section2[12].answer}, ${answers.section2[13].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Deudora",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[14].answer}, ${answers.section3[15].answer} N° ${answers.section3[16].answer}, ${answers.section3[161].answer} N° ${answers.section3[162].answer}, con domicilio sito en ${answers.section3[17].answer}, ${answers.section3[18].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Deudora",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[19].answer}, ${answers.section3[20].answer} N° ${answers.section3[21].answer}, con domicilio sito en ${answers.section3[22].answer}, ${answers.section3[23].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Garante",
      depend: true,
      question: {
        section: "section6",
        number: 511,
        answer: "Persona Física",
      },
    },
    {
      type: text,
      text:
        ": ${answers.section6[512].answer}, ${answers.section6[513].answer} N° ${answers.section6[514].answer}, con domicilio sito en ${answers.section6[515].answer}, ${answers.section6[516].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Garante",
      depend: true,
      question: {
        section: "section6",
        number: 511,
        answer: "Persona Jurídica",
      },
    },
    {
      type: text,
      text:
        ": ${answers.section6[517].answer}, ${answers.section6[518].answer} N° ${answers.section6[519].answer}, con domicilio sito en ${answers.section6[520].answer}, ${answers.section6[521].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Deudor y el Acreedor podrán ser denominadas conjuntamente como las “Partes” e individualmente como la “Parte”.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes acuerdan que el presente Contrato se regirá por los siguientes términos y condiciones:",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA PRIMERA: DEFINICIONES.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"CPCyCN": significa el Código Procesal Civil y Comercial de la Nación.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Cuenta Bancaria del Acreedor": significa la cuenta bancaria abierta a nombre del Acreedor que se detalla a continuación: ${answers.section4[33].answer}.',
      depend: true,
      question: {
        section: "section4",
        number: 32,
        answer: "En la cuenta bancaria del Acreedor",
      },
    },
  ],
  [
    {
      type: text,
      text:
        '"Cuenta Bancaria del Deudor": significa la cuenta bancaria abierta a nombre del Deudor que se detalla a continuación: ${answers.section4[26].answer}.',
      depend: true,
      question: {
        section: "section4",
        number: 25,
        answer: "Se depositará o transferirá a una cuenta bancaria del Deudor",
      },
    },
  ],
  [
    {
      type: text,
      text:
        '"Día Hábil": significa el día en el cual se lleven a cabo normalmente operaciones bancarias, financieras y cambiarias en la ciudad del domicilio del Deudor y se entenderá por día inhábil aquel que no sea día hábil.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Fecha de Vencimiento del Contrato": tiene el significado establecido en la "Cláusula Tercera: Plazo. Amortización".',
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEGUNDA: MONTO DEL PRÉSTAMO.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Por medio del presente, el Deudor solicita, y el Acreedor se compromete a otorgarle, en un desembolso, y sujeto a los términos y condiciones del Contrato, un préstamo por la suma total de ${answers.section4[24].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El desembolso del préstamo se realizará mediante transferencia o depósito bancario en la Cuenta Bancaria del Deudor ",
      depend: true,
      question: {
        section: "section4",
        number: 25,
        answer: "Se depositará o transferirá a una cuenta bancaria del Deudor",
      },
    },
    {
      type: text,
      text:
        "el ${answers.section4[28].answer}. La acreditación de los fondos en la Cuenta Bancaria servirá como suficiente recibo por la suma desembolsada por el Acreedor.",
      depend: true,
      question: {
        section: "section4",
        number: 27,
        answer: "Otra posterior",
      },
      stopRemoval: true,
    },
    {
      type: text,
      text:
        "en la fecha indicada en el encabezado del presente Contrato. La acreditación de los fondos en la Cuenta Bancaria servirá como suficiente recibo por la suma desembolsada por el Acreedor.",
      depend: true,
      question: {
        section: "section4",
        number: 27,
        answer: "Misma fecha en que se firma el Contrato",
      },
      stopRemoval: true,
    },
  ],
  [
    {
      type: text,
      text:
        "El desembolso total del préstamo se realiza en este acto, sirviendo el presente documento como suficiente recibo.",
      depend: true,
      question: {
        section: "section4",
        number: 25,
        answer: "Se otorgará en efectivo en mano",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA TERCERA: PLAZO Y TERMINACIÓN.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Plazo. Amortización",
      depend: false,
    },
    {
      type: text,
      text:
        ": La totalidad del capital objeto del préstamo deberá ser abonado por el Deudor en un único pago al vencimiento del préstamo, que será el ${answers.section4[30].answer}(en adelante, “Fecha de Vencimiento del Contrato”).",
      depend: true,
      question: {
        section: "section4",
        number: 29,
        answer: "En un único pago al finalizar el Préstamo",
      },
      stopRemoval: true,
    },
    {
      type: text,
      text:
        ": La totalidad del capital objeto del préstamo deberá ser abonado por el Deudor en dos (2) pagos de cincuenta por ciento (50%) cada uno. El primero se realizará a la mitad del plazo entre la fecha establecida en el encabezamiento y el ${answers.section4[30].answer}. El segundo pago deberá realizarse el ${answers.section4[30].answer}(en adelante, “Fecha de Vencimiento del Contrato”).",
      depend: true,
      question: {
        section: "section4",
        number: 29,
        answer:
          "En dos (2) pagos. El primero a la mitad del Préstamo, el segundo al finalizar",
      },
      stopRemoval: true,
    },
    {
      type: text,
      text:
        ": La totalidad del capital objeto del préstamo deberá ser abonado por el Deudor previo al ${answers.section4[30].answer} (en adelante, “Fecha de Vencimiento del Contrato”) y según el cronograma de pagos que se establece a continuación, a saber: ${answers.section4[31].answer}.",
      depend: true,
      question: {
        section: "section4",
        number: 29,
        answer:
          "Según cronograma de pagos distinto de las opciones anteriores (Ud. deberá detallar el cronograma)",
      },
      stopRemoval: true,
    },
  ],
  [
    {
      type: bold,
      text: "Intereses Compensatorios",
      depend: false,
    },
    {
      type: text,
      text:
        ": La suma de capital adeudada bajo el préstamo devengará intereses a una ${answers.section5[35].answer} del ${answers.section5[36].answer} por ciento nominal sobre saldo de capital. En caso de que la tasa precedente resulte violatoria de alguna ley de usura vigente, la tasa de interés aplicable será la máxima tasa de interés permitida por dicha ley.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Los intereses serán pagaderos a la Fecha de Vencimiento del Contrato.",
      depend: true,
      question: {
        section: "section5",
        number: 37,
        answer: "En un único pago al finalizar el Préstamo",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "La totalidad de los intereses objeto del préstamo deberán ser abonado por el Deudor en dos (2) pagos de cincuenta por ciento (50%) cada uno. El primero se realizará a la mitad del plazo entre la fecha establecida en el encabezamiento y la Fecha de Vencimiento del Contrato. El segundo pago deberá realizarse en la Fecha de Vencimiento el Contrato.",
      depend: true,
      question: {
        section: "section5",
        number: 37,
        answer:
          "En dos (2) pagos. El primero a la mitad del Préstamo, el segundo al finalizar",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Los intereses serán pagaderos previo a la Fecha de Vencimiento del Contrato y según el siguiente cronograma de pagos: ${answers.section5[38].answer}.",
      depend: true,
      question: {
        section: "section5",
        number: 37,
        answer:
          "Según cronograma de pagos distinto de las opciones anteriores (Ud. deberá detallar el cronograma)",
      },
    },
  ],
  [
    {
      type: bold,
      text: "Intereses Moratorios",
      depend: false,
    },
    {
      type: text,
      text:
        ": En caso de que cualquier suma adeudada no fuese abonada en su totalidad en la fecha de vencimiento, el Deudor deberá pagar además de los intereses compensatorios, un interés moratorio equivalente al cincuenta por ciento (50%) del interés compensatorio pactado. En caso de que la tasa precedente resulte violatoria de alguna ley de usura vigente, la tasa de interés aplicable será la máxima tasa de interés permitida por dicha ley.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Lugar de Pago",
      depend: false,
    },
    {
      type: text,
      text:
        ": Todos los pagos que el Deudor deba efectuar bajo el préstamo deberán realizarse mediante transferencia o depósito bancario en la Cuenta Bancaria del Acreedor. La acreditación de los fondos en la Cuenta Bancaria del Acreedor servirá como suficiente recibo por la suma desembolsada por el Deudor.",
      depend: true,
      question: {
        section: "section4",
        number: 32,
        answer: "En la cuenta bancaria del Acreedor",
      },
      stopRemoval: true,
    },
    {
      type: text,
      text:
        ": Todos los pagos que el Deudor deba efectuar bajo el préstamo deberán realizarse en el domicilio del Acreedor designado en el encabezado del Contrato. Debiendo el Acreedor extender el correspondiente recibo al Deudor.",
      depend: true,
      question: {
        section: "section4",
        number: 32,
        answer: "En el domicilio del Acreedor",
      },
      stopRemoval: true,
    },
    {
      type: text,
      text:
        ": Todos los pagos que el Deudor deba efectuar bajo el préstamo deberán realizarse en el domicilio sito en ${answers.section4[34].answer}. Debiendo el Acreedor extender el correspondiente recibo al Deudor.",
      depend: true,
      question: {
        section: "section4",
        number: 32,
        answer: "Otro domicilio",
      },
      stopRemoval: true,
    },
  ],
  [
    {
      type: bold,
      text: "Orden de Imputación",
      depend: false,
    },
    {
      type: text,
      text:
        ": Toda suma percibida por el Acreedor por parte del Deudor, se imputará en el siguiente orden: gastos, intereses moratorios, intereses compensatorios y capital.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Pre-cancelación voluntaria",
      depend: false,
    },
    {
      type: text,
      text:
        ": El capital adeudado bajo el préstamo es pre-cancelable voluntariamente, parcial o totalmente, por parte del Deudor, sin penalidad alguna. A los efectos de proceder a la pre-cancelación voluntaria del préstamo, el Deudor deberá informar al Acreedor con una antelación no inferior a los cinco (5) Días Hábiles a la fecha en que se pretende realizar la pre-cancelación, indicando el monto a pre-cancelar y fecha de pre-cancelación.",
      depend: true,
      question: {
        section: "section7",
        number: 41,
        answer: "Sí, podrá pre-cancelar el préstamo",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLAUSULA CUARTA: COMPROMISO Y OBLIGACIONES DEL DEUDOR.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Durante todo el tiempo en que cualquier suma bajo el préstamo se encontrare impaga, por cualquier concepto o causa que fuere (incluyendo caso fortuito o fuerza mayor), el Deudor se compromete a realizar la totalidad de los actos o actividades que a continuación se especifican:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Pagar debida y puntualmente el capital, los intereses compensatorios, los intereses punitorios, gastos y los impuestos de conformidad con los términos y condiciones que se establecen en el Contrato;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "A cumplir en tiempo y forma con todas y cada una de las obligaciones a su cargo emergentes del Contrato; y",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "A mantener el Contrato en plena vigencia y efecto.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA: MANIFESTACIONES Y DECLARACIONES DEL DEUDOR.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El Deudor declara y garantiza, a la fecha del presente Contrato:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Que la celebración y suscripción del Contrato, (a) constituyen actos jurídicos respecto de los cuales cuenta con capacidad para celebrarlos, y (b) no violan, contravienen o contradicen la normativa legal y reglamentaria que le resulta aplicable ni disposiciones de contratos de hipoteca, prenda, u otro instrumento o contrato u otro compromiso del que fuera parte, o en virtud del cual se encontrare obligado;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Que (a) posee la capacidad legal, patrimonial, económica y financiera requerida por la normativa que regula el crédito, como así también conoce todas y cada una de las disposiciones y normas del Banco Central de la República Argentina relativas a este tipo de financiaciones; y (b) que ha examinado detenidamente todos los términos, condiciones y especificaciones del Contrato;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Que no se halla en situación de incumplimiento en virtud de ningún acuerdo, contrato u obligación del que sean parte, o por el que pueda estar obligado, ni tampoco con respecto a ninguna orden, auto, requerimiento judicial, intimación, decreto o demanda de ninguna corte, tribunal judicial o arbitral u organismo gubernamental nacional, provincial o municipal del país o del extranjero, que pueda afectar en forma adversa y/o sustancial la capacidad para cumplir con las obligaciones de pago según lo previsto en el Contrato y que no adeuda impuestos, tanto en el país como en el extranjero, que pudieran afectar en forma adversa y sustancial su capacidad para cumplir con sus obligaciones de pago según lo previsto en el Contrato;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "Que no tiene pendiente, ni tienen conocimiento de la inminencia de litigio o procedimiento administrativo alguno ante ningún tribunal o autoridad administrativa nacional, provincial o municipal de la República Argentina o del extranjero de cualquier materia, ni tampoco proceso arbitral alguno, que, en el caso de resolverse en forma desfavorable para el Deudor pueda afectar adversa y sustancialmente (a) la capacidad para cumplir con sus obligaciones de pago según lo previsto en el Contrato; o (b) la validez, legalidad o ejecutabilidad del Contrato;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text:
        "Que no conoce que existan posibilidades de que sus activos materiales o inmateriales indispensables, necesarios para que desarrolle su actividad puedan ser ejecutados, o de que el valor de los mismos pueda verse disminuido sustancialmente ya sea por dichas ejecuciones o por actos de expropiación de manera tal que se pueda afectar en forma adversa y sustancial su capacidad para cumplir con las obligaciones de pago según lo previsto en el Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vi)",
      text:
        "Que es una sociedad regularmente constituida bajo las leyes de la República Argentina y que el presente contrato se ajusta a su estatuto y normativa societaria.",
      depend: true,
      question: {
        section: "section3",
        number: "person",
        answer: "Persona Jurídica",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA BIS: DECLARACIONES DEL GARANTE.",
      depend: true,
      question: {
        section: "section6",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "En garantía del fiel y debido cumplimiento de las obligaciones contenidas en el presente Contrato, el Garante se constituye en fiador solidario, liso, llano y principal pagador, con renuncia a los beneficios de división y excusión, por el cumplimiento de todas las obligaciones contraídas en el presente Contrato o impuestos por la ley, en especial por el repago del préstamo y sus accesorios, cualquiera fuera su monto y número de mensualidades impagas, toda carga y obligaciones pecuniarias de cualquier origen y naturaleza, importes o sumas punitorias, compensatorias y/o resarcitorias, costas y costos extrajudiciales o judiciales originadas por el incumplimiento del Deudor y demás que se originen en el presente Contrato.",
      depend: true,
      question: {
        section: "section6",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "El Garante declara conocer y aceptar todas las cláusulas del presente Contrato, en prueba de lo cual lo firma de conformidad al final del presente Contrato. El Garante garantiza igualmente el pago de los honorarios y gastos judiciales que se promuevan contra el Deudor. La presente garantía se extiende como vigente aún después de expirada la vigencia del Contrato, hasta que el Deudor cancele toda deuda y cumpla todas sus obligaciones.",
      depend: true,
      question: {
        section: "section6",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Para el caso que el Garante cayera en quiebra y/o concurso preventivo y/o fuera inhibido para disponer de sus bienes, el Acreedor tiene el derecho de exigir al Deudor que sustituya al Garante por otro de similar solvencia a la que tenía el obligado al momento de la contratación. Así también se pacta expresamente que para el caso de fallecimiento y/o falencia y/o quiebra y/o estado de insolvencia del Garante recién mencionado y también para el caso de enajenación y/o embargo o cualquier otro tipo de gravamen de los bienes de dicho garante presentado aquí en carácter de acreditación de solvencia, el Deudor deberá presentar, a requerimiento fehaciente del Acreedor, nuevo/s garante/s en el plazo perentorio de quince (15) días a partir de la recepción de dicho requerimiento, caso contrario se considerará que el Deudor incursa en grave incumplimiento de sus obligaciones esenciales de este Contrato quedando resuelto por dicha causa el mismo en forma automática.",
      depend: true,
      question: {
        section: "section6",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "En caso de renovarse o prorrogarse en forma expresa o tácita el presente Contrato, se exigirá el consentimiento expreso del Garante según lo establecido por el art. 1.225 del CCyCN.",
      depend: true,
      question: {
        section: "section6",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEXTA: EVENTOS DE INCUMPLIMIENTOS Y CADUCIDAD DE PLAZOS.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Si durante la vigencia del Contrato tuviera lugar uno o más de los siguientes eventos de incumplimiento (cada uno, denominado un “Evento de Incumplimiento”), el Acreedor podrá declarar la caducidad de los plazos otorgados al Deudor para el cumplimiento de sus obligaciones bajo el Contrato y exigir el inmediato reintegro de todas las sumas impagas:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "La falta de pago en tiempo y forma de cualquier importe adeudado bajo el Contrato;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "La falta de cumplimiento por parte del Deudor de otras obligaciones bajo el Contrato en los plazos expresamente previstos para su cumplimiento o, si tal plazo no estuviera fijado en el presente o en el documento respectivo, dentro de los quince (15) días corridos de intimado el cumplimiento de una obligación incumplida, o dentro de los quince (15) días corridos haber tomado conocimiento del incumplimiento;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Cualquier declaración efectuada por el Deudor en el Contrato y los restantes documentos relativos a éste fuera incorrecta, falsa, engañosa o incompleta en cualquier aspecto sustancial al momento en que fue realizada;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "El Deudor (a) evidenciara un estado de cesación de pagos; o admitiera por escrito su incapacidad para pagar sus deudas; o (b) solicitara su concurso preventivo, su propia quiebra o ésta fuera solicitada por un acreedor y tal pedido no fuera desestimado, por cualquier causa, o celebrara un acuerdo extrajudicial con sus acreedores; o (c) entrara en cesación de pagos en los términos de los Artículos 78 y 79 de la Ley 24.522; o (d) una repartición gubernamental dispusiera o acordara cualquier tipo de control, custodia y/o intervención judicial con relación a su administración y/o dirección y/o cualquiera de sus negocios, bienes, activos y/o ingresos en tanto cualesquiera de dichas medidas no fuese revocada o dejada sin efecto dentro de los treinta (30) días de su implementación; y/o (e) se viera impedido o de otra manera restringida sustancialmente en el ejercicio de cualquiera de sus facultades o funciones esenciales con relación a la dirección, administración y/o conducción de los negocios, actividades y operaciones en tanto dicho impedimento no cesase o fuese dejado sin efecto dentro de los treinta (30) días corridos de su implementación;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text:
        "Si cualquier repartición gubernamental confiscara, secuestrara, embargara o expropiara la totalidad o una parte sustancial de los activos del Deudor o dispusiera una transferencia o intervención por la cual se desplace a su órgano de administración en tanto cualesquiera de dichas medidas no fuese revocada o dejada sin efecto dentro de los treinta (30) días corridos de su implementación.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA: MORA AUTOMÁTICA.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La mora se producirá automáticamente por el solo vencimiento de los plazos para el cumplimiento de las obligaciones que los tuvieren, sin más trámite. La mora no exigirá intimación judicial ni extrajudicial previa. A partir de la mora, todo importe que adeude el Deudor al Acreedor devengará intereses moratorios según dispone el Contrato. Sin perjuicio de lo expuesto, si el Deudor incurriera en mora y el Acreedor aceptara en cualquier ocasión pagos parciales, ello no implicará novación, ni afectará la subsistencia de la mora, ni tampoco el curso de los intereses moratorios pactados en el Contrato, ni sentará precedente válido que el Deudor puedan invocar en el futuro.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA OCTAVA: GASTOS Y OTRAS EROGACIONES.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Deudor toma a su exclusivo cargo la totalidad de los gastos, costos o cualquier otra erogación en la que deba razonablemente incurrir el Acreedor durante toda la vigencia y desarrollo del Contrato, cuando la generación de tales gastos, costos y erogaciones estuviere relacionada con un incumplimiento del Deudor de cualquiera de las obligaciones bajo el Contrato, como por ejemplo los gastos, costos o cualquier otra erogación relacionada con: (i) las notificaciones que deban ser cursadas, ya sean éstas extrajudiciales o judiciales, y (ii) la ejecución judicial o extrajudicial del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA NOVENA: IMPUESTOS.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Deudor toma a su exclusivo cargo, y deberá pagar inmediatamente contra la sola solicitud del Acreedor, todos los impuestos, (incluyendo sin limitación, el Impuesto al Valor Agregado sobre intereses y comisiones), y cualquier otro gravamen presente o futuro, que correspondiere ser abonado con motivo del otorgamiento, instrumentación, cumplimiento o ejecución del Contrato, con excepción del impuesto a las ganancias sobre los ingresos netos globales gravados del Acreedor y del impuesto a los ingresos brutos sobre dichos ingresos del Acreedor. La falta de pago de los mencionados impuestos producirá la mora automática y caducidad de plazos, en los términos y condiciones previstos en el Contrato. El Deudor declara que cualquier variación en la alícuota de los impuestos a su cargo, dispuesta por autoridad competente, no dará a lugar a demandar la resolución del Contrato fundado en excesiva onerosidad, renunciando a invocar el principio de imprevisión previsto en el artículo 1091 del Código Civil y Comercial de la Nación.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMA: DÍAS HÁBILES.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En el supuesto en que el vencimiento de cualquiera de los plazos previstos en el Contrato ocurriese en día inhábil, dicho vencimiento se trasladará, automáticamente, al Día Hábil inmediato posterior. En todos los casos, deberán computarse los intereses que correspondan como consecuencia del traslado de los vencimientos referido en esta cláusula.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "A menos que se establezca lo contrario en el presente Contrato, todos los plazos se computarán por días corridos.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO PRIMERA: CESIÓN.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Tanto el Acreedor como el Deudor podrán ceder o de cualquier forma transmitir o transferir a terceros su posición contractual o beneficios, derechos, acciones, deberes, cargas y obligaciones emanadas del Contrato con la previa aprobación por escrito de la otra Parte.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SEGUNDA: VALIDEZ.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Salvo una disposición en particular en contrario, todas las menciones en el Contrato a determinadas cláusulas y otras subdivisiones son referencias a cláusulas y subdivisiones del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Si cualquier disposición, cláusula o parte del Contrato es declarada ilegal, nula o de cumplimiento imposible por un tribunal competente, el Contrato y sus disposiciones y partes no cuestionadas continuarán vigentes y la disposición, parte o cláusula que hubiere sido afectada por dicha declaración deberá ser reemplazada por otra en un todo de acuerdo con el espíritu fundamental del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La nulidad, inoponibilidad o inejecutabilidad de cualquier disposición del Contrato no afectará la validez, oponibilidad o ejecutabilidad de las restantes o la validez o la exigencia de cumplimiento del Contrato. Sin perjuicio de ello, si cualquier disposición del Contrato se tornara nula, inoponible o inejecutable por cualquier motivo, las Partes acuerdan modificar la misma dentro de lo posible en lugar de anularla, persiguiendo que dicha modificación se ajuste lo más estrechamente posible al espíritu del Contrato y a la intención de las Partes al contratar.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO TERCERA: PAGOS POSTERIORES A LA MORA.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La falta o demora en el ejercicio por el Acreedor de cualquier derecho, facultad o privilegio en virtud del Contrato no se considerará una renuncia al mismo, ni tampoco el ejercicio parcial de cualquier derecho, facultad o privilegio impedirá todo otro ejercicio del mismo, o el ejercicio de todo otro derecho, facultad o privilegio en virtud del Contrato. Los derechos y remedios aquí expuestos son acumulativos y no excluyentes de todo otro derecho o remedio dispuesto por la ley.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La recepción de cualquier suma de dinero u otros bienes por parte del Acreedor con posterioridad a la mora del Deudor o a la declaración de caducidad de plazos bajo el presente (sean dichas sumas pagadas, o dichos bienes entregados por el Deudor o por cualquier tercero), así como la aplicación que de dichas sumas o bienes haga el Acreedor a la cancelación de cualquier concepto debido e impago bajo el Contrato no purgará la mora del Deudor, ni la caducidad de plazos declarada de acuerdo con los términos del presente, ni sus efectos, y no importará ni podrá ser interpretada bajo ninguna circunstancia como una renuncia tácita por parte del Acreedor a ninguno de los derechos, acciones o facultades de los que son titulares los mismos bajo el presente.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO CUARTA: VÍA EJECUTIVA.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En caso que se produjera alguno de los supuestos de caducidad de plazos previstos en el Contrato, el Acreedor podrá exigir la totalidad del saldo de capital adeudado a dicha fecha, intereses e impuestos impagos por vía ejecutiva. A tales fines, las Partes acuerdan al presente instrumento la calidad de título ejecutivo suficiente (art. 523 del CPCyCN).",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA DÉCIMO QUINTA: LEY APLICABLE. JURISDICCIÓN Y COMPETENCIA.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La validez y naturaleza del Contrato y de las obligaciones emanadas del mismo, así como cualquier otra cuestión vinculada, serán analizadas, interpretadas y juzgadas por las leyes de la República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "A todos los efectos legales derivados del Contrato, las Partes se someten irrevocable, firme, expresa e incondicionalmente a la jurisdicción y competencia de los tribunales ordinarios en material comercial con sede en la ${answers.section7[39].answer}, renunciando a cualquier otro fuero o jurisdicción que pudiere corresponder.",
      depend: true,
      stopRemoval: false,
      question: {
        section: "section7",
        number: 39,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "A todos los efectos legales derivados del Contrato, las Partes se someten irrevocable, firme, expresa e incondicionalmente a la jurisdicción y competencia de los tribunales ordinarios en material comercial con sede en la provincia de ${answers.section7[39].answer}, renunciando a cualquier otro fuero o jurisdicción que pudiere corresponder.",
      depend: false,
      notBe: true,
      question: {
        section: "section7",
        number: 39,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SEXTA: DOMICILIOS.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "A todos los efectos legales derivados del Contrato, el Acreedor y el Deudor constituyen domicilios especiales en los consignados en el encabezamiento del Contrato, en los cuales se tendrán por válidas para producir sus efectos jurídicos todas las comunicaciones, citaciones, intimaciones, reclamos, interpelaciones y notificaciones, judiciales o extrajudiciales, que deban ser cursadas entre las Partes del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Los domicilios legales aquí constituidos no podrán ser modificados sin la previa notificación fehaciente por la Parte respectiva a la otra Parte expresada por escrito.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA DÉCIMO SÉPTIMA: FIRMA ANTE ESCRIBANO PÚBLICO. FECHA CIERTA.",
      depend: true,
      question: {
        section: "section7",
        number: 40,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes acuerdan certificar notarialmente el presente Contrato ante escribano público, dando fecha cierta al presente Contrato en este acto de las respectivas firmas que estampan al final del presente instrumento.",
      depend: true,
      question: {
        section: "section7",
        number: 40,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes firman dos (2) ejemplares de un mismo tenor y a un solo efecto.",
      depend: false,
      notBe: true,
      question: {
        section: "section6",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes firman tres (3) ejemplares de un mismo tenor y a un solo efecto.",
      depend: true,
      question: {
        section: "section6",
        number: 51,
        answer: "Sí",
      },
    },
  ],
];
