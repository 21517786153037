import React from "react";
import "../styles.css";

const Question = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  return (
    <div className="question-format-form">
      <textarea
        required
        type="text"
        value={currentAnswer}
        className="question-format-text-area"
        maxLength="5000"
        onChange={(e) => {
          let valueNormalized =
            typeof e.target.value === "string" ? e.target.value.split("") : [];
          let newValue = valueNormalized
            .filter((val) =>
              /^[\..*\n.*áÁéÉíÍóÓúÚñÑa-zA-Z0-9,!?¿¡@$(): ´%+"-]*$/.test(val)
            )
            .join("");
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: newValue,
              },
            },
          });
        }}
      ></textarea>
    </div>
  );
};

export default Question;
