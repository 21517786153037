import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
  dateWithoutSpace,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const PagareSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Contrato de Préstamo de Dinero",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.",
      ],
    },
    questions: [
      {
        id: 1,
        question: "¿En qué Provincia se firma el Pagaré?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question: "¿En qué Ciudad/Localidad se firma el Pagaré?",
        info: "Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se firma el Pagaré?",
        type: dateWithoutSpace,
      },
    ],
  },
  {
    id: "section2",
    section: "Librador del Pagaré/ Deudor",
    title:
      "Indicar los datos del Librador del Pagaré. Es quién deberá pagar el Pagaré.",
    type: questions,
    explanation: {
      question: "¿Quién es el Librador del Pagaré?",
      answer: [
        "El Librador es la persona que firma el Pagaré y deberá pagarlo a su vencimiento",
      ],
    },
    questions: [
      {
        id: 4,
        question: "Nombres y Apellidos Completos:",
        type: question,
      },
      {
        question: "Tipo de Documento:",
        id: 5,
        type: options,
        options: ["DNI", "Pasaporte", "Cédula de Identidad"],
      },
      {
        id: 6,
        question: "Número de Documento:",
        type: question,
      },
      {
        id: 7,
        question: "Domicilio Completo:",
        answer: [
          "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad/Localidad)",
          "Por ejemplo: Sargento Díaz 301, Piso 1, Dpto A, Tigre.",
        ],
        type: question,
      },
      {
        id: 8,
        question: "Provincia:",
        type: options,
        options: provincias,
      },
    ],
  },
  {
    id: "section3",
    section: "Beneficiario",
    title:
      "Indicar los datos del Beneficiario del Pagaré. Es quién cobrará el Pagaré.",
    type: questions,
    explanation: {
      question: "¿Quién es el Beneficiario del Pagaré?",
      answer: [
        "El Beneficario es la persona que podrá cobrar el Pagaré cuando llegue a su vencimiento. ",
        "Una vez alcanzado la fecha de vencimiento, el Beneficiario podrá exigir al Librador el pago total del Pagaré. Si el Librador se negaré a pagar, el Beneficiario podrá ejecutarlo ante un juez.",
        "Únicamente es necesario mencionar el Nombres y Apellidos completos del Beneficiario en el Pagaré. Alcanza para su perfeccionamiento.",
      ],
    },
    questions: [
      {
        id: 9,
        question: "Nombres y Apellidos Completos:",
        type: question,
      },
    ],
  },
  {
    id: "section4",
    section: "Datos del Pagaré",
    title: "Detallar los aspectos principales del Pagaré.",
    type: questions,
    explanation: {
      question: "¿Qué es un Préstamo?",
      answer: [
        "Un préstamo es una operación financiera por la cual una persona (Acreedor) otorga mediante un contrato dinero (capital) a otra persona (Deudora), a cambio de un interés que pactan las partes.",
      ],
    },
    questions: [
      {
        id: 10,
        question: "¿Qué día vencerá el Pagaré?",
        answer: [
          "Se deberá determinar a partir de qué día es exigible el pago del Pagaré.",
        ],
        type: dateWithoutSpace,
      },
      {
        id: 11,
        question: "Establecer el monto y moneda del Pagaré?",
        answer: ["Determinar de cuanto dinero será el Pagaré."],
        type: money,
      },
      {
        id: 12,
        question: "¿En qué domicilio deberá pagarse el Pagaré?",
        answer: [
          "Determinar el lugar/domicilio donde se deberá pagar el Pagaré",
          "Una vez llegada la fecha de vencimiento del Pagaré, el Librador deberá pagar al Beneficiario en el lugar donde se establezca",
        ],
        type: question,
      },
    ],
  },
];
///TERMINAR LA DESCRIPCIÓN
export const Pagare = {
  name: "Pagaré",
  codeName: "pagare",
  question: "¿Qué es un Pagaré?",
  definition: [
    "Un pagaré es un título valor que contiene la promesa incondicional de una persona, denominada libradora o deudor, de que pagará a una segunda persona, llamada beneficiario o acreedor, una suma determinada de dinero en un determinado plazo.",
  ],
  firmas: [],
  price: 250,
  size: "1 página",
  lastRevision: "Agosto 2021",
  sections: PagareSections,
  url: "pagare",
  video: null,
  useCases: {
    good: [
      "Documentar una deuda en dinero",
      "En caso de conflicto se ejecuta el pagaré (solicita ante un juez) mediante un proceso judicial especial y más acelerado",
      "Se puede utilizar como alternativa al Contrato de Préstamo de Dinero",
    ],
    bad: [
      "Documentar una donación de dinero (entrega gratuita de dinero)",
      "Documentar un préstamo de inmueble",
      "Documentar una relación laboral",
    ],
  },
};
