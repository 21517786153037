import React from "react";
import Button from "../../uiComponents/Button";
import { useHistory } from "react-router-dom";

const PaymentError = () => {
  const history = useHistory();
  const prevUrl = () => {
    if (sessionStorage.sessionUrl) {
      const url = sessionStorage.sessionUrl;
      history.push(`/${url}`);
    } else {
      history.push("/");
    }
  };

  return (
    <div style={{ padding: 60 }}>
      <h1 className="grey">
        Error en el pago <i className="fas fa-times primary margin-l"></i>
      </h1>
      <p>Ha habido un error por favor intentelo nuevamente</p>
      <Button
        onClick={prevUrl}
        label="Reintentar"
        center
        className="margin-v-l"
      />
    </div>
  );
};

export default PaymentError;
