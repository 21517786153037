import React, { useEffect } from "react";
import IconButton from "../IconButton/IconButton";
import { connect } from "react-redux";
import { closeModal } from "../../Utils/modalMethods";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import "./styles.css";

const SuccessModal = ({ data }) => {
  const { message, submessage, disclaimer, shouldReload = false } = data;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  return (
    <div className="ui-success-modal-background">
      <div className="ui-success-modal-background-children">
        <div className="ui-success-modal">
          <i
            className="far fa-window-close ui-success-modal-close"
            onClick={() => {
              closeModal();
              if (shouldReload) window.location.reload();
            }}
          ></i>
          <p className="ui-success-modal-message">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="margin-icon primary"
            />
            {message ? message : "Exito"}
          </p>
          <p className="ui-success-modal-sub-message">
            {submessage ? submessage : "Se proceso correctamente la operación."}
          </p>
          <p className="ui-success-modal-disclaimer">
            {disclaimer ? disclaimer : ""}
          </p>
          <IconButton
            label="Continuar"
            color="secondary"
            onClick={() => {
              closeModal();
              if (shouldReload) window.location.reload();
            }}
            className="center ui-success-modal-button"
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { data: state.modal.successModal };
};

export default connect(mapStateToProps, null)(SuccessModal);
