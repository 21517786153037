import { Box, Button } from "@mui/material";
import React from "react";
import InputForm from "../../uiComponents/Form/FormInput";
import styles from "./QuestionGeneratorForm.module.css";
import FormSelect from "../../uiComponents/Form/FormSelect";
import { dependencyOptions, dependencyTypes } from "../../Types/Question.types";
import DeleteIcon from "../../uiComponents/DeleteIcon/DeleteIcon";

type Props = {
  id: number;
  questionId: number;
  control: any;
  remove: any;
  watch: any;
};

const DependencyForm: React.FC<Props> = ({
  control,
  id,
  remove,
  questionId,
}) => {
  return (
    <Box
      flexDirection={"column"}
      p={4}
      my={4}
      sx={{ backgroundColor: "#554f9e" }}
      borderRadius={3}
      display="flex"
      width={"97%"}
      color="white"
    >
      <Box mb={0} width="100%" textAlign="left">
        Dependencia {id + 1}
      </Box>
      <InputForm
        control={control}
        name={`questions.${questionId}.dependencies.${id}.id`}
        label="¿De que pregunta depende?"
        required
        info={["Escribir el ID de la pregunta", "EJ: 1"]}
      />
      <FormSelect
        options={dependencyOptions}
        control={control}
        name={`questions.${questionId}.dependencies.${id}.type`}
        label="Condición para que la pregunta sea visible:"
        required
      />{" "}
      <InputForm
        control={control}
        name={`questions.${questionId}.dependencies.${id}.values`}
        label="¿Cuales deben ser los valores de la respuesta? (si son mas de uno separarlos con punto y coma)"
        maxLength={1000}
        required
      />
      <DeleteIcon
        label="Eliminar"
        sx={{ alignSelf: "end", mt: 2 }}
        onClick={() => {
          remove(id);
        }}
      />
    </Box>
  );
};

export default DependencyForm;
