import React from "react";
import TableUser2 from "../../../../../uiComponents/AndesTable/TableEntries/TableUser2.component";
import "./styles.css";

const CreatorField = ({ document }) => {
  return (
    <div className="detail-field-wrapper">
      {/* <i className='far fa-play-circle secondary margin-r'></i>
      Creado por: */}
      <div className="creator-field-wrapper b-secondary">
        {/* <img src={imageUrl} className="creator-field-pic" alt={field} /> */}
        <TableUser2
          photo={document.creatorPhotoUrl || null}
          name={document.createdBy || null}
          className="creator-field-pic"
          hideName
        />
        <div className="creator-field-text-box">
          <p className="creator-field-message">Creado por</p>
          <span className="bold creator-field">{document.createdBy || ""}</span>
        </div>
      </div>
    </div>
  );
};

export default CreatorField;
