import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
  percentage,
  catastro,
} from "./types.js";

export const TestDoc2 = [
  [
    {
      type: title,
      text: "Instrucciones para controlar los pagos y servicios de la unidad ubicada en la calle:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "${answers.section2[12].answer}, ${answers.section2[11].answer}.",
      depend: false,
    },
  ],

  [
    {
      type: text,
      text: "*SI SE FIRMO EN SIMULTANEO EL CONTRATO DE ALQ INCLUIR:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El contrato inició el ${answers.section3[1].answer} y finaliza el ${answers.section3[2].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El ajuste del alquier será cada ${answers.section3[3].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El depósito de garantía es de ${answers.section3[4].answer}.",
      depend: false,
    },
  ],

  [
    {
      type: text,
      text: "El INQUILINO es ${answers.section1[6].answer}, TEL ${answers.section1[7].answer}, MAIL: ${answers.section1[8].answer}",
      depend: false,
    },
    {
      type: text,
      text: "; y ${answers.section1[9].answer}, TEL ${answers.section1[10].answer}, MAIL: ${answers.section1[11].answer}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 5,
        answer: "2",
      },
    },
    {
      type: text,
      text: "; y ${answers.section1[12].answer}, TEL ${answers.section1[13].answer}, MAIL: ${answers.section1[14].answer}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 5,
        answer: "3",
      },
    },
  ],
  [
    {
      type: text,
      text: "El GARANTE es ${answers.section1[17].answer}, TEL ${answers.section1[18].answer}, MAIL: ${answers.section1[19].answer}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 15,
        answer: "Sí",
      },
    },
    {
      type: text,
      text: "; y ${answers.section1[20].answer}, TEL ${answers.section1[21].answer}, MAIL: ${answers.section1[22].answer}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 16,
        answer: "2",
      },
    },
  ],
  [
    {
      type: text,
      text: "El PROPIETARIO es ${answers.section1[24].answer}, TEL ${answers.section1[25].answer}, MAIL: ${answers.section1[26].answer}",
      depend: false,
    },
    {
      type: text,
      text: "; y ${answers.section1[27].answer}, TEL ${answers.section1[28].answer}, MAIL: ${answers.section1[29].answer}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 23,
        answer: "2",
      },
    },
  ],
  [
    {
      type: text,
      text: "El alquiler se abonará del ${answers.section3[301].answer} de cada mes en:",
      depend: false,
    },
    {
      type: text,
      text: "efectivo en el domicilio ${answers.section3[303].answer} ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section3",
        number: 302,
        answer: "en efectivo",
      },
    },
    {
      type: text,
      text: "la cuenta bancaria: ${answers.section3[304].answer} ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section3",
        number: 302,
        answer: "mediante trasnferencia/depósito bancario",
      },
    },
  ],
  [
    {
      type: text,
      text: "Instrucciones para el Pago de Impuestos y Servicios del inmueble sito en:",
      depend: false,
    },
    {
      type: text,
      text: "${answers.section2[12].answer}, ${answers.section2[11].answer}.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Casa",
      },
    },

    {
      type: text,
      text: "${answers.section2[12].answer}, ${answers.section2[11].answer}.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "PH",
      },
    },
    {
      type: text,
      text: "barrio cerrado denominado ${answers.section2[13].answer}, con Número de Lote ${answers.section2[16].answer}, con frente a la calle ${answers.section2[12].answer}, ${answers.section2[11].answer}.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Casa en barrio cerrado",
      },
    },
    {
      type: text,
      text: "Country denominado ${answers.section2[14].answer}, con Número de Lote ${answers.section2[15].answer}, con frente a la calle ${answers.section2[12].answer}, ${answers.section2[11].answer}.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Casa en country",
      },
    },

    {
      type: text,
      text: "${answers.section2[12].answer}, ${answers.section2[11].answer}, Unidad funcional ${answers.section2[17].answer}, Piso ${answers.section2[18].answer}.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Departamento",
      },
    },
  ],
  [
    {
      type: text,
      text: "$$^^EXPENSAS:^^$$ ${answers.section3[41].answer}. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "La administración en tu edificio es:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Administración: ${answers.section3[30].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Teléfono: ${answers.section3[31].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Correo electrónico: ${answers.section3[32].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Página y enlace de acceso: ${answers.section3[33].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "$$^^EDENOR^^$$ (LUZ)",
      depend: true,
      question: {
        section: "section3",
        number: 34,
        answer: "EDENOR",
      },
    },
  ],
  [
    {
      type: text,
      text: "$$^^EDESUR^^$$ (LUZ)",
      depend: true,
      question: {
        section: "section3",
        number: 34,
        answer: "EDESUR",
      },
    },
  ],
  [
    {
      type: text,
      text: "$$^^Número de Cliente: ${answers.section3[35].answer}^^$$",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "$$^^Número de medidor: ${answers.section3[36].answer}^^$$",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Instrucciones para descargar y pagar boletas de ${answers.section3[34].answer}:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "1.",
      text: "Accede a www.edenordigital.com.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENOR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "2.",
      text: "Regístrate o inicia sesión.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENOR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "3.",
      text: "Ve a tu cuenta para ver las facturas pendientes.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENOR",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "4.",
      text: "Descarga la factura actual.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENOR",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "5.",
      text: "Verifica los detalles.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENOR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "6.",
      text: "Selecciona el método de pago.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENOR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "7.",
      text: "Completa el pago siguiendo las instrucciones.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENOR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "8.",
      text: "Guarda el recibo de pago como comprobante junto con tu boleta impresa.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENOR",
      },
    },
  ],

  ///////////////////EDESUR
  [
    {
      type: bullet,
      bullet: "1. ",
      text: "Accede al sitio web de Edesur en www.edesur.com.ar.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENSUR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "2. ",
      text: "Inicia sesión o regístrate.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENSUR",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "3. ",
      text: "Accede a tu cuenta y encuentra tus facturas pendientes.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENSUR",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "4. ",
      text: "Descarga la boleta actual.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENSUR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "5. ",
      text: "Verifica los detalles de la factura.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENSUR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "6. ",
      text: "Selecciona el método de pago preferido.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENSUR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "7. ",
      text: "Completa el pago siguiendo las instrucciones proporcionadas.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENSUR",
      },
    },
  ],

  [
    {
      type: bullet,
      bullet: "8. ",
      text: "Guarda el recibo de pago como comprobante junto con tu boleta impresa.",
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "EDENSUR",
      },
    },
  ],
  ////////////////////AYSA
  [
    {
      type: text,
      text: "$$^^AYSA^^$$ (AGUA)",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "$$^^Número de CUENTA SERVICIOS: ${answers.section3[37].answer}^^$$",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Instrucciones para descargar y pagar boletas de AYSA:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Se puede ingresar a la página de Aysa y descargar las boletas para pagarlas. También puedes solicitarlas por WhatsApp al número 11 5984-5794, te solicitarán tu número de cuenta servicios mencionado anteriormente y con él podrás pagarla con tarjeta o en cualquier rapipago, pago fácil o Mercadopago.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "1. ",
      text: "Accede al sitio web de AYSA e inicia sesión en tu cuenta: https://www.aysa.com.ar/.",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "2. ",
      text: "Encuentra la sección de boletas o facturas.",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "3. ",
      text: "Descarga la boleta que deseas pagar en formato PDF.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "4. ",
      text: "Elige tu método de pago preferido: en línea o en sucursal. ",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "5. ",
      text: "Realiza el pago y confirma la transacción.",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "6. ",
      text: "Guarda el recibo de pago como comprobante junto con tu boleta impresa.",
      depend: false,
    },
  ],
  //////////////////////////////ABL/AGIP
  [
    {
      type: text,
      text: "$$^^ABL/AGIP^^$$",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "$$^^Número de PARTIDA: ${answers.section3[38].answer}^^$$. DÍGITO VERIFICADOR $$^^(DV): ${answers.section3[39].answer}^^$$",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Instrucciones para pagar el ABL en la Ciudad de Buenos Aires:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "1. ",
      text: "Accede al sitio web oficial del Gobierno de la Ciudad: https://lb.agip.gob.ar/ConsultaABL/. También puedes buscar “consulta de ABL” en Google y te llevará al enlace.",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "2. ",
      text: "Te solicitará los datos de la partida y el número del dígito verificador, que se encuentran en un recuadro en la margen superior derecha. *Recuerda tildar cuando cuentes con el Dígito Verificador. ",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "3. ",
      text: "Selecciona los meses que quieres abonar el ABL.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "*Generalmente se puede acceder a una bonificación cuando la boleta se abona anualmente, cuya publicación se realiza los primeros días de enero. En caso de no abonar esa boleta, se podrá abonar mes a mes o varias juntas en los meses subsiguientes. ",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "4. ",
      text: "Descarga la boleta del ABL correspondiente al período actual y elige el método de pago: tarjeta de crédito, débito o pago en efectivo. ",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "5. ",
      text: "Completa el proceso de pago siguiendo las instrucciones del sitio web.",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "6. ",
      text: "También desde este sitio podrás consultar en la parte inferior si el inmueble cuenta con deuda de ABL y descargar el libre de deuda.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "7. ",
      text: "Guarda el recibo de pago como comprobante junto con tu boleta impresa.",
      depend: false,
    },
  ],
  /////////////////////////METROGAS
  [
    {
      type: text,
      text: "$$^^METROGAS^^$$ (GAS) ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "$$^^Número de CLIENTE: ${answers.section3[40].answer}^^$$.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Instrucciones para descargar y pagar boletas de Metrogas:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "1. ",
      text: "Visita el sitio web de Metrogas: https://www.metrogas.com.ar/ y selecciona el enlace “Consulta tu saldo, descarga y paga tu factura”.",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "2. ",
      text: "Podrás descargar tu boleta y consultar su saldo con tu número de cliente. Inicia sesión o regístrate si es necesario para seguir más de cerca tu cuenta.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "3. ",
      text: "Accede a tu cuenta para ver las facturas pendientes.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "4. ",
      text: "Descarga la boleta correspondiente al período actual.",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "5. ",
      text: "Selecciona el método de pago preferido.",
      depend: false,
    },
  ],

  [
    {
      type: bullet,
      bullet: "6. ",
      text: "Selecciona el método de pago preferido.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "7. ",
      text: "Completa el pago siguiendo las instrucciones proporcionadas.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "8. ",
      text: "Guarda el recibo de pago como comprobante junto con tu boleta impresa.",
      depend: false,
    },
  ],
];
