// src/form-component/FormInputText.tsx
import styles from "./InputForm.module.css";
import { Box } from "@mui/material";
import InvisibleFormInput from "./invisibleFormInput";
import FormLabel from "./FormLabel";
import { useState } from "react";
import FormInput from "./FormInput";

type Props = {
  name: string;
  control: any;
  label?: string;
  smLabel?: boolean;
  required?: boolean;
  info?: string[] | "";
};

const FormCatastro: React.FC<Props> = ({
  name,
  label,
  smLabel,
  required,
  control,
  info,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <FormLabel
        required={required}
        label={label}
        smLabel={smLabel}
        info={info}
        isFocused={isFocused}
      />
      <Box className={styles.wrapper} mt={2}>
        <Box mr={1} width={"100%"}>
          <InvisibleFormInput
            name={`${name}.type`}
            defaultValue="catastro"
            control={control}
          />
          <FormInput
            smLabel
            label="Circunscripción:"
            name={`${name}.cir`}
            control={control}
            required
          />
          <FormInput
            label="Sección:"
            smLabel
            name={`${name}.sec`}
            control={control}
            required
          />
          <FormInput
            label="Manzana:"
            smLabel
            name={`${name}.man`}
            control={control}
            required
          />
          <FormInput
            label="Parcela:"
            smLabel
            name={`${name}.par`}
            control={control}
            required
          />
        </Box>
      </Box>
    </>
  );
};

export default FormCatastro;
