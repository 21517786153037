import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
} from "./types.js";

export const ContratoDeAlquilerTemporalDoc = [
  [
    {
      type: title,
      text: "CONTRATO DE ALQUILER TEMPORARIO – NUEVA LEY N° 27.551",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: false,
    },
    {
      type: bold,
      text: "Contrato de Alquiler Temporario ",
      depend: false,
      notBe: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text:
        "(en adelante, el “Contrato”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}entre las siguientes partes:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: bold,
      text: "Contrato de Alquiler Temporario ",
      depend: false,
    },
    {
      type: text,
      text:
        '(en adelante, el "Contrato") se celebra en la ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}entre las siguientes partes:',
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Locador",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[4].answer}, ${answers.section2[5].answer} N° ${answers.section2[6].answer}, con domicilio sito en ${answers.section2[7].answer}, ${answers.section2[8].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Locador",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[9].answer}, ${answers.section2[10].answer} N° ${answers.section2[11].answer}, con domicilio sito en ${answers.section2[12].answer}, ${answers.section2[13].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Locataria",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[14].answer}, ${answers.section3[15].answer} N° ${answers.section3[16].answer}, con domicilio sito en ${answers.section3[17].answer}, ${answers.section3[18].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Locataria",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[19].answer}, ${answers.section3[20].answer} N° ${answers.section3[21].answer}, con domicilio sito en ${answers.section3[22].answer}, ${answers.section3[23].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Locador y el Locatario podrán ser denominadas conjuntamente como las “Partes” e individualmente como la “Parte”.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes acuerdan que el presente Contrato se regirá por los siguientes términos y condiciones:",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Cláusula Primera: Definiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Autoridad Pública": significa cualquier órgano judicial, ejecutivo y/o legislativo de carácter nacional, provincial y/o municipal. ',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: '"CCyCN": Código Civil y Comercial de la Nación.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Cuenta Bancaria del Locador": significa la cuenta bancaria abierta a nombre del Locador que se detalla a continuación: ${answers.section5[37].answer}.',
      depend: true,
      question: {
        section: "section5",
        number: 36,
        answer: "En la cuenta bancaria del Propietario",
      },
    },
  ],

  [
    {
      type: text,
      text:
        '"Depósito en Garantía": significa la suma de ${answers.section6[39].answer}, que se encuentra sujeto a lo establecido en la Cláusula Quinta Bis.',
      depend: true,
      question: {
        section: "section6",
        number: 38,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        '"Domicilio": la respectiva dirección de cada Parte indicada en el encabezamiento del presente Contrato o aquella otra dirección que la respectiva Parte informare a la otra de conformidad a la Cláusula Décimo Segunda.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Forma de Pago": significa el Precio del Alquiler que deberá abonarse ${answers.section5[39].answer}, por periodo adelantado, en ',
      depend: false,
    },
    {
      type: text,
      text:
        "la Cuenta Bancaria del Locador durante la Vigencia del Contrato. El comprobante de bancario de dicha operación se considerará como suficiente recibo de cada pago realizado. ",
      depend: true,
      question: {
        section: "section5",
        number: 36,
        answer: "En la cuenta bancaria del Propietario",
      },
    },
    {
      type: text,
      text:
        "el Domicilio del Locador durante la Vigencia del Contrato. El Locador deberá extender, en favor del Locatario, los correspondientes recibos por las sumas percibidas durante la Vigencia del Contrato. ",
      depend: true,
      question: {
        section: "section5",
        number: 36,
        answer: "En el domicilio del Propietario",
      },
    },
    {
      type: text,
      text:
        "el domicilio sito en ${answers.section5[38].answer} durante la Vigencia del Contrato. El Locador deberá extender, en favor del Locatario, los correspondientes recibos por las sumas percibidas durante la Vigencia del Contrato. ",
      depend: true,
      question: {
        section: "section5",
        number: 36,
        answer: "Otro domicilio",
      },
    },
    {
      type: text,
      text:
        "Los pagos establecidos en el presente Contrato deberán realizarse del primero (1) al décimo (10) día de cada mes durante la Vigencia del Contrato.",
      depend: true,
      question: {
        section: "section5",
        number: 39,
        answer: "mensualmente",
      },
    },
  ],

  [
    {
      type: text,
      text:
        '"Inmueble": el bien inmueble con Mobiliario sito en ${answers.section4[26].answer}, ${answers.section4[25].answer} ${answers.section4[24].answer}. ',
      depend: false,
    },
    {
      type: textArray,
      text: "El Inmueble objeto del presente Contrato incluye {{array}}.",
      question: { section: "section4", number: 27 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: text,
      text:
        '"Inmueble": el bien inmueble con Mobiliario sito en ${answers.section4[26].answer}, ${answers.section4[24].answer}. ',
      depend: true,
      question: {
        section: "section4",
        number: 24,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: textArray,
      text: "El Inmueble objeto del presente Contrato incluye {{array}}.",
      question: { section: "section4", number: 27 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: text,
      text:
        '"Mobiliario": significa las cosas muebles no registrables que se encuentran en el Inmueble para uso residencial temporal objeto del presente Contrato. ',
      depend: false,
    },
    {
      type: text,
      text:
        "Su inventario se detalla a continuación: ${answers.section4[29].answer}.",
      depend: true,
      question: {
        section: "section4",
        number: 28,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        '"Precio del Alquiler": la suma de ${answers.section5[32].answer} por ${answers.section5[31].answer}.',
      depend: false,
      notBe: true,
      question: {
        section: "section5",
        number: 31,
        answer: "Un único pago",
      },
    },
  ],
  [
    {
      type: text,
      text:
        '"Precio del Alquiler": la suma total de ${answers.section5[32].answer}.',
      depend: true,
      question: {
        section: "section5",
        number: 31,
        answer: "Un único pago",
      },
    },
  ],
  [
    {
      type: textArray,
      text:
        '"Servicios": significa cualquiera de los siguientes servicios habilitados y disponibles en el Inmueble en la fecha de inicio de la Vigencia, a saber: {{array}}.',
      question: { section: "section4", number: 125 },
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Vigencia": ${answers.section5[33].answer} ${answers.section5[321].answer} contados desde la fecha que se indica en el encabezado del Contrato.',
      depend: true,
      question: {
        section: "section5",
        number: 34,
        answer: "Misma fecha que se firma el Contrato",
      },
    },
  ],
  [
    {
      type: text,
      text:
        '"Vigencia": significa la cantidad de ${answers.section5[33].answer} ${answers.section5[321].answer} contados desde el ${answers.section5[35].answer}',
      depend: true,
      question: {
        section: "section5",
        number: 34,
        answer: "Otra posterior",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEGUNDA: Objeto.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Locador da en locación el Inmueble al Locatario durante la Vigencia determinada en este Contrato. En contraprestación, el Locatario abonará el Precio del Alquiler según se encuentra establecido en la Cláusula Cuarta del presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA TERCERA: Vigencia del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Contrato se extenderá por la totalidad de la Vigencia. Ninguna Parte se obligará a alegar, alegará, ni permitirá que se alegue en contra de la otra Parte la reconducción tácita de este Contrato. Por lo que cumplida la Vigencia del mismo el vencimiento del Contrato operará de pleno derecho, sin necesidad de notificación o requerimiento alguno de parte del Locador, fecha en la cual deberá restituir el Inmueble totalmente desocupado, siendo este plazo, único, definitivo, perentorio y sin prórroga de ninguna clase.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA CUARTA: Precio del Alquiler.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Locatario se obliga a pagar al Locador el Precio del Alquiler de conformidad con la Forma de Pago establecida en este Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA: Destino del Bien. Inspección del Locador.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes establecen que el Inmueble que se afecta al presente Contrato se encuentra destinado exclusivamente a vivienda temporaria por parte del Locatario y de su grupo conviviente. El Locador se reserva el derecho de inspeccionar el Inmueble para comprobar el destino y estado de conservación del mismo, para lo cual deberá comunicar dicha intención al Locatario en un plazo no menor a cuarenta y ocho (48) horas o con suficiente tiempo de anticipación.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA BIS: Depósito en Garantía.",
      depend: true,
      question: { section: "section6", number: 38, answer: "Sí" },
    },
  ],
  [
    {
      type: text,
      text:
        "Sin perjuicio de la responsabilidad que el Locatario asume con todos sus bienes por el cumplimiento de este Contrato, el Locatario, al momento de la entrega de la tenencia del Inmueble, se obliga a otorgar al Locador el Depósito en Garantía.",
      depend: true,
      question: { section: "section6", number: 38, answer: "Sí" },
    },
  ],
  [
    {
      type: text,
      text:
        "El Depósito en Garantía no podrá computarse a pagos de alquileres atrasados o vigentes, penalidades, cargas u obligaciones debidas por el Locatario, con la salvedad de lo que a continuación se conviene. El Depósito en Garantía podrá computarse en parte o totalmente a los desperfectos, daños y/o roturas originadas en el Inmueble, el Mobiliario o accesorios y que Locador, o sus representantes, observen cuando el Locatario haya restituido el Inmueble al finalizar la Vigencia del Contrato.",
      depend: true,
      question: { section: "section6", number: 38, answer: "Sí" },
    },
  ],
  [
    {
      type: text,
      text:
        "Si el Depósito en Garantía se depositara o transfiera a la cuenta bancaria del Locador, el comprobante bancario de dicha operación será suficiente recibo del monto abonado; si el Depósito en Garantía se entrega en efectivo en este acto, el presente Contrato será suficiente recibo. Si el Locatario restituyera el Inmueble totalmente desocupado y libre en el estado en que lo recibió, excepto por los deterioros provenientes del mero transcurso del tiempo y el uso regular del mismo durante la Vigencia del Contrato, entonces podrá exigir al Locador la devolución de la totalidad del Depósito en Garantía de conformidad con el art. 1.196 inc. “b” del CCyCN.",
      depend: true,
      question: { section: "section6", number: 38, answer: "Sí" },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEXTA: Otras Obligaciones y Derechos de las Partes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "A) Obligaciones y Derechos del Locador:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "El Locador debe dar en locación y entregar el Inmueble conforme a lo acordado. A falta de previsión contractual debe entregarla en estado apropiado para su destino, excepto por los defectos que el Locatario conoció o debió haber conocido.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "El Locador con el fin de conservar el Inmueble en buen estado para el uso y goce convenido en el presente Contrato, deberá efectuar, a su cargo, toda reparación y/o erogación que sea necesario para sanear cualquier daño que sea originado por la calidad o defecto del Inmueble y/o por la culpa del Locador o la de sus dependientes y/o por hechos de terceros y/o caso fortuito que afecten al Inmueble y al normal uso y goce por parte del Locatario.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "El Locador no se obligará a realizar, ni realizará ninguna reforma arquitectónica del Inmueble salvo que le fuese requerido por una Autoridad Pública o existiere riesgo de peligro actual o potencial para la vida humana y/o vida animal y/o el medio ambiente, en dichos casos deberá notificar a la Locataria de dichas reparaciones con razonable anticipación.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "Si el Locador, al efectuar una reparación o cualquier mejora que interrumpa o turbe el uso y goce convenido por parte del Locatario, facultará a este último exigir la reducción del Precio del Alquiler en proporción a la extensión de la interrupción del uso y goce del Inmueble.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "B) Obligaciones y Derechos del Locatario:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Pagar el Precio del Alquiler en la Forma de Pago y en los términos acordados en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "El Locatario puede usar y gozar del Inmueble conforme a derecho y exclusivamente para el destino convenido, manteniendo y conservando el Inmueble en un buen estado de uso.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Una vez finalizada la Vigencia del Contrato o en el caso que  dejase de abonar el Precio del Alquiler durante dos (2) períodos consecutivos según la Forma de Pago establecida en el presente Contrato, el Locatario deberá restituir al Locador el Inmueble totalmente desocupado y libre en el estado en que lo recibió, excepto por los deterioros provenientes del mero transcurso del tiempo y el uso regular del mismo.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "Se obliga a notificar al Locador, o a su representante, todo desperfecto, humedades, filtraciones, roturas, accidentes y/o toda anomalía que pueda afectar al Inmueble.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text:
        "Se obliga a notificar al Locador, o a su representante, todo desperfecto, humedades, filtraciones, roturas, accidentes y/o toda anomalía que pueda afectar al Inmueble.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vi)",
      text:
        "Podrá tener animales domésticos en el Inmueble durante la Vigencia del Contrato.",
      notInclude: ["Tener Mascotas"],
      question: {
        section: "section6",
        number: 36,
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vi)",
      text:
        "No podrá tener animales domésticos en el Inmueble durante la Vigencia del Contrato.",
      includes: ["Tener Mascotas"],
      question: {
        section: "section6",
        number: 36,
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vii)",
      text:
        "El Locatario no permitirá que se enciendan y/o fumen cigarrillos, cigarro y/o productos similares (de hoja, naturales y/o electrónicos) en cualquier ambiente cerrado del Inmueble.",
      includes: ["Fumar dentro del inmueble"],
      question: {
        section: "section6",
        number: 36,
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA: Incumplimiento del Locatario.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En cualquiera de los casos de incumplimiento por parte del Locatario, el Locador podrá pedir el cumplimiento de este Contrato o podrá resolver el Contrato por culpa del Locatario y solicitar el inmediato desalojo de éste o quienes ocupen el Inmueble. Ante el incumplimiento de pago del Precio del Alquiler, previamente a la intimación de desalojo, el Locador debe intimar fehacientemente al Locatario al pago de la cantidad debida más los intereses correspondientes, otorgando para ello un plazo máximo de cinco (5) días corridos contados a partir de la recepción de la intimación, especificando el lugar donde se deberá realizar el pago. Para el evento de que el Locatario dejare abandonado el Inmueble, o depositare judicialmente las llaves, deberá abonar al Locador una multa igual al proporcional del Precio del Alquiler que resulte desde la iniciación del juicio hasta el día en que el Locador tome la libre y efectiva tenencia definitiva del Inmueble, ello en concepto de única y definitiva indemnización.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA OCTAVA: Mora en el Pago del Precio del Alquiler.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La mora en el pago del Precio del Alquiler se producirá en forma automática por el mero transcurso del tiempo y sin necesidad de interpelación ni gestión previa de ninguna naturaleza. Producida la mora en el pago del Precio del Alquiler, las Partes acuerdan que el Locatario deberá abonar en concepto de intereses y multa, el equivalente al uno por ciento (1%) diario del precio total de alquiler del Contrato por todo el tiempo que transcurra desde iniciada la mora hasta su cesación. En caso de que la tasa precedente resulte violatoria de alguna ley de usura vigente, la tasa de interés aplicable será la máxima tasa de interés permitida por dicha ley.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA NOVENA: Resolución por las Partes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "A) El Locador puede resolver el presente Contrato:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Por cambio de destino o uso irregular del Inmueble por parte del Locatario;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Por falta de conservación del Inmueble, o su abandono sin dejar quien haga sus veces;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Por falta de pago del Precio del Alquiler en tiempo y forma y de conformidad con la Forma de Pago; y",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "Por cualquier incumplimiento de cualquier otra obligación de hacer o no hacer que se establece en el presente Contrato si, ante la solicitud del Locador, la Locataria no hubiera restablecido el estado de las cosas al estadio anterior al incumplimiento y según lo establecido en la solicitud enviada por el Locador.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "B) El Locatario puede resolver el presente Contrato:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Si el Locador incumple la obligación de conservar la cosa con aptitud para el uso y goce convenido; y",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Por el incumplimiento de los términos y obligaciones establecidos en el presente Contrato por parte del Locador.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Parte que se trate deberá informar su voluntad de resolver el presente Contrato mediante una notificación fehaciente por escrito en el Domicilio de la otra Parte con una antelación no menor de cinco (5) días.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMA: Resolución Anticipada por parte del Locatario.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Locatario podrá dar por rescindido el presente Contrato, preavisando en forma fehaciente y por escrito al Locador. Si hace uso de la opción resolutoria, el Locatario debe abonar al Locador, en concepto de indemnización, la suma equivalente a dos (2) meses de alquiler, de conformidad con lo establecido en el art. 1.221 del CCyCN.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO PRIMERA: Prohibiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Queda prohibido para el Locatario ceder su posición contractual, los derechos y/o las obligaciones establecidos en el presente Contrato, así como la posibilidad de subarrendar total o parcialmente el Inmueble a tercero alguno, sin el previo consentimiento por escrito del Locador.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SEGUNDA: Domicilios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Cada Parte constituye domicilio en su Domicilio exclusivamente a los fines del presente Contrato. La Parte que cambiare su Domicilio informará la nueva dirección, que deberá encontrarse en la República Argentina, a la otra Parte mediante una notificación fehaciente dirigida al Domicilio de esta última.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las notificaciones que se realicen entre las Partes, así como otras comunicaciones y notificaciones prejudiciales y/o judiciales derivadas de este Contrato, se tendrán por válidamente cursadas al Domicilio de la Parte destinataria hasta que la Parte remitente sea informada del nuevo Domicilio de la Parte destinataria mediante una notificación fehaciente dirigida al Domicilio de la Parte remitente.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO TERCERA: Comunicaciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El ejercicio de un derecho o cumplimiento de una obligación por una Parte, en cualquier caso en virtud de lo dispuesto por este Contrato, el CCyCN o un acto de una Autoridad Pública relativo a este Contrato (salvo que dicho acto dispusiere otra forma), mediante el envío por dicha Parte de un mensaje a la otra Parte, será eficaz sólo si dicho mensaje fuere recibido mediante una notificación fehaciente al Domicilio de la otra.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA DÉCIMO CUARTA: Disposiciones Finales. Integridad Acuerdo. Anexos.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Contrato representa el acuerdo completo e íntegro entre las Partes con respeto al objeto del mismo. Las Partes prescinden expresamente de todas las disposiciones contenidas en cualquier otro acuerdo o correspondencia anteriores que nieguen, limiten, extiendan o entren en conflicto con las disposiciones del presente Contrato. Ningún término de este Contrato será modificado por ninguna declaración anterior o posterior, conducta o acto de cualquier Parte, a menos que las Partes hayan autorizado tal modificación en un documento escrito y ratificado por ambas Partes. En caso de existir anexos que se adjuntan a presente Contrato, las Partes establecen que los mismos serán parte integral del mismo. Si ciertos términos y condiciones del Contrato entran en colisión con ciertos términos y condiciones del Anexo, prevalecerán los establecidos en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA DÉCIMO QUINTA: Ley Aplicable. Tribunales Judiciales Competentes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Este Acuerdo se regirá e interpretará de conformidad con las leyes de la República Argentina. Las Partes acuerdan que cualquier controversia o reclamo que se derive del presente Acuerdo, será resuelto exclusivamente por los tribunales ordinarios competentes, por razón de la materia, con asiento en ${answers.section6[37].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes firman dos (2) ejemplares de un mismo tenor y a un solo efecto.",
      depend: false,
      notBe: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
];
