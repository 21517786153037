import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleLeft } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import { useHistory } from "react-router-dom";

type Props = {
  label: string;
  to?: string;
  onClick?: () => void;
  className?: string;
  color?: string;
};

const XsButton: React.FC<Props> = ({
  label,
  to,
  onClick = null,
  className = "",
  color,
}) => {
  const history = useHistory();

  const buttonColor = () => {
    if (color === "primary") {
      return "b-primary b-primary-hover";
    }

    if (color === "secondary") {
      return "b-secondary b-secondary-hover";
    }

    if (color === "tertiary") {
      return "b-tertiary b-tertiary-hover";
    }

    if (color === "red") {
      return "b-light-red b-primary-hover";
    }

    if (color === "transparent") {
      return "xs-button-transparent";
    }
    return "b-primary b-primary-hover";
  };

  const clicker = () => {
    if (onClick) {
      onClick();
      return;
    }
    if (to) {
      history.push(`${to}`);
    }
  };

  return (
    <div
      className={[`${buttonColor()}`, "xs-button", className].join(" ")}
      onClick={clicker}
    >
      <div className="xs-button-trapezoid"></div>
      <FontAwesomeIcon
        icon={faArrowAltCircleLeft}
        style={{ marginRight: 10 }}
      />
      <p className="xs-button-label">{label}</p>
    </div>
  );
};

export default XsButton;
