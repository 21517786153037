import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
  boldRight,
} from "./types.js";

export const PagareDoc = [
  [
    {
      type: title,
      text: "PAGARÉ",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: boldRight,
      text: "Por:${answers.section4[11].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: boldRight,
      text: "Vence el ${answers.section4[10].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina, al ${answers.section1[3].answer}.",
      notBe: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text:
        "${answers.section1[1].answer}, República Argentina, al ${answers.section1[3].answer}.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "El día ${answers.section4[10].answer}, PAGARÉ sin protesto (art. 50-D.Ley 5965/63) a ${answers.section3[9].answer} o a su orden la cantidad de${answers.section4[11].answer} por igual valor recibido en efectivo a su entera satisfacción. Pagadero en ${answers.section4[12].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Nombre y Apellido del Librador: ${answers.section2[4].answer}",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "${answers.section2[5].answer}: ${answers.section2[6].answer}",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Dirección Completa: ${answers.section2[7].answer}, ${answers.section2[8].answer}",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: " ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: " ",
      depend: false,
    },
  ],
  [
    {
      type: boldRight,
      text: "Firma, aclaración y documento",
      depend: false,
    },
  ],
];
