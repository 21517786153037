import React from "react";
import Box from "@mui/material/Box";
import { processAnswer } from "../../Utils/TextProcessor";
import { Question } from "../../Types/Question.types";
import { colorPallette } from "../../Common/theme";

interface AnswerDisplayProps {
  watch: any;
  name: string;
  question: Question;
}

const AnswerDisplay: React.FC<AnswerDisplayProps> = ({
  watch,
  name,
  question,
}) => {
  const value = watch[name];

  if (!value) return null;
  if (question.type !== "money" && question.type !== "pesos") return null;

  return (
    <Box
      sx={{
        width: "100%",
        color: colorPallette.primary,
        textAlign: "left",
        pl: 1,
        fontSize: 14,
      }}
    >
      {processAnswer(value)}
    </Box>
  );
};

export default AnswerDisplay;
