import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  Paragraph,
  TextRun,
} from "docx";
import { coldwellHeader } from "../docImages/coldwell";

export const styles_coldwell = {
  companyId: ["12413"],
  companyName: "Coldwell",
  font: "Arial",
  titleSize: 22,
  textSize: 22,
  lineSpacing: 380,
  marginRight: 1730,
  marginLeft: 1730,
  beforeParagraph: 0,
  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 1000,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "MIRTA ROMERO CPI 8539 · GUILLERMO TORRY CPMCLZ 3095",
            size: 18,
            font: "Calibri",
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "Arenales Nº 1901 Recoleta (1124) CABA · 5238-4084 · ",
            size: 18,
            font: "Calibri",
          }),
          new TextRun({
            text: "destino@coldwellbanker.com.ar",
            size: 18,
            font: "Calibri",
            color: "0000FF",
            underline: {},
          }),
        ],
      }),
    ],
  }),
  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 600,
        },
        alignment: AlignmentType.CENTER,
        indent: {
          left: -300,
        },
        children: [
          new ImageRun({
            data: coldwellHeader,
            transformation: {
              width: 550,
              height: 150,
            },
          }),
        ],
      }),
    ],
  }),
};
