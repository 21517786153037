import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface commonInternalGeneratorCardId {
  expandedCardId: number | null;
  formWatch: any;
  internalDocumentSaveOrExpandTrigger: number;
  expandedInternalDocumentCardId: number;
  currentParagraphLength: number[];
  saveDocumentTrigger: boolean;
}

const initialState: commonInternalGeneratorCardId = {
  expandedCardId: 0,
  formWatch: [],
  internalDocumentSaveOrExpandTrigger: 0,
  expandedInternalDocumentCardId: 0,
  currentParagraphLength: [0],
  saveDocumentTrigger: false,
};

const commonComponentsSlice = createSlice({
  name: "expansion",
  initialState,
  reducers: {
    toggleCardExpansion: (state, action: PayloadAction<number | null>) => {
      state.expandedCardId =
        state.expandedCardId === action.payload ? null : action.payload;
    },
    setFormWatch: (state, action: PayloadAction<any>) => {
      state.formWatch = action.payload;
    },
    setInternalDocumentSaveOrExpandTrigger: (
      state,
      action: PayloadAction<number>
    ) => {
      state.internalDocumentSaveOrExpandTrigger = action.payload;
    },
    setExpandedInternalDocumentCardId: (
      state,
      action: PayloadAction<number>
    ) => {
      state.expandedInternalDocumentCardId = action.payload;
    },
    setCurrentParagraphLenth: (state, action: PayloadAction<number[]>) => {
      state.currentParagraphLength = action.payload;
    },
    setSaveDocumentTrigger: (state) => {
      state.saveDocumentTrigger = !state.saveDocumentTrigger;
    },
  },
});

export const {
  toggleCardExpansion,
  setFormWatch,
  setInternalDocumentSaveOrExpandTrigger,
  setExpandedInternalDocumentCardId,
  setCurrentParagraphLenth,
  setSaveDocumentTrigger,
} = commonComponentsSlice.actions;

export default commonComponentsSlice.reducer;
