import { Box, Stack } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ClickableIcon = ({
  icon,
  onClick,
  color,
  isSelected,
  label,
}: any) => {
  return (
    <Stack
      onClick={!isSelected && onClick}
      width={45}
      height={45}
      mx={"2px"}
      sx={{
        position: "relative",
        backgroundColor: isSelected ? colorPallette.secondary : "transparent",
        borderRadius: 2,
        color: color || "inherit",
        transition: "all 0.1s",
        cursor: "pointer",
        "&:hover": !isSelected
          ? { backgroundColor: colorPallette.tertiary }
          : {},
        "&:hover .taskbarIcon": !isSelected
          ? {
              transform: "scale(0.95)",
              transition: "all 0.1s",
            }
          : {},
        "&:hover #taskbarLabel": !isSelected
          ? {
              opacity: "1 !important",
              transform: "translateY(0) scale(1)",
            }
          : {},
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Box
        sx={{
          position: "absolute",
          top: -50,
          backgroundColor: colorPallette.dark,
          color: "white",
          borderRadius: 2,
          whiteSpace: "nowrap",
          px: 1,
          py: 1,
          fontSize: 13,
          transform: "translateY(20px) scale(0.8)",
          opacity: 0,
          pointerEvents: "none",
          transition: "all 0.1s ease-in",
        }}
        id="taskbarLabel"
      >
        {label}
      </Box>
      <FontAwesomeIcon fontSize={20} icon={icon} className="taskbarIcon" />
    </Stack>
  );
};
