import React, { useState } from "react";
import ConfirmationModal from "../../../../uiComponents/Modal/ConfirmationModal/ConfirmationModal";
import { resetModals } from "../../../../redux/modal/modal.actions";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPost } from "../../../../Services/authenticated";
import { useHistory } from "react-router";

const DeleteFolderModal = ({ resetModals, data }) => {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const onClose = () => {
    resetModals();
  };

  const deleteFolder = async () => {
    setLoading(true);
    let docIdArray = [];
    const folderDocuments = data.documents;
    if (folderDocuments && folderDocuments.length > 0) {
      docIdArray = folderDocuments.map((e) => {
        return e.documentId;
      });
    }
    try {
      const accessToken = await getAccessTokenSilently();
      const deleter = await APIPost("/folders/delete-folder", accessToken, {
        documents: docIdArray,
        folderId: data.folder.folderId,
      });
      if (deleter.folderDeleted) {
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const deleteDocument = await APIPost(
        "/doc-ref/delete-document",
        accessToken,
        {
          ...data.document,
        }
      );
      if (deleteDocument.documentDeleted) {
        history.push("/dashboard/finished");
        window.location.reload();
      }
    } catch (e) {
      alert("Error: inténtelo nuevamente.");
      setLoading(false);
    }
  };

  return (
    <ConfirmationModal
      message="Eliminar Carpeta"
      subMessage={`¿Seguro desea eliminar la siguiente carpeta? `}
      onClose={onClose}
      onConfirm={deleteFolder}
      loading={loading}
      disclaimer='*Por seguridad todos los documentos contenidos en la carpeta pasarán a la carpeta "General (sin asignar)"'
      fileName={data.folder.folderName}
    />
  );
};

const mapDispatchToProps = {
  resetModals,
};

const mapStateToProps = (state) => {
  return {
    data: state.modal.deleteFolderModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFolderModal);
