import React, { useState } from "react";
import "./TableUser.styles.css";
import { Box } from "@mui/material";

type Props = {
  name: string;
  photo: string;
  onClick?: () => void;
  forceSmall?: boolean;
  hideName?: boolean;
};

const TableUser2: React.FC<Props> = ({
  name,
  photo,
  onClick,
  forceSmall,
  hideName,
}) => {
  const [showImage, setShowImage] = useState(false);
  const getFristLetters = (str: string) => {
    const firstLetters = str
      .split(" ")
      .map((word: string) => word.charAt(0))
      .join("");

    if (firstLetters.length < 3) {
      return firstLetters;
    } else {
      const reducedInitials = `${firstLetters.charAt(0)}${firstLetters.charAt(
        1
      )}`;
      return reducedInitials;
    }
  };

  const arrayOfColors = [
    "b-primary white",
    "b-secondary grey",
    "b-dark white",
    "b-light-red white",
    "b-table-green white",
  ];

  const getAvatarColor = (letter: string) => {
    if (!letter) return 0;
    if (
      letter === "q" ||
      letter === "w" ||
      letter === "s" ||
      letter === "d" ||
      letter === "f"
    ) {
      return 1;
    }
    if (
      letter === "y" ||
      letter === "u" ||
      letter === "i" ||
      letter === "o" ||
      letter === "j" ||
      letter === "k" ||
      letter === "l"
    ) {
      return 2;
    }
    if (
      letter === "z" ||
      letter === "x" ||
      letter === "c" ||
      letter === "v" ||
      letter === "b" ||
      letter === "n" ||
      letter === "m"
    ) {
      return 3;
    }
    if (letter === "e" || letter === "r" || letter === "t" || letter === "a")
      return 4;

    return 0;
  };

  const letter = name ? name.charAt(0).toLocaleLowerCase() : "a";

  return (
    <div
      className={`bold roboto ui-table-user primary ${
        forceSmall && "force-small-user"
      }`}
      onClick={onClick}
    >
      <img
        src={photo}
        className={`ui-table-user-pic table-user-show-pic-${showImage}`}
        alt={name}
        title="Foto Usuario"
        onError={() => {
          setShowImage(false);
        }}
        onLoad={() => setShowImage(true)}
      />
      {!showImage && (
        <div
          className={`ui-table-user-avatar ${
            arrayOfColors[getAvatarColor(letter)]
          }`}
        >
          <span>{name ? getFristLetters(name) : "A"}</span>
        </div>
      )}
      {!hideName && (
        <Box component="span" className="elipsis">
          {name}
        </Box>
      )}
    </div>
  );
};

export default TableUser2;
