import React, { useEffect, useState } from "react";
import Button from "../../../../uiComponents/Button";
import { connect, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import { interpolateAnswers } from "./InterploateAnswers";
import { useAuth0 } from "@auth0/auth0-react";
import {
  APIGetWithError,
  APIPostFile,
  APIPostWithError,
} from "../../../../Services/authenticated";
import Image from "../../../../uiComponents/Image";

import "../styles.css";
import { useHistory } from "react-router";
import "./styles.css";
import { setEditorDocument } from "../../../../redux/slices/EditorDataSlice";
import { setAppError, setLoading } from "../../../../Utils/modalMethods";
import { setCompany } from "../../../../redux/slices/masterData";

import * as AndesDocuments from "../../../../Contracts/contractExporter";

const CompanyDocumentGenerator = ({ answers }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [documentName, setDocumentName] = useState(null);
  const [downloadDone, setDownloadDone] = useState(false);
  const { company } = useSelector((state) => state.masterData);

  const history = useHistory();

  const dispatch = useDispatch();

  const convertObjectToArray = (object) => {
    const keys = Object.keys(object);
    const finalArray = [];
    keys.forEach((key) => {
      finalArray.push(object[key]);
    });
    return finalArray;
  };

  const getFileDataAndLoadDocumentFromServer = async (fileId) => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();

      const testRes = await APIPostWithError(
        "/doc-ref/get-one-doc",
        accessToken,
        {
          documentId: fileId,
        }
      );
      if (!testRes.document && testRes.document) {
        setLoading(false);
        return;
      }
      dispatch(setEditorDocument(testRes.document));
      history.push("/docx-editor");
    } catch {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const generate = async (doc, docName) => {
    const now = Date.now();
    try {
      const accesToken = await getAccessTokenSilently();
      const blob = await Packer.toBlob(doc);
      const file = new File([blob], "test.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      let newDocName = docName;

      if (window.sessionStorage.getItem("custom_document_name")) {
        newDocName = window.sessionStorage.getItem("custom_document_name");
      }

      const params = {
        data: file,
        docName: `${newDocName}_${now}.docx`,
        documentType: "Word",
        fileNameNoExtension: newDocName,
        versionId: now,
        versionNumber: 1,
        date: now,
        size: file.size,
        updateDate: now,
        format: "word",
      };

      if (window.sessionStorage.getItem("expirationDate")) {
        params.expirationDate = Number(
          window.sessionStorage.getItem("expirationDate")
        );
        window.sessionStorage.removeItem("expirationDate");
      }

      const docRef = await APIPostFile(
        "/doc-ref/create-doc-ref",
        accesToken,
        params
      );

      if (docRef.documentSaved) {
        dispatch(setEditorDocument(null));
        setDocumentName(newDocName);
        setTimeout(() => {
          saveAs(blob, `${newDocName}.docx`);
          setDownloadDone(true);
          getFileDataAndLoadDocumentFromServer(String(now));
        }, 5000);
      }
    } catch (error) {
      alert("Ha ocurrido un error por favor intentelo nuevamente");
    }
  };

  const getCompanyName = (companyId) => {
    if (
      companyId.includes("12346") ||
      companyId.includes("12357") ||
      companyId.includes("12355") ||
      companyId.includes("12394") ||
      companyId.includes("12354") ||
      companyId.includes("12356")
    ) {
      return "soldati";
    }
    if (companyId.includes("12406")) {
      return "soldatiConecta";
    }
    if (companyId.includes("12390")) {
      return "toribioAchaval";
    }
    if (companyId.includes("12391")) {
      return "flechaBus";
    }
    if (companyId.includes("12392")) {
      return "fabian";
    }
    if (companyId.includes("12395")) {
      return "clickAparts";
    }
    if (companyId.includes("12404")) {
      return "guimat";
    }
    if (companyId.includes("12401")) {
      return "izr";
    }
    if (companyId.includes("12405")) {
      return "andesMar";
    }
    return "none";
  };

  const getCompany = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const company = await APIGetWithError(
        "/companies/get-company",
        accessToken
      );
      if (company) {
        dispatch(setCompany(company));
      }
    } catch {
      setAppError();
    }
  };

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (location.pathname.includes("/andes-library")) {
      const andesDocument = AndesDocuments[answers.codeName];
      // const firmas = AndesQuestions[answers.codeName];
      generate(
        interpolateAnswers(andesDocument, answers, "andes", false),
        answers.currentDocument
      );
      return;
    }
    if (company) {
      const docData = sessionStorage.getItem("currentCompanyDocumentData");
      const questionData = sessionStorage.getItem("currentCompanyQuestionData");
      const parsed = JSON.parse(docData);
      const parsedQuestionData = JSON.parse(questionData);
      const docName = parsedQuestionData.name;
      const removeHeaderAndFooter = parsedQuestionData.removeHeaderAndFooter
        ? parsedQuestionData.removeHeaderAndFooter
        : false;

      let companyName = "none";

      if (company.companyId) {
        companyName = getCompanyName(company.companyId);
      }

      if (docData && questionData) {
        generate(
          interpolateAnswers(
            convertObjectToArray(parsed),
            answers,
            companyName,
            removeHeaderAndFooter
          ),
          docName
        );
      } else {
        history.push("/company-document-creator");
      }
    } else {
      getCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  return (
    <div>
      <h2 className="company-document-generator-title">
        {downloadDone
          ? "¡Documento generado y descargado!"
          : "¡Ya casi esta listo!"}{" "}
      </h2>
      {!downloadDone && (
        <div>
          <Image
            name="atom-dark.png"
            className="company-document-generator-atom"
          />
          <p className="primary">Procesando ...</p>
          {documentName && (
            <div>
              <p style={{ marginTop: 20, fontSize: 24 }}>
                <span className="bold">{documentName}</span>
              </p>
              <p className="company-document-generator-entry company-document-generator-entry-1">
                1. Aplicando logica de generación
                <i className="far fa-check-circle secondary company-document-generator-check"></i>
              </p>
              <p className="company-document-generator-entry company-document-generator-entry-2">
                2. Comprobando datos y textos
                <i className="far fa-check-circle secondary company-document-generator-check"></i>
              </p>
              <p className="company-document-generator-entry company-document-generator-entry-3">
                3. Generando estilo del documento
                <i className="far fa-check-circle secondary company-document-generator-check"></i>
              </p>
            </div>
          )}
        </div>
      )}
      {downloadDone && (
        <div>
          <Image
            name="rocket.png"
            className="company-document-generator-rocket"
          />
        </div>
      )}
      {downloadDone && documentName && (
        <div className="company-document-generator-document-name-box">
          <p className="company-document-generator-document-name primary">
            {documentName}
          </p>
          <i className="far fa-check-circle secondary company-document-generator-check"></i>
        </div>
      )}
      {downloadDone && (
        <Button
          color="secondary"
          size="large"
          label="Ir al Dashboard"
          center
          shadow={false}
          className="download-button-desktop"
          onClick={() => {
            history.push("/dashboard/finished");
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    answers: state.answers,
    // currentDocumentData: state.currentDocumentData,
  };
};

export default connect(mapStateToProps)(CompanyDocumentGenerator);
