import React, { memo, useState } from "react";
import ParagraphGeneratorForm from "./ParagraphGeneratorForm";
import ParagraphCollapsed from "./ParagraphCollapsed";
import { useSelector } from "react-redux";

type Props = {
  id: number;
};

const ParagraphGeneratorMemo: React.FC<Props> = ({ id }) => {
  const [reRenderTrigger, setReRenderTrigger] = useState(true);

  const { expandedInternalDocumentCardId } = useSelector(
    (state: any) => state.commonComponents
  );

  const isCollapsed = expandedInternalDocumentCardId !== id;

  if (isCollapsed) {
    return <ParagraphCollapsed id={id} />;
  }

  if (!reRenderTrigger) return <></>;

  return (
    <>
      <ParagraphGeneratorForm id={id} setReRenderTrigger={setReRenderTrigger} />
    </>
  );
};

export default memo(ParagraphGeneratorMemo);
