import React, { useEffect } from 'react';
import '../../styles.css';

const PathOptions = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
  setDependantQuestions,
}) => {
  useEffect(() => {
    question.options.forEach((e) => {
      if (currentAnswer === e.name) {
        setDependantQuestions(e.questions);
      }
    });
  }, [currentAnswer, question.options, setDependantQuestions]);
  return (
    <div className='question-format-form'>
      <select
        required
        value={currentAnswer}
        className='question-format-input'
        onChange={(e) => {
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: e.target.value,
              },
            },
          });
        }}
      >
        <option value=''>Seleccionar</option>
        {question.options.map((e) => {
          return <option value={e.name}>{e.name}</option>;
        })}
      </select>
    </div>
  );
};

export default PathOptions;
