export default false;

export const bancos = [
  "BACS BANCO DE CREDITO Y SECURITIZACION S",
  "BANCO BBVA ARGENTINA S.A.",
  "BANCO CMF S.A.",
  "BANCO COLUMBIA S.A	",
  "BANCO COMAFI SOCIEDAD ANONIMA	",
  "BANCO CREDICOOP COOPERATIVO LIMITADO",
  "BANCO DE CORRIENTES S.A.",
  "BANCO DE FORMOSA S.A.",
  "BANCO DE GALICIA Y BUENOS AIRES S.A.U.",
  "BANCO DE INVERSION Y COMERCIO EXTERIOR S.A.",
  "BANCO DE LA CIUDAD DE BUENOS AIRES",
  "BANCO DE LA NACION ARGENTINA",
  "BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA MIXTA",
  "BANCO DE LA PROVINCIA DE BUENOS AIRES",
  "BANCO DE LA PROVINCIA DE CORDOBA S.A.",
  "BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY",
  "BANCO DE SAN JUAN S.A.",
  "BANCO DE SANTA CRUZ S.A.",
  "BANCO DE SANTIAGO DEL ESTERO S.A.",
  "BANCO DE VALORES S.A.",
  "BANCO DEL CHUBUT S.A.",
  "BANCO DEL SOL S.A.",
  "BANCO DINO S.A.",
  "BANCO HIPOTECARIO S.A.",
  "BANCO INDUSTRIAL S.A.",
  "BANCO ITAU ARGENTINA S.A.",
  "BANCO MACRO S.A.",
  "BANCO MARIVA S.A.",
  "BANCO MERIDIAN S.A.",
  "BANCO PATAGONIA S.A.",
  "BANCO PIANO S.A.",
  "BANCO PROVINCIA DE TIERRA DEL FUEGO",
  "BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ",
  "BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL",
  "BANCO SANTANDER RIO S.A.",
  "BANCO SUPERVIELLE S.A.",
  "BANK OF AMERICA, NATIONAL ASSOCIATION",
  "BANK OF CHINA LIMITED SUCURSAL BUENOS AI",
  "BNP PARIBAS",
  "BRUBANK S.A.U.",
  "CITIBANK N.A.",
  "HSBC BANK ARGENTINA S.A.",
  "INDUSTRIAL AND COMMERCIAL BANK OF CHINA",
  "JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO",
  "NARANJA DIGITAL COMPAÑÍA FINANCIERA S.A.",
];
