import { useSelector } from "react-redux";
import QuestionGeneratorForm from "./QuestionGeneratorForm";
import CollapedForm from "./CollapsedForm";

type Props = {
  id: number;
  control: any;
  removeQuestion: any;
  nextId?: string;
  index: number;
  insert: any;
  field: any;
  remove: any;
  getValues: any;
  trigger: any;
};

const QuestionGeneratorMemo: React.FC<Props> = ({
  control,
  id,
  removeQuestion,
  index,
  insert,
  field,
  remove,
  getValues,
  trigger,
}) => {
  const expandedCardId = useSelector(
    (state: any) => state.commonComponents.expandedCardId
  );
  const isExpanded = expandedCardId === id;

  if (!isExpanded)
    return (
      <CollapedForm
        id={id}
        removeQuestion={removeQuestion}
        getValues={getValues}
        trigger={trigger}
        index={index}
        insert={insert}
      />
    );

  return (
    <>
      <QuestionGeneratorForm
        control={control}
        id={index}
        key={field.id}
        removeQuestion={remove}
        index={index}
        insert={insert}
        trigger={trigger}
      />
    </>
  );
};

export default QuestionGeneratorMemo;
