import React, { useState } from "react";
import ConfirmationModal from "../../../../uiComponents/Modal/ConfirmationModal/ConfirmationModal";
import { setModal } from "../../../../redux/modal/modal.actions";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPost } from "../../../../Services/authenticated";
import { useHistory } from "react-router";

const DeleteFileModal = ({ setModal, data }) => {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const history = useHistory();

  const onClose = () => {
    setModal({
      deleteDocumentModal: {
        show: false,
        document: null,
      },
    });
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const deleteDocument = await APIPost(
        "/doc-ref/delete-document",
        accessToken,
        {
          ...data.document,
        }
      );
      if (deleteDocument.documentDeleted) {
        history.push("/dashboard/finished");
        window.location.reload();
      }
    } catch (e) {
      alert("Error: inténtelo nuevamente.");
      setLoading(false);
    }
  };

  return (
    <ConfirmationModal
      message="Eliminar Documento"
      subMessage={`¿Seguro desea eliminar el siguiente documento?`}
      onClose={onClose}
      onConfirm={onDelete}
      fileName={data.document.fileName}
      disclaimer="*No se podrá deshacer"
      loading={loading}
    />
  );
};

const mapDispatchToProps = {
  setModal,
};

const mapStateToProps = (state) => {
  return {
    data: state.modal.deleteDocumentModal,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteFileModal);
