import { Box, Button, Stack, Typography } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt } from "@fortawesome/free-solid-svg-icons";

export const YoutubeVideoSection: React.FC = () => {
  return (
    <Stack
      sx={{
        width: "700px",
        maxWidth: "90%",
        alignItems: "center",
        gap: 2,
        my: 12,
        mx: "auto",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        fontWeight="bold"
        sx={{ width: "90%", maxWidth: "700px" }}
        mb={3}
      >
        Conocé
        <span style={{ color: colorPallette.primary }}></span> como{" "}
        <span style={{ color: colorPallette.primary }}>funciona</span>
      </Typography>

      <Box
        component="a"
        href="https://www.youtube.com/watch?v=uJBUtOm3_Tk&t=1s"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          display: "block",
          width: "100%",
          maxWidth: "1600px",
        }}
      >
        <Box
          component="img"
          src={process.env.PUBLIC_URL + "/images/thumb_tute.jpg"}
          className="por-que-automation"
          alt="Product Image"
          title="Imagen del producto 1"
          sx={{
            width: "100%",
            borderRadius: "10px",
          }}
        />
      </Box>
      <Button
        variant="contained"
        sx={{
          width: "700px",
          maxWidth: "90vw",
          bgcolor: colorPallette.dark,
          "&:hover": {
            bgcolor: colorPallette.primary,
          },
        }}
        component="a"
        href="https://www.youtube.com/watch?v=uJBUtOm3_Tk&t=1s"
        target="_blank"
        rel="noopener noreferrer"
      >
        Ver video{" "}
        <FontAwesomeIcon
          icon={faBolt}
          style={{ marginLeft: "8px", color: colorPallette.secondary }}
        />
      </Button>
    </Stack>
  );
};
