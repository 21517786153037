import React from "react";

type Props = {
  name: string;
  alt?: string;
  className?: string;
  style?: any;
};

const Image: React.FC<Props> = ({ name, alt, className, style }) => {
  return (
    <img
      src={process.env.PUBLIC_URL + `/images/${name}`}
      className={className && className}
      alt={alt && alt}
      style={style}
      title="Imagen Andes Docs"
    />
  );
};

export default Image;
