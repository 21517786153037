import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../uiComponents/DeleteIcon/DeleteIcon";
import { Box } from "@mui/material";
import styles from "./QuestionGeneratorForm.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toggleCardExpansion } from "../../redux/slices/commonComponentsSlice";
import { memo, useEffect, useState } from "react";
import { setAppError } from "../../Utils/modalMethods";

type Props = {
  id: number;
  removeQuestion: any;
  getValues: any;
  trigger: any;
  insert: any;
  index: any;
};

const CollapedForm: React.FC<Props> = ({
  id,
  removeQuestion,
  getValues,
  trigger,
  insert,
  index,
}) => {
  const [values, setValues] = useState<any>(null);

  const expandedCardId = useSelector(
    (state: any) => state.commonComponents.expandedCardId
  );
  const isExpanded = expandedCardId === id;

  const dispatch = useDispatch();

  const handleToggleExpansion = async () => {
    const isValid = await trigger();
    if (isValid) dispatch(toggleCardExpansion(id));
    else {
      setAppError("Error", "Por favor complete los campos requeridos");
    }
  };

  const handleAddQuestion = async () => {
    const isValid = await trigger();
    if (!isValid) {
      setAppError("Error", "Por favor complete los campos requeridos");
      return;
    }
    const currentSection = getValues().questions[id].section;
    insert(index + 1, {
      dependencies: [],
      type: "question",
      question: "",
      section: currentSection,
      id: "",
    });
    setTimeout(() => {
      dispatch(toggleCardExpansion(id + 1));
    }, 100);
  };

  useEffect(() => {
    setValues(getValues().questions[id]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded]);

  return (
    <>
      <Box
        className={styles.card}
        maxWidth="1000px"
        position={"relative"}
        textAlign={"left"}
        sx={{
          animation: "scaleIn 0.5s ease-out",
          "@keyframes scaleIn": {
            "0%": {
              transform: "scale(0.97)",
              opacity: 0,
            },
            "100%": {
              transform: "scale(1)",
              opacity: 1,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <DeleteIcon
            sx={{ pr: "10px", pt: "1px" }}
            onClick={() => {
              removeQuestion(id);
            }}
          />
          <i
            className="fas fa-chevron-circle-right primary"
            style={{ marginRight: 10 }}
          ></i>

          <Box component="span" className="primary bold" mr={1}>
            {values?.id || ""}:
          </Box>
          {values?.question || ""}
        </Box>

        <Box
          position="absolute"
          right={24}
          top={24}
          sx={{ cursor: "pointer" }}
          className="primary"
          onClick={handleToggleExpansion}
        >
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
        </Box>
      </Box>
      <Box
        component={"button"}
        mb={5}
        sx={{
          cursor: "pointer",
          background: "none",
          border: "none",
          "&:hover .hoverClassNmae": {
            opacity: 0.9,
            transform: "scale(1.3) rotate(90deg)",
            transition: "all 0.3s",
          },
        }}
        role="button"
        tabIndex={0}
        onClick={handleAddQuestion}
      >
        <FontAwesomeIcon className="hoverClassNmae" icon={faPlusCircle} />
      </Box>
    </>
  );
};

export default memo(CollapedForm);
