import React, { useState, useEffect } from "react";
import Button from "../uiComponents/Button";
import "./styles.css";
import * as Questions from "../data/questionsExporter";
import * as Documents from "../Contracts/contractExporter";
import { APIGetProd, APIPostProd } from "../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "../screens/LoadingScreen";
import { setAppError } from "../Utils/modalMethods";

const files = [
  {
    questionsName: "TestQuestions1",
    docName: "TestDoc1",
    documentData: Documents.Test1,
    questionData: Questions.Test1,
  },
  {
    questionsName: "TestQuestions2",
    docName: "TestDoc2",
    documentData: Documents.Test2,
    questionData: Questions.Test2,
  },
  {
    questionsName: "TestQuestions3",
    docName: "TestDoc3",
    documentData: Documents.Test3,
    questionData: Questions.Test3,
  },
  {
    questionsName: "TestQuestions4",
    docName: "TestDoc4",
    documentData: Documents.Test4,
    questionData: Questions.Test4,
  },
  {
    questionsName: "TestQuestions5",
    docName: "TestDoc5",
    documentData: Documents.Test5,
    questionData: Questions.Test5,
  },
  {
    questionsName: "TestQuestions6",
    docName: "TestDoc6",
    documentData: Documents.Test6,
    questionData: Questions.Test6,
  },
  {
    questionsName: "TestQuestions7",
    docName: "TestDoc7",
    documentData: Documents.Test7,
    questionData: Questions.Test7,
  },
];

const DataUpdater = () => {
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const [documentData, setDocumentData] = useState(null);
  const [documentName, setDocumentName] = useState("");
  const [documentName1, setDocumentName1] = useState("");
  const [documentName2, setDocumentName2] = useState("");
  const [selectedQuestionData, setSelectedQuestionData] = useState("");
  const [selectedDocumentData, setSelectedDocumentData] = useState("");
  const [password, setPassword] = useState("");
  const [companies, setCompanies] = useState([]);
  const [companyDocs, setCompanyDocs] = useState(null);
  const [stage, setStage] = useState(1);

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const getAllCompanies = async () => {
    const accesToken = await getAccessTokenSilently();
    const allCompanies = await APIGetProd(
      "/companies/get-all-companies",
      accesToken
    );
    setCompanies(allCompanies);
    setLoading(false);
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const getCompanyDocs = async () => {
    const accesToken = await getAccessTokenSilently();
    const thisdocuments = await APIPostProd(
      "/company-docs/get-all-company-docs-for-update",
      accesToken,
      { companyId }
    );
    if (thisdocuments.documents && thisdocuments.documents.length > 0) {
      setCompanyDocs(thisdocuments.documents);
      setStage(2);
    } else {
      setAppError(
        "Esta empresa no tiene ningún documento",
        "Elija otra empresa",
        ""
      );
    }
  };

  const goToSection2 = async () => {
    setLoading(true);
    await getCompanyDocs();
    setLoading(false);
  };

  const selectDocument = (docName) => {
    setDocumentName(docName);
    const documentSelected = companyDocs.find(
      (e) => e.documentName === docName
    );
    if (documentSelected) {
      setDocumentData(documentSelected);
      setDocumentName1(documentSelected.documentName1);
      setDocumentName2(documentSelected.documentName2);
    }
  };

  const goToSection3 = () => {
    if (documentData) {
      setDocumentName(documentData.documentName);
      setDocumentName1(documentData.documentName1);
      setDocumentName2(documentData.documentName2);
      setStage(3);
    }
  };

  const upload = async () => {
    if (
      selectedDocumentData.trim() === "" ||
      selectedQuestionData.trim() === "" ||
      documentName.trim() === "" ||
      documentName1.trim() === "" ||
      documentName2.trim() === "" ||
      companyId.trim() === "" ||
      password.trim() === ""
    ) {
      alert("Llenar todos los campos");
      return;
    }
    setLoading(true);
    const finalQuestionData = files.find(
      (e) => e.questionsName === selectedQuestionData
    );

    const finalDocumentData = files.find(
      (e) => e.docName === selectedDocumentData
    );

    const finalCompanyData = companies.find((e) => e.companyId === companyId);

    const now = Date.now();

    const params = {
      documentName,
      documentName1,
      documentName2,
      questionData: finalQuestionData.questionData,
      documentData: finalDocumentData.documentData,
      date: now.toString(),
      companyId: finalCompanyData.companyId,
      companyName: finalCompanyData.companyName,
      password,
      prevDocId: documentData.documentId,
      prevDataPath: documentData.dataPath,
    };

    const accesToken = await getAccessTokenSilently();
    const uploadData = await APIPostProd(
      "/company-docs/update-company-document",
      accesToken,
      params
    );

    if (uploadData.saved) {
      alert("guardado correctamente");
      window.location.reload();
    }
    setLoading(false);
  };

  return (
    <div className="data-uploader-wrapper b-tertiary">
      <h2>Data Updater</h2>
      {loading && <LoadingScreen />}
      {!loading && isAuthenticated && (
        <div className="data-uploader-card b-white">
          <form style={{ width: "100%" }}>
            {stage === 1 && companies && (
              <div className="data-uploader-field">
                <label>Seleccione a que empresa pertenece el documento:</label>
                <select required onChange={(e) => setCompanyId(e.target.value)}>
                  <option value="">Seleccionar</option>
                  {companies.map((com, index) => {
                    return (
                      <option value={com.companyId} key={index}>
                        {com.companyName}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            {stage === 2 && (
              <div className="data-uploader-field">
                <label>Seleccione el documento que desea modificar:</label>
                <select
                  required
                  onChange={(e) => {
                    selectDocument(e.target.value);
                  }}
                >
                  <option value="">Seleccionar</option>
                  {companyDocs &&
                    companyDocs.map((doc, index) => {
                      return (
                        <option value={doc.documentName} key={index}>
                          {doc.documentName}
                        </option>
                      );
                    })}
                </select>
              </div>
            )}

            {stage === 3 && (
              <>
                <div className="data-uploader-field">
                  <label>Preguntas:</label>
                  <select
                    required
                    onChange={(e) => setSelectedQuestionData(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    {files.map((data, index) => {
                      return (
                        <option value={data.questionsName} key={index}>
                          {data.questionsName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="data-uploader-field">
                  <label>Documento:</label>
                  <select
                    required
                    onChange={(e) => setSelectedDocumentData(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    {files.map((data, index) => {
                      return (
                        <option value={data.docName} key={index}>
                          {data.docName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="data-uploader-field">
                  <label>Nombre del Documento:</label>
                  <input
                    type="text"
                    onChange={(e) => setDocumentName(e.target.value)}
                    value={documentName}
                    defaultValue={documentName}
                  />
                </div>
                <div className="data-uploader-field">
                  <label>Nombre parte regular:</label>
                  <input
                    type="text"
                    onChange={(e) => setDocumentName1(e.target.value)}
                    value={documentName1}
                    defaultValue={documentName1}
                  />
                </div>
                <div className="data-uploader-field">
                  <label>Nombre parte negrita:</label>
                  <input
                    type="text"
                    onChange={(e) => setDocumentName2(e.target.value)}
                    value={documentName2}
                    defaultValue={documentName2}
                  />
                </div>
                <div className="data-uploader-field" style={{ marginTop: 70 }}>
                  <label>Password:</label>
                  <input
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>
              </>
            )}

            {stage === 1 && (
              <Button
                label={"Continuar"}
                style={{ width: "100%", marginTop: 40 }}
                onClick={goToSection2}
              />
            )}
            {stage === 2 && (
              <Button
                label={"Continuar"}
                style={{ width: "100%", marginTop: 40 }}
                onClick={goToSection3}
              />
            )}
            {stage === 3 && (
              <Button
                label={"Cargar Data"}
                style={{ width: "100%", marginTop: 40 }}
                onClick={upload}
              />
            )}
          </form>
        </div>
      )}
    </div>
  );
};

export default DataUpdater;
