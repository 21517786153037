import React, { useState, useEffect } from "react";
import Text from "../../../uiComponents/Text";

import SelectorBox from "../../../uiComponents/SelectorBox";
import QuestionSelector from "../QuestionSelector";

import { connect } from "react-redux";
import { setAnswers } from "../../../redux/answers/answers.actions";

const Person = ({
  data,
  currentSection,
  sectionId,
  answers,
  setAnswers,
  setCurrentSection,
}) => {
  const [selected, setSelected] = useState(undefined);
  const thisSection = data.sections[currentSection - 1].pathQuestion;

  // sectionParse converts current section number to be the same as section id, for example 1 to 'section1'

  const sectionParse = `section${currentSection}`;

  // this one checks wheather at least 1 question was answered, beacause the object is created when a question is answered

  const sectionHasAtLeast1Answer = answers.hasOwnProperty(sectionParse);

  // use effect checks the answers and sets the selection to what was answered

  useEffect(() => {
    // here we set the person to active inputs

    const setActiveInputs = () => {
      if (sessionStorage.activeInputs) {
        const prevInputs = JSON.parse(sessionStorage.activeInputs);
        prevInputs.push("person");
        sessionStorage.setItem("activeInputs", JSON.stringify(prevInputs));
      } else {
        sessionStorage.setItem("activeInputs", JSON.stringify(["person"]));
      }
    };
    setActiveInputs();
  }, []);

  useEffect(() => {
    if (sectionHasAtLeast1Answer) {
      if (answers[sectionId].person.answer === "Persona Física") {
        setSelected(0);
      } else {
        setSelected(1);
      }
    } else {
      setSelected(undefined);
    }
  }, [answers, sectionHasAtLeast1Answer, sectionId]);

  // this functions sets the answer of person and resets answers when changing
  const personSetter = (personIndex) => {
    setAnswers({
      [sectionId]: {
        person: {
          question: thisSection.question,
          answer: thisSection.options[personIndex].name,
        },
      },
    });
  };

  return (
    <div className="person-div">
      <Text>{thisSection.question}</Text>
      {thisSection.options.map((option, index) => {
        return (
          <SelectorBox
            image={option.image}
            title={option.name}
            description={option.description}
            selected={index === selected}
            onClick={() => {
              if (index !== selected) {
                setSelected(index);
                personSetter(index);
              }
            }}
          />
        );
      })}
      {selected !== undefined && (
        <div>
          {thisSection.options[selected].questions.map((question) => {
            return (
              <QuestionSelector
                question={question}
                key={question.id}
                sectionId={sectionId}
              />
            );
          })}
          <div className="flex next-section-button-div ">
            <div>
              {currentSection > 1 && (
                <span
                  className="prev-section-button primary"
                  onClick={() => {
                    setCurrentSection(currentSection - 1);
                  }}
                >
                  Anterior
                </span>
              )}
            </div>

            <input
              type="submit"
              value="Siguiente"
              formAction={`/redirect/${currentSection}`}
              className="next-section-button b-primary"
            ></input>
          </div>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setAnswers,
};

const mapStateToProps = (state) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Person);
