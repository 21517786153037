import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
} from './types';

import currency from './currency';
import { provincias } from './provincias';

export const ContratoDeAlquilerTemporalSections = [
  {
    id: 'section1',
    section: 'Tiempo y Lugar',
    title: 'Tiempo y Lugar de Celebración del Contrato de Alquiler Temporal',
    type: questions,
    explanation: {
      question: 'Que es el tiempo y el lugar?',
      answer: [
        'El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.',
      ],
    },
    questions: [
      {
        id: 1,
        question:
          '¿En qué Provincia se celebra el Contrato de Alquiler Temporal?',
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question:
          '¿En que Ciudad/Localidad se celebra el Contrato de Alquiler Temporal?',
        info:
          'Si arriba elegistes Ciudad Autónoma de Buenos Aires, respondé lo mismo en esta pregunta.',
        type: question,
        notBe: {
          id: 1,
          answer: 'Ciudad Autónoma de Buenos Aires',
        },
      },
      {
        id: 3,
        question: '¿En qué fecha se celebra el Contrato?',
        type: date,
      },
    ],
  },
  {
    id: 'section2',
    section: 'Propietario',
    title:
      'Indicar los datos del Propietario que dará en Alquiler Temporal su Inmueble.',
    type: person,
    explanation: {
      question: '¿Quién es Propietario del Contrato de Alquiler?',
      answer: [
        'El Propietario es el dueño del Inmueble que lo da en alquiler y recibe a cambio un monto en dinero a cambio.',
      ],
    },
    pathQuestion: {
      question: '¿Persona física o jurídica?',
      id: 'person1',
      type: select,
      options: [
        {
          name: 'Persona Física',
          description: 'Una persona real, humana.',
          image: 'girl.png',
          questions: [
            {
              id: 4,
              question: 'Nombres y Apellidos Completos:',
              type: question,
            },
            {
              id: 5,
              question: 'Tipo de Documento:',
              type: options,
              options: ['DNI', 'Pasaporte', 'Cédula de Identidad'],
            },
            {
              id: 6,
              question: 'Número de Documento:',
              type: question,
            },
            {
              id: 7,
              question: 'Domicilio Completo:',
              info: '(Calle, Piso, Departamento, Ciudad)',
              type: question,
            },
            {
              id: 8,
              question: 'Provincia:',
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: 'Persona Jurídica',
          description:
            'Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones',
          image: 'srl.png',
          questions: [
            {
              id: 9,
              question: 'Denominación Social (incluyendo tipo societario):',
              info:
                'Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.',
              type: question,
            },
            {
              id: 10,
              question: 'Tipo de identificacion:',
              type: options,
              options: ['CUIT'],
            },
            {
              id: 11,
              question: 'Número de CUIT:',
              type: question,
            },
            {
              id: 12,
              question:
                'Domicilio Completo de la sede principal de la entidad:',
              info: '(Calle, Piso, Departamento, Ciudad)',
              type: question,
            },
            {
              id: 13,
              question: 'Provincia:',
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: 'section3',
    section: 'Inquilino',
    title:
      'Indicar los datos del Inquilino que alquilará el Inmueble temporalmente.',
    type: person,
    explanation: {
      question: '¿Quién es el Inquilino del Contrato de Alquiler Temporal?',
      answer: [
        'El Inquilino es quien alquilará el Inmueble del Propietario y pagará un alquiler a cambio.',
      ],
    },
    pathQuestion: {
      question: '¿Persona física o jurídica?',
      id: 'person2',
      type: select,
      options: [
        {
          name: 'Persona Física',
          description: 'Una persona real, humana.',
          image: 'girl.png',
          questions: [
            {
              id: 14,
              question: 'Nombres y Apellidos Completos:',
              type: question,
            },
            {
              question: 'Tipo de Documento:',
              id: 15,
              type: options,
              options: ['DNI', 'Pasaporte', 'Cédula de Identidad'],
            },
            {
              id: 16,
              question: 'Número de Documento:',
              type: question,
            },
            {
              id: 17,
              question: 'Domicilio Completo:',
              info: '(Calle, Piso, Departamento, Ciudad)',
              type: question,
            },
            {
              id: 18,
              question: 'Provincia:',
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: 'Persona Jurídica',
          description:
            'Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones',
          image: 'srl.png',
          questions: [
            {
              id: 19,
              question: 'Denominación Social (incluyendo tipo societario):',
              info:
                'Por ej.: Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.',
              type: question,
            },
            {
              id: 20,
              question: 'Tipo de identificacion:',
              type: options,
              options: ['CUIT'],
            },
            {
              id: 21,
              question: 'Número de CUIT:',
              type: question,
            },
            {
              id: 22,
              question:
                'Domicilio Completo de la sede principal de la entidad:',
              info: '(Calle, Piso, Departamento, Ciudad)',
              type: question,
            },
            {
              id: 23,
              question: 'Provincia:',
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: 'section4',
    section: 'Inmueble',
    title: 'Detallar el Inmueble que se alquilará',
    type: questions,
    explanation: {
      question: '¿Qué es el Inmueble?',
      answer: [
        'El inmueble puede ser de cualquier tipo apto para ser usado como vivienda (departamento, PH, casa en un barrio privado o abierto, country, etc.) y de cualquier tamaño, condición (nuevo o usado) con o sin cochera y/o amoblado o desamoblado.',
      ],
    },
    questions: [
      {
        id: 24,
        question: '¿En qué Provincia se ubica el Inmueble?:',
        type: options,
        options: provincias,
      },
      {
        id: 25,
        question: '¿En qué Ciudad/Localidad se encuentra el Inmueble?',
        answer: ['Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc.'],
        type: question,
        notBe: {
          id: 24,
          answer: 'Ciudad Autónoma de Buenos Aires',
        },
      },

      {
        id: 26,
        question:
          '¿Cuál es la úbicación exacta del Inmueble? (Calle, Número, Departamento, Piso y Unidad)',
        answer: [
          'Si es un Departamento o PH, por ej: Beruti 3574, Piso 3, dpto "A"',
          'Si es una Casa, Lote, etc., por ej: Ramallo 3571, Beccar; ó Blanco Encalada 1200, Barrio Laguna de la Luna, Lote 3525',
        ],
        type: question,
      },
      {
        id: 125,
        question: '¿Con que servicios cuenta el inmueble?',
        type: multipleOptions,
        info:
          'Esto NO implica que vayan a ser pagados por el dueño del inmueble',
        options: [
          'Agua corriente',
          'Electricidad',
          'Gas de red',
          'Telefonía fija',
          'Televisión por cable',
          'Internet',
        ],
      },
      {
        id: 27,
        question: '¿El inmueble incluye cochera y/o baulera?',
        type: multipleOptions,
        options: ['Cochera', 'Baulera'],
      },
      {
        id: 28,
        question: 'Mobiliario del Inmueble ¿Desea agregar un inventario?',
        answer: [
          'La ley exige para los Alquileres Temporales que el Inmueble que se alquila venga amoblado. Andes Docs prevé esto en el Contrato.',
          'Favor de establecer si incluirá o no un Inventario con el detalle de los muebles/cosas que se encuentran dentro del Inmueble.',
          'El Inventario es un detalle pormenizado de las cosas muebles del Inmueble. No es obligatorio contemplarlo en el Contrato.',
        ],
        type: options,
        options: ['Sí', 'No'],
      },
      {
        id: 29,
        question: 'Detalle los muebles que se van a incluir (inventario)',
        answer: [
          'Por ejemplo: Un sillón, una mesa comedor con 6 sillas, 6 juegos de cubiertos, 6 juegos de copas, etc.',
        ],
        type: textarea,
        depend: {
          id: 28,
          answer: 'Sí',
        },
      },
    ],
  },
  {
    id: 'section5',
    section: 'Precio, Pago y Duración',
    title: 'Precio, Forma de Pago y Duración del Alquiler',
    type: questions,
    explanation: {
      question: '¿Qué es el Precio, la Forma de Pago y la Duración?',
      answer: [
        'El Precio del Alquiler es el dinero que el Inquilino le pagará al Propietario.',
        'La Forma de Pago es la manera que se pagará el Precio del Alquiler.',
        'El Propietario y el Inquilino acuerdan libremente la duración (o plazo) del Contrato. Sin embargo, la Ley no permite que sea mayor a tres (3) meses. El presente Contrato se utiliza para alquileres temporales.',
        'Recordamos que para este tipo de Contrato temporal, no se aplica ajustes, ni actualizaciones de precios. Se deberá preever el precio en la presente claúsula junto con la forma de pago.',
      ],
    },
    questions: [
      {
        id: 31,
        question:
          'Precio del Alquiler Temporal ¿De qué manera se calculará el precio del Alquiler?',
        answer: [
          'El precio del alquiler se puede cálcular por día, semana, quincena, mes o pago en su totalidad.',
          'En la pregunta siguiente establecerás el monto del precio del alquiler.',
          'Deberás determinar la base de cálculo. Por ejemplo: $750 por día; $15.000 la quincena; $32.000 el mes; etc.',
        ],
        type: options,
        options: ['Día', 'Semana', 'Quincena', 'Mes', 'Un único pago'],
      },
      {
        id: 32,
        question: '¿Cúal es el Precio del alquiler temporal?',
        info:
          'En línea con la pregunta anterior, establecer el precio del alquiler temporal.',

        type: money,
      },
      {
        id: 321,
        question: 'La duración del Alquiler ¿Se calculará en días o meses?',
        answer: [
          'Establecer si la duración del Alquiler se calculará en días o meses.',
          'En la siguiente pregunta determinarás la cantidad. Por ej: 3 meses, 10 días, 1 mes.',
        ],
        type: options,
        options: ['días', 'meses'],
      },
      {
        id: 33,
        question:
          '¿Cuánto tiempo durará el alquiler temporal? Recordar que el máximo legal son 3 Meses.',
        answer: [
          'En línea con la respuesta anterior, determinar EN NÚMEROS la duración del Alquiler.',
        ],
        type: number,
      },
      {
        id: 34,
        question: '¿A partir de qué fecha comenzará el alquiler temporal?',
        answer: [
          'Indicar a partir de que momento inciará el alquiler entre el Propietario y el Inquilino.',
          'Indicar si empieza el mismo día que se firma el Contrato ó si empieza en otra fecha posterior.',
          'En caso que sea otra fecha posterior, deberá indicar cuál.',
        ],
        type: options,
        options: ['Misma fecha que se firma el Contrato', 'Otra posterior'],
      },
      {
        id: 35,
        question: '¿En que fecha empieza?',
        type: date,
        depend: {
          id: 34,
          answer: 'Otra posterior',
        },
      },
      {
        id: 36,
        question: '¿Dónde deberá el Inquilino pagar el alquiler temporal?',
        answer: [
          'Indicar el lugar/manera donde deberá el Inquilino pagar el alquiler temporal del Inmueble.',
          'Se podrá hacer en una cuenta bancaria del Propietario mediante depósito o transferencia bancaria.',
          'O se podrá realizar mediante pago en efectivo en mano en el domicilio del Propietario o en otro domicilio acordado.',
        ],
        type: options,
        options: [
          'En la cuenta bancaria del Propietario',
          'En el domicilio del Propietario',
          'Otro domicilio',
        ],
      },

      {
        id: 37,
        question:
          'Del Propietario. Detallar los datos completos de la Cuenta Bancaria de donde se realizarán los pagos del alquiler temporal',
        answer: [
          'Detallar N° de cuenta, CBU, Alias y Banco del Propietario, en donde el Inquilino deberá pagar el alquiler temporal del Inmueble.',
          'Por ej.: Caja de Ahorro N° 40015878, CBU N° 031584623598700000155, Alias: "Andes.Docs.Propietario" del Banco Francés SAS',
        ],
        type: question,
        depend: {
          id: 36,
          answer: 'En la cuenta bancaria del Propietario',
        },
      },
      {
        id: 38,
        question:
          'Detallar el domicilio donde el Inquilino deberá realizar sus pagos',
        info:
          'Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad) en donde el Inquilino deberá pagar el alquiler temporal.',
        type: question,
        depend: {
          id: 36,
          answer: 'Otro domicilio',
        },
      },

      {
        id: 39,
        question:
          'Forma de Pago ¿De qué manera el Inquilino pagará el alquiler temporal?',
        answer: ['Indicar cada cuanto deberá pagar el alquiler el Inquilino.'],
        type: options,
        options: [
          'diariamente',
          'semanalmente',
          'quincenalmente',
          'mensualmente',
          'en su totalidad',
        ],
      },
    ],
  },
  {
    id: 'section6',
    section: 'Condiciones Específicas',
    title: 'Cláusulas Específicas, Jurisdicción y Depósito en Garantía',
    type: questions,
    explanation: {
      question: '¿Que vamos a definir?',
      answer: [
        'Vamos a definir ciertas claúsulas específicas sobre el alquiler y vamos a definir la Jurisdicción que aplicará al Contrato si hubiera algún conflicto entre el Inquilino y el Propietario por el alquiler temporal del Inmueble',
        'Vamos a definir si habrá o no un Depósito en Garantía. Si bien no es obligatorio por ley, es práctica generalizada que al firmarse el Contrato el Inquilino entregue un depósito de garantía a la Propietaria. El Propietario deberá devolver el Depósito en Garantía si el Locatario restituyera el Inmueble totalmente desocupado y libre en el estado en que lo recibió, excepto por los deterioros provenientes del mero transcurso del tiempo y el uso regular del mismo.',
      ],
    },
    questions: [
      {
        id: 36,
        question: '¿Desea prohibir alguna de estas actividades en el inmueble?',
        type: multipleOptions,
        options: ['Fumar dentro del inmueble', 'Tener Mascotas'],
      },
      {
        id: 37,
        question:
          'En caso de conflicto ¿En qué jurisdicción se deberá resolver?',
        answer: [
          'En caso de conflicto, las Partes deberán resolverlo ante un Juez. Definir en que provincia se encontrará el Juez que resolveran el conflicto. (Se suele elegir la misma provincia donde se celebra el Contrato)',
        ],
        type: options,
        options: provincias,
      },
      {
        id: 38,
        question: '¿Se va a dejar un Depósito en Garantía?',
        answer: [
          'En caso que se opté por "Sí", deberá indicar a continuación el monto del Depósito en Garantía.',
          'En caso que opte por "No", no lo incorporaremos al Contrato.',
        ],
        type: options,
        options: ['Sí', 'No'],
      },
      {
        id: 39,
        question: '¿Cuál será el monto del depósito en garantía?',
        type: money,
        depend: {
          id: 38,
          answer: 'Sí',
        },
      },
    ],
  },
];

export const ContratoDeAlquilerTemporal = {
  name: 'Contrato de Alquiler Temporal',
  codeName: 'contratoDeAlquilerTemporal',
  question: '¿Qué es un Contrato de Alquiler Temporal?',
  definition: [
    'El Contrato de Alquiler Temporal se utiliza para documentar la locación temporaria de un inmueble urbano, con muebles, por un plazo de entre 1 día y 3 meses. El alquiler se debe realizar con fines de turismo, descanso o similares. Este documento cumple los requisitos del Código Civil y Comercial de la Nación y de la Ley N° 27.551.',
    'La ley establece que el inmueble debe alquilarse con los muebles esenciales para que la locataria pueda habitarlo (cama/as, heladera, mesa, sillas, muebles de living, etc.).',
  ],
  firmas: [
    [
      { id: 4, section: 'section2' },
      { id: 9, section: 'section2' },
    ],
    [
      { id: 14, section: 'section3' },
      { id: 19, section: 'section3' },
    ],
  ],
  price: 999.99,
  size: '9-12 Páginas',
  lastRevision: 'Junio 2020',
  sections: ContratoDeAlquilerTemporalSections,
  url: 'contrato-de-alquiler-temporal',
  video: null,
  useCases: {
    good: [
      'Alquilar un departamento por un plazo no mayor a tres (3) meses',
      'Alquilar un departamento/casa/PH por plataformas como Airbnb o similares, por un plazo entre 1 día y 3 meses',
      'Alquilar un inmueble con fines turísticos, de descanso o similares',
    ],
    bad: [
      'Alquilar un inmueble para vivienda por un plazo mayor a tres (3) meses',
      'Alquilar un departamento/casa/PH para uso comercial o como oficina',
      'Comprar o vendar un inmueble',
    ],
  },
};
