import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Tooltip,
  Typography,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import React from "react";
import { styled } from "@mui/system";
import { colorPallette } from "../../Common/theme";

interface AndesTooltipProps {
  title: string;
  description: string;
  icon: IconProp;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
}

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: colorPallette.dark,
    color: colorPallette.tertiary,
    fontSize: "1rem",
    borderRadius: "4px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: colorPallette.dark,
  },
}));

export const AndesTooltip: React.FC<AndesTooltipProps> = ({
  title,
  description,
  icon,
  placement = "top",
}) => {
  return (
    <CustomTooltip
      title={
        <>
          <Typography color="inherit" fontWeight="Bold" pb={1}>
            {title}
          </Typography>
          <Typography variant="body2">{description}</Typography>
        </>
      }
      placement={placement}
      arrow
    >
      <span>
        <FontAwesomeIcon icon={icon} className="info-icon" />
      </span>
    </CustomTooltip>
  );
};
