import React from "react";

const DashboardSectionTitle = ({ title }) => {
  return (
    <div>
      <h2 className="a-left" style={{ fontSize: "1.5rem" }}>
        <i className="fas fa-play primary margin-r"></i>
        {title}
      </h2>
    </div>
  );
};

export default DashboardSectionTitle;
