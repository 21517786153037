import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
} from "./types.js";

export const ContratoDeAlquilerNormalDoc = [
  [
    {
      type: title,
      text: "CONTRATO DE ALQUILER",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: false,
    },
    {
      type: bold,
      text: "Contrato de Alquiler ",
      depend: false,
      notBe: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text: "(en adelante, el “Contrato”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}entre las siguientes partes:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: bold,
      text: "Contrato de Alquiler ",
      depend: false,
    },
    {
      type: text,
      text: '(en adelante, el "Contrato") se celebra en la ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}entre las siguientes partes:',
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Locador",
      depend: false,
    },
    {
      type: text,
      text: ": ${answers.section2[4].answer}, ${answers.section2[5].answer} N° ${answers.section2[6].answer}, con domicilio sito en ${answers.section2[7].answer}, ${answers.section2[8].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Locador",
      depend: false,
    },
    {
      type: text,
      text: ": ${answers.section2[9].answer}, ${answers.section2[10].answer} N° ${answers.section2[11].answer}, con domicilio sito en ${answers.section2[12].answer}, ${answers.section2[13].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Locataria",
      depend: false,
    },
    {
      type: text,
      text: ": ${answers.section3[14].answer}, ${answers.section3[15].answer} N° ${answers.section3[16].answer}, con domicilio sito en ${answers.section3[17].answer}, ${answers.section3[18].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Locataria",
      depend: false,
    },
    {
      type: text,
      text: ": ${answers.section3[19].answer}, ${answers.section3[20].answer} N° ${answers.section3[21].answer}, con domicilio sito en ${answers.section3[22].answer}, ${answers.section3[23].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Garante",
      depend: true,
      question: {
        section: "section8",
        number: 511,
        answer: "Persona Física",
      },
    },
    {
      type: text,
      text: ": ${answers.section8[512].answer}, ${answers.section8[513].answer} N° ${answers.section8[514].answer}, con domicilio sito en ${answers.section8[515].answer}, ${answers.section8[516].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Garante",
      depend: true,
      question: {
        section: "section8",
        number: 511,
        answer: "Persona Jurídica",
      },
    },
    {
      type: text,
      text: ": ${answers.section8[517].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El Locador y el Locatario podrán ser denominadas conjuntamente como las “Partes” e individualmente como la “Parte”.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Las Partes acuerdan que el presente Contrato se regirá por los siguientes términos y condiciones:",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Cláusula Primera: Definiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: '"Autoridad Pública": significa cualquier órgano judicial, ejecutivo y/o legislativo de carácter nacional, provincial y/o municipal. ',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: '"CCyCN": Código Civil y Comercial de la Nación.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: '"Cronograma de Pagos": tiene el significado asignado en la Cláusula Cuarta.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: '"Cuenta Bancaria del Locador": significa la cuenta bancaria abierta a nombre del Locador que se detalla a continuación: ${answers.section5[322].answer}.',
      depend: true,
      question: {
        section: "section5",
        number: 321,
        answer: "En la cuenta bancaria del Propietario",
      },
    },
  ],
  [
    {
      type: text,
      text: '"Depósito en Garantía": significa la suma equivalente a ${answers.section7[141].answer} y se regirá según lo establecido en la Cláusula Quinta Bis.',
      depend: true,
      question: {
        section: "section7",
        number: 40,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: '"Domicilio": la respectiva dirección de cada Parte indicada en el encabezamiento del presente Contrato o aquella otra dirección en la República Argentina que la respectiva Parte informare a la otra Parte de conformidad a la Cláusula Décimo Novena Domicilios.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: '"Forma de Pago": el Precio Total del Alquiler deberá abonarse mensualmente en ',
      depend: false,
    },
    {
      type: text,
      text: "la Cuenta Bancaria del Locador ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 321,
        answer: "En la cuenta bancaria del Propietario",
      },
    },
    {
      type: text,
      text: "el Domicilio del Locador ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 321,
        answer: "En el domicilio del Propietario",
      },
    },
    {
      type: text,
      text: "el domicilio sito en ${answers.section5[323].answer} ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 321,
        answer: "Otro domicilio",
      },
    },
    {
      type: text,
      text: "a mes adelantado ",
      notBe: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 32,
        answer: "A mes vencido",
      },
    },
    {
      type: text,
      text: "a mes vencido ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 32,
        answer: "A mes vencido",
      },
    },
    {
      type: text,
      text: "y ${answers.section5[324].answer} de cada mes durante la Vigencia del Contrato y de conformidad con el Cronograma de Pagos.",
      notBe: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 324,
        answer: "Otro",
      },
    },
    {
      type: text,
      text: "y ${answers.section5[325].answer} de cada mes durante la Vigencia del Contrato y de conformidad con el Cronograma de Pagos.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 324,
        answer: "Otro",
      },
    },
  ],
  [
    {
      type: text,
      text: '"Garantía": significa el ${answers.section8[53].answer} que se adjunta al presente como Anexo y que se encuentra sujeto a lo establecido en la Cláusula Séptima Bis.',
      depend: true,
      question: {
        section: "section8",
        number: 52,
        answer: "Sí",
      },
    },
  ],
  [
    /// Para garantía de Garante, el anterior inquilino
    {
      type: text,
      text: '"Garantía": significa el ${answers.section8[523].answer} que se adjunta al presente como Anexo y que se encuentra sujeto a lo establecido en la Cláusula Séptima Bis.',
      depend: true,
      question: {
        section: "section8",
        number: 522,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: '"Inmueble": el bien inmueble sito en ${answers.section4[26].answer}, ${answers.section4[25].answer}, ${answers.section4[24].answer}. ',
      depend: false,
    },
    {
      type: textArray,
      text: "El Inmueble objeto del presente Contrato incluye {{array}}.",
      question: { section: "section4", number: 27 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: text,
      text: '"Inmueble": el inmueble sito en ${answers.section4[26].answer}, ${answers.section4[24].answer}. ',
      depend: true,
      question: {
        section: "section4",
        number: 24,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: textArray,
      text: "El Inmueble objeto del presente Contrato incluye {{array}}.",
      question: { section: "section4", number: 27 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: text,
      text: '"Mobiliario": significa las cosas muebles no registrables que se encuentran en el Inmueble para uso residencial objeto del presente Contrato. ',
      includes: ["Mobiliario"],
      question: {
        section: "section4",
        number: 27,
      },
    },
    {
      type: text,
      text: "Su inventario se detalla a continuación: ${answers.section4[29].answer}.",
      includes: ["Mobiliario"],
      question: {
        section: "section4",
        number: 28,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: '"Precio Total del Alquiler": significa la suma acumulada de ${answers.section5[31].answer}. El Precio Total del Alquiler quedará sujeto al Cronograma de Pagos que se detalla en la Cláusula Cuarta y a la Forma de Pago que se establece en el presente Contrato.',
    },
  ],
  [
    {
      type: textArray,
      text: '"Servicios": significa cualquiera de los siguientes servicios habilitados y disponibles en el Inmueble en la fecha de inicio de la Vigencia, a saber: {{array}}.',
      question: { section: "section4", number: 125 },
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: '"Vigencia": significa la cantidad de ${answers.section5[33].answer} contados desde la fecha que se indica en el encabezado del Contrato.',
      depend: true,
      question: {
        section: "section5",
        number: 34,
        answer: "Misma fecha que se firma el Contrato",
      },
    },
  ],
  [
    {
      type: text,
      text: '"Vigencia": significa la cantidad de ${answers.section5[33].answer} contados desde el ${answers.section5[35].answer}.',
      depend: true,
      question: {
        section: "section5",
        number: 34,
        answer: "Otra posterior",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEGUNDA: Objeto.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El Locador da en locación al Locatario el Inmueble de su propiedad durante la Vigencia determinada en este Contrato. En contraprestación, el Locatario abonará el Precio Total del Alquiler según se encuentra establecido en la Cláusula Cuarta del presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA TERCERA: Vigencia del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente Contrato se extenderá por la totalidad de la Vigencia. Ninguna Parte se obligará a alegar, alegará, ni permitirá que se alegue en contra de la otra Parte la reconducción tácita de este Contrato. Por lo que cumplida la Vigencia del mismo el vencimiento del Contrato operará de pleno derecho, sin necesidad de notificación o requerimiento alguno de parte del Locador, fecha en la cual deberá restituir el Inmueble totalmente desocupado, siendo este plazo, único, definitivo, perentorio y sin prórroga de ninguna clase.",
      depend: false,
    },
  ],

  [
    {
      type: bold,
      text: "CLÁUSULA CUARTA: Pago del alquiler.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El Locatario se obliga a pagar al Locador el Precio Total del Alquiler de conformidad con la Forma de Pago establecida en este Contrato y con el Cronograma de Pagos que se detalla a continuación: ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "En el semestre de Vigencia del Contrato se abonará mensualmente la suma de ${answers.section5[3111].answer} (en adelante, denominado conjuntamente como el “Cronograma de Pagos”).",
      depend: true,
      question: {
        section: "section5",
        number: 3110,
        answer: "Se ajusta en uno (1)",
      },
    },
  ],
  [
    {
      type: text,
      text: "En el primer semestre de Vigencia del Contrato se abonará mensualmente la suma de ${answers.section5[3112].answer} y en el segundo semestre del Contrato se abonará mensualmente la suma de ${answers.section5[3113].answer} (en adelante, denominado conjuntamente como el “Cronograma de Pagos”).",
      depend: true,
      question: {
        section: "section5",
        number: 3110,
        answer: "Se ajusta en dos (2)",
      },
    },
  ],
  [
    {
      type: text,
      text: "En el primer semestre de Vigencia del Contrato se abonará mensualmente la suma de ${answers.section5[3114].answer}; en el segundo semestre del Contrato se abonará mensualmente la suma de ${answers.section5[3115].answer}; y en el tercer semestre del Contrato se abonará mensualmente la suma de ${answers.section5[3116].answer} (en adelante, denominado conjuntamente como el “Cronograma de Pagos”).",
      depend: true,
      question: {
        section: "section5",
        number: 3110,
        answer: "Se ajusta en tres (3)",
      },
    },
  ],
  [
    {
      type: text,
      text: "En el primer semestre de Vigencia del Contrato se abonará mensualmente la suma de ${answers.section5[3117].answer}; en el segundo semestre del Contrato se abonará mensualmente la suma de ${answers.section5[3118].answer}; en el tercer semestre del Contrato se abonará mensualmente la suma de ${answers.section5[3119].answer}; y en el cuarto y último semestre se abonará mensualmente la suma de ${answers.section5[3120].answer} (en adelante, denominado conjuntamente como el “Cronograma de Pagos”).",
      depend: true,
      question: {
        section: "section5",
        number: 3110,
        answer:
          "El Precio mensual del Alquiler se ajusta en cuatro (4) semestres",
      },
    },
  ],
  [
    {
      type: text,
      text: "${answers.section5[3121].answer} (en adelante, denominado conjuntamente como el “Cronograma de Pagos”).",
      depend: true,
      question: {
        section: "section5",
        number: 3110,
        answer: "Se ajusta de otra manera",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA: Destino del Bien. Inspección del Locador.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Las Partes establecen que el Inmueble que se afecta al presente Contrato se encuentra destinado exclusivamente a vivienda familiar. El Locador se reserva el derecho de inspeccionar el Inmueble para comprobar el destino y estado de conservación del mismo, para lo cual deberá comunicar dicha intención al Locatario en un plazo no menor a cuarenta y ocho (48) horas o con suficiente tiempo de anticipación. Si el Inmueble tuviese reglamento de propiedad horizontal, el Locatario será exclusiva y absolutamente responsable por el cumplimiento del mismo.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA BIS: Depósito en Garantía.",
      notBe: true,
      question: { section: "section7", number: 40, answer: "No" },
    },
  ],
  [
    {
      type: text,
      text: "Sin perjuicio de la responsabilidad que el Locatario asume con todos sus bienes por el cumplimiento de este Contrato, el Locatario, al momento de la entrega de la tenencia del Inmueble, se obliga a otorgar al Locador el Depósito en Garantía.",
      notBe: true,
      question: { section: "section7", number: 40, answer: "No" },
    },
  ],
  [
    {
      type: text,
      text: "El Depósito en Garantía no podrá computarse a pagos de alquileres atrasados o vigentes, penalidades, cargas u obligaciones debidas por el Locatario, con la salvedad de lo que a continuación se conviene. El Depósito en Garantía podrá computarse en parte o totalmente a los desperfectos, daños y/o roturas originadas en el Inmueble, sus artefactos o accesorios y que Locador, o sus representantes, observen cuando el Locatario haya restituido el Inmueble al finalizar la Vigencia del Contrato.",
      notBe: true,
      question: { section: "section7", number: 40, answer: "No" },
    },
  ],
  [
    {
      type: text,
      text: "Si el Locatario restituyera el Inmueble totalmente desocupado y libre en el estado en que lo recibió, excepto por los deterioros provenientes del mero transcurso del tiempo y el uso regular del mismo, y sin deudas de Servicios y/o expensas, de existir, devengados durante la Vigencia del Contrato, podrá exigir al Locador la devolución del Depósito en Garantía de conformidad con el CCyCN.",
      notBe: true,
      question: { section: "section7", number: 40, answer: "No" },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEXTA: Otras Obligaciones y Derechos de las Partes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "A) Obligaciones y Derechos del Locador:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "El Locador debe dar en locación y entregar el Inmueble conforme a lo acordado. A falta de previsión contractual debe entregarla en estado apropiado para su destino, excepto por los defectos que el Locatario conoció o debió haber conocido.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "El Locador con el fin de conservar el Inmueble en buen estado para el uso y goce convenido en el presente Contrato, deberá efectuar, a su cargo, toda reparación y/o erogación que sea necesario para sanear cualquier daño que sea originado por la calidad o defecto del Inmueble y/o por la culpa del Locador o la de sus dependientes y/o por hechos de terceros y/o caso fortuito que afecten al Inmueble y al normal uso y goce por parte del Locatario.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "El Locador no se obligará a realizar, ni realizará ninguna reforma arquitectónica del Inmueble salvo que le fuese requerido por una Autoridad Pública o existiere riesgo de peligro actual o potencial para la vida humana y/o vida animal y/o el medio ambiente, en dichos casos deberá notificar a la Locataria de dichas reparaciones con razonable anticipación.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text: "Si el Locador, al efectuar una reparación o cualquier mejora que interrumpa o turbe el uso y goce convenido por parte del Locatario, facultará a este último exigir la reducción del Precio Total del Alquiler en proporción a la extensión de la interrupción del uso y goce del Inmueble, o en el caso que dicha interrupción se prolongue por un plazo mayor a veinte (20) días, podrá resolver el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text: "El Locador tiene a su cargo el pago de las expensas extraordinarias.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "B) Obligaciones y Derechos del Locatario:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "Pagar el Precio Total del Alquiler en la Forma de Pago y según el Cronograma de Pagos establecidos en el presente Contrato y pagar los Servicios y expensas ordinarias, de existir, que pesen sobre el Inmueble.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "El Locatario puede usar y gozar del Inmueble conforme a derecho y exclusivamente para el destino convenido.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "El Locatario debe mantener y conservar el Inmueble en el estado en que la recibió. Incumplirá con la presente obligación si abandona el Inmueble sin designar persona alguna que lo mantenga y lo conserve. El Locatario responderá por cualquier deterioro causado al Inmueble, incluso por visitantes ocasionales, pero no por acción del Locador o sus dependientes; asimismo responde por la destrucción del Inmueble por incendio no originado en caso fortuito.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text: "Una vez finalizada la Vigencia del Contrato, o bien si el Locatario que dejase de abonar el alquiler durante dos (2) períodos mensuales consecutivos o durante (3) períodos mensuales no consecutivos, el Locatario deberá restituir al Locador el Inmueble totalmente desocupado y libre en el estado en que lo recibió, excepto por los deterioros provenientes del mero transcurso del tiempo y el uso regular del mismo. Deberá entregar el Inmueble sin deudas de Servicios y/o expensas, de existir, devengados durante la Vigencia del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text: "Deberá entregar al Locador las constancias de los pagos que efectuó en razón de la relación locativa y que resulten atinentes al Inmueble y a los Servicios y expensas, de existir, que carguen sobre el Inmueble.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vi)",
      text: "No efectuar modificaciones, ni reformas, ni mejoras en el Inmueble, salvo que medie autorización previa y por escrito del Locador. Las mejoras realizadas quedarán, al finalizar el presente Contrato, a beneficio exclusivo del Locador sin cargo alguno para éste y sin derecho del Locatario a indemnización alguna ni reclamo de ningún tipo, salvo por aquellas mejores que sean de carácter necesarias.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vii)",
      text: "Responder por todos aquellos accidentes a las personas y/o a las cosas y los deterioros que se produzcan como consecuencia de negligencia y/o acciones propias de su entera responsabilidad y/o causados en y por terceros que concurran al Inmueble.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(viii)",
      text: "Se obliga a notificar al Locador, o a su representante, todo desperfecto, humedades, filtraciones, roturas, accidentes y/o toda anomalía que pueda afectar al Inmueble bajo apercibimiento de responder por los daños y perjuicios ocasionados.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ix)",
      text: "Podrá tener animales domésticos en el Inmueble durante la Vigencia del Contrato.",
      notInclude: ["Tener Mascotas"],
      question: {
        section: "section6",
        number: 37,
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ix)",
      text: "No podrá tener animales domésticos en el Inmueble durante la Vigencia del Contrato.",
      includes: ["Tener Mascotas"],
      question: {
        section: "section6",
        number: 37,
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(x)",
      text: "El Locatario no permitirá que se enciendan y/o fumen cigarrillos, cigarro y/o productos similares (de hoja, naturales y/o electrónicos) en cualquier ambiente cerrado del Inmueble.",
      includes: ["Fumar dentro del inmueble"],
      question: {
        section: "section6",
        number: 37,
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA: Declaraciones y Garantías de las Partes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "A) El Locador declara y garantiza que:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "Cuenta con la capacidad suficiente para celebrar el presente Contrato y obligarse conforme los términos aquí pactados.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "Tiene derecho para dar en locación el Inmueble objeto del presente Contrato por la Vigencia establecida.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "Se encuentra legalmente representada por el abajo firmante.",
      depend: true,
      question: {
        section: "section2",
        number: "person",
        answer: "Persona Jurídica",
      },
    },
  ],
  [
    {
      type: text,
      text: "B) La Locataria declara y garantiza que:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "Cuenta con la capacidad suficiente para celebrar el presente Contrato y obligarse conforme los términos aquí pactados.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "Se encuentra en su derecho de recibir en locación el Inmueble y cuenta con la capacidad económica y financiera suficiente para afrontar el pago del Precio Total del Alquiler según se establece en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "Se encuentra legalmente representada por el abajo firmante.",
      depend: true,
      question: {
        section: "section3",
        number: "person",
        answer: "Persona Jurídica",
      },
    },
  ],
  [
    {
      type: text,
      text: "C) La Garante declara y garantiza que:",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "Cuenta con la capacidad suficiente para celebrar el presente Contrato y obligarse conforme los términos aquí pactados.",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "Tiene la obligación de afianzar las obligaciones de la Locataria como se establece en el presente Contrato.",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "Cuenta con la capacidad económica y financiera suficiente para afianzar las obligaciones de la Locataria establecidas en el presente Contrato.",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],

  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA BIS: Garantía del Inquilino.",
      depend: true,
      question: {
        section: "section8",
        number: 52,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "El Locatario constituye la Garantía a los fines de garantizar el cumplimiento de las obligaciones de pago establecidas en el siguiente Contrato, la cual se adjunta como Anexo.",
      depend: true,
      question: {
        section: "section8",
        number: 52,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "La Garantía podrá ser ejecutada por el incumplimiento en los pagos del alquiler y/o expensas y/o Servicios y/o impuestos a cargo del Locatario siempre y cuando se configuren los incumplimientos establecidos Cláusula Décima inc. “(ii)”, “(iii)” y “(v)” y efectivamente se haya resuelto y/o finalizado el presente Contrato. Además, podrá ser ejecutada por los daños y perjuicios derivados por la ocupación indebida del Locatario y/o por los daños y perjuicios derivados del deterioro y/o daño ocasionado sobre el Inmueble y accesorios. La potestad de ejecutar la Garantía concluye cuando se extinguen todas las obligaciones del Locatario.",
      depend: true,
      question: {
        section: "section8",
        number: 52,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA BIS: Garante.",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "En garantía del fiel y debido cumplimiento de las obligaciones contenidas en el presente Contrato el Garante se constituye en fiador solidario, liso, llano y principal pagador, con renuncia a los beneficios de división y excusión, por el cumplimiento de todas las obligaciones contraídas en el presente Contrato o impuestos por la ley, en especial por el pago de alquiler y sus accesorios, cualquiera fuera su monto y número de mensualidades impagas, toda carga y obligaciones pecuniarias de cualquier origen y naturaleza, importes o sumas punitorias, compensatorias y/o resarcitorias, costas y costos extrajudiciales o judiciales originadas por el incumplimiento del Locatario y demás que se originen en el presente Contrato. ",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
    {
      type: text,
      text: "El Garante afecta al presente Contrato la Garantía (tal como se define en la Cláusula primera) a los fines de garantizar el cumplimiento de las obligaciones de pago establecidas en el siguiente Contrato y de conformidad con lo establecido en la presente Cláusula.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section8",
        number: 522,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "El Garante declara conocer y aceptar todas las cláusulas del presente Contrato, en prueba de lo cual lo firma de conformidad al final del presente Contrato. El Garante garantiza igualmente el pago de los honorarios y gastos judiciales que se promuevan contra el Locatario por desalojo judicial, daños y perjuicios, desperfectos y cobro de alquileres. La presente garantía se extiende como vigente aún después de expirado la Vigencia del Contrato, mientras el Locatario continúe ocupando el Inmueble o adeudando cualquier concepto o suma, hasta la efectiva desocupación y entrega del Inmueble al Locador y recepción por éste en las condiciones pactadas, cancelación de toda deuda y cumplimiento de todas las obligaciones del Locatario.",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "Para el caso que el Garante cayera en quiebra y/o concurso preventivo y/o fuera inhibido para disponer de sus bienes, el Locador tiene el derecho de exigir al Locatario que sustituya al Garante por otro de similar solvencia a la que tenía el obligado al momento de la contratación. Así también se pacta expresamente que para el caso de fallecimiento y/o falencia y/o quiebra y/o estado de insolvencia del Garante recién mencionado y también para el caso de enajenación y/o embargo o cualquier otro tipo de gravamen de los bienes de dicho garante presentado aquí en carácter de acreditación de solvencia, el Locatario deberá presentar, a requerimiento fehaciente del Locador, nuevo/s garante/s en el plazo perentorio de quince (15) días a partir de la recepción de dicho requerimiento, caso contrario se considerará que el Locatario incursa en grave incumplimiento de sus obligaciones esenciales de este Contrato quedando resuelto por dicha causa el mismo en forma automática y quedando en consecuencia expedita la vía para iniciar las correspondientes acciones de desalojo.",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "El Garante se compromete a mantener dentro de su dominio la Garantía antes relacionada y a no realizar ningún acto de disposición u ofrecerlo en garantía que pueda poner en peligro su libre disponibilidad en forma irrestricta sin antes haber comunicado fehacientemente de esta situación al Locador y, una vez aprobada por el Locador, podrá ser sustituida la garantía por otra Garantía de igual o mayor valor a criterio y aprobación del Locador.",
      depend: true,
      question: {
        section: "section8",
        number: 523,
        answer: "Título de propiedad Inmueble",
      },
    },
  ],
  [
    {
      type: text,
      text: "En caso de renovarse o prorrogarse en forma expresa o tácita el presente Contrato, se exigirá el consentimiento expreso del Garante para la subsistencia de la garantía detallada, según lo establecido por el art. 1.225 del CCyCN.",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA OCTAVA: Incumplimiento del Locatario.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "En cualquiera de los casos de incumplimiento por parte del Locatario, sin perjuicio de las penalidades que se establecen en las demás Cláusulas, el Locador podrá pedir el cumplimiento de este Contrato o podrá resolver el Contrato por culpa del Locatario y solicitar el inmediato desalojo de éste o quienes ocupen el Inmueble. Ante el incumplimiento de pago del Precio Total del Alquiler, o ante el incumplimiento de abonar el alquiler correspondiente, de conformidad con el Cronograma de Pago, durante dos (2) períodos mensuales consecutivos o durante (3) períodos mensuales no consecutivos, y previo a la intimación de desalojo, el Locador deberá intimar fehacientemente al Locatario al pago de la cantidad debida más los intereses correspondientes, otorgando para ello un plazo máximo de cinco (5) días corridos contados a partir de la recepción de la intimación, especificando el lugar donde se deberá realizar el pago.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Para el evento de que el Locatario dejare abandonado el Inmueble, o depositare judicialmente las llaves, deberá abonar al Locador una multa igual al equivalente del alquiler mensual más oneroso, según se detalla en el Cronograma de Pago, que resulte desde la iniciación del juicio hasta el día en que el Locador tome la libre y efectiva tenencia definitiva del Inmueble, ello en concepto de única y definitiva indemnización.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA NOVENA: Mora en el pago del Alquiler.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "La mora en el pago del alquiler se producirá en forma automática por el mero transcurso del tiempo y sin necesidad de interpelación ni gestión previa de ninguna naturaleza. Producida la mora en el pago de cualquier mes del Precio Total del Alquiler según la Forma de Pago y el Cronograma de Pago, las Partes acuerdan que el Locatario deberá abonar en concepto de intereses y multa, el equivalente al uno por ciento (1%) diario del valor del alquiler del mes en curso por todo el tiempo que transcurra desde iniciada la mora hasta su cesación. En caso de que la tasa precedente resulte violatoria de alguna ley de usura vigente, la tasa de interés aplicable será la máxima tasa de interés permitida por dicha ley.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMA: Resolución por las Partes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "A) El Locador podrá resolver el presente Contrato:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "Por cambio de destino o uso irregular del Inmueble por parte del Locatario.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "Por falta de conservación del Inmueble, o su abandono sin dejar quien haga sus veces.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "Por falta de pago del alquiler durante dos (2) períodos mensuales consecutivos o tres (3) períodos mensuales no consecutivos.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text: "Si el Locatario no respetare y/o no mantuviere las declaraciones y garantías establecidas en la Cláusula Séptima.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text: "Por falta de pago de los Servicios y/o expensas, de existir, por dos (2) períodos mensuales consecutivos.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vi)",
      text: "Por cualquier incumplimiento de cualquier otra obligación de hacer o no hacer que se establece en el presente Contrato si, ante la solicitud del Locador, la Locataria no hubiera restablecido el estado de las cosas al estadio anterior al incumplimiento y según lo establecido en la solicitud enviada por el Locador.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "B) El Locatario podrá resolver el presente Contrato:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "Si el Locador incumple la obligación de conservar la cosa con aptitud para el uso y goce convenido.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "Si el Locador no respetare y/o no mantuviere las declaraciones y garantías establecidas en la Cláusula Séptima.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "Por el incumplimiento de los términos y obligaciones establecidos en el presente Contrato por parte del Locador",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "La Parte que se trate deberá informar su voluntad de resolver el presente Contrato mediante una notificación fehaciente por escrito en el Domicilio de la otra Parte con una antelación no menor de cinco (5) días.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO PRIMERA: Resolución Anticipada por parte del Locatario.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Concluido el primer periodo de seis (6) meses, el Locatario podrá dar por rescindido el presente Contrato, preavisando en forma fehaciente y por escrito al Locador con al menos un (1) mes de anticipación. Si hace uso de la opción resolutoria en el primer año de vigencia de la relación locativa, debe abonar al Locador, en concepto de indemnización, la suma equivalente a un (1) mes y medio del monto del alquiler al momento de desocupar el Inmueble y la de un (1) mes si la opción se ejercita transcurrido dicho lapso. Cuando la notificación por parte del Locatario al Locador se realizará con una anticipación de tres (3) meses o más, transcurridos al menos seis (6) meses del Contrato, no corresponde el pago de indemnización alguna por dicho concepto.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SEGUNDA: Prohibiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Queda prohibido para el Locatario ceder su posición contractual, los derechos y/o las obligaciones establecidos en el presente Contrato, así como la posibilidad de subarrendar total o parcialmente el Inmueble a tercero alguno, sin el previo consentimiento por escrito del Locador.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO TERCERA: Domicilios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Cada Parte constituye domicilio en su Domicilio exclusivamente a los fines del presente Contrato. La Parte que cambiare su Domicilio informará la nueva dirección, que deberá encontrarse en la República Argentina, a la otra Parte mediante una notificación fehaciente dirigida al Domicilio de esta última.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Las notificaciones que se realicen entre las Partes, así como otras comunicaciones y notificaciones prejudiciales y/o judiciales derivadas de este Contrato, se tendrán por válidamente cursadas al Domicilio de la Parte destinataria hasta que la Parte remitente sea informada del nuevo Domicilio de la Parte destinataria mediante una notificación fehaciente dirigida al Domicilio de la Parte remitente.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO CUARTA: Comunicaciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El ejercicio de un derecho o cumplimiento de una obligación por una Parte, en cualquier caso en virtud de lo dispuesto por este Contrato, el CCyCN o un acto de una Autoridad Pública relativo a este Contrato (salvo que dicho acto dispusiere otra forma), mediante el envío por dicha Parte de un mensaje a la otra Parte, será eficaz sólo si dicho mensaje fuere recibido mediante una notificación fehaciente al Domicilio de la otra.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO QUINTA: Interpretación.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text: "Títulos. Los títulos temáticos utilizados en este Contrato se incluyen para fines de conveniencia únicamente y no serán interpretados de forma que se les asigne importancia significativa o se los considere indicativos de que todas las disposiciones de este Contrato referidas a un tema en particular se encuentran en un única Cláusula;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text: "Singular y Plural. La referencia a la forma singular incluye la referencia a la forma plural y viceversa;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text: "Género. La referencia a uno de los géneros incluye la referencia a todos los demás géneros;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text: "Incluye. “Incluye” e “incluyendo” significará que incluyen sin limitar la generalidad de la descripción que precede a dichos términos, y se utilizan en el presente en un sentido ilustrativo y no limitativo;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text: 'Las expresiones "este Contrato", "el presente Contrato", "este instrumento" o "este documento" refieren al presente Contrato y todos los Anexos y todas las modificaciones que tuviere en conjunto;',
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vi)",
      text: "Las palabras en mayúscula utilizadas en este Contrato tienen el significado que se les asigna en el mismo;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vii)",
      text: "Las referencias a Cláusulas o Anexos utilizadas en este Contrato sin indicar su fuente son a Cláusulas o Anexos de este Contrato exclusivamente; y ",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(viii)",
      text: "Todos los plazos contractuales se computan en días calendarios corridos salvo cuando en este Contrato expresamente se dispone de otra forma.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SEXTA: Disposiciones Finales. Integridad Acuerdo. Anexos.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente Contrato representa el acuerdo completo e íntegro entre las Partes con respeto al objeto del mismo. Las Partes prescinden expresamente de todas las disposiciones contenidas en cualquier otro acuerdo o correspondencia anteriores que nieguen, limiten, extiendan o entren en conflicto con las disposiciones del presente Contrato. Ningún término de este Contrato será modificado por ninguna declaración anterior o posterior, conducta o acto de cualquier Parte, a menos que las Partes hayan autorizado tal modificación en un documento escrito y ratificado por ambas Partes. En caso de existir anexos que se adjuntan a presente Contrato, las Partes establecen que los mismos serán parte integral del Contrato. Los términos y condiciones allí establecidas serán vinculantes para las Partes. Si ciertos términos y condiciones del Contrato entran en colisión con ciertos términos y condiciones de algún anexo, prevalecerán los establecidos en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "No se considerará que cualquiera de las Partes ha renunciado por ningún acto, retraso, omisión u otro, de forma expresa o implícita, a cualquiera de sus derechos, competencias o soluciones jurídicas, individual o colectivamente, a menos que dicha renuncia se realice por escrito y esté firmada por una persona autorizada de dicha Parte. Una renuncia de este tipo será ejecutable únicamente en la medida específicamente establecida en la renuncia. La renuncia de una Parte a cualquier derecho, competencia o solución jurídica, individual o colectivamente, en una ocasión cualquiera no deberá interpretarse como un obstáculo o renuncia a cualquier derecho, competencia o solución jurídica, individual o colectivamente, que dicha Parte tendría de otra forma en cualquier ocasión futura, tanto si es de naturaleza similar como si no lo es.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "En el caso de que una o más provisiones de este Contrato llegasen a perder total o parcialmente su validez, legalidad o su vigencia en cualquier respecto, bajo la ley aplicable, la validez, la legalidad y la vigencia de las restantes provisiones de este Contrato no se verán afectadas o anuladas en ningún caso. Cualquier provisión que, total o parcialmente, haya perdido su validez, legalidad o vigencia deberá ser reemplazada por una provisión que se ajuste de la mejor manera al propósito de la provisión reemplazada; lo mismo se aplicará en el caso de una omisión.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SÉPTIMA: Ley Aplicable. Tribunales Judiciales Competentes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Este Contrato se regirá e interpretará de conformidad con las leyes de la República Argentina. Las Partes acuerdan que cualquier controversia o reclamo que se derive del presente Contrato, será resuelto exclusivamente por los tribunales ordinarios competentes, por razón de la materia, con asiento en ${answers.section6[36].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO OCTAVA: Firma ante escribano público. Fecha Cierta.",
      depend: true,
      question: {
        section: "section6",
        number: 38,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "Las Partes acuerdan certificar notarialmente el presente Contrato ante escribano público, dando fecha cierta al presente Contrato en este acto de las respectivas firmas que estampan al final del presente instrumento.",
      depend: true,
      question: {
        section: "section6",
        number: 38,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "Las Partes firman dos (2) ejemplares de un mismo tenor y a un solo efecto.",
      notBe: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text: "Las Partes firman tres (3) ejemplares de un mismo tenor y a un solo efecto.",
      depend: true,
      question: {
        section: "section8",
        number: 51,
        answer: "Sí",
      },
    },
  ],
];
