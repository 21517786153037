import React, { useEffect, useRef, useState } from "react";
import "./DocxEditorMain.styles.css";
import {
  DocumentEditorContainerComponent,
  Toolbar,
} from "@syncfusion/ej2-react-documenteditor";
import { useDispatch, useSelector } from "react-redux";
import { downloadWordFromS3andReturn } from "../../Utils/S3Download";
import { modalOpen, setAppError, setLoading } from "../../Utils/modalMethods";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { L10n } from "@syncfusion/ej2-base";
import DocxToolbar from "./DocxToolbar";
import { APIPostFile } from "../../Services/authenticated";
import { setShouldESign } from "../../redux/slices/EditorDataSlice";
import { RootState } from "../../redux/store";
import LoaderComponent from "../../uiComponents/Loader";
import DocxNotCompatibleMessage from "./DocxNotCompatibleMessage";

L10n.load({
  ar: {
    documenteditor: {
      //   Table: "AAAAAAAAAAAAAA",
      //   Row: "لصف",
      //   Cell: "الخليه",
      //   Ok: "موافق",
      //   Cancel: "إلغاء الأمر",
      //   Size: "حجم",
      //   "Preferred Width": "العرض المفضل",
      //   Points: "نقاط",
      //   Percent: "المائه",
      //   "Measure in": "القياس في",
      //   Alignment: "محاذاه",
      //   Left: "ليسار",
      //   Center: "مركز",
      //   Right: "الحق",
      //   Justify: "تبرير",
      //   "Indent from left": "مسافة بادئه من اليسار",
      //   "Borders and Shading": "الحدود والتظليل",
      //   Options: "خيارات",
      //   "Specify height": "تحديد الارتفاع",
      //   "At least": "الاقل",
      //   Exactly: "تماما",
      //   "Row height is": "ارتفاع الصف هو",
      //   "Allow row to break across pages": "السماح بفصل الصف عبر الصفحات",
      //   "Repeat as header row at the top of each page":
      //     "تكرار كصف راس في اعلي كل صفحه",
      //   "Vertical alignment": "محاذاة عمودي",
      //   Top: "أعلى",
      //   Bottom: "اسفل",
      //   "Default cell margins": "هوامش الخلية الافتراضية",
      //   "Default cell spacing": "تباعد الخلايا الافتراضي",
      //   "Allow spacing between cells": "السماح بالتباعد بين الخلايا",
      //   "Cell margins": "هوامش الخلية",
      //   "Same as the whole table": "نفس الجدول بأكمله",
      //   Borders: "الحدود",
      //   None: "اي",
      //   Single: "واحد",
      //   Dot: "نقطه",
      //   DashSmallGap: "داشسمجاب",
      //   DashLargeGap: "الاتحاد الخاص",
      //   DashDot: "داشدوت",
      //   DashDotDot: "ددهدودوت",
      //   Double: "انقر نقرا مزدوجا",
      //   Triple: "الثلاثي",
      //   ThinThickSmallGap: "فجوه صغيره سميكه رقيق",
      //   ThickThinSmallGap: "الفجوة الصغيرة رقيقه سميكه",
      //   ThinThickThinSmallGap: "صغيره سميكه رقيقه الفجوة الصغيرة",
      //   ThinThickMediumGap: "فجوه متوسطه سميك",
      //   ThickThinMediumGap: "سميكه الفجوة متوسطه رقيقه",
      //   ThinThickThinMediumGap: "رقيقه سميكه متوسطه الفجوة",
      //   ThinThickLargeGap: "الفجوة الكبيرة رقيقه سميكه",
      //   ThickThinLargeGap: "فجوه كبيره رقيقه سميك",
      //   ThinThickThinLargeGap: "رقيقه سميكه الفجوة الكبيرة",
      //   SingleWavy: "واحد مائج",
      //   DoubleWavy: "مزدوج مائج",
      //   DashDotStroked: "اندفاعه نقطه القوية",
      //   Emboss3D: "D3مزخرف",
      //   Engrave3D: "D3نقش",
      //   Outset: "البدايه",
      //   Inset: "الداخلي",
      //   Thick: "سميكه",
      //   Style: "نمط",
      //   Width: "عرض",
      //   Height: "ارتفاع",
      //   Letter: "رساله",
      //   Tabloid: "التابلويد",
      //   Legal: "القانونيه",
      //   Statement: "بيان",
      //   Executive: "التنفيذي",
      //   A3: "A3",
      //   A4: "A4",
      //   A5: "A5",
      //   B4: "B4",
      //   B5: "B5",
      //   "Custom Size": "حجم مخصص",
      //   "Different odd and even": "مختلفه غريبه وحتى",
      //   "Different first page": "الصفحة الاولي مختلفه",
      //   "From edge": "من الحافة",
      //   Header: "راس",
      //   Footer: "تذييل الصفحه",
      //   Margin: "الهوامش",
      //   Paper: "الورق",
      //   Layout: "تخطيط",
      //   Orientation: "التوجه",
      //   Landscape: "المناظر الطبيعيه",
      //   Portrait: "صوره",
      //   "Table Of Contents": "جدول المحتويات",
      //   "Show page numbers": "إظهار أرقام الصفحات",
      //   "Right align page numbers": "محاذاة أرقام الصفحات إلى اليمين",
      //   Nothing: "شيء",
      //   "Tab leader": "قائد علامة التبويب",
      //   "Show levels": "إظهار المستويات",
      //   "Use hyperlinks instead of page numbers":
      //     "استخدام الارتباطات التشعبية بدلا من أرقام الصفحات",
      //   "Build table of contents from": "بناء جدول محتويات من",
      //   Styles: "انماط",
      //   "Available styles": "الأنماط المتوفرة",
      //   "TOC level": "مستوي جدول المحتويات",
      //   Heading: "عنوان",
      //   "Heading 1": "عنوان 1",
      //   "Heading 2": "عنوان 2",
      //   "Heading 3": "عنوان 3",
      //   "Heading 4": "عنوان 4",
      //   "Heading 5": "عنوان 5",
      //   "Heading 6": "عنوان 6",
      //   "List Paragraph": "فقره القائمة",
      //   Normal: "العاديه",
      //   "Outline levels": "مستويات المخطط التفصيلي",
      //   "Table entry fields": "حقول إدخال الجدول",
      //   Modify: "تعديل",
      //   Color: "لون",
      //   Setting: "اعداد",
      //   Box: "مربع",
      //   All: "جميع",
      //   Custom: "المخصصه",
      //   Preview: "معاينه",
      //   Shading: "التظليل",
      //   Fill: "ملء",
      //   "Apply To": "تنطبق علي",
      //   "Table Properties": "خصائص الجدول",
      //   "Cell Options": "خيارات الخلية",
      //   "Table Options": "خيارات الجدول",
      //   "Insert Table": "ادراج جدول",
      //   "Number of columns": "عدد الاعمده",
      //   "Number of rows": "عدد الصفوف",
      //   "Text to display": "النص الذي سيتم عرضه",
      //   Address: "عنوان",
      //   "Insert Hyperlink": "ادراج ارتباط تشعبي",
      //   "Edit Hyperlink": "تحرير ارتباط تشعبي",
      //   Insert: "ادراج",
      //   General: "العامه",
      //   Indentation: "المسافه البادئه",
      //   "Before text": "قبل النص",
      //   Special: "الخاصه",
      //   "First line": "السطر الأول",
      //   Hanging: "معلقه",
      //   "After text": "بعد النص",
      //   By: "من",
      //   Before: "قبل",
      //   "Line Spacing": "تباعد الأسطر",
      //   After: "بعد",
      //   At: "في",
      //   Multiple: "متعدده",
      //   Spacing: "تباعد",
      //   "Define new Multilevel list": "تحديد قائمه متعددة الاصعده جديده",
      //   "List level": "مستوي القائمة",
      //   "Choose level to modify": "اختر المستوي الذي تريد تعديله",
      //   Level: "مستوي",
      //   "Number format": "تنسيق الأرقام",
      //   "Number style for this level": "نمط الرقم لهذا المستوي",
      //   "Enter formatting for number": "إدخال تنسيق لرقم",
      //   "Start at": "بداية من",
      //   "Restart list after": "أعاده تشغيل قائمه بعد",
      //   Position: "موقف",
      //   "Text indent at": "المسافة البادئة للنص في",
      //   "Aligned at": "محاذاة في",
      //   "Follow number with": "اتبع الرقم مع",
      //   "Tab character": "حرف علامة التبويب",
      //   Space: "الفضاء",
      //   Arabic: "العربية",
      //   UpRoman: "حتى الروماني",
      //   LowRoman: "الرومانية منخفضه",
      //   UpLetter: "",
      //   LowLetter: "",
      //   Number: "عدد",
      //   "Leading zero": "يؤدي صفر",
      //   Bullet: "رصاصه",
      //   Ordinal: "الترتيبيه",
      //   "Ordinal Text": "النص الترتيبي",
      //   "For East": "للشرق",
      //   "No Restart": "لا أعاده تشغيل",
      //   Font: "الخط",
      //   "Font style": "نمط الخط",
      //   "Underline style": "نمط التسطير",
      //   "Font color": "لون الخط",
      //   Effects: "الاثار",
      //   Strikethrough: "يتوسطه",
      //   Superscript: "مرتفع",
      //   Subscript: "منخفض",
      //   "Double strikethrough": "خط مزدوج يتوسطه خط",
      //   Regular: "العاديه",
      //   Bold: "جريئه",
      //   Italic: "مائل",
      //   Cut: "قطع",
      //   Copy: "نسخ",
      //   Paste: "لصق",
      //   Hyperlink: "الارتباط التشعبي",
      //   "Open Hyperlink": "فتح ارتباط تشعبي",
      //   "Copy Hyperlink": "نسخ ارتباط تشعبي",
      //   "Remove Hyperlink": "أزاله ارتباط تشعبي",
      //   Paragraph: "الفقره",
      //   "Linked(Paragraph and Character)": "مرتبط (فقره وحرف)",
      //   Character: "حرف",
      //   "Merge Cells": "دمج الخلايا",
      //   "Insert Above": "ادراج أعلاه",
      //   "Insert Below": "ادراج أدناه",
      //   "Insert Left": "ادراج إلى اليسار",
      //   "Insert Right": "ادراج اليمين",
      //   Delete: "حذف",
      //   "Delete Table": "حذف جدول",
      //   "Delete Row": "حذف صف",
      //   "Delete Column": "حذف عمود",
      //   "File Name": "اسم الملف",
      //   "Format Type": "نوع التنسيق",
      //   Save: "حفظ",
      //   Navigation: "التنقل",
      //   Results: "نتائج",
      //   Replace: "استبدال",
      //   "Replace All": "استبدال الكل",
      //   "We replaced all": "استبدلنا جميع",
      //   Find: "العثور",
      //   "No matches": "لا توجد تطابقات",
      //   "All Done": "كل القيام به",
      //   Result: "نتيجه",
      //   of: "من",
      //   instances: "الحالات",
      //   with: "مع",
      //   "Click to follow link": "انقر لمتابعه الارتباط",
      //   "Continue Numbering": "متابعه الترقيم",
      //   "Bookmark name": "اسم الإشارة المرجعية",
      //   Close: "اغلاق",
      //   "Restart At": "أعاده التشغيل عند",
      //   Properties: "خصائص",
      //   Name: "اسم",
      //   "Style type": "نوع النمط",
      //   "Style based on": "نمط استنادا إلى",
      //   "Style for following paragraph": "نمط للفقرة التالية",
      //   Formatting: "التنسيق",
      //   "Numbering and Bullets": "الترقيم والتعداد النقطي",
      //   Numbering: "ترقيم",
      //   "Update Field": "تحديث الحقل",
      //   "Edit Field": "تحرير الحقل",
      //   Bookmark: "الإشارة المرجعية",
      //   "Page Setup": "اعداد الصفحة",
      //   "No bookmarks found": "لم يتم العثور علي إشارات مرجعيه",
      //   "Number format tooltip information":
      //     "تنسيق رقم أحادي المستوي:" +
      //     "</br>" +
      //     "[بادئه]% [مستوي الاعداد] [لاحقه]" +
      //     "</br>" +
      //     // tslint:disable-next-line:max-line-length
      //     'علي سبيل االمثال ، "الفصل% 1." سيتم عرض الترقيم مثل' +
      //     "</br>" +
      //     "الفصل الأول- البند" +
      //     "</br>" +
      //     "الفصل الثاني- البند" +
      //     "</br>..." +
      //     "</br>" +
      //     "الفصل نون-البند" +
      //     "</br>" +
      //     // tslint:disable-next-line:max-line-length
      //     "</br>" +
      //     "تنسيق رقم متعدد الإعدادات:" +
      //     "</br>" +
      //     "[بادئه]% [مستوي المستوي]" +
      //     "</br>" +
      //     "[لاحقه] + [بادئه]%" +
      //     "</br>" +
      //     "[المستوي] [لاحقه]" +
      //     "</br>" +
      //     'علي سبيل المثال ، "% 1.% 2." سيتم عرض الترقيم مثل' +
      //     "</br>" +
      //     "1.1 البند" +
      //     "</br>" +
      //     "1.2 البند" +
      //     "</br>..." +
      //     "</br>" +
      //     "1. نون-البند",
      //   Format: "تنسيق",
      //   "Create New Style": "إنشاء نمط جديد",
      //   "Modify Style": "تعديل النمط",
      //   New: "الجديد",
      //   Bullets: "الرصاص",
      //   "Use bookmarks": "استخدام الإشارات المرجعية",
      //   "Table of Contents": "جدول المحتويات",
      //   AutoFit: "الاحتواء",
      //   "AutoFit to Contents": "احتواء تلقائي للمحتويات",
      //   "AutoFit to Window": "احتواء تلقائي للإطار",
      //   "Fixed Column Width": "عرض العمود الثابت",
      //   Reset: "اعاده تعيين",
      //   "Match case": "حاله المباراة",
      //   "Whole words": "كلمات كامل",
      //   Add: "اضافه",
      //   "Go To": "الانتقال إلى",
      //   "Search for": "البحث عن",
      //   "Replace with": "استبدال",
      //   "TOC 1": "جدول المحتويات 1",
      //   "TOC 2": "جدول المحتويات 2",
      //   "TOC 3": "جدول المحتويات 3",
      //   "TOC 4": "جدول المحتويات 4",
      //   "TOC 5": "جدول المحتويات 5",
      //   "TOC 6": "جدول المحتويات 6",
      //   "TOC 7": "جدول المحتويات 7",
      //   "TOC 8": "جدول المحتويات 8",
      //   "TOC 9": "جدول المحتويات 9",
      //   "Right-to-left": "من اليمين إلى اليسار",
      //   "Left-to-right": "من اليسار إلى اليمين",
      //   Direction: "الاتجاه",
      //   "Table direction": "اتجاه الجدول",
      //   "Indent from right": "مسافة بادئه من اليمين",
      //   Page: "صفحه",
      //   "Fit one page": "احتواء صفحه واحد",
      //   "Fit page width": "احتواء عرض الصفحة",
      //   // tslint:disable-next-line:max-line-length
      //   "The current page number in the document. Click or tap to navigate specific page.":
      //     "رقم الصفحة الحالية في المستند. انقر أأو اضغط للتنقل في صفحه معينه",
    },
    documenteditorcontainer: {
      New: "Nuevo",
      Open: "Abrir",
      Undo: "Deshacer",
      Redo: "Rehacer",
      Image: "Imagen",
      "Form Fields": "Campos",
      Table: "Tabla",
      Link: "Link",
      Bookmark: "Favoritos",
      //   "Table of Contents": "جدول المحتويات",
      //   "HEADING - - - - 1": "العنوان----1",
      //   "HEADING - - - - 2": "العنوان----2",
      //   "HEADING - - - - 3": "العنوان----3",
      Header: "Encabezado",
      Footer: "Pie",
      "Page Setup": "Configurar Página",
      "Page Number": "Número de Página",
      Break: "Salto",
      Find: "Buscar",
      "Local Clipboard": "Portapapeles Local",
      "Restrict Editing": "Restringir Edición",
      "Upload from computer": "Cargar archivo local",
      "By URL": "Por URL",
      "Page Break": "Salto de página",
      //   "Section Break": "فاصل المقطع",
      "Header And Footer": "Encabezado y pie",
      Options: "Opciones",
      //   Levels: "مستويات",
      //   "Different First Page": "الصفحة الاولي المختلفة",
      //   "Different header and footer for odd and even pages":
      //     "راس وتذييل مختلف للصفحات الفردية والزوجية.",
      //   "Different Odd And Even Pages": "مختلف الفردية & حتى صفحات",
      //   "Different header and footer for first page":
      //     "راس وتذييل الصفحة المختلفة للصفحة الاولي.",
      //   Position: "موقف",
      //   "Header from Top": "راس من الأعلى",
      //   "Footer from Bottom": "تذييل من الأسفل",
      //   "Distance from top of the page to top of the header.":
      //     "المسافة من اعلي الصفحة إلى اعلي الراس.",
      //   "Distance from bottom of the page to bottom of the footer":
      //     "المسافة من أسفل الصفحة إلى أسفل التذييل.",
      //   "Aspect ratio": "نسبه العرض إلى الارتفاع",
      W: "W",
      H: "H",
      Width: "Ancho",
      Height: "Altura",
      Text: "Texto",
      Paragraph: "Párrafo",
      //   Fill: "ملء",
      //   "Fill color": "لون التعبئة",
      //   "Border Style": "نمط الحدود",
      //   "Outside borders": "خارج الحدود",
      //   "All borders": "جميع الحدود",
      //   "Inside borders": "داخل الحدود",
      //   "Left border": "الحد الأيسر",
      //   "Inside vertical border": "داخل الحدود العمودية",
      //   "Right border": "الحدود اليمني",
      //   "Top border": "الحد العلوي",
      //   "Inside horizontal border": "داخل الحدود الافقيه",
      //   "Bottom border": "الحد السفلي",
      //   "Border color": "لون الحدود",
      //   "Border width": "عرض الحدود",
      //   Cell: "الخليه",
      //   "Merge cells": "دمج الخلايا",
      //   "Insert Or Delete": "ادراج/حذف",
      //   "Insert columns to the left": "ادراج أعمده إلى اليسار",
      //   "Insert columns to the right": "ادراج أعمده إلى اليمين",
      //   "Insert rows above": "ادراج صفوف أعلاه",
      //   "Insert rows below": "ادراج صفوف أدناه",
      //   "Delete rows": "حذف الصفوف",
      //   "Delete columns": "حذف الاعمده",
      //   "Cell Margin": "هامش الخلية",
      //   Top: "أعلى",
      //   Bottom: "اسفل",
      //   Left: "اليسار",
      //   Right: "الحق",
      //   "Align Text": "محاذاة النص",
      //   "Align top": "محاذاة لاعلي",
      //   "Align bottom": "محاذاة إلى الأسفل",
      //   "Align center": "محاذاة المركز",
      //   "Number of heading or outline levels to be shown in table of contents":
      //     "عدد مستويات العنوان أو المخطط التفصيلي التي ستظهر في جدول المحتويات.",
      //   "Show page numbers": "إظهار أرقام الصفحات",
      //   "Show page numbers in table of contents":
      //     "إظهار أرقام الصفحات في جدول المحتويات.",
      //   "Right align page numbers": "محاذاة أرقام الصفحات إلى اليمين",
      //   "Right align page numbers in table of contents":
      //     "محاذاة أرقام الصفحات إلى اليمين في جدول المحتويات.",
      //   "Use hyperlinks": "استخدام الارتباطات التشعبية",
      //   "Use hyperlinks instead of page numbers":
      //     "استخدام الارتباطات التشعبية بدلا من أرقام الصفحات.",
      //   Font: "الخط",
      //   "Font Size": "حجم الخط",
      //   "Font color": "لون الخط",
      //   "Text highlight color": "لون تمييز النص",
      //   "Clear all formatting": "مسح كافة التنسيقات",
      //   "Bold Tooltip": "غامق (Ctrl + B)",
      //   "Italic Tooltip": "مائل (Ctrl + I)",
      //   "Underline Tooltip": "تسطير (Ctrl + U)",
      //   Strikethrough: "يتوسطه",
      //   "Superscript Tooltip": "مرتفع (Ctrl + Shift + +)",
      //   "Subscript Tooltip": "منخفض (Ctrl + =)",
      //   "Align left Tooltip": "محاذاة إلى اليسار (Ctrl + L)",
      //   "Center Tooltip": "المركز (Ctrl + E)",
      //   "Align right Tooltip": "محاذاة إلى اليمين (Ctrl + R)",
      //   "Justify Tooltip": "ضبط (Ctrl + J)",
      //   "Decrease indent": "إنقاص المسافة البادئة",
      //   "Increase indent": "زيادة المسافة البادئة",
      //   "Line spacing": "تباعد الأسطر",
      //   Bullets: "الرصاص",
      //   Numbering: "ترقيم",
      //   Styles: "انماط",
      //   "Manage Styles": "أداره الأنماط",
      //   Page: "صفحه",
      //   of: "من",
      //   "Fit one page": "احتواء صفحه واحده",
      //   "Spell Check": "التدقيق الإملائي",
      //   "Underline errors": "أخطاء التسطير",
      //   "Fit page width": "احتواء عرض الصفحة",
      //   Update: "تحديث",
      //   Cancel: "إلغاء الأمر",
      //   Insert: "ادراج",
      //   "No Border": "بلا حدود",
      //   "Create a new document": "إنشاء مستند جديد.",
      //   "Open a document": "افتح مستندا.",
      //   "Undo Tooltip": "التراجع عن العملية الاخيره (Ctrl + Z).",
      //   "Redo Tooltip": "أعاده العملية الاخيره (Ctrl + Y).",
      //   "Insert inline picture from a file": "ادراج صوره مضمنه من ملف.",
      //   "Insert a table into the document": "ادراج جدول في المستند",
      //   "Create Hyperlink":
      //     "إنشاء ارتباط في المستند للوصول السريع إلى صفحات الويب والملفات (Ctrl + K).",
      //   "Insert a bookmark in a specific place in this document":
      //     "ادراج اشاره مرجعيه في مكان محدد في هذا المستند.",
      //   "Provide an overview of your document by adding a table of contents":
      //     "توفير نظره عامه حول المستند عن طريق أضافه جدول محتويات.",
      //   "Add or edit the header": "أضافه الراس أو تحريره.",
      "Add or edit the footer": "Insertar nota al pie",
      //   "Open the page setup dialog": "افتح مربع حوار اعداد الصفحة.",
      //   "Add page numbers": "أضافه أرقام الصفحات.",
      //   "Find Text": "البحث عن نص في المستند (Ctrl + F).",
      //   "Toggle between the internal clipboard and system clipboard":
      //     "التبديل بين الحافظة الداخلية وحافظه النظام. </br > تم رفض الوصول إلى حافظه النظام من خلال البرنامج النصي بسبب نهج أمان المستعرضات. بدلا من ذلك ، </br > 1. يمكنك تمكين الحافظة الداخلية للقطع والنسخ واللصق داخل المكون. </br > 2. يمكنك استخدام اختصارات لوحه المفاتيح (Ctrl + X و Ctrl + C و Ctrl + V) للقطع والنسخ واللصق مع حافظه النظام.",
      //   "Restrict editing.": "تقييد التحرير.",
      "Current Page Number": "Página actual",
      "Read only": "Solo lectura",
      //   Protections: "الحمايه",
      //   "Error in establishing connection with web server":
      //     "خطا في تاسيس اتصال مع ملقم ويب",
      Single: "Simple",
      Double: "Doble",
      "New comment": "Nuevo comentario",
      Comments: "Comentarios",
      //   "Web layout": "تخطيط ويب",
      //   "Text Form": "شكل نصي",
      //   "Check Box": "خانة اختيار",
      //   DropDown: "اسقاط",
      //   "Update Fields": "تحديث الحقول",
      //   "Update cross reference fields": "تحديث حقول الإسناد الترافقي",
      "Track Changes": "Control de cambios",
      TrackChanges: "Control de cambios",
      //   "Insert Footnote": "أدخل حاشية سفلي",
      //   "Insert Endnote": "أدخل تعليق ختامي",
      //   "Footnote Tooltip": "أدخل حاشية سفلية (Alt + Ctrl + F).",
      //   "Endnote Tooltip": "أدخل تعليقًا ختاميًا (Alt + Ctr l + F).",
      //   AllCaps: "كل قبعات",
      //   "Change case Tooltip": "تغيير الحالةلة",
    },

    colorpicker: {
      //   Apply: "تطبيق",
      //   Cancel: "إلغاء الأمر",
      //   ModeSwitcher: "مفتاح كهربائي الوضع",
    },
  },
});

const serviceUrl =
  "https://services.syncfusion.com/react/production/api/documenteditor/";
// "https://ej2services.syncfusion.com/production/web-services/api/documenteditor/";

DocumentEditorContainerComponent.Inject(Toolbar);

const DocxEditorMain: React.FC = () => {
  let editorObj: DocumentEditorContainerComponent | null = null;
  const wordDocument = useSelector((state: any) => state.editorData.document);
  const { getAccessTokenSilently, user } = useAuth0();
  const history = useHistory();
  const dispatch = useDispatch();
  const showEditor = useRef(false);
  const [isDocumentReady, setIsDocumentReady] = useState(false);
  const [showCompatibilityNotification, setShowCompatibilityNotification] =
    useState(false);
  const reduxUser = useSelector((state: RootState) => state.masterData.user);

  const exitEditor = () => {
    if (window.location.href.includes("/dashboard")) {
      history.push("/dashboard/finished");
    } else {
      history.push("/dashboard/finished");
    }
  };

  const loadFileFromServer = async (documentData: any) => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const file = await downloadWordFromS3andReturn(documentData, accessToken);

      let ajax: XMLHttpRequest = new XMLHttpRequest();
      ajax.open("POST", serviceUrl + "Import", true);
      ajax.onreadystatechange = () => {
        if (ajax.readyState === 4) {
          if (ajax.status === 200 || ajax.status === 304) {
            // open SFDT text in document editor
            editorObj?.documentEditor.open(ajax.responseText);
            if (editorObj?.documentEditor.isDocumentLoaded) {
              showEditor.current = true;
              setIsDocumentReady(true);
              setLoading(false);
              window.scrollTo(0, 100);
            } else {
              exitEditor();
            }
          }
        }
      };
      let formData: FormData = new FormData();
      formData.append("files", file);
      ajax.send(formData);
    } catch {
      setLoading(false);
      exitEditor();
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const onDownload = () => {
    if (wordDocument && wordDocument.fileName)
      editorObj?.documentEditor.save(wordDocument.fileName, "Docx");
  };

  const updateDocument = async (shouldESign: boolean) => {
    const now = Date.now();
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const blob = await editorObj?.documentEditor.saveAsBlob("Docx");
      if (!blob) {
        setAppError();
        return;
      }

      const file = new File([blob], `${wordDocument.fileName}.docx`, {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      const params = {
        data: file,
        ...wordDocument,
        updateDate: now,
      };

      const docRef = await APIPostFile(
        "/doc-ref/save-edited-document",
        accesToken,
        params
      );

      if (docRef.documentSaved && !shouldESign) {
        setTimeout(() => {
          modalOpen({
            successModal: {
              show: true,
              message: "Documento guardado exitósamente",
              submessage: "Lo podés descargar en el dashboard",
              disclaimer: "",
              shouldReload: false,
            },
          });
        }, 1000);
      } else if (docRef.documentSaved && shouldESign) {
        dispatch(setShouldESign(true));
        setLoading(false);
        history.push("/dashboard/esignature");
      } else {
        setAppError();
      }
    } catch (error) {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  const userName = reduxUser?.name ? reduxUser.name : user?.name;

  const sendToEsign = async () => {
    await updateDocument(true);
  };

  useEffect(() => {
    if (wordDocument) {
      setTimeout(() => {
        loadFileFromServer(wordDocument);
      });
    }
    if (!wordDocument) {
      exitEditor();
    }
    setTimeout(() => {
      // Check if the code is running on the client side
      if (showEditor.current === false) {
        setShowCompatibilityNotification(true);
      }
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wordDocument]);

  return (
    <>
      <div className="docx-loader">
        {!showCompatibilityNotification && <LoaderComponent />}
        {showCompatibilityNotification && <DocxNotCompatibleMessage />}
      </div>
      {wordDocument && isDocumentReady && (
        <DocxToolbar
          document={wordDocument}
          onDownload={onDownload}
          updateDocument={updateDocument}
          sendToEsign={sendToEsign}
        />
      )}
      {!showCompatibilityNotification && (
        <div
          className="docx-ditor-wrapper"
          style={{ opacity: isDocumentReady ? 1 : 0 }}
        >
          <DocumentEditorContainerComponent
            locale="ar"
            height="1200px"
            enableToolbar={true}
            ref={(ins) => (editorObj = ins)}
            serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
            currentUser={userName || "Usuario Invitado"}
            // id="#docx-editor-andes-docs"
          ></DocumentEditorContainerComponent>
        </div>
      )}
    </>
  );
};

export default DocxEditorMain;
