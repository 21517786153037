import React, { useState } from "react";
import "../styles.css";
import "./styles.css";

const Catastro = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  const [circunscripcion, setCircunscripción] = useState("");
  const [seccion, setSeccion] = useState("");
  const [manzana, setManzana] = useState("");
  const [parcela, setParcela] = useState("");

  const onChangeInput = (e, type) => {
    let valueNormalized =
      typeof e.target.value === "string" ? e.target.value.split("") : [];
    let newValue = valueNormalized
      .filter((val) => /^[\.áÁéÉíÍóÓúÚñÑa-zA-Z0-9,!?@$¿¡(): ´%+"-]*$/.test(val))
      .join("");

    let newFullAnswer = `Circunscripción: ${circunscripcion}, Sección: ${seccion}, Manzana: ${manzana}, Parcela: ${parcela}`;

    if (type === "Circunscripción") {
      setCircunscripción(newValue);
      newFullAnswer = `Circunscripción: ${newValue}, Sección: ${seccion}, Manzana: ${manzana}, Parcela: ${parcela}`;
    }
    if (type === "Sección") {
      setSeccion(newValue);
      newFullAnswer = `Circunscripción: ${circunscripcion}, Sección: ${newValue}, Manzana: ${manzana}, Parcela: ${parcela}`;
    }
    if (type === "Manzana") {
      setManzana(newValue);
      newFullAnswer = `Circunscripción: ${circunscripcion}, Sección: ${seccion}, Manzana: ${newValue}, Parcela: ${parcela}`;
    }
    if (type === "Parcela") {
      setParcela(newValue);
      newFullAnswer = `Circunscripción: ${circunscripcion}, Sección: ${seccion}, Manzana: ${manzana}, Parcela: ${newValue}`;
    }

    onChange({
      [sectionId]: {
        ...currentSectionAnswers,
        [question.id]: {
          question: question.question,
          answer: newFullAnswer,
        },
      },
    });
  };

  return (
    <div className="question-format-form">
      <div className="catastro-wrapper">
        <p className="catastro-label">Circunscripción:</p>
        <input
          required
          type="text"
          value={circunscripcion}
          className="question-format-input"
          maxLength="150"
          onChange={(e) => {
            onChangeInput(e, "Circunscripción");
          }}
        ></input>
        <p className="catastro-label">Sección:</p>
        <input
          required
          type="text"
          value={seccion}
          className="question-format-input"
          maxLength="150"
          onChange={(e) => {
            onChangeInput(e, "Sección");
          }}
        ></input>
        <p className="catastro-label">Manzana:</p>

        <input
          required
          type="text"
          value={manzana}
          className="question-format-input"
          maxLength="150"
          onChange={(e) => {
            onChangeInput(e, "Manzana");
          }}
        ></input>
        <p className="catastro-label">Parcela:</p>
        <input
          required
          type="text"
          value={parcela}
          className="question-format-input"
          maxLength="150"
          onChange={(e) => {
            onChangeInput(e, "Parcela");
          }}
        ></input>
      </div>
    </div>
  );
};

export default Catastro;
