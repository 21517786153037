export const deleteAndRestart = () => {
  window.localStorage.removeItem("generator-form-document");
  window.localStorage.removeItem("generator-form-data");
  window.localStorage.removeItem("generator-form-sections");
  window.localStorage.removeItem("generator-form-answers");
  window.location.reload();
};

export const deleteAndRedirect = (history: any) => {
  window.localStorage.removeItem("generator-form-document");
  window.localStorage.removeItem("generator-form-data");
  window.localStorage.removeItem("generator-form-sections");
  window.localStorage.removeItem("generator-form-answers");
  history.push("/internal-generator");
};

export const sections = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
