import React from "react";
import { useDispatch } from "react-redux";
import { setCurrentDocumentExpirationDate } from "../../../redux/slices/currentDocumentDataSlice";
// import Text from "../../../uiComponents/Text";
import "../styles.css";
// import { isSafari } from "react-device-detect";

const mes = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "septiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

const Date = ({ question, onChange, sectionId, currentSectionAnswers }) => {
  const dateFormater = (e) => {
    let dateFormat = `${new window.Date(e.target.value).getUTCDate()} de ${
      mes[new window.Date(e.target.value).getUTCMonth()]
    } del ${new window.Date(e.target.value).getUTCFullYear()}`;
    return dateFormat;
  };

  const dispatch = useDispatch();

  return (
    <div>
      {/* {isSafari && (
        <Text blue className="margin-v question-format-info">
          Introducir la fecha con el siguiente formato: 23 del agosto de 2021
        </Text>
      )} */}
      <div className="question-format-form">
        {/* {!isSafari && ( */}
        <input
          required
          className="question-format-input"
          onChange={(e) => {
            if (
              question.question.toLowerCase().includes("fecha de vencimiento")
            ) {
              const date = new window.Date(e.target.value);
              const dateToSave = date.valueOf() + 14400000;
              dispatch(setCurrentDocumentExpirationDate(dateToSave));
              window.sessionStorage.setItem("expirationDate", dateToSave);
            }
            onChange({
              [sectionId]: {
                ...currentSectionAnswers,
                [question.id]: {
                  question: question.question,
                  answer: dateFormater(e),
                },
              },
            });
          }}
          type="date"
        ></input>
        {/* )} */}
        {/* {isSafari && (
          <input
            required
            className="question-format-input"
            onChange={(e) => {
              onChange({
                [sectionId]: {
                  ...currentSectionAnswers,
                  [question.id]: {
                    question: question.question,
                    answer: e.target.value,
                  },
                },
              });
            }}
          ></input>
        )} */}
      </div>
    </div>
  );
};

export default Date;
