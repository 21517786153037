import { atom } from "jotai";

type ConfirmationModalType = {
  disclaimer?: string | null;
  message: string;
  subMessage: string;
  onClose?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  fileName?: string | null;
  show: boolean;
};

export const initialConfirmationModalState: ConfirmationModalType = {
  message: "",
  subMessage: "",
  show: false,
};

export const confirmationModalAtom = atom<ConfirmationModalType>(
  initialConfirmationModalState
);
