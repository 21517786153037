import React, { useEffect, useState } from "react";
import { APIPost, APIPostWithError } from "../../../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import SignatureRow from "./SignatureRow/SignatureRow";
import "./styles.css";
import IconButton from "../../../../uiComponents/IconButton/IconButton";
import { setLoading } from "../../../../Utils/modalMethods";

const SignatureStatusTable = () => {
  const { getAccessTokenSilently } = useAuth0();

  const [siganturesList, setSignaturesList] = useState(null);
  const [length, setLength] = useState(null);
  const [loadedLength, setLoadedLength] = useState(0);
  const [nextPage, setNextPage] = useState(null);

  const getCompanySignatureDocuments = async (page) => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const data = await APIPostWithError(
        "/electronic-signature/get-all-signatures",
        token,
        {
          page: page,
        }
      );
      if (!data) {
        return;
      }
      setLength(data.length);
      if (data.signatures && data.signatures.length > 0) {
        setLoadedLength((prev) => prev + data.signatures.length);
        if (page === 0) {
          setSignaturesList(data.signatures);
        } else {
          const prevList = siganturesList;
          setSignaturesList([...prevList, ...data.signatures]);
        }
        setNextPage(page + 1);
      }
    } finally {
      setLoading(false);
    }
  };

  const reload = async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    setSignaturesList(null);
    try {
      const token = await getAccessTokenSilently();
      const data = await APIPost(
        "/electronic-signature/get-all-signatures",
        token,
        {
          page: 0,
        }
      );
      setLength(data.length);
      if (data.signatures && data.signatures.length > 0) {
        setSignaturesList(data.signatures);
        setLoadedLength(data.signatures.length);
        setNextPage(1);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCompanySignatureDocuments(0);
  }, []);

  return (
    <div className="sig-table-wrapper">
      {/* <div
        className="sig-amount
      "
      >
        <p className="dashboard-message">
          <i className="fas fa-play secondary margin-r" />
          Mostrando{" "}
          <span className=" document-manager-length">
            {loadedLength || 0}{" "}
          </span>{" "}
          Documentos
        </p>
      </div> */}
      {siganturesList &&
        siganturesList.map((sig) => {
          return <SignatureRow data={sig} reload={reload} />;
        })}
      {length && siganturesList && length > loadedLength && (
        <IconButton
          icon="fas fa-plus"
          label="Cargar Más"
          className="sig-more-button"
          onClick={() => getCompanySignatureDocuments(nextPage)}
        />
      )}
    </div>
  );
};

export default SignatureStatusTable;
