import React, { useEffect, useState } from "react";
import Button from "../../../../uiComponents/Button";
import { connect } from "react-redux";
import * as documents from "../../../../Contracts/contractExporter";
import * as data from "../../../../data/questionsExporter";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import { interpolateAnswers } from "../CompanyDocumentGenerator/InterploateAnswers";

import "../styles.css";

const DocumentGenerator = ({ answers }) => {
  // const { user, getAccessTokenSilently } = useAuth0();
  // const [alreadySaved, setAlreadySaved] = useState(false);
  // const history = useHistory();

  const currentDocument = documents[answers.codeName];

  const generate = async (doc) => {
    const blob = await Packer.toBlob(doc);

    const docName =
      window.sessionStorage.getItem("custom_document_name") ||
      answers.currentDocument;

    saveAs(blob, `${docName}.docx`);
  };

  const [answersReady, setAnswersReady] = useState(false);
  useEffect(() => {
    // this will check that the answers are already ready so that we can render the pdf file
    // if (Object.keys(answers).length === 0 && answers.constructor === Object) {
    //   return;
    // } else {
    setAnswersReady(true);
    // }
  }, [answers]);
  return (
    <div>
      <p style={{ margin: 50 }}>Zona de descarga de TEST</p>
      <p style={{ fontSize: 14 }} className="primary">
        *Solo para AndesDockers
      </p>
      {answersReady && (
        <Button
          color="secondary"
          size="large"
          label="Descargar documento de test!"
          center
          shadow={false}
          className="download-button-desktop"
          style={{ margin: 40 }}
          onClick={() => {
            generate(interpolateAnswers(currentDocument, answers, "andes"));
          }}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps)(DocumentGenerator);
