import React, { useState } from "react";
import ConfirmationModal from "../../../../../uiComponents/Modal/ConfirmationModal/ConfirmationModal";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { APIPostWithError } from "../../../../../Services/authenticated";
import { closeModal } from "../../../../../Utils/modalMethods";

const DeleteSignatureModal = ({ data }) => {
  const [loading, setLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const deleteSignature = async () => {
    setLoading(true);
    const accesToken = await getAccessTokenSilently();

    const res = await APIPostWithError(
      "/electronic-signature/delete-signature",
      accesToken,
      { token: data.token }
    );
    setLoading(false);
    if (res && res !== null) {
      // reload();
      window.location.reload();
    }
  };

  return (
    <ConfirmationModal
      message="Eliminar firma electrónica"
      subMessage={`¿Seguro desea esta firma electrónica?`}
      onClose={closeModal}
      onConfirm={deleteSignature}
      disclaimer="*No se podrá deshacer"
      loading={loading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.modal.deleteSignatureModal.signature,
  };
};

export default connect(mapStateToProps, null)(DeleteSignatureModal);
