/* eslint no-template-curly-in-string: 0 */

import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
} from "./types.js";

export const TestDoc = [
  [
    {
      type: title,
      text: "Acuerdo de Confidencialidad",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Acuerdo de Confidencialidad (en adelante, el “Acuerdo”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer} entre las siguientes partes:",
      depend: false,
      notBe: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
  ],
  [
    {
      type: title,
      text: "Sigue la prueba lalalalalalalalal lololololo",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: bold,
      text: "${answers.section1[8].answer}",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Índice de Ajuste": significa el índice denominado “Índice para Contratos de Locación (Ley N° 27.551) publicado por la Comunicación “A” 7096 de fecha 27/08/2020. El índice se encuentra conformado por partes iguales por las variaciones mensuales del índice de precios al consumidor (IPC) y la remuneración imponible promedio de los trabajadores estables (RIPTE), que deberá ser elaborado y publicado mensualmente por el Banco Central de la República Argentina.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: '"Inmueble": el inmueble sito en.',
      notInclude: ["Baulera", "Mobiliario", "Cochera"],
      question: { section: "section1", number: 27 },
      stopRemoval: true,
    },
    {
      type: text,
      text: '"Inmueble": el inmueble con cochera sito en',
      includes: ["Cochera"],
      notInclude: ["Baulera", "Mobiliario"],
      question: { section: "section1", number: 27 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: textArray,
      text: "Este es el array {{array}}",
      question: { section: "section1", number: 27 },
      stopRemoval: false,
    },
  ],
];
