// src/form-component/FormInputText.tsx
import { Controller } from "react-hook-form";
import styles from "./InputForm.module.css";
import { Box, Stack } from "@mui/material";
import { useState } from "react";
import FormLabel from "./FormLabel";

type Props = {
  name: string;
  control: any;
  label?: string;
  smLabel?: boolean;
  required?: boolean;
  defaultValue?: string;
  width?: string;
  info?: string[] | "";
  maxLength?: number;
  rules?: any;
};

const FormInput: React.FC<Props> = ({
  name,
  control,
  label,
  smLabel,
  required,
  defaultValue,
  width,
  info,
  maxLength,
  rules,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const customRules = rules || {};

  return (
    <>
      <FormLabel
        required={required}
        label={label}
        smLabel={smLabel}
        info={info}
        isFocused={isFocused}
      />
      <Box className={styles.wrapper}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: required || false,
              message: "Por favor, complete este campo",
            },
            ...customRules,
          }}
          defaultValue={defaultValue}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <Stack width={width || "100%"} textAlign={"left"}>
              <input
                value={value}
                className={styles.input}
                style={
                  error && {
                    borderColor: "red",
                    backgroundColor: "#fef7f6",
                  }
                }
                maxLength={maxLength || 150}
                onChange={onChange}
                defaultValue={value}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              <Box className="red" mt={-2} mb={2} ml={1}>
                {error?.message && error.message}
              </Box>
            </Stack>
          )}
        />
      </Box>
    </>
  );
};

export default FormInput;
