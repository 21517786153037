import { Box } from "@mui/material";
import React, { memo } from "react";
import styles from "./ParagraphGeneratorForm.module.css";
import DeleteIcon from "../../uiComponents/DeleteIcon/DeleteIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentParagraphLenth,
  setFormWatch,
} from "../../redux/slices/commonComponentsSlice";
import { setAppError } from "../../Utils/modalMethods";
type Props = {
  id: number;
};

const ParagraphCollapsed: React.FC<Props> = ({ id }) => {
  const getPrevData = () => {
    return JSON.parse(localStorage.getItem("generator-form-document") || "{}");
  };
  const currentValue = getPrevData()?.document[id]?.text || "";

  const dispatch = useDispatch();

  const { expandedInternalDocumentCardId, currentParagraphLength } =
    useSelector((state: any) => state.commonComponents);

  const handleDelete = () => {
    if (expandedInternalDocumentCardId !== 9999) {
      setAppError(
        "Error: Cierra el parrafo actual",
        "Primero debes cerrar o eliminar el parrafo abierto actualmente para poder eliminar otros parrafos"
      );
      return;
    }
    if (currentParagraphLength?.length === 1) {
      setAppError(
        "Error",
        "No puedes eliminar el primer párrafo si hay solo uno"
      );
      return;
    }
    const prevData = getPrevData().document;
    if (!prevData) return;
    const updated = [...prevData];
    updated.splice(id, 1);
    localStorage.setItem(
      "generator-form-document",
      JSON.stringify({ document: updated })
    );
    dispatch(setFormWatch(updated));
    const newArray = [...currentParagraphLength];
    newArray.pop();
    dispatch(setCurrentParagraphLenth([...newArray]));
  };

  return (
    <>
      <Box className={`paragraph_ref_${id}`} mb={5} />
      <Box
        className={`${styles.card} paragraph_ref_${id}`}
        maxWidth="1000px"
        position={"relative"}
        textAlign={"left"}
        pr={"100px"}
        display={"flex"}
      >
        <DeleteIcon sx={{ pr: "10px", pt: "1px" }} onClick={handleDelete} />
        {/* <FontAwesomeIcon icon={faPlayCircle} className="primary" /> */}
        <Box fontSize={14} ml={1}>
          {currentValue}
        </Box>
      </Box>
    </>
  );
};

export default memo(ParagraphCollapsed);
