import React, { useMemo, useState } from "react";
import { MRT_ColumnDef } from "material-react-table";
import TableEntryName from "../../../uiComponents/AndesTable/TableEntries/Name.component";
import {
  faTrashAlt,
  faEdit,
  faUser,
  faCrown,
} from "@fortawesome/free-solid-svg-icons";
import TableActions, {
  TableAction,
} from "../../../uiComponents/AndesTable/TableEntries/TableActions.components";
import AndesTable, {
  renderHeader,
} from "../../../uiComponents/AndesTable/AndesTable.component";
import ManagementUserTableUser from "../../../uiComponents/AndesTable/TableEntries/ManagementUserTableUser.component";
import { Box, Typography } from "@mui/material";
import ConfirmationModal from "../../../uiComponents/Modal/ConfirmationModal/ConfirmationModal";
import { colorPallette } from "../../../Common/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

type Props = {
  documents: any[];
  onEditUser: (user: any) => void;
  onDeleteUser: (user: any) => void;
};

const UsersManagementTable: React.FC<Props> = ({
  documents,
  onEditUser,
  onDeleteUser,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteUserId, setDeleteUserId] = useState<String | null>(null);
  const [deleteUserName, setDeleteUserName] = useState<String | null>(null);

  const handleConfirmDelete = async () => {
    if (deleteUserId) {
      await onDeleteUser(deleteUserId);
      setShowDeleteModal(false);
    }
  };

  const userTableActions: TableAction[] = [
    {
      name: "Editar",
      icon: faEdit,
      onClickWithParams: (data: any) => onEditUser(data),
    },
    {
      name: "Eliminar",
      icon: faTrashAlt,
      onClickWithParams: (data: any) => {
        if (data.userId) {
          setDeleteUserId(data.userId);
          setDeleteUserName(data.email);
          setShowDeleteModal(true);
        }
      },
      color: "light-red",
    },
  ];

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: "name",
        header: "Nombre",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        Cell: ({ cell }: any) => {
          const user = cell.row.original;
          return (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <ManagementUserTableUser name={user.name} photo={user.photo} />
              <TableEntryName
                name={user.name}
                image={user.isExpirationHandled ? "" : ""}
              />
            </Box>
          );
        },
        maxSize: 300,
        minSize: 250,
      },
      {
        accessorKey: "email",
        header: "Email",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 250,
        Cell: ({ cell }: any) => {
          return (
            <Typography
              color={colorPallette.primary}
              variant="body2"
              fontWeight={"bold"}
            >
              {cell.getValue()}
            </Typography>
          );
        },
      },
      {
        accessorKey: "role",
        header: "Rol",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 200,
        Cell: ({ cell }: any) => {
          return (
            <Box
              sx={{
                color: colorPallette.primary,
              }}
            >
              {cell.getValue() === "admin" ? (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  color={"green"}
                >
                  <FontAwesomeIcon icon={faUserTie} fontSize={15} />
                  <Typography variant="body2" fontWeight={"bold"}>
                    Admin
                  </Typography>
                </Box>
              ) : cell.getValue() === "superadmin" ? (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  color={"green"}
                >
                  <FontAwesomeIcon icon={faCrown} fontSize={15} />{" "}
                  <Typography variant="body2" fontWeight={"bold"}>
                    Superadmin
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  color={colorPallette.dark}
                >
                  <FontAwesomeIcon icon={faUser} fontSize={15} />
                  <Typography variant="body2" fontWeight={"bold"}>
                    User
                  </Typography>
                </Box>
              )}
            </Box>
          );
        },
      },
      {
        accessorKey: "documentShare",
        header: "Acceso",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        minSize: 150,
        Cell: ({ cell }: any) => {
          const documentShare = cell.getValue();
          return (
            <Box
              sx={{
                color: documentShare
                  ? colorPallette.primary
                  : colorPallette.lightRed,
                fontWeight: "bold",
              }}
            >
              {documentShare
                ? "Todos los documentos de la empresa"
                : "Solo los documentos del usuario"}
            </Box>
          );
        },
      },
      {
        header: "Acciones",
        Header: ({ column }: any) => renderHeader(column.columnDef.header),
        enableColumnActions: false,
        minSize: 200,
        Cell: ({ cell }: any) => (
          <TableActions
            actions={userTableActions}
            rowData={cell.row.original}
          />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <AndesTable
        documents={documents}
        columns={columns}
        initialSort={[{ id: "name", desc: false }]}
      />
      {showDeleteModal && (
        <ConfirmationModal
          message={"¿Está seguro?"}
          subMessage={`Se eliminará el usuario: ${deleteUserName}`}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
    </>
  );
};

export default UsersManagementTable;
