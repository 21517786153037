import React, { useEffect, useState } from 'react';
import LogInButton from '../../../../Authentication/LogIn';
import LoadingScreen from '../../../LoadingScreen';

const PleaseLogIn = () => {
  const [load, setLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoad(false);
    }, 5000);
  });
  return (
    <div className='center mobile-disapear' style={{ padding: 40 }}>
      {load && <LoadingScreen />}
      {!load && (
        <div>
          <h1 className='margin-v-l'>
            Por Favor ingrese a su cuenta Andes Docs
          </h1>
          <p>
            Para poder ingresar al creador de documentos por favor ingrese a su
            cuenta Andes Docs
          </p>
          <LogInButton className='document-creator-log-in b-primary mobile-disapear center b-primary-hover' />
        </div>
      )}
    </div>
  );
};

export default PleaseLogIn;
