import store from "../redux/store";
import { setModal, resetModals } from "../redux/modal/modal.actions";

export const modalOpen = (object: any) => {
  store.dispatch(setModal(object));
};

export const closeModal = () => {
  store.dispatch(resetModals());
};

export const closeErrorModal = () => {
  store.dispatch(
    setModal({
      errorModal: {
        show: false,
        messsage: null,
        submessage: null,
        disclaimer: null,
      },
    })
  );
};

export const setAppError = (
  message?: string,
  submessage?: string,
  disclaimer?: string
) => {
  store.dispatch(
    setModal({
      errorModal: {
        show: true,
        message,
        submessage,
        disclaimer,
      },
    })
  );
};

export const setLoading = (bool = false) => {
  store.dispatch(
    setModal({
      loadingModal: {
        show: bool,
      },
    })
  );
};

export const setSuccessModal = (
  message?: string,
  submessage?: string,
  disclaimer?: string
) => {
  store.dispatch(
    setModal({
      successModal: {
        show: true,
        message: message || "Operacion Exitosa",
        submessage: submessage || "La operacion se ha completado con exito",
        disclaimer: disclaimer || "",
        shouldReload: false,
      },
    })
  );
};

export default null;
