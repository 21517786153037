import { AlignmentType, Footer, Header, ImageRun, Paragraph } from "docx";

import { baigunFooter, headerBaigun } from "../docImages/baigun";

export const styles_baigun = {
  companyId: ["12403"],
  companyName: "Baigún",
  font: "Palatino Linotype",
  titleSize: 22,
  textSize: 22,
  lineSpacing: 380,
  marginRight: 1730,
  marginLeft: 1730,
  beforeParagraph: 0,
  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 1400,
          after: 350,
        },
        alignment: AlignmentType.CENTER,
        indent: {
          left: -300,
        },
        children: [
          new ImageRun({
            data: baigunFooter,
            transformation: {
              width: 600,
              height: 60,
            },
          }),
        ],
      }),
    ],
  }),

  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 1700,
        },
        alignment: AlignmentType.CENTER,
        indent: {
          left: -300,
        },
        children: [
          new ImageRun({
            data: headerBaigun,
            transformation: {
              width: 600,
              height: 40,
            },
          }),
        ],
      }),
    ],
  }),
};
