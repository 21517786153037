import { styles_baigun } from "./baigun";
import { styles_coldwell } from "./coldwell";
import { styles_guimat } from "./guimat";
import { styles_soldati } from "./soldati";
import { styles_soldati_conecta } from "./soldatiConecta";

export const stylesPerCompany = [
  styles_soldati,
  styles_soldati_conecta,
  styles_guimat,
  styles_baigun,
  styles_coldwell,
];
