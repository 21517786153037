import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
  dateWithoutSpace,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const ComodatoSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Contrato de Comodato",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.",
      ],
    },
    questions: [
      {
        id: 1,
        question: "¿En qué Provincia se celebra el Contrato de Comodato?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question:
          "¿En que Ciudad/Localidad se celebra el Contrato de Comodato?",
        info: "Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se celebra el Comodato?",
        type: dateWithoutSpace,
      },
    ],
  },
  {
    id: "section2",
    section: "Comodante",
    title:
      "Indicar los datos del Comodante que dará en comodato/préstamo su Inmueble.",
    type: person,
    explanation: {
      question: "¿Quién es Comodante del Contrato de Comodato?",
      answer: [
        "El Comodante es el dueño del Inmueble que lo da en préstamo a otra persona, llamada Comodataria, en forma gratiuta.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 4,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 5,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 6,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 7,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 8,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 9,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 10,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 11,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 12,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 13,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section3",
    section: "Comodataria",
    title: "Indicar los datos de la Comodataria que usará el Inmueble.",
    type: person,
    explanation: {
      question: "¿Quién es la Comodataria del Contrato de Comodato?",
      answer: [
        "La Comodataria es quien usará el Inmueble del Comodante, en carácter de prestamo/comodato en forma gratiuta y por un tiempo determinado.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person2",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 14,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              question: "Tipo de Documento:",
              id: 15,
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 16,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 17,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 18,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 19,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 20,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 21,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 22,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 23,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section4",
    section: "Inmueble",
    title: "Detallar el Inmueble que se dará en Comodato",
    type: questions,
    explanation: {
      question: "¿Qué es el Inmueble?",
      answer: [
        "El inmueble puede ser de cualquier tipo apto para ser usado como vivienda (departamento, PH, casa en un barrio privado o abierto, country, etc.) y de cualquier tamaño, condición (nuevo o usado) con o sin cochera y/o amoblado o desamoblado.",
      ],
    },
    questions: [
      {
        id: 24,
        question: "¿En qué Provincia se ubica el Inmueble?:",
        type: options,
        options: provincias,
      },
      {
        id: 25,
        question: "¿En qué Ciudad/Localidad se encuentra el Inmueble?",
        answer: ["Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc."],
        type: question,
        notBe: {
          id: 24,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },

      {
        id: 26,
        question:
          "¿Cuál es la úbicación exacta del Inmueble? (Calle, Número, Departamento, Piso y Unidad)",
        answer: [
          'Si es un Departamento o PH, por ej: Beruti 3574, Piso 3, dpto "A"',
          "Si es una Casa, Lote, etc., por ej: Ramallo 3571, Beccar; ó Blanco Encalada 1200, Barrio Laguna de la Luna, Lote 3525",
        ],
        type: question,
      },
      {
        id: 125,
        question: "¿Con que servicios cuenta el Inmueble?",
        type: multipleOptions,
        info:
          "Esto NO implica que vayan a ser pagados por el dueño del inmueble",
        options: [
          "Agua corriente",
          "Electricidad",
          "Gas de red",
          "Telefonía fija",
          "Televisión por cable",
          "Internet",
        ],
      },
      {
        id: 27,
        question:
          "¿El inmueble incluye cochera y/o baulera y/o mobiliario (muebles)?",
        answer: ["Seleccionar en caso que incluya alguna de estas opciones"],
        type: multipleOptions,
        options: ["Cochera", "Baulera", "Mobiliario"],
      },
      {
        id: 28,
        question: "¿Desea agregar un inventario?",
        answer: [
          "Favor de establecer si incluirá o no un Inventario con el detalle de los muebles/cosas que se encuentran dentro del Inmueble.",
          "El Inventario es un detalle pormenizado de las cosas muebles del Inmueble. No es obligatorio contemplarlo en el Contrato.",
        ],
        type: options,
        dependInclude: {
          id: 27,
          answer: "Mobiliario",
        },
        options: ["Sí", "No"],
      },
      {
        id: 29,
        question: "Detalle los muebles que se van a incluir (inventario)",
        type: textarea,
        dependIncludeDouble: [
          {
            id: 27,
            answer: "Mobiliario",
          },
          {
            id: 28,
            answer: "Sí",
          },
        ],
      },
    ],
  },
  {
    id: "section5",
    section: "Duración",
    title: "Duración del Comodato.",
    type: questions,
    explanation: {
      question: "¿Qué es la Duración del Comodato?",
      answer: [
        "El Comodante y la Comodataria acuerdan libremente la duración (o plazo) del Contrato.",
        "El Comodato es un contrato gratuito, es decir, que la Comodataria no deberá pagarle al Comodante ningún dinero o contraprestación a cambio del uso del Inmueble. Igualmente, se puede establecer que el Comodatario pague los impuestos municipales que afectan directamente al uso del Inmueble. Por ejemplo el ABL (Alumbrado, Barrido y Limpieza).",
      ],
    },
    questions: [
      {
        id: 321,
        question:
          "La duración del Comodato ¿Se calculará en días, meses o año/os?",
        answer: [
          "Establecer si la duración del comodato/préstamo del Inmueble se calculará en días, meses o año/os.",
          "En la siguiente pregunta determinarás la cantidad. Por ej: 3 meses, 10 días, 1 año.",
        ],
        type: options,
        options: ["días", "meses", "año", "años"],
      },
      {
        id: 33,
        question:
          "¿Cuánto tiempo durará el Comodato? (Días, meses o año/os de acuerdo a la pregunta anterior) .",
        answer: [
          "En línea con la respuesta anterior, determinar EN NÚMEROS la duración del Alquiler.",
        ],
        type: number,
      },
      {
        id: 34,
        question:
          "¿A partir de qué fecha comenzará el comodato en el Inmueble?",
        answer: [
          "Indicar a partir de que momento inciará el comodato/préstamo entre el Comodante y el Comodatario.",
          "Indicar si empieza el mismo día que se firma el Contrato ó si empieza en otra fecha posterior.",
          "En caso que sea otra fecha posterior, deberá indicar cuál.",
        ],
        type: options,
        options: ["Misma fecha que se firma el Contrato", "Otra posterior"],
      },
      {
        id: 35,
        question: "¿En que fecha empieza?",
        type: date,
        depend: {
          id: 34,
          answer: "Otra posterior",
        },
      },
    ],
  },
  {
    id: "section6",
    section: "Condiciones Específicas",
    title: "Cláusulas Específicas, Jurisdicción y Pago de Impuestos",
    type: questions,
    explanation: {
      question: "¿Que vamos a definir?",
      answer: [
        "Vamos a definir ciertas claúsulas específicas sobre el Comodato y vamos a definir la Jurisdicción que aplicará al Contrato si hubiera algún conflicto entre el Comodatario y el Comodante por el Inmueble.",
        "Solamente se toman a consideración los impuestos municipales que graven especificamente el USO del bien inmueble. No incluye impuestos nacionales y/o provinciales, ni otros impuestos municipales.",
      ],
    },
    questions: [
      {
        id: 36,
        question: "¿Desea prohibir alguna de estas actividades en el inmueble?",
        type: multipleOptions,
        options: ["Fumar dentro del inmueble", "Tener Mascotas"],
      },
      {
        id: 37,
        question:
          "En caso de conflicto ¿En qué jurisdicción se deberá resolver?",
        answer: [
          "En caso de conflicto, se deberá resolver ante un Juez. Definir en que provincia se encontrará el Juez que resolveran el conflicto. (Se suele elegir la misma provincia donde se celebra el Contrato)",
        ],
        type: options,
        options: provincias,
      },
      {
        id: 38,
        question:
          "¿El Comodatario deberá pagar los impuestos municipales del Inmueble? (Por ej. ABL)",
        answer: [
          'En caso que se opté por "Sí", el Contrato contemplará que el Comodatario debe pagar este tipo de impuesto.',
          'En caso que opte por "No", el pago de estos impuestos no se incorporará al Contrato.',
        ],
        type: options,
        options: ["Sí", "No"],
      },
    ],
  },
];

export const Comodato = {
  name: "Contrato de Comodato – Vivienda Familiar",
  codeName: "comodato",
  question: "¿Qué es un Contrato de Comodato?",
  definition: [
    "El comodato es un préstamo de uso, en el que una de las partes entrega a otra gratuitamente algún bien no fungible, mueble o inmueble para que se sirva de ella y restituya la misma cosa/inmueble recibida.",
    "El presente Contrato de Comodato se establece para el caso de préstamo de vivienda para uso habitacional familiar. Es decir, no para uso comercial.",
  ],
  firmas: [
    [
      { id: 4, section: "section2" },
      { id: 9, section: "section2" },
    ],
    [
      { id: 14, section: "section3" },
      { id: 19, section: "section3" },
    ],
  ],
  price: 999.99,
  size: "9-12 Páginas",
  lastRevision: "Septiembre 2021",
  sections: ComodatoSections,
  url: "comodato",
  video: null,
  useCases: {
    good: [
      "Prestar un departamento en forma gratuita",
      "Prestar una casa para uso familiar por un tiempo determinado",
      "Prestar una quinta, casa de fin de semana, PH, Country a otra persona",
    ],
    bad: [
      "Alquilar un inmueble para vivienda",
      "Alquilar un departamento/casa/PH para uso comercial o como oficina",
      "Comprar o vendar un inmueble",
    ],
  },
};
