import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { APIPostWithError } from "../../../../../Services/authenticated";
import IconButton from "../../../../../uiComponents/IconButton/IconButton";
import Modal from "../../../../../uiComponents/Modal/Modal";
import {
  closeModal,
  modalOpen,
  setAppError,
  setLoading,
} from "../../../../../Utils/modalMethods";
import "./SignerEmailModal.styles.css";

type Props = {
  data: any;
};

const SignerEmailModal: React.FC<Props> = ({ data }) => {
  const [email, setEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    setEmail(data.email);
  }, [data]);

  const updateSignerEmail = async () => {
    if (!email || !email.includes("@") || !email.includes(".")) {
      setErrorMessage(
        "El email no puede estar vacio, y debe contener el formato correcto de un email"
      );
      return;
    }
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const res = await APIPostWithError(
        "/electronic-signature/update-signature",
        accesToken,
        {
          signerToken: data.token,
          email,
        }
      );
      if (res.signatureUpdated === true) {
        const body = document.querySelector("#root");
        const signatureUrl = data.sign_url;
        // Create a fake `textarea` and set the contents to the text
        // you want to copy

        if (body) {
          const storage = document.createElement("textarea");
          storage.value = signatureUrl;
          body.appendChild(storage);

          // Copy the text in the fake `textarea` and remove the `textarea`
          storage.select();
          storage.setSelectionRange(0, 99999);
          document.execCommand("copy");
          body.removeChild(storage);
        }

        setTimeout(() => {
          modalOpen({
            successModal: {
              show: true,
              message: "Email actualizado exitósamente",
              submessage:
                "¡Se ha copiado el link! Por favor envíelo al firmante.",
              disclaimer: data.sign_url,
              shouldReload: false,
            },
          });
        }, 500);
        closeModal();
      } else {
        setTimeout(() => {
          setAppError();
        }, 50);
        closeModal();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <div className="b-white margin-v-l signer-email-modal">
        <i
          className="far fa-window-close close"
          onClick={() => closeModal()}
        ></i>
        <div className="title bold">Modificar email del firmante</div>
        <form>
          <label>Email:</label>
          <input
            type="text"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
          />
        </form>
        {errorMessage && (
          <div className="red f-small" style={{ marginBottom: 10 }}>
            *{errorMessage}
          </div>
        )}
        <IconButton
          label="Guardar"
          icon="fas fa-save"
          className="button"
          color="secondary"
          type={"submit"}
          onClick={updateSignerEmail}
        />
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: any) => {
  return {
    data: state.modal.signerEmailModal.data,
  };
};

export default connect(mapStateToProps, null)(SignerEmailModal);
