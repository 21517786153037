import { Dependency, Question } from "../Types/Question.types";
import { processAnswerForDependency } from "./TextProcessor";

export const checkDependencies = (question: Question, answers: any) => {
  const { dependencies } = question;
  if (!dependencies || dependencies.length === 0) return true;

  let shouldRender = true;

  if (dependencies) {
    dependencies.forEach((dependency: Dependency) => {
      const { type, values, id } = dependency;

      const valuesToCheck = values.split(";").map((val) => val.toLowerCase());

      const processedAnswer = processAnswerForDependency(answers[id]).map(
        (ans) => ans.toLowerCase()
      );

      if (type === "exclude") {
        processedAnswer.forEach((ans) => {
          if (valuesToCheck.includes(String(ans))) {
            shouldRender = false;
          }
        });
      }
      if (type === "contain any") {
        shouldRender = false;
        processedAnswer.forEach((ans) => {
          if (valuesToCheck.includes(String(ans))) {
            shouldRender = true;
          }
        });
      }
      if (type === "exact") {
        if (valuesToCheck[0] !== processedAnswer[0]) {
          shouldRender = false;
        }
      }
      if (type === "contain all") {
        valuesToCheck.forEach((val) => {
          if (!processedAnswer.includes(String(val))) {
            shouldRender = false;
          }
        });
      }
    });
  }
  return shouldRender;
};
