import React from "react";
import "./text.css";

const getColor = (golden, white, grey, red, green, blue, customColor) => {
  if (golden) return "#ddc080";
  if (white) return "#ffffff";
  if (grey) return "#707070";
  if (red) return "#ff4848";
  if (green) return "#71d17a";
  if (blue) return "#155ec5";

  return customColor;
};

/**
 * Primary UI component for user interaction
 */
const Text = ({
  children,
  size,
  style,
  customColor,
  align,
  white,
  golden,
  grey,
  red,
  green,
  blue,
  className,
  roboto,
  ...rest
}) => {
  const color = getColor(golden, white, grey, red, green, blue, customColor);

  return (
    <p
      style={{ color, ...style }}
      className={`default-text text-size-${size} text-align-${align} ${className} roboto-${roboto}`}
      {...rest}
    >
      {children}
    </p>
  );
};

Text.defaultProps = {
  size: "medium",
  children: "Please, input some text as content",
  align: "left",
};

export default Text;
