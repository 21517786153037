/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Box, Stack } from "@mui/material";
import DocumentPreview from "./DocumentPreview";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faPlay } from "@fortawesome/free-solid-svg-icons";
import InternalGeneratorTaskBar from "../InternalGenerator/InternalGeneratorTaskBar";
import { colorPallette } from "../../Common/theme";
import ParagraphGeneratorMemo from "./ParagraphGeneratorMemo";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentParagraphLenth,
  setExpandedInternalDocumentCardId,
  setFormWatch,
  setInternalDocumentSaveOrExpandTrigger,
  setSaveDocumentTrigger,
} from "../../redux/slices/commonComponentsSlice";

const InternalDocumentGenerator = () => {
  const getPrevData = () => {
    return JSON.parse(localStorage.getItem("generator-form-document") || "{}");
  };

  const { expandedInternalDocumentCardId, currentParagraphLength } =
    useSelector((state: any) => state.commonComponents);

  const dispatch = useDispatch();

  useEffect(() => {
    const prevData = getPrevData().document;
    if (!prevData) {
      localStorage.setItem(
        "generator-form-document",
        JSON.stringify({ document: [{ type: "newParagraph", id: 0 }] })
      );
      dispatch(setFormWatch([{ type: "newParagraph", id: 0 }]));
      return;
    }
    const newLength: number[] = [];
    prevData.forEach((element: any, index: number) => {
      newLength.push(index);
    });
    dispatch(setCurrentParagraphLenth([...newLength]));
    dispatch(setFormWatch(prevData));
  }, []);

  const handleExpandOrCollapse = (index: number) => {
    if (index === expandedInternalDocumentCardId) {
      dispatch(setInternalDocumentSaveOrExpandTrigger(9999));
      return;
    }
    if (expandedInternalDocumentCardId !== 9999) {
      dispatch(setInternalDocumentSaveOrExpandTrigger(index));
    } else {
      dispatch(setInternalDocumentSaveOrExpandTrigger(index));
      dispatch(setExpandedInternalDocumentCardId(index));
    }
  };

  return (
    <Box
      sx={{
        "& input": {
          color: "#5b55a0",
        },
      }}
      display="grid"
      gridTemplateColumns="1fr 1fr"
    >
      {/* <FormSections formWatch={formWatch} /> */}
      <Box width={"100%"}>
        <Stack
          height="calc(100dvh - 74px)"
          alignItems="center"
          px={4}
          // sx={{ backgroundColor: "#5b55a0" }}
          className="b-tertiary"
          borderRight={"solid 2px #e8eeff"}
          pb={"120px"}
          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <Box
            textAlign="left"
            mb={1}
            ml={1}
            pt={4}
            width="100%"
            maxWidth={1000}
          >
            <FontAwesomeIcon icon={faPlay} className="margin-icon primary" />
            Secciones y párrafos del documento{" "}
          </Box>
          {currentParagraphLength.map((field: any, index: number) => (
            <Box position="relative" width="100%" maxWidth={1000}>
              <ParagraphGeneratorMemo id={index} key={index} />
              <Box
                position="absolute"
                right={24}
                top={62}
                sx={{
                  cursor: "pointer",

                  transform:
                    index === expandedInternalDocumentCardId
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                  transition: "all 0.3s",
                }}
                className="primary"
                onClick={() => {
                  handleExpandOrCollapse(index);
                }}
              >
                <FontAwesomeIcon icon={faChevronDown} />
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>
      <Box
        alignItems="center"
        p={4}
        // sx={{ backgroundColor: "#5b55a0" }}
        className="b-tertiary"
        height="calc(100dvh - 74px)"
        sx={{
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <Box
          onClick={() => {
            dispatch(setSaveDocumentTrigger());
          }}
          color="secondary"
          position="absolute"
          top={0}
          right={0}
          sx={{
            zIndex: 100,
            backgroundColor: colorPallette.secondary,
            fontSize: 14,
            padding: "4px 24px",
            cursor: "pointer",
            transition: "all 0.1s",
            borderBottomLeftRadius: 10,
            "&:hover": {
              opacity: 0.6,
            },
          }}
        >
          Guardar +
        </Box>
        <Box textAlign="left" mb={5} ml={1}>
          <FontAwesomeIcon icon={faPlay} className="margin-icon primary" />
          Previsualización del Documento
        </Box>
        <DocumentPreview />
      </Box>
      <InternalGeneratorTaskBar
        onSave={(callback) => {
          dispatch(setSaveDocumentTrigger());
          callback && callback();
        }}
      />
    </Box>
  );
};

export default InternalDocumentGenerator;
