import React, { useEffect } from "react";
import "./styles.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="policy-wrapper">
      <div>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "center",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              POLÍTICA DE PRIVACIDAD Y
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "center",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              PROTECCIÓN DE DATOS PERSONALES
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "center",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              DEL SITIO DE INTERNET
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "center",
          }}
        >
          <a href="http://www.andesdocs.com">
            <strong>
              <span style={{ fontFamily: '"Arial",sans-serif' }}>
                www.andesdocs.com
              </span>
            </strong>
          </a>
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              &nbsp;- Argentina
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;
              <br />
              &nbsp;
              <br />
              &nbsp;CLÁUSULA (1). Definiciones
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Acceder
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : una o más acciones del Usuario necesarias: tratándose del Sitio,
            para el Acceso del Sitio; o, tratándose de un Sitio de Tercero, para
            abrir conforme al protocolo de Internet denominado Hypertext
            Transfer Protocol (HTTP) o al protocolo de Internet denominado
            Hypertext Transfer Protocol Secure (HTTPS) una o más conexiones HTTP
            o HTTPS, respectivamente, entre la dirección de Internet de dicho
            Sitio de Tercero y la dirección de Internet de un dispositivo
            utilizado por el Usuario para abrir cada dicha conexión.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Acceso del Sitio
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : la apertura conforme al protocolo de Internet denominado Hypertext
            Transfer Protocol (HTTP) o al protocolo de Internet denominado
            Hypertext Transfer Protocol Secure (HTTPS) de una o más conexiones
            HTTP o HTTPS, respectivamente, entre la dirección de Internet del
            Sitio y la dirección de Internet de un dispositivo utilizado por el
            Usuario para abrir cada dicha conexión.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Actividad en el Sitio
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : la utilización por el Usuario de una o más funcionalidades
            incluidas y habilitadas en el Sitio.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Administrador
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : la siguiente persona jurídica: Southern Legal Tech LLC,
            L20000097674, inscripta en el estado de Florida, USA, con correo
            electrónico en help@andesdocs.com.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Autoridad Pública
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : cualquier órgano legislativo, ejecutivo o judicial de carácter
            nacional, provincial o municipal.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Cookie
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : cada archivo digital que cumple los siguientes requisitos: (1) es
            creado por un sitio de Internet accedido por el Usuario por primera
            vez; (2) almacena uno o más datos sobre el Usuario (por ej., su
            identificación de acceso) y/o la actividad del Usuario en dicho
            sitio de Internet (por ej., las selecciones realizadas) con la
            finalidad de facilitar al Usuario el acceso a dicho sitio de
            Internet y la actividad en el mismo al menos por segunda vez; (3) es
            almacenado por el navegador web que el Usuario utilizó para acceder
            a dicho sitio de Internet por primera vez; y (4) puede ser leído
            solamente o leído y modificado por dicho sitio de Internet si el
            Usuario vuelve a acceder a dicho sitio de Internet utilizando el
            mismo navegador web al menos por segunda vez.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Credencial de Seguridad
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : uno o más Datos Personales y/u otros datos u otra información
            suministrados por una persona humana, por sí misma o por intermedio
            de un programa informático no autorizado previa y expresamente por
            el Administrador, para otorgar a dicha persona humana una identidad
            única e inequívoca como Usuario.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Dato Personal
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : cada dato de propiedad del Usuario (incluyendo, sin limitación,
            cada dato sobre el dispositivo utilizado por el Usuario para Acceder
            al Sitio -modelo, sistema operativo, conexión, etc.- y la ubicación
            geográfica del Usuario durante la Permanencia en el Sitio) que
            cumple los siguientes requisitos: (1) conforme a la Ley 25326 no
            está prohibido al Administrador recolectarlo del Usuario conforme a
            la Cláusula Recolección de Datos Personales; y (2) no es de
            conocimiento público al momento en que el Administrador lo recolecta
            del Usuario conforme a la Cláusula Recolección de Datos Personales.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Ley 25326
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : la Ley N° 25.326 y el Decreto N° 1558/2001 del Poder Ejecutivo
            nacional.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Permanecer en el Sitio
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : una o más acciones del Usuario, en el Sitio y conforme a los TyC,
            necesarias para prolongar la Permanencia en el Sitio.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Permanencia en el Sitio
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : el tiempo que transcurriere desde, e incluyendo, el Acceso del
            Sitio hasta, e incluyendo, la Salida del Sitio.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Salida del Sitio
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : el cierre conforme al protocolo de Internet denominado Hypertext
            Transfer Protocol (HTTP) o al protocolo de Internet denominado
            Hypertext Transfer Protocol Secure (HTTPS) de la única o la última,
            según el caso, conexión HTTP o HTTPS, respectivamente, abierta por
            el Acceso del Sitio.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Sitio
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : cada archivo digital vacío o conteniendo uno o más textos
            (originales o reproducciones autorizadas), imágenes, videos,
            sonidos, gráficos, iconos, logotipos, isotipos, marcas, dibujos,
            emblemas, combinaciones de colores, combinación de letras y números,
            frases publicitarias u otro contenido digital o digitalizado
            (distinto de instrucciones de programa informático) y cada archivo
            de programa informático (ya sea que utilice uno o más de los
            anteriores archivos digitales o no) directa o indirectamente
            asociado por el Administrador con la dirección de Internet a la que
            corresponde el siguiente nombre de dominio:
          </span>
          <span style={{ fontFamily: '"Arial",sans-serif' }}>
            &nbsp;<span style={{ color: "black" }}>www.andesdocs.com</span>
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Sitio de Tercero
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : cada dirección de Internet distinta del Sitio.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              TyC
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : los términos y condiciones del Sitio publicados en la sección
            "Terms and Conditions" (www.andesdocs.com/terms-and-conditions) del
            Sitio.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Usuario
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : cada persona humana que Accede al Sitio, Permanece en el Sitio y
            realiza la Actividad en el Sitio, por sí misma o por intermedio de
            un programa informático no autorizado previa y expresamente por el
            Administrador, y cada Usuario Necesario; <em>estipulándose</em> que
            la Actividad en el Sitio realizada con Credencial de Seguridad se
            considerará realizada por la persona humana que suministró, por sí
            misma o por intermedio de un programa informático no autorizado
            previa y expresamente por el Administrador, dicha Credencial de
            Seguridad.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <em>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              Usuario Necesario
            </span>
          </em>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            : cada persona humana que es titular o delegada de la
            responsabilidad parental o representante necesaria, según el caso,
            de otra persona humana que Accede al Sitio, por sí misma o por
            intermedio de un programa informático no autorizado previa y
            expresamente por el Administrador, y no tiene 18 (dieciocho) años de
            edad cumplidos y no está emancipado por matrimonio o por otra causa
            no goza de capacidad civil para Acceder al Sitio, Permanecer en el
            Sitio y realizar la Actividad en el Sitio cumpliendo los TyC.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (2). Desarrollo del Sitio
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (a)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;El Sitio ha sido desarrollado con respeto por la privacidad
            del Usuario en Internet y considerando el deber del Administrador de
            proteger, conforme a la Ley 25.326, cualesquiera Datos Personales
            que el Usuario transmitiere al Sitio en relación con el Acceso del
            Sitio, la Permanencia en el Sitio, la Actividad en el Sitio y la
            Salida del Sitio.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (b)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;La presente Política de Privacidad y Protección de Datos
            Personales es parte de los TyC y debe ser leída e interpretada en
            conjunto con los TyC.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (3). Recolección de Datos Personales
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            Cada Acceso del Sitio automáticamente representa para el Usuario su
            consentimiento sin condiciones para que el Administrador pueda
            recolectar, en el Sitio y/o por correo electrónico recibido del
            Usuario, uno o más Datos Personales con las siguientes finalidades
            salvo indicación expresa en contrario del Administrador en el Sitio
            y/o por correo electrónico enviado al Usuario:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (a)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;mejorar la interacción entre el Usuario y el Sitio durante la
            Permanencia en el Sitio; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (b)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;elaborar estadísticas anónimas (es decir, no susceptibles de
            posibilitar la identificación del Usuario) del Sitio; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (c)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;cumplir una orden de una Autoridad Pública recibida por el
            Administrador.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (4). Cookies
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            El Administrador deberá obtener del Usuario, en el Sitio y/o por
            correo electrónico recibido del Usuario, el consentimiento sin
            condiciones del Usuario para guardar una o más Cookies del Sitio en
            el dispositivo utilizado por el Usuario para Acceder al Sitio y
            utilizar dichas Cookies del Sitio antes de guardar éstas en dicho
            dispositivo.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (5). Negativa del Usuario
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            El Usuario:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (a)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;podrá en cualquier momento durante la Permanencia en el Sitio
            comunicar al Administrador en el Sitio su negativa sin condiciones
            a:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "45.0pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (I)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;suministrar uno o más Datos Personales al Administrador
            conforme a la Cláusula Recolección de Datos Personales; y/o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "45.0pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (II)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;permitir el almacenamiento y la utilización de una o más
            Cookies del Sitio conforme a la Cláusula Cookies; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (b)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;reconoce y acepta que la interacción entre el Usuario y el
            Sitio durante la Permanencia en el Sitio podría ser menos
            satisfactoria (cuantitativa y/o cualitativamente) que lo sería por
            el contrario si:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "45.0pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (I)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;comunicare una negativa conforme al apartado (a); o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "45.0pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (II)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;suministrare Datos Personales incompletos, inexactos o falsos
            a los fines de la Cláusula Recolección de Datos Personales.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (6). Almacenamiento de Datos Personales
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            El Administrador almacenará los Datos Personales en una base de
            datos cuya administración será responsabilidad exclusiva del
            Administrador en el siguiente domicilio: 201 Alhambra Circle, Coral
            Gables, FL 33134.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (7). Gestión de Datos Personales
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            Los Datos Personales que el Administrador recolectare conforme a la
            Cláusula Recolección de Datos Personales podrán ser almacenados,
            procesados y transferidos exclusivamente por:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (a)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;el Administrador; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (b)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;cada persona humana o jurídica con la que el Administrador
            celebrare un contrato de transferencia o cesión de uno o más Datos
            Personales; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (c)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;cada Autoridad Pública que requiriere al Administrador la
            transferencia o cesión de uno o más Datos Personales por resolución
            judicial y cuando medien razones fundadas relativas a la seguridad
            pública, la defensa nacional o la salud pública.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (8). Derechos del Usuario
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (a)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;Conforme a la Ley 25326 el Usuario podrá solicitar al
            Administrador respecto de uno o más Datos Personales:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (I)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;el acceso gratuito a dichos Datos Personales cada 6 o más
            meses aniversario continuos desde la fecha del último acceso
            solicitado por el Usuario salvo que demostrare tener un interés
            legítimo en acceder a dichos Datos Personales antes del transcurso
            de 6 meses aniversario continuos desde la fecha del último acceso
            solicitado por el Usuario; y/o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (II)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;la actualización de dichos Datos Personales si hubieran
            perdido vigencia por haber cambiado las circunstancias del Usuario;
            y/o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (III)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;la rectificación de dichos Datos Personales si fueren
            inexactos o estuvieren incompletos; y/o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (IV)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;el bloqueo de dichos Datos Personales; o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (V)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;la supresión de dichos Datos Personales.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (b)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;Cada solicitud del Usuario conforme al apartado (a) deberá ser
            efectuada mediante:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (I)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;una carta documento si el Usuario solicitare acceder a uno o
            más Datos Personales; o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (II)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;una carta simple, acompañada de una fotocopia simple de su
            Documento Nacional de Identidad o Pasaporte vigente, si el Usuario
            solicitare la actualización, la rectificación, el bloqueo o la
            supresión de uno o más Datos Personales.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (c)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;El Usuario deberá entregar o causar que se entregue la
            comunicación correspondiente según el apartado (b) únicamente en el
            domicilio del Administrador indicado en la Cláusula Almacenamiento
            de Datos Personales.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (d)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;El correo electrónico del Administrador indicado en la
            Cláusula Definiciones no es un domicilio electrónico y, en
            consecuencia, cualquier correo electrónico conteniendo o adjuntando
            una notificación, una comunicación o un emplazamiento que se enviare
            a aquel correo electrónico no deberá considerarse enviado al
            Administrador ni recibido por el Administrador a ningún efecto de la
            notificación o comunicación o del emplazamiento de que se trate.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (e)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;El Administrador:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (I)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;no estará obligado a responder favorablemente:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "45.0pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (1)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;una solicitud de acceso, rectificación y/o supresión de uno o
            más Datos Personales recibida del Usuario conforme al apartado (a)
            si el cumplimiento con la solicitud de que se trate afectare la
            protección de la defensa de la Nación, del orden y la seguridad
            públicos o de los derechos e intereses de terceros; o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "45.0pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (2)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;una solicitud de acceso de uno o más Datos Personales recibida
            del Usuario conforme al apartado (a) sub-apartado (I) si dicha
            solicitud no estuviere fechada por lo menos 6 (seis) meses después
            de la fecha de la última solicitud de acceso de Datos Personales que
            el Administrador hubiera recibido del Usuario conforme al apartado
            (a) sub-apartado (I) salvo que el Usuario demostrare razonablemente
            al Administrador (a criterio exclusivo del Administrador) que tiene
            un interés legítimo para acceder a los Datos Personales de que se
            trate antes de transcurrir 6 (seis) meses desde de la fecha de
            aquella última solicitud de acceso; o
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "45.0pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (3)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;una solicitud de supresión de uno o más Datos Personales
            recibida del Usuario conforme al apartado (a) sub-apartado (V) si la
            supresión solicitada pudiere causar perjuicios a derechos o
            intereses legítimos de terceros o impidiere al Administrador cumplir
            una obligación legal de conservar los Datos Personales de que se
            trate; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (II)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;informará los fundamentos de cada negativa conforme al
            sub-apartado (I) mediante una comunicación escrita dirigida al
            domicilio que el Usuario hubiera informado en la solicitud rechazada
            de que se trate.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (f)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;La Agencia de Acceso a la Información Pública, en su carácter
            de Órgano de Control de la Ley 25326, tiene la atribución de atender
            las denuncias y reclamos que interpongan quienes resulten afectados
            en sus derechos por incumplimiento de las normas vigentes en materia
            de protección de Datos Personales.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (9). Modificaciones
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (a)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;El Administrador:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (I)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;podrá a su exclusivo criterio, en cualquier momento y sin
            necesidad de dar aviso o explicación al Usuario en forma previa,
            simultánea o posterior, modificar, total o parcialmente, de manera
            temporaria o definitiva, esta Política de Privacidad y Protección de
            Datos Personales; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (II)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;sin perjuicio de lo dispuesto en el sub-apartado (I), podrá
            comunicar al Usuario, en el Sitio y/o por correo electrónico enviado
            al Usuario, en la oportunidad y durante el tiempo que en cada caso
            el Administrador determinare, una modificación, total o parcial,
            temporaria o definitiva, de esta Política de Privacidad y Protección
            de Datos Personales.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (b)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;El Usuario:
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (I)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;no estará obligado a aceptar, total o parcialmente, ninguna
            modificación de esta Política de Privacidad y Protección de Datos
            Personales realizada por el Administrador; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (II)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;deberá manifestar expresamente, en el Sitio y/o por correo
            electrónico enviado al Administrador, en la oportunidad y durante el
            tiempo que en cada caso el Administrador determinare, haber leído,
            comprendido y aceptado sin condiciones y totalmente la modificación
            de esta Política de Privacidad y Protección de Datos Personales como
            requisito previo para Permanecer en el Sitio y/o realizar la
            Actividad en el Sitio; y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (III)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;deberá abstenerse de Acceder al Sitio si no tuviere intención
            de cumplir con lo dispuesto en el sub-apartado (II); y
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "22.5pt",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (IV)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;no deberá Permanecer en el Sitio o realizar la Actividad en el
            Sitio si no hubiera cumplido con lo dispuesto en el sub-apartado
            (II).
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              (c)
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;A los fines de esta Cláusula la Política de Privacidad y
            Protección de Datos Personales es la publicada por el Administrador
            en el Sitio durante la Permanencia en el Sitio salvo indicación
            expresa en contrario del Administrador en el Sitio y/o por correo
            electrónico enviado al Usuario.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (10).
            </span>
          </strong>
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            &nbsp;<strong>Sitio de Tercero</strong>
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            Esta Política de Privacidad y Protección de Datos Personales no se
            aplica a ningún Sitio de Tercero ya sea antes del Acceso del Sitio,
            durante la Permanencia en el Sitio a partir del Acceso del Sitio de
            Tercero de que se trate mediante la utilización por el Usuario de
            uno o más enlaces a dicho Sitio de Tercero incluidos y habilitados
            en el Sitio para Acceder a dicho Sitio de Tercero o después de la
            Salida del Sitio.
          </span>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <strong>
            <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
              <br />
              &nbsp;CLÁUSULA (11). Ley Aplicable
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: "12.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            lineHeight: "normal",
            fontSize: "15px",
            fontFamily: "Helvetica",
            textAlign: "justify",
          }}
        >
          <span style={{ fontFamily: '"Arial",sans-serif', color: "black" }}>
            Esta Política de Privacidad y Protección de Datos Personales se rige
            exclusivamente por la ley de la República Argentina.
          </span>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
