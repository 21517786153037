/* eslint no-template-curly-in-string: 0 */

import { title, text, bold, bullet, subtitle, firma } from "./types.js";

export const ContratoDeServicioDoc = [
  [
    {
      type: title,
      text: "Contrato de Servicios",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Contrato de Servicios (en adelante, el “Contrato”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina a los ${answers.section1[3].answer} entre las siguientes partes:",
      depend: false,
      notBe: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
  ],
  [
    {
      type: text,
      text:
        'El presente Contrato de Servicio (en adelante, el "Contrato") se celebra en la ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer} entre las siguientes partes:',
      depend: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Contratante: ${answers.section2[4].answer}, ${answers.section2[5].answer} N° ${answers.section2[6].answer}, con domicilio sito en ${answers.section2[7].answer}, ${answers.section2[8].answer} (en adelante, la “Contratante”); y",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Contratante: ${answers.section2[9].answer}, ${answers.section2[10].answer} N° ${answers.section2[11].answer}, con domicilio sito en ${answers.section2[12].answer}, ${answers.section2[13].answer} representada legalmente por el abajo firmante (en adelante, la “Contratante”); y",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Prestadora: ${answers.section3[14].answer}, ${answers.section3[15].answer} N° ${answers.section3[16].answer}, con domicilio sito en ${answers.section3[17].answer}, ${answers.section3[18].answer} (en adelante, la “Prestadora”).",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Prestadora: ${answers.section3[19].answer}, ${answers.section3[20].answer} N° ${answers.section3[21].answer}, con domicilio sito en ${answers.section3[22].answer}, ${answers.section3[23].answer} representada legalmente por el abajo firmante (en adelante, la “Prestadora”).",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Contratante y la Prestadora podrán ser denominadas conjuntamente como las “Partes”, e individualmente como la “Parte”.",
      depend: false,
    },
  ],
  [
    {
      type: subtitle,
      text: "LAS PARTES ACUERDAN:",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA PRIMERA: Definiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Autoridad Pública: significa cualquier órgano judicial, ejecutivo y/o legislativo de carácter nacional, provincial y/o municipal. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "CCyCN: Código Civil y Comercial de la Nación.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Domicilio: la respectiva dirección de cada Parte indicada en el encabezamiento del presente Contrato o aquella otra dirección en la República Argentina que la respectiva Parte informare a la otra Parte de conformidad a la Cláusula Décimo Novena Domicilios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Forma de Pago: significa un único pago dentro de los tres (3) días de finalizada la Vigencia del Contrato.",
      depend: true,
      question: {
        section: "section5",
        number: 30,
        answer: "Un único pago al finalizar el Servicio",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Forma de Pago: significa el cronograma de pagos que se detalla a continuación: ${answers.section5[311].answer}.",
      depend: true,
      question: {
        section: "section5",
        number: 30,
        answer: "Cronograma de Pagos",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Forma de Pago: significa los pagos mensuales que se realizarán entre el primero (1) y el décimo (10) día de cada mes y el monto será el resultante de dividir el Precio por la cantidad de meses establecidos en la Vigencia.",
      depend: true,
      question: {
        section: "section5",
        number: 30,
        answer: "Pagos Mensuales",
      },
    },
  ],
  [
    {
      type: text,
      text: "Forma de Pago: según se encuentra determinado en el Presupuesto.",
      depend: true,
      question: {
        section: "section5",
        number: 26,
        answer: "No, ya firmamos un Presupuesto y lo agregaremos",
      },
    },
  ],
  [
    {
      type: text,
      text: "IVA: significa Impuesto al Valor Agregado.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Mora: tiene el significado asignado en la Cláusula 12.1.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Multa: suma de dinero producto de multiplicar la suma de ${answers.section7[34].answer} por el número de días transcurridos desde el día que una obligación de hacer o no hacer de la Prestadora venció y no fue cumplida hasta el día que dicha obligación es cumplida por la Prestadora o de otra forma se extingue (ambos días inclusive).",
      depend: true,
      question: { section: "section7", number: 33, answer: "Si" },
    },
  ],
  [
    {
      type: text,
      text:
        "Período de Mora: lapso de tiempo transcurrido desde el día siguiente a una fecha de pago del Precio de acuerdo con la Forma de Pago hasta el día anterior a la fecha cuando la Contratante realiza el pago del Precio (ambos días inclusive).",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Precio: la suma de ${answers.section5[28].answer}.",
      depend: true,
      question: {
        section: "section5",
        number: 26,
        answer: "Si, (recomendado)",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Precio: la suma de ${answers.section5[27].answer} ${answers.section5[28].answer} más IVA.",
      depend: true,
      question: {
        section: "section5",
        number: 26,
        answer: "Si, (recomendado)",
      },
    },
  ],
  [
    {
      type: text,
      text: "Precio: según se encuentra determinado en el Presupuesto.",
      depend: true,
      question: {
        section: "section5",
        number: 26,
        answer: "No, ya firmamos un Presupuesto y lo agregaremos",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Presupuesto: documento suscripto entre las Partes que se acompaña como Anexo A del presente.",
      depend: true,
      question: {
        section: "section5",
        number: 26,
        answer: "No, ya firmamos un Presupuesto y lo agregaremos",
      },
    },
  ],
  [
    {
      type: text,
      text: "Servicio: la siguiente actividad: ${answers.section4[24].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Tasa de Interés Moratorio: 45,00% (cuarenta y cinco por ciento) nominal anual considerando un año de 365 días. En caso de que la tasa precedente resulte violatoria de alguna ley de usura vigente, la tasa de interés aplicable será la máxima tasa de interés permitida por dicha ley.",

      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Tribunales: los órganos judiciales locales competentes por razón de la materia indicados en la Cláusula Tribunales Judiciales Competentes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Vigencia: ${answers.section4[25].answer} contados desde la fecha que se indica en el encabezado del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEGUNDA: Objeto.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.1 Las Partes acuerdan que la Prestadora se obliga a prestar los Servicios (conforme se encuentra detallado en la Cláusula Primera) y cumplir con los términos y obligaciones que se establecen en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.2 En contraprestación por los Servicios prestados por la Prestadora, la Contratante deberá pagar el Precio que se establece en la Cláusula Cuarta y deberá cumplir con los restantes términos y condiciones establecidos en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.3 Se deja expresa constancia que a efectos de celebrar el presente Contrato se ha tenido en especial consideración las características particulares de la Prestadora.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.4 La Prestadora asume total responsabilidad por la provisión de los Servicios acordados en favor del Contratante, en el ámbito y marco de lo establecido dentro del Contrato y en virtud de lo dispuesto por los artículos 959 y 1251 y subsiguientes del CCyCN.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA TERCERA: Vigencia del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Contrato se extenderá por la totalidad de la Vigencia. Ninguna Parte se obligará a alegar, alegará, ni permitirá que se alegue en contra de la otra Parte la reconducción tácita de este Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA CUARTA: Precio. Intereses Moratorios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "4.1 La Contratante abonará el Precio de conformidad con la Forma de Pago en favor de la Prestadora. El Precio deberá abonarse en la cuenta bancaria que la Prestadora oportunamente informe a la Contratante o, en su defecto, en el Domicilio de la Prestadora.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "4.2 Cada una de las Partes se hará cargo de la totalidad de los impuestos, cargas, aranceles, tasas y demás gastos de cualquier naturaleza que pudieran corresponderle como consecuencia de la contratación del Servicio. En caso de corresponder, el impuesto de sellos será soportado por las Partes en partes iguales.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "4.3 Las facturas que emitiese la Prestadora, en el marco del Contrato, deberán ajustarse a las disposiciones legales en vigor y a las exigencias establecidas por las autoridades impositivas competentes y sólo podrán presentarse al cobro debidamente conformadas por el personal responsable de la Prestadora ante la Contratante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "4.4 Una vez recibido correctamente el Precio, la Prestadora deberá extender correspondiente recibo en favor de la Contratante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "4.5 Las Partes establecen que si la Contratante no abona el Precio en la Forma de Pago indicada, se devengarán los Intereses Moratorios calculado sobre el Período de Mora de conformidad con la Cláusula Décimo Tercera.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA: Ausencia de Relación Laboral.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "5.1 La Prestadora deberá asegurarse que cualquier persona en relación de dependencia o vinculado de cualquier modo al Servicio, cumpla fielmente con lo previsto en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "5.2 El Servicio objeto del Contrato no refleja relación laboral alguna entre las Partes y/o entre los empleados de cada una de ellas. La Prestadora será la única responsable de las obligaciones legales y/o convencionales que asuma respecto de sus empleados, contratistas y/o subcontratistas y/o personas que presten servicios para el mismo. El personal con el que cuente la Prestadora para el cumplimiento del Servicio será de su exclusiva relación de dependencia, no teniendo ningún vínculo laboral ni de ninguna otra índole con la Contratante. En este sentido, la Contratante no responderá por ningún reclamo que eventualmente se presente basado en una presunta solidaridad laboral debiendo la Prestadora mantener indemne a la Contratante en los términos de la Cláusula Décimo Primera del presente Contrato. En virtud de ello, la Prestadora asume en forma única y excluyente toda responsabilidad derivada de las situaciones indicadas, tanto respecto de su personal como de terceros.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEXTA: Manifestaciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "6.1 La Prestadora declara y garantiza que:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Cuenta con la capacidad adecuada para celebrar el presente Contrato y llevar a cabo el Servicio y las obligaciones aquí pactadas.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Las obligaciones asumidas en este Contrato consisten exclusivamente en la prestación del Servicio y cualquier otra obligación establecida en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "La Prestadora posee el “know how” suficiente para la prestación del Servicio.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "La Contratante no asumirá responsabilidad solidaria alguna con la Prestadora frente a terceros o frente a su personal y, en caso de resolución en contrario de Autoridad Pública, la Prestadora mantendrá indemne a la Contratante conforme la Cláusula Décimo Primera.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text: "Se encuentra legalmente representada por el abajo firmante.",
      depend: true,
      question: {
        section: "section3",
        number: "person",
        answer: "Persona Jurídica",
      },
    },
  ],
  [
    {
      type: text,
      text: "6.2 La Contratante declara y garantiza que:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Cuenta con la capacidad adecuada para celebrar el presente Contrato y cumplir con las obligaciones aquí pactadas.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Se encuentra en su derecho de recibir el Servicio y obligado a pagar el Precio según se encuentra estipulado en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Cuenta con la capacidad económica y financiera suficiente para afrontar el pago del Precio según la Forma de Pago establecida.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text: "Se encuentra legalmente representada por el abajo firmante.",
      depend: true,
      question: {
        section: "section2",
        number: "person",
        answer: "Persona Jurídica",
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA: Otras Obligaciones de la Contratante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Contratante se obliga también a no incumplir las disposiciones, directivas, instrucciones y/o indicaciones relativas a la prestación del Servicio que la Prestadora le informare periódicamente con razonable anticipación a la fecha de aplicación de dichas disposiciones, directivas, instrucciones y/o indicaciones. Las mencionadas disposiciones, directivas, instrucciones y/o indicaciones deberán ser razonables y conducentes para la realización del Servicio por parte de la Prestadora.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA OCTAVA: Otras Obligaciones de la Prestadora. Deber de lealtad y buena fe.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "8.1 La Prestadora se obliga también a informar a la Contratante dentro del día hábil siguiente de tener, o deber haber tenido, conocimiento sobre cualquier suspensión o interrupción de la prestación del Servicio objeto del presente Contrato por cualquier tiempo que fuere y, en particular, sobre: (a) la causa de dicha suspensión o interrupción (sin importar si fuere imputable directa o indirectamente a la Prestadora o no) y la fecha estimada para la continuación del Servicio; y (b) la valoración y estimación del impacto de dicha suspensión o interrupción en el cumplimiento del objeto de este Contrato por parte de la Prestadora.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "8.2 La Prestadora, sin perjuicio de otras obligaciones y/o deberes acordados en estos términos y condiciones del Contrato, y complementariamente al párrafo anterior, deberá proceder con lealtad y buena fe, debiendo actuar con la diligencia exigida para el cumplimiento de los fines aquí acordados.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA NOVENA: Confidencialidad.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Ninguna de las Partes podrá divulgar la celebración y el contenido del presente Contrato sin el consentimiento previo por escrito de la contraparte salvo que dicha divulgación sea en virtud de un requerimiento formal por parte de una Autoridad Pública.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA DÉCIMA: Prohibición de Cesión y Subcontratación. Cambios en la unidad de negocios de la Prestadora.",
      depend: true,
      question: { section: "section6", number: 31, answer: "No" },
    },
    {
      type: bold,
      text:
        "CLÁUSULA DÉCIMA: Prohibición de Cesión y Subcontratación. Cambios en la unidad de negocios de la Prestadora.Exclusividad",
      depend: true,
      question: { section: "section6", number: 31, answer: "Si" },
    },
  ],
  [
    {
      type: text,
      text:
        "10.1 La Prestadora no podrá ceder ninguno de los derechos y obligaciones emergentes de este Contrato, sin la previa conformidad del Contratante expresada por escrito, en cuyo caso resultarán plenamente aplicables al cesionario los derechos y obligaciones emergentes del presente Contrato para el cedente. Asimismo, la Prestadora no podrá subcontratar a terceras partes para la realización del Servicio objeto del presente Contrato sin la previa autorización por escrito de la Contratante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "10.2 Las Partes establecen que en caso de que la unidad de negocio de la Prestadora se someta a procesos de transformación, tales como reorganización, reestructuración, fusión, adquisición, desprendimiento de parte de activos por venta o liquidación, escisión, constitución de “joint venture” (unión transitoria de empresas) o emprendimiento de cualquier otra actividad que altere en forma sustancial la prestación del Servicio estipulado en la presente, la Prestadora deberá informar tal situación a la Contratante dentro de las cuarenta y ocho (48) horas de iniciado cualquiera de los procesos enumerados. En tales casos, la Contratante tendrá derecho a rescindir el presente Contrato mediando notificación por escrito con una antelación no menor de diez (10) días corridos a la fecha de la efectiva rescisión, sin incurrir en responsabilidad alguna en razón de la extinción anticipada.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "10.3 Las Partes acuerdan que durante la Vigencia del Contrato la Prestadora no se obligará a prestar, ni prestará a tercero/os, servicio alguno de características similares al Servicio objeto del presente Contrato.",
      depend: true,
      question: { section: "section6", number: 31, answer: "Si" },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO PRIMERA: Indemnidad.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "11.1 La Prestadora se compromete a mantener a la Contratante y, si fuese aplicable, a sus funcionarios, directores, empleados, apoderados, controlantes, controladas, subsidiarias y afiliadas indemne de cualquier reclamo de terceros y/o administrativo y/o judicial y/o impositivo, quejas y gastos (incluidos los honorarios de abogados y profesionales afines) que puedan presentarse con motivo y/u ocasión de la presente, así como consecuencia directa o indirecta de la acciones legales iniciadas por terceros relacionadas con, basadas en, emergentes de o asociadas al incumplimiento de cualquiera de las declaraciones, garantías, compromisos, términos y/u obligaciones asumidos en virtud del presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "11.2 La presente indemnidad se extiende durante y con motivo de la Vigencia del presente Contrato y hasta la prescripción de las correspondientes acciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "11.3. La Prestadora se obliga además a reintegrar a la Contratante dentro del término de diez (10) días hábiles cualquier importe que la Contratante deba abonar como consecuencia de una sentencia judicial o medida cautelar dictada en virtud de tales reclamos contra la Contratante y/o la Prestadora, incluyendo capital, intereses, multas, gastos, honorarios profesionales, costas, impuestos y/o cualquier otra erogación.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SEGUNDA: Mora automática. Resolución Opcional.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "12.1 La mora se producirá de manera automática por el incumplimiento de las obligaciones asumidas por las Partes, sin necesidad de interpelación previa judicial o extrajudicial (en adelante, la “Mora”).",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "12.2 Sin perjuicio de lo estipulado en el párrafo precedente, en caso de retardo, demora en el cumplimiento de las obligaciones que asumen en virtud de la presente las Partes, no se considerará que han incurrido en mora, hasta tanto la Parte cumplidora intime de cumplimiento a la Parte no cumplidora por un plazo no menor a diez (10) días hábiles, transcurridos los cuales, y no verificándose el cumplimiento de la intimación cursada, la Parte cumplidora podrá aplicar las siguientes medidas que se establecen a continuación, según corresponda:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Si la Parte cumplidora fuese la Contratante podrá dar por resuelto el presente Contrato. En este caso la Contratante se hará acreedor de los daños y perjuicios efectivamente ocasionados.",
      depend: true,
      question: { section: "section7", number: 33, answer: "No" },
    },
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Si la Parte cumplidora fuese la Contratante, podrá exigir a la Prestadora el pago de la Multa establecida en el presente Contrato y/o podrá dar por resuelto el presente Contrato. En este último caso la Contratante se hará acreedor de los daños y perjuicios efectivamente ocasionados. El devengamiento de la Multa y su cobro por la Contratante no importará renuncia de ésta a ningún derecho de indemnización de los daños y perjuicios causados por el incumplimiento de la obligación de hacer o no hacer de la Prestadora que originó la Multa. El pago de la Multa deberá realizarse en un plazo no mayor a quince (15) días corridos contados desde su requerimiento.",
      depend: true,
      question: { section: "section7", number: 33, answer: "Si" },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Si la Parte cumplidora fuese la Prestadora, ella podrá dar por resuelto el presente Contrato haciéndose acreedor de los daños y perjuicios efectivamente ocasionados. En caso que la Contratante se encuentre en Mora en el pago de alguna suma de dinero, la Prestadora podrá aplicar lo dispuesto en la Cláusula Décimo Tercera.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO TERCERA: Intereses.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Contratante pagará a la Prestadora los Intereses Moratorios correspondientes al Período de Mora por cualquier obligación de pagar sumas de dinero que le atribuye este Contrato que se encuentre en Mora. El devengamiento de los Intereses Moratorios y su cobro por la Prestadora no se interpretarán como una renuncia de ésta a ningún derecho de indemnización de los daños y perjuicios causados por el incumplimiento de la obligación de pagar sumas de dinero de la Contratante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO CUARTA: Gastos.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Los gastos y/o erogaciones en los que las Partes hayan incurrido para celebrar y/o ejecutar los términos del presente Contrato, serán soportados exclusivamente por la Parte que haya incurrido en los mismos.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO QUINTA: Interpretación.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Títulos. Los títulos temáticos utilizados en este Contrato se incluyen para fines de conveniencia únicamente y no serán interpretados de forma que se les asigne importancia significativa o se los considere indicativos de que todas las disposiciones de este Contrato referidas a un tema en particular se encuentran en un única Cláusula;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Singular y Plural. La referencia a la forma singular incluye la referencia a la forma plural y viceversa;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Género. La referencia a uno de los géneros incluye la referencia a todos los demás géneros;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "Incluye. “Incluye” e “incluyendo” significará que incluyen sin limitar la generalidad de la descripción que precede a dichos términos, y se utilizan en el presente en un sentido ilustrativo y no limitativo;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(v)",
      text:
        'Las expresiones "este Contrato", "el presente Contrato", "este instrumento" o "este documento" refieren al presente Contrato y todos los Anexos y todas las modificaciones que tuviere en conjunto;',
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vi)",
      text:
        "Las palabras en mayúscula utilizadas en este Contrato tienen el significado que se les asigna en el mismo;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(vii)",
      text:
        "Las referencias a Cláusulas o Anexos utilizadas en este Contrato sin indicar su fuente son a Cláusulas o Anexos de este Contrato exclusivamente; y ",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(viii)",
      text:
        "Todos los plazos contractuales se computan en días calendarios corridos salvo cuando en este Contrato expresamente se dispone de otra forma.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SEXTA: Contrato Completo.",
      depend: true,
      question: { section: "section6", number: 32, answer: "No" },
    },
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO SEXTA: Contrato Completo. Anexo",
      depend: true,
      question: {
        section: "section6",
        number: 26,
        answer: "No, ya firmamos un Presupuesto y lo agregaremos",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Contrato representa el acuerdo completo e íntegro entre las Partes con respecto al objeto del mismo. Las Partes prescinden expresamente de todas las disposiciones contenidas en cualquier otro acuerdo o correspondencia anteriores que nieguen, limiten, extiendan o entren en conflicto con las disposiciones del presente Contrato. Ningún término de este Contrato será modificado por ninguna declaración anterior o posterior, conducta o acto de cualquier Parte, a menos que las Partes hayan autorizado tal modificación en un documento escrito y ratificado por ambas Partes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Sin perjuicio de lo establecido en el párrafo anterior, las Partes acuerdan incorporar al presente Contrato el Anexo que se adjunta al presente como parte integral del mismo. Los términos y condiciones allí establecidas serán vinculantes para las Partes. Cuando ciertos términos y condiciones del Contrato entren en colisión con ciertos términos y condiciones del Anexo, prevalecerán los establecidos en el presente Contrato.",
      depend: true,
      question: {
        section: "section6",
        number: 26,
        answer: "No, ya firmamos un Presupuesto y lo agregaremos",
      },
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA DÉCIMO SÉPTIMA: Disposiciones Finales. Validez e Integridad del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "17.1 No se considerará que cualquiera de las Partes ha renunciado por ningún acto, retraso, omisión u otro, de forma expresa o implícita, a cualquiera de sus derechos, competencias o soluciones jurídicas, individual o colectivamente, a menos que dicha renuncia se realice por escrito y esté firmada por una persona autorizada de dicha Parte. Una renuncia de este tipo será ejecutable únicamente en la medida específicamente establecida en la renuncia. La renuncia de una Parte a cualquier derecho, competencia o solución jurídica, individual o colectivamente, en una ocasión cualquiera no deberá interpretarse como un obstáculo o renuncia a cualquier derecho, competencia o solución jurídica, individual o colectivamente, que dicha Parte tendría de otra forma en cualquier ocasión futura, tanto si es de naturaleza similar como si no lo es.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "17.2 En el caso de que una o más provisiones de este Contrato llegasen a perder total o parcialmente su validez, legalidad o su vigencia en cualquier respecto, bajo la ley aplicable, la validez, la legalidad y la vigencia de las restantes provisiones de este Contrato no se verán afectadas o anuladas en ningún caso. Cualquier provisión que, total o parcialmente, haya perdido su validez, legalidad o vigencia deberá ser reemplazada por una provisión que se ajuste de la mejor manera al propósito de la provisión reemplazada; lo mismo se aplicará en el caso de una omisión.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO OCTAVA: Comunicaciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El ejercicio de un derecho o cumplimiento de una obligación por una Parte, en cualquier caso en virtud de lo dispuesto por este Contrato, el CCyCN o un acto de una Autoridad Pública relativo a este Contrato (salvo que dicho acto dispusiere otra forma), mediante el envío por dicha Parte de un mensaje a la otra Parte, será eficaz sólo si dicho mensaje fuere recibido mediante una notificación fehaciente al Domicilio de la otra.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO NOVENA: Domicilios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "19.1 Cada Parte constituye domicilio en su Domicilio exclusivamente a los fines del presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "19.2 La Parte que cambiare su domicilio informará la nueva dirección, que deberá encontrarse en la República Argentina, a la otra Parte mediante una notificación fehaciente dirigida al Domicilio de esta última.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "19.3 Las notificaciones que se realicen entre las Partes, así como otras comunicaciones y notificaciones prejudiciales y/o judiciales derivadas de este Contrato, se tendrán por válidamente cursadas al Domicilio de la Parte destinataria hasta que la Parte remitente sea informada del nuevo Domicilio de la Parte destinataria mediante una notificación fehaciente dirigida al Domicilio de la Parte remitente.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text:
        "CLÁUSULA VIGÉSIMA: Ley Aplicable. Tribunales Judiciales Competentes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Este Contrato se regirá e interpretará de conformidad con las leyes de la República Argentina. Las Partes acuerdan que cualquier controversia o reclamo que se derive del presente Contrato, será resuelto exclusivamente por los tribunales ordinarios competentes, por razón de la materia, con asiento en ${answers.section6[32].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA VIGÉSIMO PRIMERA: Ejemplares.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Contrato se inicializa y suscribe en dos (2) ejemplares originales de igual tenor y efecto. En este acto las Partes reciben dichos ejemplares a razón de uno para cada una.",
      depend: false,
    },
  ],
];
