import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../../uiComponents/Button";
import { useSelector } from "react-redux";

const SupportConfig = () => {
  const { isAuthenticated, user } = useAuth0();
  let history = useHistory();
  const reduxUser = useSelector((state) => state.masterData.user);

  return (
    <div style={{ padding: 70 }}>
      <h1 className="margin-v-l">
        ¡Gracias por Registrarte en
        <span className="primary"> Andes Docs!</span>
      </h1>
      <p className="margin-v">Solo falta un paso mas:</p>

      <p className="margin-v">
        Por favor contactate con soporte para ser asignado a tu empresa o a una
        prueba gratuita.
      </p>
      <Button
        label="Contactar Soporte"
        onClick={() => {
          history.push("/contacto");
        }}
        color="secondary"
        center
      />
      <Button
        label="Reintentar"
        onClick={() => {
          history.push("/dashboard/profile");
        }}
        color="secondary"
        center
      />
    </div>
  );
};

export default SupportConfig;
