import React, { useEffect, useState } from "react";
import "./styles.css";
import FoldersPage from "./FoldersPage/FoldersPage";
import IconButton from "../../../uiComponents/IconButton/IconButton.tsx";
import { connect } from "react-redux";
import { setModal } from "../../../redux/modal/modal.actions";
import AvailableSpace from "./AvailableSpace";
import DocumentsManagerTable from "../../../components/Tables/DocumentManager/DocumentManagerTable.component";

const DocumentsManager = ({
  documents,
  searchDocuments,
  initialDocuments,
  setModal,
  searchFolders,
  sortDocuments,
  spaceUsage,
  isAndesDocker = false,
}) => {
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    if (sessionStorage.getItem("dashboardPage")) {
      const sessionPage = sessionStorage.getItem("dashboardPage");
      const parsedPage = JSON.parse(sessionPage);
      setSelectedPage(parsedPage);
    }
  }, []);

  const setSessionStoragePage = (page) => {
    window.sessionStorage.setItem("dashboardPage", page);
  };

  return (
    <div className="documents-manager">
      <AvailableSpace spaceUsage={spaceUsage} />
      <IconButton
        label="Cargar Documento"
        icon="fas fa-cloud-upload-alt"
        className="document-manager-upload-button"
        color={"secondary"}
        onClick={() => {
          setModal({
            uploadFileModal: {
              show: true,
            },
          });
        }}
      />
      <IconButton
        label="Nueva Carpeta"
        icon="fas fa-plus"
        className="document-manager-new-folder-button"
        onClick={() => {
          setSelectedPage(2);
          setSessionStoragePage(2);
          setModal({
            newFolderModal: {
              show: true,
              document: null,
            },
          });
        }}
      />
      <div>
        <p className="dashboard-message">
          <i className="fas fa-play secondary margin-r" />
          Mostrando{" "}
          <span className="b-white document-manager-length">
            {documents ? documents.length : 0}{" "}
          </span>{" "}
          Documentos
        </p>
      </div>
      <div className="document-manager-action-box">
        <div className="document-manager-page-toggle-box">
          <div
            className={`document-manager-page-toggle document-manager-page-toggle-1 ${
              selectedPage === 1
                ? "document-manager-page-toggle-selected"
                : "document-manager-page-toggle-not-selected"
            }`}
            onClick={() => {
              setSelectedPage(1);
              setSessionStoragePage(1);
              searchDocuments("");
            }}
          >
            Ver Todo
          </div>
          <div
            className={`document-manager-page-toggle document-manager-page-toggle-2 ${
              selectedPage === 2
                ? "document-manager-page-toggle-selected"
                : "document-manager-page-toggle-not-selected"
            }`}
            onClick={() => {
              setSelectedPage(2);
              setSessionStoragePage(2);
              searchFolders("");
            }}
          >
            Ver por Carpetas
          </div>
        </div>
      </div>

      {documents && selectedPage === 1 && (
        <div className={`documents-manager-wrapper`}>
          <DocumentsManagerTable documents={documents} />
        </div>
      )}
      {documents && initialDocuments && selectedPage === 2 && (
        <div className={`documents-manager-wrapper`}>
          <FoldersPage documents={initialDocuments} />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setModal,
};

export default connect(null, mapDispatchToProps)(DocumentsManager);
