export const questionDefaultValues = [
  {
    type: "money",
    value: {
      type: "money",
      number: "",
      decimals: "",
      currency: "",
    },
  },
  {
    type: "pesos",
    value: {
      type: "money",
      number: "",
      decimals: "",
      currency: "",
    },
  },
  {
    type: "percentage",
    value: { type: "percentage", number: "", decimals: "" },
  },
];
