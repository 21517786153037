import {
  DETAIL_SETTER,
  DETAIL_RESETTER,
  DETAIL_FULL_SETTER,
} from "./fileDetail.types";
export const setDetails = (newDetail) => {
  return {
    type: DETAIL_SETTER,
    payload: newDetail,
  };
};

export const resetDetails = () => {
  return {
    type: DETAIL_RESETTER,
  };
};

export const setFullDetails = (allDETAILs) => {
  return {
    type: DETAIL_FULL_SETTER,
    payload: allDETAILs,
  };
};
