import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AllSignaturesTable from "../../../../components/Tables/AllSignatures/AllSignaturesTable";

type Props = {
  getSignatures: () => void;
};

const AllSignaturesPage: React.FC<Props> = ({ getSignatures }) => {
  const { electronicSignatures } = useSelector(
    (state: any) => state.masterData
  );

  useEffect(() => {
    if (!electronicSignatures) getSignatures();
  }, []);

  return (
    <>
      {electronicSignatures && (
        <AllSignaturesTable documents={electronicSignatures} />
      )}
    </>
  );
};

export default AllSignaturesPage;
