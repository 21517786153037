import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
  catastro,
  searchServerOptions,
  advancedOptions,
  documentName,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const TestSections = [
  {
    id: "section1",
    section: "Tiempo, Lugar y Autorizante",
    title:
      "Tiempo, Lugar y designado para la Celebración de la Reserva de Compra",
    type: questions,
    explanation: {
      question: "¿Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma la Reserva de Venta.",
      ],
    },
    questions: [
      {
        id: 1,
        question:
          "¿En qué oficina de GRUPO SOLDATI se firma la Reserva de Compra?",
        type: options,
        options: [
          "Av. Libertador 742 - Piso 5 (B1638ET), Vicente López (Sucursal Libertador)",
          "Washington 2201 (C1430ETI), Ciudad Autónoma de Buenos Aires (Sucursal Belgrano R)",
          "Rodriguez Peña 1431 (C1021ABE), Ciudad Autónoma de Buenos Aires (Sucursal Recoleta)",
          "Los Crisantemos 475, Ayres Loft Módulo “C”, Of 114/115, Pilar, Buenos Aires (Sucursal Pilar)",
          "Humboldt 1986 (C1414CTV), Ciudad Autónoma de Buenos Aires (Sucursal Palermo Hollywood)",
          "Av. Las Heras 4073 (C1425ATE), Ciudad Autónoma de Buenos Aires (Sucursal Botánico)",
          "Del Caminante 80 Of. 506, Edificio Vientos del Delta 2, Nordelta, Buenos Aires (Sucursal Nordelta)",
          "Av. Argentina 197 Piso 3 Dpto. B, Neuquén (Sucursal Neuquén)",
          "Av. Alvear 1883 - Posadas 1564 Piso 2, Oficina i, Ciudad Autónoma de Buenos Aires (Sucursal Posadas)",
        ],
      },
      {
        id: 3,
        question: "Elija el nombre para el documento que se va a generar:",
        type: documentName,
      },
      // {
      //   id: 4,
      //   question: "Seleccione el Agenciero correspondiente",
      //   type: searchServerOptions,
      //   optionsId: "flechaBusAgencieros",
      // },
      {
        id: 43,
        question: "Seleccione el Agenciero correspondiente",
        type: searchServerOptions,
        sheetId: "1oenc8T2vRhdwgZDpkr4GHqmeUl5MEVAlRFqS7ygmxtg",
      },

      {
        id: 41,
        question: "Seleccione una opcion correspondiente",
        type: advancedOptions,
        options: [
          {
            label: "Juan",
            value: "juan, el parlante",
          },
          {
            label: "Felipe",
            value: "felipe, el parlante",
          },
          {
            label: "pedro",
            value: "pedro, el parlante",
          },
        ],
      },
      {
        question: "Tipo de Documento:",
        id: 5,
        type: options,
        options: ["DNI", "Pasaporte", "Cédula de Identidad", "CUIL"],
      },
      {
        id: 6,
        question: "Número de Documento:",
        type: question,
      },
      {
        id: 7,
        question: "Domicilio Completo del Reservante",
        answer: [
          "Detallar el domicilio completo (Calle, Piso, Departamento, Ciudad/Localidad) del futuro comprador del Inmueble",
          "Por ejemplo: Sargento Díaz 301, Piso 1, Dpto A, Tigre.",
        ],
        type: question,
      },
      {
        id: 8,
        question: "Domicilio Electrónico (E-mail) del Reservante",
        answer: ["Detallar el email del futuro comprador del Inmueble"],
        type: question,
      },
    ],
  },
  {
    id: "section2",
    section: "Detalles del Inmueble",
    title: "Indicar los detalles del Inmueble involucrado",
    type: questions,
    questions: [
      {
        id: 10,
        question: "¿Qué tipo de Inmueble es?",
        type: options,
        options: [
          "Casa",
          "Casa en barrio cerrado",
          "Casa en country",
          "Departamento",
          "Lote",
        ],
      },
      {
        id: 11,
        question: "¿En qué Provincia se encuentra el Inmueble?",
        type: options,
        options: provincias,
      },
      {
        id: 12,
        question:
          "Calle donde se encuentra el Inmueble (Calle, Altura y Ciudad/Localidad)",
        answer: [
          "Detallar frente a qué calle se encuentra (Calle, Altura y Ciudad/Localidad)",
          "Por ejemplo: Sargento Díaz 301, Tigre; San Martín 234, San Isidro",
          "En caso de Barrio Cerrado o Country, la dirección del Country/Barrio Cerrado",
        ],
        type: question,
      },
      {
        id: 13,
        question: "Detallar Nombre del Barrio Cerrado",
        type: question,
        depend: {
          id: 10,
          answer: "Casa en barrio cerrado",
        },
      },
      {
        id: 14,
        question: "Detallar Nombre del Country",
        type: question,
        depend: {
          id: 10,
          answer: "Casa en country",
        },
      },
      {
        id: 15,
        question: "Detallar Número de Lote",
        type: question,
        depend: {
          id: 10,
          answer: "Casa en country",
        },
      },
      {
        id: 16,
        question: "Detallar Número de Lote",
        type: question,
        depend: {
          id: 10,
          answer: "Casa en barrio cerrado",
        },
      },
      {
        id: 17,
        question: "Detallar Unidad Funcional",
        type: question,
        depend: {
          id: 10,
          answer: "Departamento",
        },
      },
      {
        id: 18,
        question: "Detallar Piso",
        type: question,
        depend: {
          id: 10,
          answer: "Departamento",
        },
      },
      {
        id: 190,
        question: "Detallar Nomenclatura Catastral",
        type: catastro,
        depend: {
          id: 10,
          answer: "Lote",
        },
      },
      {
        id: 191,
        question: "¿El Inmueble cuenta con Cochera?",
        type: options,
        options: ["No", "Sí", "Sí, y con número de Unidad Funcional propio"],
        depend: {
          id: 10,
          answer: "Departamento",
        },
      },
      {
        id: 192,
        question: "Detallar el Número de Unidad Funcional de la Cochera",
        type: question,
        dependIncludeDouble: [
          {
            id: 10,
            answer: "Departamento",
          },

          {
            id: 191,
            answer: "Sí, y con número de Unidad Funcional propio",
          },
        ],
      },
      {
        id: 193,
        question: "¿El Inmueble cuenta con Baulera?",
        type: options,
        options: ["No", "Sí"],
        depend: {
          id: 10,
          answer: "Departamento",
        },
      },
    ],
  },
  {
    id: "section3",
    section: "Detalles de la Transacción",
    title: "Indicar los detalles de la Transacción",
    type: questions,
    questions: [
      {
        id: 20,
        question: "Indicar el monto de la Reserva",
        answer: [
          "Detallar el valor que deberá abonar el Reservante en concepto de Reserva",
        ],
        type: money,
      },
      {
        id: 21,
        question: "¿La Reserva será válida por cuantos días?",
        answer: ["Seleccionar la duración de la Reserva"],
        type: options,
        options: ["siete (7) días", "diez (10) días", "veinte (20) días"],
      },
      {
        id: 22,
        question:
          "Establecer si se avanzará con un Boleto de Compraventa o directo a la Escritura",
        answer: [
          "Detallar si se utilizará un Boleto de Compraventa o si se irá a la Escritura traslativa de dominio directo",
        ],
        type: options,
        options: ["Boleto de Compraventa", "Escritura"],
      },
      {
        id: 23,
        question: "¿A los cúantos días se firmará el Boleto de Compraventa?",
        answer: [
          "Detallar en días corridos. Determinar solo en números, la palabra “día/as” se incorporará automáticamente.",
        ],
        type: number,
        depend: {
          id: 22,
          answer: "Boleto de Compraventa",
        },
      },
      {
        id: 24,
        question:
          "Una vez firmada la Reserva o el Boleto ¿A los cúantos días se firmará la Escritura Traslativa de Dominio?",
        answer: [
          "Detallar en días corridos a los cuantos días se firmará la Escritura.",
          "Determinar solo en números, la palabra “día/as” se incorporará automáticamente.",
        ],
        type: number,
      },
      {
        id: 241,
        question: "¿Dónde se firmará la Escritura Traslativa de Dominio?",
        type: options,
        options: provincias,
      },

      {
        id: 25,
        question:
          "Escribano. ¿Lo designa el Comprador o ya se encuentra individualizado?",
        answer: [
          "En caso que ya sepamos quién es el Escribano interviniente, detallaremos en una próxima pregunta quién es.",
        ],
        type: options,
        options: [
          "Lo designa el Comprador",
          "Existe Escribano individualizado",
        ],
      },
      {
        id: 26,
        question:
          "Detallar Nombre, Apellido y Matrícula del Escribano Público que intervendra en la transacción",
        type: question,
        depend: {
          id: 25,
          answer: "Existe Escribano individualizado",
        },
      },

      {
        id: 27,
        question: "Establecer el Precio de la Compraventa del Inmueble",
        answer: ["Detallar a que Precio se venderá el Inmueble"],
        type: money,
      },

      {
        id: 28,
        question:
          "Del monto establecido ¿Cúanto se abonará al momento de la firma del Boleto de Compraventa?",
        answer: [
          "Detallar cuanto se abonará a la firma del Boleto de Compraventa",
        ],
        type: money,
        depend: {
          id: 22,
          answer: "Boleto de Compraventa",
        },
      },
      {
        id: 29,
        question:
          "Determinar el Saldo Restante que se abonará al momento de la Escritura",
        answer: [
          "Detallar cuanto se abonará a la firma de la Escritura Traslativa de dominio",
        ],
        type: money,
        depend: {
          id: 22,
          answer: "Boleto de Compraventa",
        },
      },
      {
        id: 30,
        question: "Establecer el Porcentaje de Honorarios Inmobiliarios",
        answer: [
          "Detallar los honorarios inmobiliarios que se percibirán. Definir en porcentaje.",
        ],
        type: numberOf,
        fixedValue: "%",
      },
    ],
  },
];
export const Test1 = {
  name: "Reserva de Compra",
  codeName: "Test1",
  question: "¿Qué es un Reserva de Compra?",
  definition: [
    "La Reserva de Compra es un documento por el cúal se deja constancia del dinero que una persona -Reservante- deja en reserva para la Compra de un Inmueble.",
  ],
  sections: TestSections,
  url: "test1",
  removeHeaderAndFooter: true,
};
