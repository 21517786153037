import {
  ANSWER_SETTER,
  ANSWER_RESETTER,
  ANSWER_FULL_SETTER,
} from "./answers.types";
export const setAnswers = (newAnswer) => {
  let sessionAnswers = {};
  if (sessionStorage.answers) {
    sessionAnswers = JSON.parse(sessionStorage.answers);
  }
  const newFullAnswer = { ...sessionAnswers, ...newAnswer };
  sessionStorage.setItem("answers", JSON.stringify(newFullAnswer));
  return {
    type: ANSWER_SETTER,
    payload: newAnswer,
  };
};

export const resetAnswers = () => {
  let sessionAnswers = {};
  sessionStorage.setItem("answers", JSON.stringify(sessionAnswers));

  return {
    type: ANSWER_RESETTER,
  };
};

export const setFullAnswers = (allAnswers) => {
  sessionStorage.setItem("answers", JSON.stringify(allAnswers));

  return {
    type: ANSWER_FULL_SETTER,
    payload: allAnswers,
  };
};
