import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
  dateWithoutSpace,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";
import { bancos } from "./bancos";

export const ReciboSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Recibo",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Recibo.",
      ],
    },
    questions: [
      {
        id: 1,
        question: "¿En qué Provincia se firma el Recibo?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question: "¿En qué Ciudad/Localidad se firma el Recibo?",
        info: "Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se firma el Recibo?",
        type: dateWithoutSpace,
      },
    ],
  },
  {
    id: "section2",
    section: "Librador del Recibo/Acreedor",
    title:
      "Indicar los datos del Librador del Recibo. Es quién recibe el pago y firma el Recibo.",
    type: person,
    explanation: {
      question: "¿Quién es el Librador del Recibo?",
      answer: [
        "El Librador es la persona que firma el Recibo y que recibe el pago del Deudor.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 4,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 5,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 6,
              question: "Número de Documento:",
              type: question,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 41,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 51,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 61,
              question: "Número de CUIT:",
              type: question,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section3",
    section: "Pagadora",
    title:
      "Indicar los datos del Deudor/Pagadora del Recibo. Es quién pagó al Acreedor/Librador.",
    type: person,
    explanation: {
      question: "¿Quién es el Deudor/Pagadora del Pagaré?",
      answer: [
        "Es quién se encuentra obligado por ley, contrato, convención, etc. a pagar al Acreedor un monto de dinero, cheque, etc.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 7,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 8,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 9,
              question: "Número de Documento:",
              type: question,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 71,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 81,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 91,
              question: "Número de CUIT:",
              type: question,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section4",
    section: "Datos del Recibo",
    title: "Detallar los aspectos principales del Recibo.",
    type: questions,
    explanation: {
      question: "¿Qué vamos a definir?",
      answer: [
        "Vamos a definir el monto del Recibo (dinero o cheque) y el concepto por el cúal se entrego se realizó el pago.",
      ],
    },
    questions: [
      {
        id: 12,
        question: "Definir que se entrega ¿Dinero o Cheques?",
        answer: [
          "Se deberá determinar si será dinero o cheque/es lo que paga el Pagador y recibe el Acreedor.",
        ],
        type: options,
        options: ["Dinero en efectivo", "Un (1) Cheque", "Varios Cheques"],
      },
      {
        id: 13,
        question:
          "Establecer el monto y moneda del Dinero en Efectivo que se entrega",
        answer: ["Determinar de cuanto dinero será el Recibo."],
        type: money,
        depend: {
          id: 12,
          answer: "Dinero en efectivo",
        },
      },
      {
        id: 14,
        question: "Determinar el Banco Emisor del Cheque que se entrega",
        answer: ["Determinar de que Banco es el Cheque que se entrega."],
        type: options,
        options: bancos,
        depend: {
          id: 12,
          answer: "Un (1) Cheque",
        },
      },
      {
        id: 15,
        question: "Determinar el monto y la moneda del Cheque que se entrega",
        answer: ["Por Ej.: $30.000 o USD 500."],
        type: money,
        depend: {
          id: 12,
          answer: "Un (1) Cheque",
        },
      },
      {
        id: 16,
        question: "Detallar el Número de Orden del Cheque que se entrega",
        answer: [
          "Cada cheque tiene un número de orden propio para su correcta identificación. Suele indicarse en la parte superior del cheque. Favor de detallarlo.",
          "El Número de orden se utiliza para identificar cada cheque que se emite.",
        ],
        type: question,
        depend: {
          id: 12,
          answer: "Un (1) Cheque",
        },
      },
      {
        id: 170,
        question: "Determinar la cantidad de cheques que se entregan",
        answer: ["Determinar el número de cheques que se entregan"],
        type: options,
        options: [
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
        ],
        depend: {
          id: 12,
          answer: "Varios Cheques",
        },
      },
      {
        id: 17,
        question: "Determinar el Banco Emisor de los Cheques que se entregan",
        answer: [
          "Determinar de que Banco son los Cheques que se entregan.",
          "Únicamente se podrá indicar un (1) Banco emisor.",
        ],
        type: options,
        options: bancos,
        depend: {
          id: 12,
          answer: "Varios Cheques",
        },
      },
      {
        id: 18,
        question:
          "Determinar el monto acumulado de los Cheques que se entregan",
        answer: ["Por Ej.: $30.000 o USD 500."],
        type: money,
        depend: {
          id: 12,
          answer: "Varios Cheques",
        },
      },
      {
        id: 19,
        question: "Detallar los Números de los Cheques que se entregan",
        answer: [
          "Cada cheque tiene un número de orden propio para su correcta identificación. Suele indicarse en la parte superior del cheque. Favor de detallarlo.",
          "El Número de orden se utiliza para identificar cada cheque que se emite.",
          'Detallar cada uno de ellos separados por una coma ","',
        ],
        type: textarea,
        depend: {
          id: 12,
          answer: "Varios Cheques",
        },
      },
      {
        id: 20,
        question:
          "Definir el concepto por el cual se realizó el pago y se entrega el recibo",
        answer: [
          "Se deberá determinar la causa o razón del pago realizado.",
          "Por ej.: Pago de primera cuota de Préstamos; Cancelación de Honorarios Profesionales; Pago de Expensas; Pago de Alquiler mes de Abril; etc.",
        ],
        type: question,
      },
    ],
  },
];

export const Recibo = {
  name: "Recibo",
  codeName: "recibo",
  question: "¿Qué es un Recibo?",
  definition: [
    "Un recibo es un documento físico utilizado para reflejar o dejar constancia de la realización de un pago pendiente. El pagador lo recibe como comprobante de pago.",
  ],
  firmas: [
    [
      { id: 4, section: "section2" },
      { id: 41, section: "section2" },
    ],
  ],
  price: 250,
  size: "1 página",
  lastRevision: "Agosto 2021",
  sections: ReciboSections,
  url: "recibo",
  video: null,
  useCases: {
    good: [
      "Documentar la recepción de una suma de dinero o cheque/es",
      "Documentar la cancelación, total o parcial, de una deuda",
    ],
    bad: [
      "Documentar una deuda",
      "Documentar un préstamo de dinero",
      "Documentar una relación laboral",
    ],
  },
};
