/* eslint no-template-curly-in-string: 0 */

import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
} from "./types.js";

export const TestDoc1 = [
  [
    {
      type: title,
      text: "RESERVA DE COMPRA",
    },
  ],
  [
    {
      type: text,
      text:
        "En el día de la fecha comparece en nuestras oficinas sitas en ${answers.section1[1].answer}, ",
      depend: false,
      stopRemoval: true,
    },
    {
      type: text,
      text:
        "${answers.section1[4].answer}, ${answers.section1[5].answer} ${answers.section1[6].answer} que constituye domicilio en la calle ${answers.section1[7].answer}, y constituyendo domicilio electrónico en los términos del art 75 del CC y CN en ${answers.section1[8].answer}, en adelante “el Reservante” y manifiesta que es interesado directo en la COMPRA ",
      depend: false,
      stopRemoval: true,
    },

    {
      type: text,
      text:
        "del inmueble ubicado con frente a la calle ${answers.section2[12].answer}, ${answers.section2[11].answer} (en adelante el “Inmueble”).",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Casa",
      },
    },

    {
      type: text,
      text:
        "del inmueble ubicado en el barrio cerrado denominado ${answers.section2[13].answer}, con frente a la calle ${answers.section2[12].answer}, ${answers.section2[11].answer}, con número de Lote ${answers.section2[16].answer} (en adelante el “Inmueble”).",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Casa en barrio cerrado",
      },
    },

    {
      type: text,
      text:
        "del inmueble ubicado en el country denominado ${answers.section2[14].answer}, con frente a la calle ${answers.section2[12].answer}, ${answers.section2[11].answer}, con número de Lote ${answers.section2[15].answer} (en adelante el “Inmueble”).",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Casa en country",
      },
    },
    {
      type: text,
      text:
        "del inmueble -Lote- ubicado en ${answers.section2[12].answer}, ${answers.section2[11].answer}, con Nomenclatura Catastral: ${answers.section2[190].answer} (en adelante el “Inmueble”).",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Lote",
      },
    },
    {
      type: text,
      text:
        "del departamento con Unidad funcional ${answers.section2[17].answer}, piso ${answers.section2[18].answer}, con frente a la calle ${answers.section2[12].answer}, ${answers.section2[11].answer} (en adelante el “Inmueble”).",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 10,
        answer: "Departamento",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "El Inmueble incluye cochera bajo unidad funcional ${answers.section2[192].answer}.",
      depend: true,
      question: {
        section: "section2",
        number: 191,
        answer: "Sí, y con número de Unidad Funcional propio",
      },
    },
  ],
  [
    {
      type: text,
      text: "El Inmueble incluye cochera.",
      depend: true,
      question: {
        section: "section2",
        number: 191,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "En el marco de la presente Reserva, el Reservante hace ENTREGA en calidad de RESERVA y en garantía de la oferta, la cantidad de ${answers.section3[20].answer}.  ",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "PRIMERA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "PRECIO: ${answers.section3[27].answer} pagaderos de la siguiente manera: ",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Boleto de Compraventa",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(a)",
      text:
        "${answers.section3[28].answer}, dentro de los ${answers.section3[23].answer} días corridos contados a partir de la aceptación de la presente oferta por parte del titular de dominio del Inmueble, momento en que se suscribirá el Boleto de Compraventa, en calidad de a cuenta de precio y principio de ejecución de contrato; y ",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Boleto de Compraventa",
      },
    },
  ],
  [
    {
      type: bullet,
      bullet: "(b)",
      text:
        "el saldo, o sea la suma de ${answers.section3[29].answer}, dentro de los ${answers.section3[24].answer} días corridos contados a partir de la firma del Boleto de Compraventa, ocasión en que se otorgará la escritura traslativa de dominio y simultánea entrega del Inmueble, ",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Boleto de Compraventa",
      },
    },
    {
      type: text,
      text:
        "por ante el Escribano que determinará el comprador con la debida anticipación.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section3",
        number: 25,
        answer: "Lo designa el Comprador",
      },
    },
    {
      type: text,
      text:
        "por ante el Escribano ${answers.section3[26].answer} quien citará a las partes con la debida anticipación.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section3",
        number: 25,
        answer: "Existe Escribano individualizado",
      },
    },
  ],
  [
    {
      type: bold,
      text: "PRIMERA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "PRECIO: ${answers.section3[27].answer} pagaderos dentro de los ${answers.section3[24].answer} días corridos contados desde la presente, oportunidad en que se otorgará la escritura traslativa de dominio y simultánea entrega del Inmueble, ",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Escritura",
      },
    },
    {
      type: text,
      text:
        "por ante el Escribano ${answers.section3[26].answer} quien citará a las partes con la debida anticipación.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section3",
        number: 25,
        answer: "Existe Escribano individualizado",
      },
    },
    {
      type: text,
      text:
        "por ante el Escribano que determinará el comprador con la debida anticipación.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section3",
        number: 25,
        answer: "Lo designa el Comprador",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Se deja constancia, que de conformidad con el mandato expresamente impartido por la parte vendedora, en caso de pago en efectivo con billetes dólar estadounidense, los mismos deberán estar en buen estado, no podrán encontrarse manchados, rotos ni rayados. No serán aceptados billetes de series anteriores a las emitidas al año 1996 ni los denominados vulgarmente de cabeza chica, como tampoco serán aceptados billetes de valor nominal menor a U$S 100 (billetes dólares estadounidenses CIEN).",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El Inmueble objeto de la presente venta incluye baulera.",
      depend: true,
      question: {
        section: "section2",
        number: 193,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bold,
      text: "SEGUNDA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "La presente reserva será válida por el término de ${answers.section3[21].answer} desde la suscripción del presente Reserva.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "TERCERA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "La firma de la escritura traslativa de dominio se podrá realizar en un Banco de primera línea dentro del radio de ${answers.section3[241].answer}, a determinar por las partes, por ante el Escribano interviniente. ",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CUARTA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "El Reservante acepta y reconoce irrevocablemente que es condición esencial de la presente operación: a) que el pago del precio se efectúe en DOLARES ESTADOUNIDENSES BILLETE en moneda de libre disponibilidad. A este efecto el Reservante declara que al día de la fecha se encuentra en posesión real y efectiva de la cantidad de dólares en efectivo necesarios para cancelar las obligaciones asumidas en la presente y que se obliga a mantenerlos hasta el momento de otorgarse la escritura traslativa de dominio para poder realizar el pago del precio; b) que el Reservante renuncia a invocar la facultad prevista en el art. 765 del CCyCN, por entender las partes que se trata de una norma supletoria y en pleno uso de la libertad contractual; c) el Reservante renuncia en forma expresa e irrevocable a invocar la teoría de la imprevisión, la teoría del abuso del derecho o cualquier otro instituto previsto o no por la ley o por la jurisprudencia y que esté vigente en la actualidad o se establezca en el futuro para pretender la revisión de lo pactado, una disminución o reajuste del precio fijado en forma definitiva en la compraventa; d) que sin perjuicio de lo dispuesto precedentemente si por algún motivo ajeno a las partes o por una sentencia judicial firme se determine que el comprador podrá liberarse de su obligación entregando pesos argentinos, el comprador solo podrá liberarse de su obligación entregando la cantidad de pesos que resulten necesarios, a la fecha de la efectiva cancelación de la obligación para adquirir la cantidad de dólares pactados en el mercado de Uruguay o Nueva York o para adquirir los bonos que vendidos en las plazas de Nueva York o Uruguay sean suficientes  para cancelar libre de gastos y/o comisiones el precio de compra.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "QUINTA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "La escritura del Inmueble se otorgará en base a títulos perfectos. El Inmueble deberá ser entregado completamente desocupado, libre de ocupantes, inquilinos, subinquilinos, y/o intrusos, en el estado en que actualmente se encuentra, libre de gravámenes, restricciones, servidumbres u otras afectaciones de carácter real o personal. Los impuestos, expensas, tasas, contribuciones y servicios deberán encontrarse pagos hasta el momento de la entrega de la posesión y/o escrituración, lo que ocurra primero.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "SEXTA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "La presente reserva se efectúa ad-referendum del propietario: a) si el titular de dominio del Inmueble aceptara los términos y condiciones de esta oferta dentro del plazo vigencia de la misma, deberá dar su conformidad firmando al pie de la presente. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Reservante será notificado de esta aceptación mediante correo electrónico a la siguiente dirección: ${answers.section1[8].answer} y quedará obligado a concurrir a las oficinas de GRUPO SOLDATI S.A -indicadas en el encabezado del presente- a notificarse en forma personal.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "A partir de ese momento, las partes se comprometen a asistir a la firma del Boleto de Compraventa dentro del plazo fijado, en el día y hora que fijen de común acuerdo. ",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Boleto de Compraventa",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "A partir de ese momento, las partes se comprometen a asistir a la firma de la Escritura traslativa de dominio dentro del plazo fijado, en el día y hora que fijen de común acuerdo. ",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Escritura",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "En caso de no presentarse el/los titular/es de dominio del Inmueble a suscribir el Boleto de Compraventa el día o lugar indicado, la firma GRUPO SOLDATI S.A, reintegrará al Reservante el importe entregado en este acto en concepto de reserva, y el/los titular/es de dominio del Inmueble, deberá/n entregar al Reservante otro tanto en concepto de única indemnización. ",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Boleto de Compraventa",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "En caso de no presentarse el/los titular/es de dominio del Inmueble a suscribir la Escritura traslativa de dominio el día o lugar indicado, la firma GRUPO SOLDATI S.A, reintegrará al Reservante el importe entregado en este acto en concepto de reserva, y el/los titular/es de dominio del Inmueble, deberá/n entregar al Reservante otro tanto en concepto de única indemnización. ",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Escritura",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Si fuere el Reservante quien no se presentara a dicho otorgamiento en las condiciones acordadas, autoriza en este acto a GRUPO SOLDATI S.A, a entregar al/los titular/es de dominio del Inmueble, la suma entregada en este acto como reserva, en concepto de única indemnización por su incumplimiento; o b) si por el contrario, el/los titular/es de dominio del Inmueble rechazara/n la presente oferta, o si hubiera vencido el plazo para su aceptación, sin manifestación alguna de su parte al respecto, la presente reserva caducará de pleno derecho, sin necesidad de notificación alguna. En tal caso, se devolverá al Reservante la suma entregada en este acto, dentro de las 24 horas de su rechazo o del vencimiento del plazo de vigencia de la presente, según corresponda. ",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "SÉPTIMA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "El impuesto a la transmisión de Inmuebles que se aplique sobre la presente operación de compraventa y/o cualquier otro que lo sustituya o complemente estará a cargo del titular de dominio del Inmueble. El impuesto de sellos, si correspondiere, será soportado por las partes en iguales proporciones.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "OCTAVA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "En el acto de suscribirse el Boleto de Compraventa, el Reservante abonará a la firma GRUPO SOLDATI S.A. el ${answers.section3[30].answer} más IVA sobre el monto total de la compraventa, en concepto de honorarios por su intervención.",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Boleto de Compraventa",
      },
    },
  ],
  [
    {
      type: bold,
      text: "OCTAVA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "En el acto de suscribirse la Escritura traslativa de dominio, el Reservante abonará a la firma GRUPO SOLDATI S.A. el ${answers.section3[30].answer} más IVA sobre el monto total de la compraventa, en concepto de honorarios por su intervención.",
      depend: true,
      question: {
        section: "section3",
        number: 22,
        answer: "Escritura",
      },
    },
  ],
  [
    {
      type: bold,
      text: "NOVENA: ",
      depend: false,
    },
    {
      type: text,
      text:
        "A todos los efectos legales las partes fijan los tribunales Ordinarios de la Ciudad de Buenos Aires, para cualquier cuestión que se suscite con motivo de la presente, renunciando desde ya a otros fueros, denunciando sus domicilios en los arriba nombrados. ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En prueba de conformidad se firma el presente en tres ejemplares, en ${answers.section1[2].answer}, ${answers.section1[1].answer}, el ${answers.section1[3].answer} ",
      depend: false,
      stopRemoval: true,
      notBe: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text:
        "En prueba de conformidad se firma el presente en tres ejemplares, en la ${answers.section1[1].answer}, el ${answers.section1[3].answer} ",
      stopRemoval: true,
      depend: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
  ],
  [
    {
      type: text,
      text: " ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: " ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "__________________________",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "FIRMA, ACLARACIÓN Y DNI RESERVANTE",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: " ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: " ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "__________________________",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "FIRMA, ACLARACIÓN Y DNI TITULAR DE DOMINIO/VENDEDORA",
      depend: false,
    },
  ],
];
