import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
  boldRight,
  documentName,
} from "./types.js";

export const TestDoc3 = [
  [
    {
      type: title,
      text: "PAGARÉ",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: boldRight,
      text: "${answers.section3[11].answer}",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "En ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina, al ${answers.section1[3].answer}.",
      notBe: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text: "En la ${answers.section1[1].answer}, República Argentina, al ${answers.section1[3].answer}.",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
  ],
  [
    {
      type: text,
      text: "A LA VISTA PAGARÉ SIN PROTESTO (Art. 50 Decreto Ley N° 5965/63 de la República Argentina) a RIO URUGUAY S.R.L. Y OTROS U.T. CUIT N° 30-71674544-5 o a su orden la cantidad de ",
      depend: false,
    },
    {
      type: bold,
      text: "${answers.section3[11].answer} ",
      depend: false,
    },
    {
      type: text,
      text: "por igual valor recibido en ${answers.section3[13].answer} a mi entera satisfacción.",
      depend: false,
    },
    // NOS TIENEN QUE DAR EL TEXTO ESTANDÁR PARA EL CONCEPTO DEL PAGARÉ
  ],

  [
    {
      type: text,
      text: "Esta obligación será pagadera en ${answers.section3[12].answer}.",
      depend: false,
    },
    // PUEDE SER QUE HAYA TEXTO ESTANDAR.
  ],
  [
    {
      type: text,
      text: " ",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Firmante: ${answers.section2[4].answer.nombreDeResponsable}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 1,
        answer: "Venta de Pasajes",
      },
    },
    {
      type: text,
      text: "Firmante: ${answers.section2[5].answer.nombreResponsable}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 1,
        answer: "Servicio de Encomienda",
      },
    },
  ],
  [
    {
      type: text,
      text: "Domicilio: ${answers.section2[4].answer.direccionRepresentante}, ${answers.section2[4].answer.localidad}, ${answers.section2[4].answer.provincia}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 1,
        answer: "Venta de Pasajes",
      },
    },
    {
      type: text,
      text: "Domicilio: ${answers.section2[5].answer.direccionParticular}, ${answers.section2[5].answer.localidad}, ${answers.section2[5].answer.provincia}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 1,
        answer: "Servicio de Encomienda",
      },
    },
  ],
  [
    {
      type: text,
      text: "Cuil/Cuit: ${answers.section2[4].answer.cuitCuil}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 1,
        answer: "Venta de Pasajes",
      },
    },
    {
      type: text,
      text: "Cuil/Cuit: ${answers.section2[5].answer.cuitCuil}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section2",
        number: 1,
        answer: "Servicio de Encomienda",
      },
    },
  ],
  [
    {
      type: text,
      text: "Teléfono: ${answers.section2[17].answer}",
      depend: false,
    },
  ],
  // Puede que haya opciones pre-establecidas como el Dropdown de Agencieros dados de alta.
];
