import React from "react";
import "../styles.css";
import BigOptions from "./BigOptions";

const Options = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  if (question.options.length < 11) {
    return (
      <BigOptions
        question={question}
        onChange={onChange}
        currentAnswer={currentAnswer}
        sectionId={sectionId}
        currentSectionAnswers={currentSectionAnswers}
      />
    );
  }
  return (
    <div className="question-format-form">
      <select
        required
        value={currentAnswer}
        className="question-format-input"
        onChange={(e) => {
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: e.target.value,
              },
            },
          });
        }}
      >
        <option value="">Seleccionar</option>
        {question.options.map((e) => {
          return <option value={e}>{e}</option>;
        })}
      </select>
    </div>
  );
};

export default Options;
