import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ActionButton.styles.css";

type Props = {
  icon?: any;
  label?: string;
  onClick?: () => void;
  className?: string;
};

const ActionButton: React.FC<Props> = ({ icon, label, onClick, className }) => {
  return (
    <div
      onClick={onClick}
      className={`action-button-wrapper primary mr1 ${className}`}
    >
      {label && <div className="label b-dark">{label}</div>}
      {icon && <FontAwesomeIcon className="icon" icon={icon} />}
    </div>
  );
};

export default ActionButton;
