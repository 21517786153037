import React from "react";
import "./Entries.styles.css";
import Image from "../../Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  name: string;
  image: string | null;
  icon?: any;
  onClick?: () => void;
  imageLarge?: boolean;
  subtitle?: string;
};

const TableEntryName: React.FC<Props> = ({
  name,
  image,
  icon,
  onClick,
  imageLarge,
  subtitle,
}) => {
  return (
    <div
      // style={{ width: width }}
      className="table-entry-name"
      onClick={onClick && onClick}
    >
      {image && (
        <Image
          name={image}
          className={`table-entry-image ${
            imageLarge && "table-entry-image-large"
          }`}
        />
      )}
      {icon && (
        <FontAwesomeIcon icon={icon} className="table-entry-icon primary" />
      )}
      {name}
      {subtitle && <div className="table-entry-subtitle">{subtitle}</div>}
    </div>
  );
};

export default TableEntryName;
