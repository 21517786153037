import React, { useState } from "react";
import Text from "../../../uiComponents/Text";

import Options from "./pathOptions";
import QuestionSelector from "../QuestionSelector";

import { connect } from "react-redux";
import { setAnswers } from "../../../redux/answers/answers.actions";

import "../styles.css";

const Path = ({
  data,
  currentSection,
  sectionId,
  answers,
  setAnswers,
  setCurrentSection,
}) => {
  const [dependantQuestions, setDependantQuestions] = useState(undefined);
  const thisSection = data.sections[currentSection - 1];
  // this checks the current answer of the question and returns it
  const currentAnswer =
    answers.hasOwnProperty(sectionId) &&
    answers[sectionId].hasOwnProperty(thisSection.pathQuestion.id)
      ? answers[sectionId][thisSection.pathQuestion.id].answer
      : null;

  // this gets all the answers of the section, it is needed to spread it inside the objecto of answers
  const currentSectionAnswers = answers.hasOwnProperty(sectionId)
    ? answers[sectionId]
    : {};
  return (
    <div>
      <div className="question-card">
        <Text className="question-format-text">
          <i
            className="fas fa-chevron-circle-right secondary"
            style={{ marginRight: 10 }}
          ></i>
          {thisSection.pathQuestion.question}
        </Text>
        {thisSection.pathQuestion.info && (
          <Text grey size="small" className="margin-v question-format-info">
            - {thisSection.pathQuestion.info}
          </Text>
        )}
        {thisSection.pathQuestion.answer &&
          thisSection.pathQuestion.answer.map((e) => {
            return (
              <Text grey size="small" className="margin-v question-format-info">
                - {e}
              </Text>
            );
          })}
        <Options
          question={thisSection.pathQuestion}
          onChange={setAnswers}
          currentAnswer={currentAnswer}
          sectionId={sectionId}
          currentSectionAnswers={currentSectionAnswers}
          setDependantQuestions={setDependantQuestions}
          required
        />
        {currentAnswer && (
          <Text green>
            <i className="far fa-check-circle" style={{ marginRight: 10 }}></i>
            Respuesta Actual: {currentAnswer}
          </Text>
        )}
      </div>
      {dependantQuestions &&
        dependantQuestions.map((question) => (
          <QuestionSelector
            question={question}
            key={question.id}
            sectionId={sectionId}
          />
        ))}
    </div>
  );
};

const mapDispatchToProps = {
  setAnswers,
};

const mapStateToProps = (state) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Path);
