import React, { useState, useEffect } from "react";

import LeftSection from "./sections/LeftSection";
import MiddleSection from "./sections/MainSection";

import { connect } from "react-redux";
import { setAnswers, resetAnswers } from "../../redux/answers/answers.actions";
import Loader from "react-loader-spinner";

import "./styles.css";
const DocumentCreator = ({ data, answers, setAnswers }) => {
  const [currentSection, setCurrentSection] = useState(0);
  const [isNewDocument, setIsNewDocument] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentSection]);

  const setSessionCurrentSection = (sec) => {
    sessionStorage.setItem("currentSection", sec);
    setCurrentSection(sec);
  };

  const setSessionUrl = (url) => {
    sessionStorage.setItem("sessionUrl", url);
  };

  useEffect(() => {
    //this code will check wether the document you are editing is the same as before
    // if it isnt it will reset de answers
    // if it is it will keep the answers so that te user can continue where he left
    let storedAnswers = {};
    let sessionCurrentSection = undefined;
    if (sessionStorage.answers && sessionStorage.currentSection) {
      storedAnswers = JSON.parse(sessionStorage.answers);
      sessionCurrentSection = JSON.parse(sessionStorage.currentSection);
    }
    if (isNewDocument) {
      if (!storedAnswers.currentDocument) {
        resetAnswers();
        setAnswers({ currentDocument: data.name, codeName: data.codeName });
        setSessionCurrentSection(0);
        setSessionUrl(data.url);
      } else if (
        storedAnswers.currentDocument &&
        storedAnswers.currentDocument !== data.name
      ) {
        resetAnswers();
        setAnswers({ currentDocument: data.name, codeName: data.codeName });
        setSessionCurrentSection(0);
        setSessionUrl(data.url);
        window.location.reload();
      } else if (sessionCurrentSection) {
        setCurrentSection(sessionCurrentSection);
      }
      setIsNewDocument(false);
    }
    setLoading(false);
  }, [answers, data.codeName, data.name, data.url, isNewDocument, setAnswers]);

  return (
    <div>
      <div>
        <div className="document-creator b-tertiary">
          <LeftSection
            data={data}
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
          />
          {loading ? (
            <div className="document-creator-loading-div">
              <Loader
                type="TailSpin"
                color="rgb(106, 250, 106)"
                height={60}
                width={60}
                className="document-creator-loader"
              />
              <span>Cargando ...</span>
            </div>
          ) : (
            <MiddleSection
              data={data}
              currentSection={currentSection}
              setCurrentSection={setSessionCurrentSection}
            />
          )}
        </div>
        <div className="document-creator-mobile">
          {/* // THIS IS MOBILE VERSION OF DocumentCreator DONT DELETE */}
          {/* {loading ? (
          <div className="document-creator-loading-div">
            <Loader
              type="TailSpin"
              color="rgb(106, 250, 106)"
              height={60}
              width={60}
              className="document-creator-loader"
            />
            <span>Cargando ...</span>
          </div>
        ) : (
          <div>
            <TopMobileSection
              data={data}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
            />
            <MiddleSection
              data={data}
              currentSection={currentSection}
              setCurrentSection={setSessionCurrentSection}
            />
          </div>
        )} */}
          <div className="center" style={{ padding: 40 }}>
            <h1 className="margin-v-l">Ingrese desde una Computadora</h1>
            <p>
              Para poder ingresar al creador de documentos por favor ingrese
              desde una computadora. Si aún ve este mensaje, establezca el zoom
              del navegador en 100% ó contáctese con soporte
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    answers: state.answers,
  };
};

const mapDispatchToProps = () => ({
  setAnswers,
  resetAnswers,
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCreator);
