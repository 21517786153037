import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { APIPostWithError } from "../../../Services/authenticated";
import XsButton from "../../../uiComponents/XsButton/XsButton";
import { setLoading } from "../../../Utils/modalMethods";
import "../styles.css";

const SearchServerOptions: React.FC<any> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const [options, setOptions] = useState([]);
  const [serverError, setServerError] = useState(false);
  const [defaultValue, setDefaultValue] = useState(undefined);

  const findDefaultValue = (opt: any) => {
    const value = opt.find((option: any) => option.value === currentAnswer);
    setDefaultValue(value);
  };
  const getAndSetOoptionsFromServer = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently();
      const data = await APIPostWithError(
        "/server-options/get-sheet-data",
        accessToken,
        {
          sheetId: question.sheetId,
        }
      );
      if (data?.options) {
        if (currentAnswer) findDefaultValue(data.options);
        setOptions(data.options);
        setServerError(false);
      } else {
        setServerError(true);
      }
    } catch {
      setServerError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAndSetOoptionsFromServer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBackggroundColor = (state: any) => {
    if (state.isSelected) return "#a8bbfd";
    if (state.isFocused) return "#e8eeff";
    return "white";
  };

  const getColor = (state: any) => {
    if (state.isSelected) return "white";
    if (state.isFocused) return "#818181";
    return "rgb(163, 138, 255)";
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      color: getColor(state),
      backgroundColor: getBackggroundColor(state),
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: "100%",
      height: "44px",
      border: "none",
      marginBottom: 20,
      marginTop: 20,
      color: "rgb(163, 138, 255)",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: "solid 1px rgb(163, 138, 255)",
    }),
    input: (provided: any) => ({
      ...provided,
      minHeight: "1px",
      textAlign: "left",
      height: "44px",
      color: "rgb(163, 138, 255)",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      color: "rgb(163, 138, 255)",
      textAlign: "left",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "rgb(163, 138, 255)",
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: "19px",
    }),
    menuList: (provided: any) => ({
      ...provided,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "rgba(163, 138, 255, 0.4)",
      textAlign: "left",
    }),
  };

  return (
    <div>
      <div className="question-format-form">
        {options.length > 0 && (
          <Select
            name={`${question.id}`}
            required
            options={options || []}
            defaultValue={defaultValue}
            isSearchable={true}
            styles={{ ...customStyles }}
            placeholder="Buscar o Seleccionar"
            noOptionsMessage={() => "No hay opciones que coincidan"}
            isDisabled={serverError}
            onChange={(e: any) => {
              if (question.question.toLowerCase().includes("apoderado")) {
                window.sessionStorage.setItem("currentLogo", e.label);
              }
              onChange({
                [sectionId]: {
                  ...currentSectionAnswers,
                  [question.id]: {
                    question: question.question,
                    answer: e,
                  },
                },
              });
            }}
          />
        )}
      </div>
      {serverError && (
        <div>
          <div className="red margin-v-l">
            *Ha habido un error al obtener las opciones
          </div>
          <XsButton
            label={"Reintentar"}
            onClick={() => getAndSetOoptionsFromServer()}
            className="center"
          />
        </div>
      )}
    </div>
  );
};

export default SearchServerOptions;
