import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { colorPallette, gradients } from "../../Common/theme";
import Logo from "../../uiComponents/Logo/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudArrowUp,
  faSignature,
  faWandSparkles,
  faChartLine,
  faArrowRight,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import pattern from "../../Assets/svg/pattern.svg";
import { useHistory } from "react-router-dom";

const mainFeatures = [
  { label: "Automatizador de Documentos", icon: faWandSparkles },
  { label: "Firma Electrónica", icon: faSignature },
  { label: "Colaboración en la Nube", icon: faCloudArrowUp },
  { label: "Gestión y Vencimientos", icon: faChartLine },
];

const HomeTop: React.FC = () => {
  const history = useHistory();

  return (
    <Box
      sx={{
        background: gradients.dark,
        display: "flex",
        justifyContent: "center",
        position: "relative",
        pb: { xs: 10, sm: 20, md: 15, lg: 10 },
      }}
    >
      <Box
        component={"img"}
        sx={{
          width: "1800px",
          opacity: 0.4,
          maxWidth: "100%",
          position: "absolute",
          display: { xs: "none", sm: "none", md: "block" },
          filter: "invert(1)",
          top: {
            xl: 0,
            md: "calc(200px - (10vw))",
          },
        }}
        src={pattern}
        alt="chat icon"
        title="Patron 1"
      />

      <Box
        component={"img"}
        sx={{
          height: "100vw",
          maxWidth: { xs: "1000px", s: "800px" },
          objectFit: "cover",
          minWidth: "auto",
          opacity: 0.4,
          top: "calc(-70px - 10vw)",
          display: { xs: "block", sm: "block", md: "none" },
          position: "absolute",
          transform: { xs: "rotate(90deg)", sm: "rotate(90deg)", md: "none" },
          filter: "invert(1)",
        }}
        src={pattern}
        alt="chat icon"
        title="Patron 2"
      />
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "white",
          width: "100%",
          height: { xs: "20%", sm: "30%" },
          bottom: 0,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          padding: { md: 4 },
          width: "1500px",
          maxWidth: "90vw",
          position: "relative",
        }}
      >
        <Logo width="240px" sx={{ my: { xs: 3, sm: 3, md: 5 } }} />
        <Typography
          variant="h1"
          sx={{
            mb: 2,
            mt: 4,
            color: colorPallette.dark,
            fontWeight: 900,
            fontSize: { xs: "2.1rem", md: "4.5rem" },
          }}
          className="roboto"
          mb={8}
        >
          El CLM más{" "}
          <span style={{ color: colorPallette.primary }}> Potente</span>
        </Typography>
        <Typography
          variant="h1"
          sx={{
            mb: { xs: "50px", sm: "20px" },
            color: colorPallette.dark,
            fontWeight: 900,
            fontSize: { xs: "1.4rem", md: "2.5rem" },
          }}
          className="roboto"
        >
          <span style={{ color: colorPallette.white }}>Gestiona y firma</span>{" "}
          tus contratos desde una sola plataforma
        </Typography>
        <Box
          sx={{
            mb: "40px",
            color: colorPallette.dark,
            fontSize: "1.3rem",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
            rowGap: 2,
            textAlign: "left",
            width: {
              sm: "280px",
              md: "auto",
            },
          }}
        >
          {mainFeatures.map((feature, index) => (
            <Box
              key={index}
              sx={{
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  color: "white",
                  fontSize: "1rem",
                  px: 2,
                }}
              >
                <FontAwesomeIcon
                  icon={feature.icon}
                  style={{
                    marginRight: "10px",
                    color: colorPallette.primary,
                  }}
                />
                {feature.label}
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: { xs: 1, md: 2 },
            mb: 3,
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              history.push("/contacto");
            }}
            sx={{
              width: "180px",
              height: "50px",
            }}
          >
            Solicitar Demo{" "}
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{
                marginLeft: 8,
                color: colorPallette.white,
                fontSize: 12,
              }}
            />
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              history.push("/productTour");
            }}
            sx={{
              width: "180px",
              "&:hover": {
                color: "#A8BBFD",
              },
            }}
          >
            Product Tour
            <FontAwesomeIcon
              icon={faBolt}
              style={{
                marginLeft: 8,
                color: colorPallette.white,
                fontSize: 12,
              }}
            />
          </Button>
        </Box>
        <Box
          component="img"
          src={process.env.PUBLIC_URL + "/images/screens-home1.png"}
          className="por-que-automation"
          alt="Product Image"
          title="Imagen del producto 1"
          sx={{
            width: "100%",
            maxWidth: "1600px",
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
          }}
        />
        <Box
          component="img"
          src={process.env.PUBLIC_URL + "/images/document-creator1.png"}
          className="por-que-automation"
          alt="Product Image"
          title="Imagen del producto 2"
          sx={{
            width: "100%",
            maxWidth: "600px",
            minWidth: "375px",
            display: { lg: "none" },
          }}
        />
      </Box>
    </Box>
  );
};

export default HomeTop;
