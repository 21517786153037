import React from "react";
import { faHourglass, faClockFour } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Entries.styles.css";

type Props = {
  date: string | number;
  onClick?: () => void;
  fallback?: string;
  color?: string;
  hideTime?: boolean;
};

const TableDate: React.FC<Props> = ({
  date,
  onClick,
  fallback,
  color,
  hideTime,
}) => {
  if (date === null || !date) {
    return (
      <>
        {fallback && (
          <span style={{ opacity: 0.6 }} className="grey">
            {fallback}
          </span>
        )}
      </>
    );
  }

  const dateToParse = typeof date === "string" ? parseInt(date) : date;

  if (isNaN(dateToParse)) {
    return (
      <>
        {fallback && (
          <span style={{ opacity: 0.6 }} className="grey">
            {fallback}
          </span>
        )}
      </>
    );
  }

  const parsedDate = new Date(dateToParse);

  const displayDate = `${parsedDate.getDate()} /  ${
    parsedDate.getMonth() + 1
  } / ${parsedDate.getFullYear()}`;

  const hours = parsedDate.getHours();

  const minutes = parsedDate.getMinutes();

  const minutesWithZero =
    minutes.toString().length < 2 ? `0${minutes}` : minutes;

  return (
    <div className="relative">
      <div onClick={onClick && onClick}>
        <div className={`table-entry-date ${color ? color : "b-primary"}`}>
          {displayDate}
        </div>
      </div>
      {!hideTime && (
        <p className="table-entry-date-time">
          <FontAwesomeIcon icon={faClockFour} className="primary" /> {hours}:
          {minutesWithZero} hs
        </p>
      )}
    </div>
  );
};

export default TableDate;
