import React from "react";
import MaterialReactTable, { MRT_Icons } from "material-react-table";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import "./AndesTable.styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faPlay, faPlus } from "@fortawesome/free-solid-svg-icons";

const headStyle = {
  sx: {
    opacity: 1,
    height: 48,
    borderLeft: "2px solid #d7e7f8",
    backgroundColor: "transparent",
  },
};

const headCellStyle = {
  sx: {
    borderTop: "1px solid #d7e7f8",
    borderBottom: "1px solid #d7e7f8",
    backgroundColor: "#e8eeff",
    // fontWeight: 300,
    color: "#5b55a0",
    borderRight: "2px solid #d7e7f8",
    fontWeight: 700,
    // color: "white",
  },
};

const tableBodyStyle = {
  sx: {
    // top: 20,
    marginTop: 20,
  },
};

const tableBodyRowStyle = {
  hover: true,
  sx: {
    backgroundColor: "#fff",
    height: 80,
    // borderLeft: "6px solid #d7e7f8",
    // borderRight: "6px solid #d7e7f8",
    borderRadius: "4px",
  },
};

const tableCellStyle = {
  sx: {
    borderBottom: "1px solid #d7e7f8",
    textWrap: "no-wrap",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: "#5b55a0",
    fontSize: "0.85rem",
  },
};

const bottomToolbarStyle = {
  sx: {
    // backgroundColor: "#e8eeff",
    borderBottom: "2px solid #d7e7f8",
    borderTop: "1px solid #d7e7f8",
    color: "#5b55a0",
  },
};

const topToolbarStyle = {
  sx: {
    backgroundColor: "transparent",
    height: "100px  ",
  },
};

const muiTableHeadRowStyle = {
  sx: {
    backgroundColor: "transparent",
  },
};

const tablePaperStyle = {
  sx: {
    background: "none",
    boxShadow: "none",
    position: "relative",
    top: -80,
    maxWidth: "100%",
  },
};

type Props = {
  documents: any[];
  columns: any;
  initialSort?: any;
  hideSearch?: boolean;
};

export const renderHeader: React.FC<any> = (title) => {
  return (
    <div className="roboto">
      <FontAwesomeIcon className="primary icon-margin-r" icon={faPlay} />{" "}
      {title}
      {"   "}
    </div>
  );
};

const AndesTable: React.FC<Props> = ({
  documents,
  columns,
  initialSort,
  hideSearch,
}) => {
  const fontAwesomeIcons: Partial<MRT_Icons> = {
    ArrowDownwardIcon: (props: any) => (
      <FontAwesomeIcon icon={faArrowDown} {...props} />
    ),
    MoreVertIcon: () => (
      <FontAwesomeIcon className="andes-table-icon" icon={faPlus} />
    ),
  };

  return (
    <MaterialReactTable
      data={documents}
      columns={columns}
      // enablePagination={false}
      // enableBottomToolbar={false}
      muiTableHeadProps={{ ...headStyle }}
      muiTableHeadCellProps={{ ...headCellStyle }}
      muiTableBodyProps={{
        ...tableBodyStyle,
      }}
      muiTableBodyRowProps={{
        ...tableBodyRowStyle,
      }}
      muiTableBodyCellProps={{ ...tableCellStyle }}
      localization={MRT_Localization_ES}
      enableFullScreenToggle={false}
      enableColumnFilters={false}
      enableColumnFilterModes={false}
      enableDensityToggle={false}
      enableHiding={false}
      enableTopToolbar={hideSearch ? false : true}
      // globalFilterFn="contains" // this is better search function but somehow it is breaking code in click apparts environment
      muiTablePaperProps={{ ...tablePaperStyle }}
      muiTopToolbarProps={{ ...topToolbarStyle }}
      muiTableHeadRowProps={{ ...muiTableHeadRowStyle }}
      initialState={{
        showGlobalFilter: true, //show the global filter by default
        sorting: initialSort ? initialSort : [],
        pagination: { pageSize: 25, pageIndex: 0 },
      }}
      icons={fontAwesomeIcons}
      muiTableProps={{
        sx: {
          tableLayout: "fixed",
        },
      }}
      muiBottomToolbarProps={{ ...bottomToolbarStyle }}
      muiTablePaginationProps={{
        sx: { color: "#5b55a0" },
      }}
    />
  );
};

export default AndesTable;
