import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "../../screens/LoadingScreen";
import { getOrCreateUser } from "../../Utils/getMasterData";

const LoginRedirect = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  let history = useHistory();

  const userCreationOrGet = async () => {
    const accesToken = await getAccessTokenSilently();
    await getOrCreateUser(accesToken, user);
  };

  const handleRedirect = async () => {
    try {
      if (isAuthenticated && user && user.email_verified) {
        await userCreationOrGet();
        history.push("/dashboard/profile");
      } else if (isAuthenticated && user && user.email_verified === false) {
        await userCreationOrGet();
        history.push("/email_verification");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleRedirect();
  }, [isAuthenticated, user]);

  return <LoadingScreen />;
};

export default LoginRedirect;
