import React from "react";

import * as DATA from '../data/questionsExporter';

const DataDownloader = () => {
  var stockData = [
    {
      Symbol: "AAPL",
      Company: "Apple Inc.",
      Price: "132.54",
    },
    {
      Symbol: "INTC",
      Company: "Intel Corporation",
      Price: "33.45",
    },
    {
      Symbol: "GOOG",
      Company: "Google Inc",
      Price: "554.52",
    },
  ];

  function downloadCSV(args) {
    var data, filename, link;
    var csv = "data:text/json;charset=utf-8," + JSON.stringify(DATA.contratoDeAlquier);
    filename =  "contratoDeAlquiler.json";
    data = encodeURI(csv);
    link = document.createElement("a");
    link.setAttribute("href", data);
    link.setAttribute("download", filename);
    link.click();
  }
  return (
    <div>
      <a onClick={downloadCSV}>Download data</a>
    </div>
  );
};

export default DataDownloader;
