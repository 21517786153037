import { Stack, Box, Button } from "@mui/material";
import React from "react";
import InputForm from "../../uiComponents/Form/FormInput";
import styles from "./QuestionGeneratorForm.module.css";
import FormSelect from "../../uiComponents/Form/FormSelect";
import { questionTypeOptions } from "../../Types/Question.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "../../uiComponents/DeleteIcon/DeleteIcon";
import { useFieldArray, useWatch } from "react-hook-form";
import DependencyForm from "./DependencyForm";
import { sections } from "../../Utils/DocumentGeneratorMethods";
import { useDispatch } from "react-redux";
import { toggleCardExpansion } from "../../redux/slices/commonComponentsSlice";
import { setAppError } from "../../Utils/modalMethods";

type Props = {
  id: number;
  control: any;
  removeQuestion: any;
  nextId?: string;
  index: number;
  insert: any;
  trigger: any;
};

const QuestionGeneratorForm: React.FC<Props> = ({
  control,
  id,
  removeQuestion,
  index,
  insert,
  trigger,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `questions[${id}].dependencies`,
  });

  const watch = useWatch({ control, name: `questions` });

  const numberOFWatch = watch[id]?.type === "numberOf";
  //const searchServerOptionsWatch =
  //  watch[id]?.type === "searchServerOptions";
  const optionsWatch = watch[id]?.type === "options";
  const advancedOptionsWatch = watch[id]?.type === "advancedOptions";
  const multipleOptionsWatch = watch[id]?.type === "multipleOptions";
  const sheetsDataBaseWatch = watch[id]?.type === "sheetsDataBase";
  const sectionWatch = watch[id]?.section;

  const dispatch = useDispatch();

  const handleToggleExpansion = async () => {
    const isValid = await trigger();
    if (isValid) dispatch(toggleCardExpansion(id));
    else {
      setAppError("Error", "Por favor complete los campos requeridos");
    }
  };

  const handleAddQuestion = async () => {
    const isValid = await trigger();
    if (!isValid) {
      setAppError("Error", "Por favor complete los campos requeridos");
      return;
    }
    const currentSection = sectionWatch;
    insert(index + 1, {
      dependencies: [],
      type: "question",
      question: "",
      section: currentSection,
      id: "",
    });
    setTimeout(() => {
      dispatch(toggleCardExpansion(id + 1));
    }, 100);
  };

  return (
    <>
      {index === 0 && (
        <Box
          component={"button"}
          mb={5}
          sx={{
            cursor: "pointer",
            background: "none",
            border: "none",
            "&:hover .hoverClassNmae": {
              opacity: 0.9,
              transform: "scale(1.3) rotate(90deg)",
              transition: "all 0.3s",
            },
          }}
          role="button"
          tabIndex={0}
          onClick={() => {
            insert(index + 0, {
              dependencies: [],
              type: "question",
              question: "",
              section: sectionWatch,
              id: "",
            });
          }}
        >
          <FontAwesomeIcon className="hoverClassNmae" icon={faPlusCircle} />
        </Box>
      )}
      <Box
        className={styles.card}
        sx={{
          animation: "scaleIn 0.5s ease-out",
          "@keyframes scaleIn": {
            "0%": {
              transform: "scale(0.97)",
              opacity: 0,
            },
            "100%": {
              transform: "scale(1)",
              opacity: 1,
            },
          },
        }}
        maxWidth="1000px"
        position="relative"
      >
        <Box
          position="absolute"
          right={24}
          top={24}
          sx={{ cursor: "pointer" }}
          className="primary"
          onClick={handleToggleExpansion}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </Box>

        <Stack alignItems="center">
          <InputForm
            control={control}
            name={`questions.${id}.id`}
            label="Campo identificador de la pregunta:"
            required
            info={["No debe incluir espacios, ej: nombreInquilino"]}
            rules={{
              validate: (value: string) =>
                !String(value).includes(" ") ||
                "No se permiten espacios en blanco",
            }}
          />
          <FormSelect
            options={sections.map((type: string) => ({
              value: type,
              label: `Sección ${type}`,
            }))}
            control={control}
            name={`questions.${id}.section`}
            label="¿A que sección pertenece la pregunta?"
            info={[
              "En la sección de la izquierda puede editar el nombre de las secciones",
            ]}
            required
          />

          <FormSelect
            options={questionTypeOptions}
            control={control}
            name={`questions.${id}.type`}
            label="¿Qué tipo de pregunta es?"
            required
          />
          <InputForm
            control={control}
            name={`questions.${id}.question`}
            label="¿Cuál es la pregunta?"
            required
          />
          <InputForm
            control={control}
            name={`questions.${id}.info`}
            label="¿Desea agregar una aclaración o información extra?"
            info={[
              'Separe las aclaraciones con ";"',
              // "En el formulario se mostraran como esta lista de lamparitas.",
            ]}
            maxLength={2000}
          />
          {/* <InputForm
            control={control}
            name={`questions.${id}.fieldType`}
            label="Nombre del campo en la base de datos"
            info={[
              'Separe las aclaraciones con ";"',
              "En el formulario se mostraran como esta lista de lamparitas.",
            ]}
          /> */}
          {numberOFWatch && (
            <InputForm
              control={control}
              name={`questions.${id}.fixedValue`}
              label="Escriba el concepto en plural (ej: meses)"
              required
            />
          )}
          {numberOFWatch && (
            <InputForm
              control={control}
              name={`questions.${id}.fixedValueSingular`}
              label="Escriba el concepto en singular (ej: mes)"
              required
            />
          )}
          {sheetsDataBaseWatch && (
            <InputForm
              control={control}
              name={`questions.${id}.sheetId`}
              label="Cuál es el sheetId de la base de datos?"
              required
            />
          )}

          {(advancedOptionsWatch || optionsWatch || multipleOptionsWatch) && (
            <InputForm
              control={control}
              name={`questions.${id}.options`}
              label="¿Cuáles son las opciones?"
              required
              info={[
                "Escriba las opciones separadas por un punto y coma y sin espacios entre si.",
                "EJ: lunes;martes;miercoles;jueves;viernes",
              ]}
              maxLength={10000}
            />
          )}
          {fields.map((field, index) => (
            <DependencyForm
              control={control}
              id={index}
              key={field.id}
              remove={remove}
              watch={watch}
              questionId={id}
            />
          ))}
          <Button
            onClick={() => {
              append({});
            }}
            sx={{ marginTop: 1, width: 300, color: "#5b55a0" }}
            // variant="contained"
          >
            <FontAwesomeIcon icon={faPlus} className="primary mr1" />
            Agregar una dependencia
          </Button>
          <DeleteIcon
            label="Eliminar"
            sx={{ alignSelf: "end" }}
            onClick={() => {
              removeQuestion(id);
              dispatch(toggleCardExpansion(null));
            }}
          />
        </Stack>
      </Box>
      <Box
        component={"button"}
        mb={5}
        sx={{
          cursor: "pointer",
          background: "none",
          border: "none",
          "&:hover .hoverClassNmae": {
            opacity: 0.9,
            transform: "scale(1.3) rotate(90deg)",
            transition: "all 0.3s",
          },
        }}
        role="button"
        tabIndex={0}
        onClick={handleAddQuestion}
      >
        <FontAwesomeIcon className="hoverClassNmae" icon={faPlusCircle} />
      </Box>
    </>
  );
};

export default QuestionGeneratorForm;
