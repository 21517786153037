import React from "react";
import "./styles.css";

type Props = {
  children?: any;
};

const Modal: React.FC<Props> = ({ children }) => {
  return (
    <div className="ui-modal-background">
      <div className="ui-modal-background-children">{children && children}</div>
    </div>
  );
};

export default Modal;
