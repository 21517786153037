import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
  faHourglass2,
  faPlus,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";
import "./TableEntries.styles.css";
import { toggleDocumentStatus } from "../../../Utils/commonTableActions";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

type Props = {
  status: string;
  approvedBy: string;
  document: any;
};

const ApprovalActions: React.FC<any> = ({ document }) => {
  const [showActions, setShowActions] = useState<Boolean>(false);
  const { user } = useSelector((state: RootState) => state.masterData);
  const { getAccessTokenSilently } = useAuth0();

  const handleToggle = async (status: string) => {
    const accessToken = await getAccessTokenSilently();

    let name = "";

    if (user?.name) {
      name = user.name;
    }

    await toggleDocumentStatus(document, accessToken, name, status);
    setShowActions(false);
  };

  if (user?.role === "admin" || user?.role === "Admin") {
    return (
      <>
        {showActions ? (
          <div className="table-approve-icon-box b-secondary">
            <FontAwesomeIcon
              icon={faThumbsUp}
              className="white b-primary table-approve-icon"
              onClick={() => {
                handleToggle("approved");
              }}
            />
            <FontAwesomeIcon
              icon={faThumbsDown}
              className="white b-light-red table-approve-icon"
              onClick={() => {
                handleToggle("disapproved");
              }}
            />
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="light-red table-approve-icon"
              onClick={() => setShowActions(false)}
            />
          </div>
        ) : (
          <div
            className="table-approve-icon-box-closed b-secondary"
            onClick={() => {
              setShowActions(true);
            }}
          >
            <FontAwesomeIcon icon={faPlus} className="table-plus-icon" />
          </div>
        )}
      </>
    );
  }
  return <></>;
};

const TableEntryAproval: React.FC<Props> = ({
  status,
  approvedBy,
  document,
}) => {
  if (status === "approved")
    return (
      <div className="table-green bold table-entry-aproval">
        <FontAwesomeIcon icon={faCheckCircle} className="icon-margin-r" />
        Aprobado
        {approvedBy && (
          <div className="table-entry-aproval-by">Por: {approvedBy}</div>
        )}
        <ApprovalActions document={document} />
      </div>
    );
  if (status === "disapproved")
    return (
      <div className="light-red bold table-entry-aproval">
        <FontAwesomeIcon icon={faTimesCircle} className="icon-margin-r" />
        Desaprobado
        {approvedBy && (
          <div className="table-entry-aproval-by">Por: {approvedBy}</div>
        )}
        <ApprovalActions document={document} />
      </div>
    );

  return (
    <div className="table-entry-aproval" style={{ color: "rgba(0,0,0,0.2)" }}>
      <FontAwesomeIcon icon={faHourglass2} className="icon-margin-r" />
      Sin Aprobar
      <ApprovalActions document={document} />
    </div>
  );
};

export default TableEntryAproval;
