import { MODAL_SETTER, MODAL_RESETTER, MODAL_FULL_SETTER } from './modal.types';

export const setModal = (modal) => {
  return {
    type: MODAL_SETTER,
    payload: modal,
  };
};

export const resetModals = () => {
  return {
    type: MODAL_RESETTER,
  };
};
