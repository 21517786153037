import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
  catastro,
  searchServerOptions,
  documentName,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const TestSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Contrato de Préstamo de Dinero",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.",
      ],
    },
    questions: [
      {
        id: 1,
        question: "¿En qué Provincia se firma el Pagaré?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question: "¿En qué Ciudad/Localidad se firma el Pagaré?",
        info: "Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En qué fecha se firma el Pagaré?",
        type: date,
      },
      {
        id: 4,
        question: "¿nombre del documento?",
        type: documentName,
      },
    ],
  },
  {
    id: "section2",
    section: "Librador del Pagaré/ Deudor",
    title:
      "Indicar los datos del Librador del Pagaré. Es quién deberá pagar el Pagaré.",
    type: questions,
    explanation: {
      question: "¿Quién es el Librador del Pagaré?",
      answer: [
        "El Librador es la persona que firma el Pagaré y deberá pagarlo a su vencimiento",
      ],
    },
    questions: [
      {
        id: 1,
        question:
          "¿El Pagaré es corresponde a un servicio de Encomienda o Venta de Pasajes?",
        type: options,
        options: ["Servicio de Encomienda", "Venta de Pasajes"],
      },
      {
        id: 4,
        question: "Seleccionar la Agencia sujeta a deuda",
        type: searchServerOptions,

        sheetId: "1vpSYZZemMoW_IqYibUAox12HHx3d0fMic_5xR4UnHZQ",
        depend: {
          id: 1,
          answer: "Venta de Pasajes",
        },
      },
      {
        id: 5,
        question: "Seleccionar la Agencia sujeta a deuda",
        type: searchServerOptions,

        sheetId: "1oenc8T2vRhdwgZDpkr4GHqmeUl5MEVAlRFqS7ygmxtg",
        depend: {
          id: 1,
          answer: "Servicio de Encomienda",
        },
      },

      {
        id: 17,
        question: "Número de Teléfono:",
        type: question,
      },
    ],
  },

  {
    id: "section3",
    section: "Datos del Pagaré",
    title: "Detallar los aspectos principales del Pagaré.",
    type: questions,
    explanation: {
      question: "¿Qué es un Préstamo?",
      answer: [
        "Un préstamo es una operación financiera por la cual una persona (Acreedor) otorga mediante un contrato dinero (capital) a otra persona (Deudora), a cambio de un interés que pactan las partes.",
      ],
    },
    questions: [
      {
        id: 11,
        question: "Establecer el monto y moneda del Pagaré",
        answer: ["Determinar de cuanto dinero será el Pagaré."],
        type: money,
      },
      {
        id: 12,
        question: "¿En qué domicilio deberá pagarse el Pagaré?",
        answer: [
          "Determinar el lugar/domicilio donde se deberá pagar el Pagaré",
          "Una vez llegada la fecha de vencimiento del Pagaré, el Librador deberá pagar al Beneficiario en el lugar donde se establezca",
        ],
        type: question,
      },
      {
        id: 13,
        question: "Establecer el concepto del Pagaré",
        type: question,
      },
    ],
  },
];
export const Test3 = {
  name: "Pagaré",
  codeName: "Test3",
  question: "¿Qué es un Pagaré?",
  definition: ["Es un Título Valor como promesa incondicional de pago"],
  sections: TestSections,
  url: "test3",
  removeHeaderAndFooter: false,
};
