const emptyQuestionEraser = (answers, setAnswers, currentSection) => {
  if (sessionStorage.activeInputs) {
    const activeInputs = JSON.parse(sessionStorage.activeInputs) || [];
    const sectionName = `section${currentSection}`;
    const newAnswers = answers;
    let filteredAnswersObject = {};
    activeInputs.forEach((e) => {
      filteredAnswersObject[e] = newAnswers[sectionName][e];
    });
    newAnswers[sectionName] = filteredAnswersObject;
    setAnswers(newAnswers);
    sessionStorage.removeItem("activeInputs");
  }
};

export default emptyQuestionEraser;
