import { useEffect } from "react";
import Image from "../../uiComponents/Image";
import { Box } from "@mui/material";
import { cardStyle, colorPallette, gradients } from "../../Common/theme";
import pattern from "../../Assets/svg/pattern.svg";

import "./styles.css";

const PricingCard = ({ plan }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className="empresas-card" sx={cardStyle.dark}>
      {/* <i className='fas fa-bolt empresas-card-bolt secondary'></i> */}
      <Image name="AndesSquareWeb.png" className="empresas-card-logo" />
      <p className="empresas-card-titulo primary">{plan.title}</p>
      <Box
        mb={2}
        fontWeight={700}
        color={colorPallette.dark}
        fontSize={"1.2rem"}
      >
        Suscripción Mensual
      </Box>
      {plan.disclaimer && (
        <p className="empresas-card-disclaimer">{plan.disclaimer}</p>
      )}
      <Box
        className="empresas-card-box"
        sx={{ background: gradients.dark, color: colorPallette.dark }}
      >
        <Box color={colorPallette.primary}>Características</Box>
        {plan.caracterisiticas.map((caracteristicas) => {
          return (
            <p className="empresas-card-entry">
              <i className="fas fa-check secondary mr1"></i> {caracteristicas}
            </p>
          );
        })}
      </Box>
    </Box>
  );
};

const mensual = [
  {
    title: "Start Up",
    price: "Suscripción",
    monthly: true,
    disclaimer: "*Consultar precios",
    caracterisiticas: [
      "Hasta 3 plantillas propias ",
      "15 Firmas Electrónicas",
      "Hasta 3 usuarios",
      "Amacenamiento: 1gb",
    ],
  },
  {
    title: "Profesional",
    price: "Suscripción",
    monthly: true,
    disclaimer: "*Consultar precios",
    best: true,
    caracterisiticas: [
      "Hasta 7 plantillas propias ",
      "30 Firmas Electrónicas",
      "Hasta 8 usuarios",
      "Amacenamiento: 5gb",
    ],
  },
  {
    title: "Enterprise",
    price: "Suscripción",
    monthly: true,
    disclaimer: "*Consultar precios",
    caracterisiticas: [
      "Mas de 7 plantillas propias ",
      "100+ Firmas Electrónicas",
      "Usuarios ilimitados",
      "Amacenamiento: 20gb",
    ],
  },
];

const Empresas = () => {
  return (
    <Box
      className="empresas"
      sx={{
        background: gradients.dark,
        height: "100%",
        minHeight: "calc(100vh - 60px)",
      }}
    >
      <Box
        component={"img"}
        sx={{
          width: "1800px",
          opacity: 0.4,
          maxWidth: "100%",
          position: "absolute",
          display: { xs: "none", sm: "none", md: "block" },
          filter: "invert(1)",
        }}
        src={pattern}
        alt="chat icon"
        title="Icono del chat"
      />
      <div className="empresas-text-box">
        <h1 style={{ color: colorPallette.white }}>Planes Premium</h1>
        <p className="intro">
          Elegí alguno de nuestros planes y accede a nuestra base de datos.
          Súmate a la comunidad de Andes Docs!
        </p>
      </div>
      <div className="empresas-card-grid">
        {mensual.map((plan) => {
          return <PricingCard plan={plan} />;
        })}
      </div>
    </Box>
  );
};

export default Empresas;
