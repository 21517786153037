import React from "react";
import { Box } from "@mui/material";

interface LogoProps {
  width?: string;
  sx?: any;
}

const Logo: React.FC<LogoProps> = ({ width = "300px", sx = {} }) => {
  return (
    <Box
      component="img"
      src={`${process.env.PUBLIC_URL}/images/andesdocs.png`}
      alt="logo-andes"
      title="Logo Andes Docs"
      sx={{
        width: width,
        cursor: "pointer",
        ...sx,
      }}
    />
  );
};

export default Logo;
