import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box } from "@mui/material";
import React from "react";

type Props = {
  label?: string;
  onClick?: () => void;
  sx?: any;
};

const DeleteIcon: React.FC<Props> = ({ label, onClick, sx }) => {
  return (
    <Box
      sx={{
        ...sx,
        cursor: "pointer",
        transition: "all 0.1s",
        fontWeight: 500,
        fontSize: 14,
        fontFamily: "Roboto",
        letterSpacing: 0.3,
        "&:hover": {
          transform: "scale(0.96)",
        },
      }}
      onClick={onClick && onClick}
    >
      <FontAwesomeIcon
        icon={faTrashCan}
        className="light-red"
        style={{ fontSize: "1rem", marginRight: "4px" }}
      />{" "}
      {label && label}
    </Box>
  );
};

export default DeleteIcon;
