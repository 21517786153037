import React, { useEffect } from "react";
import DocumentCard from "./DocumentCard/DocumentCard";
import Image from "../../uiComponents/Image";

import { documents } from "./data";

import "./styles.css";

const price = "$4.999";

const DocumentSelector = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="document-selector b-tertiary">
      <div className="document-selector-text-box">
        <h1 className="document-selector-title roboto">
          Biblioteca Andes Docs
        </h1>
        <p className="document-selector-intro center">
          Elegí que <span className="bold">documento</span> queres generar!
          Todos estan actualizados y creados por{" "}
          <span className="bold">profesionales</span>!
        </p>
        <Image name="pencil.png" className="document-selector-pencil" />
      </div>
      <div className="document-selector-grid center">
        {documents.map((doc, index) => {
          return (
            <DocumentCard
              title1={doc.title1}
              title2={doc.title2}
              to={doc.to}
              price={doc.price || price}
              index={index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DocumentSelector;
