import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { cardStyle, colorPallette, gradients } from "../../Common/theme";
import pattern from "../../Assets/svg/pattern.svg";
import AutomaticForm, { Field } from "../../uiComponents/Form/AutomaticForm";
import { useForm } from "react-hook-form";
import { APIPost } from "../../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { VideoTour } from "./VideoTour";
import { Image } from "@mui/icons-material";

export const ProductTour = () => {
  const videoTutorial = [
    {
      title: "Generación de Contrato en Andes Docs",
      icon: "file-pen",
      src: "https://www.loom.com/embed/99d75a5e6c00450eb122ec00cb78352d?sid=92fcf5d1-1724-4190-8cea-564157619af0",
      message: "En este video aprenderás a crear contratos automatizados:",
    },
  ];

  const { getAccessTokenSilently } = useAuth0();
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const fields: Field[] = [
    {
      name: "email",
      label: "Email",
      type: "email",
      required: true,
      validation: {
        pattern: {
          value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
          message: "Por favor ingresa un correo electrónico válido",
        },
      },
    },
  ];

  const onSubmit = async (data: { email: string }) => {
    setShowForm(false);
    setShowVideo(true);
    const params = {
      email: data.email,
    };

    const accessToken = await getAccessTokenSilently();
    APIPost("/product/send-email-tour", accessToken, params);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <Box
      sx={{
        background: gradients.dark,
        pt: 6,
        pb: 10,
        height: "100vh",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        component={"img"}
        sx={{
          width: "1800px",
          opacity: 0.4,
          maxWidth: "100%",
          position: "absolute",
          display: { xs: "none", sm: "none", md: "block" },
          filter: "invert(1)",
        }}
        src={pattern}
        alt="Patron Tour"
        title="Patron Tour"
      />
      <Stack width={"100%"} height={"100%"}>
        {showForm && (
          <Stack alignItems={"center"} pt={15}>
            <Typography
              variant="h3"
              fontWeight={"900"}
              style={{ color: colorPallette.white }}
            >
              Product Tour
            </Typography>
            <Box className="intro" px={2} pt={4}>
              Completa tu email y accede al video.
            </Box>
            <Box width={"300px"} pt={2}>
              <AutomaticForm control={control} fields={fields} />

              <Button
                onClick={() => handleSubmit(onSubmit)()}
                sx={{ mt: 2, width: "100%" }}
                variant="outlined"
                disabled={loading}
              >
                Ver Product Tour
              </Button>
            </Box>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleCloseSnackbar}
              message={
                <span style={{ display: "flex", alignItems: "center" }}>
                  Te estamos redireccionando al Tour
                  <CircularProgress
                    size={24}
                    color="inherit"
                    style={{ marginLeft: 8 }}
                  />
                </span>
              }
            />
          </Stack>
        )}
        {showVideo && (
          <>
            <Typography
              variant="h3"
              fontWeight={"900"}
              style={{ color: colorPallette.white }}
            >
              Product Tour
            </Typography>
            <Box className="intro" px={2} pt={4}>
              Conoce como podes optimizar tus procesos contratuales con Andes
              Docs.
            </Box>
            <Box
              className="center"
              sx={{
                maxWidth: "900px",
                width: "100%",
                height: { xs: "40vh", sm: "50vh", md: "60vh", lg: "70vh" },
                maxHeight: "70vh",
                mt: 5,
                borderRadius: "8px",

                pt: 4,
                ...cardStyle.dark,
              }}
            >
              <Image name="AndesSquareWeb.png" style={{ width: 120 }} />

              {videoTutorial.map((tutorial, index) => (
                <VideoTour key={index} src={tutorial.src} />
              ))}
            </Box>
          </>
        )}
      </Stack>
    </Box>
  );
};
