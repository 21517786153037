import { ndaDoc } from './NdaDoc';
import { ContratoDeServicioDoc } from './ContratoServicioDoc';
import { ContratoDeAlquilerDoc } from './ContratodeAlquilerDoc';
import { ContratoDeTrabajoAPlazoFijoDoc } from './ContratoTrabajoPlazoFijo';
import { ContratoDePrestamoDoc } from './ContratoDePrestamoDeDinero';
import { TestDoc } from './TestDoc';
import { TestDoc1 } from './TestDoc1';
import { TestDoc2 } from './TestDoc2';
import { TestDoc3 } from './TestDoc3';
import { TestDoc4 } from './TestDoc4';
import { TestDoc5 } from './TestDoc5';
import { TestDoc6 } from './TestDoc6';
import { TestDoc7 } from './TestDoc7';

import { ContratoDeAlquilerTemporalDoc } from './ContratoAlqTemporalDoc';
import { PagareDoc } from './PagareDoc';
import { ComodatoDoc } from './ComodatoDoc';
import { ReservaDeLocacionDoc } from './ReservadeLocacionDoc';
import { ReciboDoc } from './ReciboDoc';
import { ContratoDeAlquilerNormalDoc } from './ContratoAlquilerNormalDoc';

export const nda = ndaDoc;
export const contratoDeServicios = ContratoDeServicioDoc;
export const contratoDeAlquier = ContratoDeAlquilerDoc;
export const contratoDeTrabajo = ContratoDeTrabajoAPlazoFijoDoc;
export const contratoDePrestamo = ContratoDePrestamoDoc;
export const contratoDeAlquilerTemporal = ContratoDeAlquilerTemporalDoc;
export const Test = TestDoc;
export const Test1 = TestDoc1;
export const Test2 = TestDoc2;
export const Test3 = TestDoc3;
export const Test4 = TestDoc4;
export const Test5 = TestDoc5;
export const Test6 = TestDoc6;
export const Test7 = TestDoc7;
export const pagare = PagareDoc;
export const comodato = ComodatoDoc;
export const reservaDeLocacion = ReservaDeLocacionDoc;
export const recibo = ReciboDoc;
export const contratoDeAlquilerNormal = ContratoDeAlquilerNormalDoc;
