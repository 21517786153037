import { APIGetWithError, APIPostWithError } from "../Services/authenticated";

export const getAndSetSheetDataByCompanyId = async (
  sheetId: string,
  accessToken: string
) => {
  try {
    const company = await APIGetWithError(
      "/companies/get-company",
      accessToken
    );

    const data = await APIPostWithError(
      "/server-options/get-sheet-data",
      accessToken,
      { sheetId }
    );

    if (data?.options && company?.companyId) {
      const companyData = data.options.find(
        (opt: any) => String(opt.companyId) === String(company.companyId)
      );
      return companyData;
    } else {
      return null;
    }
  } catch {
    return null;
  }
};
