export const questions = "questions";
export const path = "path";
export const options = "options";
export const select = "select";
export const question = "question";
export const date = "date";
export const dateWithoutSpace = "dateWithoutSpace";
export const number = "number";
export const numberOf = "numberOf";
export const person = "person";
export const pathQuestion = "pathQuestion";
export const multipleOptions = "multipleOptions";
export const textarea = "textarea";
export const money = "money";
export const pesos = "pesos";
export const catastro = "catastro";
export const percentage = "percentage";
export const moneyAndDates = "moneyAndDates";
export const moneyAndDatesWithIVA = "moneyAndDatesWithIVA";
export const searchServerOptions = "searchServerOptions";
export const advancedOptions = "advancedOptions";
export const documentName = "documentName";
