import React from "react";
import { Box, styled, keyframes } from "@mui/material";

interface CompaniesGridProps {
  urls: string[];
}

const scroll = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const CarouselContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  overflow: "hidden",
  width: "100%",
  maxWidth: "1400px",
  height: 100,
  whiteSpace: "nowrap",
  marginTop: "50px",
  position: "relative",
  left: "50%",
  transform: "translateX(-50%)",
  "& > div": {
    display: "flex",
    animation: `${scroll} 140s linear infinite`,
    [theme.breakpoints.down("sm")]: {
      animationDuration: "40s",
    },
    [theme.breakpoints.down("xs")]: {
      animationDuration: "40s",
    },
    "&:hover": {
      animationPlayState: "paused", // Pause animation on hover
    },
  },
}));

const LogoBox = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  overflow: "hidden",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 100,
  width: 250,
  position: "relative",
  marginRight: theme.spacing(2),
}));

const CompaniesGrid: React.FC<CompaniesGridProps> = ({ urls }) => {
  return (
    <CarouselContainer>
      <Box>
        {[...urls, ...urls].map((url, index) => (
          <LogoBox key={index}>
            <img
              src={url}
              alt={`Company Logo ${index + 1}`}
              title="Logo de la empresa"
              style={{
                width: "100%",
                borderRadius: "4px",
                transform: "scale(0.5)",
                filter: "grayscale(20%)",
                opacity: 0.9,
              }}
            />
          </LogoBox>
        ))}
      </Box>
    </CarouselContainer>
  );
};

export default CompaniesGrid;
