import React, { useEffect, useState } from "react";
import Select from "react-select";
import BigAdvancedOptions from "./BigAdvancedOptions";
import "../styles.css";

const AdvancedOptions: React.FC<any> = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  const [defaultValue, setDefaultValue] = useState(undefined);

  const findDefaultValue = (opt: any) => {
    const value = opt.find((option: any) => option.value === currentAnswer);
    setDefaultValue(value);
  };

  useEffect(() => {
    findDefaultValue(question.options);
  }, []);

  const getBackggroundColor = (state: any) => {
    if (state.isSelected) return "#a8bbfd";
    if (state.isFocused) return "#e8eeff";
    return "white";
  };

  const getColor = (state: any) => {
    if (state.isSelected) return "white";
    if (state.isFocused) return "#818181";
    return "rgb(163, 138, 255)";
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      color: getColor(state),
      backgroundColor: getBackggroundColor(state),
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      width: "100%",
      height: "44px",
      border: "none",
      marginBottom: 20,
      marginTop: 20,
      color: "rgb(163, 138, 255)",
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      border: "solid 1px rgb(163, 138, 255)",
    }),
    input: (provided: any) => ({
      ...provided,
      minHeight: "1px",
      textAlign: "left",
      height: "44px",
      color: "rgb(163, 138, 255)",
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      color: "rgb(163, 138, 255)",
      textAlign: "left",
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: "rgb(163, 138, 255)",
    }),
    menu: (provided: any) => ({
      ...provided,
      marginTop: "19px",
    }),
    menuList: (provided: any) => ({
      ...provided,
    }),
    placeholder: (provided: any) => ({
      ...provided,
      color: "rgba(163, 138, 255, 0.4)",
      textAlign: "left",
    }),
  };

  if (question.options.length < 11) {
    return (
      <BigAdvancedOptions
        question={question}
        onChange={onChange}
        currentAnswer={currentAnswer}
        sectionId={sectionId}
        currentSectionAnswers={currentSectionAnswers}
      />
    );
  }

  return (
    <div>
      <div className="question-format-form">
        {question.options.length > 0 && (
          <Select
            name={`${question.id}`}
            required
            options={question.options || []}
            defaultValue={defaultValue}
            isSearchable={true}
            styles={{ ...customStyles }}
            placeholder="Buscar o Seleccionar"
            noOptionsMessage={() => "No hay opciones que coincidan"}
            onChange={(e: any) => {
              onChange({
                [sectionId]: {
                  ...currentSectionAnswers,
                  [question.id]: {
                    question: question.question,
                    answer: e.value,
                    label: e.label,
                    value: e.value,
                  },
                },
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default AdvancedOptions;
