import {
  AlignmentType,
  Footer,
  Header,
  ImageRun,
  PageNumber,
  Paragraph,
  TextRun,
} from "docx";

import soldatiConectaLogo from "../../Assets/CompanyLogos/soldati-conecta-logo.png";

export const styles_soldati_conecta = {
  companyId: ["12406"],
  companyName: "SoldatiConecta",
  font: "Arial",
  titleSize: 21,
  textSize: 18,
  lineSpacing: 310,
  marginRight: 880,
  marginLeft: 880,
  beforeParagraph: 300,
  footer: new Footer({
    children: [
      new Paragraph({
        spacing: {
          before: 300,
          after: 0,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "MERCADO RESIDENCIAL – EMPRENDIMIENTOS E INVERSIONES – MERCADO INTERNACIONAL",
            size: 13,
            font: "Arial",
          }),
        ],
      }),
      new Paragraph({
        spacing: {
          before: 60,
          after: 200,
        },
        alignment: AlignmentType.CENTER,
        children: [
          new TextRun({
            text: "soldati.com / documento creado en andesdocs.com",
            size: 15,
            font: "Arial",
          }),
        ],
      }),
      new Paragraph({
        alignment: AlignmentType.RIGHT,
        children: [
          new TextRun({
            children: [PageNumber.CURRENT],
          }),
        ],
      }),
    ],
  }),
  header: new Header({
    children: [
      new Paragraph({
        spacing: {
          before: 0,
          after: 550,
        },
        alignment: AlignmentType.RIGHT,
        children: [
          new ImageRun({
            data: soldatiConectaLogo,
            transformation: {
              width: 100.69,
              height: 43,
            },
          }),
        ],
      }),
    ],
  }),
};
