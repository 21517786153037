import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const ContratoDeServiciosSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Contrato",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.",
      ],
    },
    questions: [
      {
        id: 1,
        question: "¿En qué Provincia se celebra el Contrato?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question: "¿En que ciudad se celebra el Acuerdo?",
        info:
          "Si arriba elegistes Ciudad de Buenos Aires, respondé lo mismo en esta pregunta.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se celebra el Contrato?",
        type: date,
      },
    ],
  },
  {
    id: "section2",
    section: "Contratante",
    title:
      "Indicar los datos de la persona que encargará la actividad detallada en el Contrato.",
    type: person,
    explanation: {
      question: "¿Quién es la Contratante del Servicio?",
      answer: [
        "Contratante es quien encargará la actividad a otra persona o entidad para que realice el Servicio que se detalla en el Contrato.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 4,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 5,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 6,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 7,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 8,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 9,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 10,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 11,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 12,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 13,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section3",
    section: "Prestadora",
    title:
      "Indicar los datos de la Prestadora que realizará la actividad detallada en el Contrato.",
    type: person,
    explanation: {
      question: "¿Quién es la Prestadora del Contrato?",
      answer: [
        "La Prestadora es quien realizará la actividad encargada para la Contratante.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person2",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 14,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 15,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 16,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 17,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 18,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 19,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 20,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 21,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 22,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 23,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section4",
    section: "Servicio y Duración",
    title: "Detalle del Servicio y su Duración",
    type: questions,
    explanation: {
      question: "¿Qué es Servicio y Duración?",
      answer: [
        "El Servicio será la actividad que la Prestadora deberá realizar por encargo de la Contratante. ",
        "Por ej.: Representación legal ante juicio, contratación de arquitecto para la realización de planos, etc. ",
        "La Duración será el plazo en el cúal deberá realizarse el Servicio establecido en el Contrato. ",
        "Por ej: 24 meses, 12 meses, 1 mes.",
      ],
    },
    questions: [
      {
        id: 24,
        question:
          "¿Cuál es la descripción completa y precisa del Servicio que prestará la Prestadora? Detallar.",
        answer: [
          "El Servicio será la actividad que la Prestadora deberá realizar por encargo de la Contratante.",
          "Por ej.: Representación legal ante juicio, contratación de arquitecto para la realización de planos, etc.",
        ],
        type: question,
      },
      {
        id: 25,
        question: "¿Por cuántos meses deberá ser prestado el Servicio?",
        type: numberOf,
        fixedValue: "meses",
        fixedValueSingular: "mes",
      },
    ],
  },
  {
    id: "section5",
    section: "Precio y Forma de Pago",
    title: "Precio y Forma de Pago por el Servicio prestado",
    type: questions,
    explanation: {
      question: "¿Qué es el Precio y Forma de Pago?",
      answer: [
        "El Precio que definiremos es el precio del Servicio que se va a contratar.",
        "La Forma de Pago es la modalidad con la cúal se abonará el Precio.",
      ],
    },
    questions: [
      {
        question:
          "¿Desea agregar el tiempo de realización, el precio y la forma de pago al Contrato?",
        id: 26,
        answer: [
          'Las Partes suelen firmar un Presupuesto donde se detallan estos conceptos. Si esto es así, podés elegir la opción "No" y agregar una copia del presupuesto al final del contrato.',
        ],
        type: options,
        options: [
          "Si, (recomendado)",
          "No, ya firmamos un Presupuesto y lo agregaremos",
        ],
      },
      {
        id: 28,
        question:
          "¿Cúanto será el importe total que deberá abonar el Contratante por el Servicio? Indicar el monto total del Precio",
        answer: [
          "Indicar el monto total del Precio. Si el pago es mensual, detallar la totalidad.",
          "Por ej: En caso de ser 12 pagos de $10.000, poner $120.000",
        ],
        type: money,
        depend: {
          id: 26,
          answer: "Si, (recomendado)",
        },
      },
      {
        id: 29,
        question: "¿Se deberá sumar el IVA al Precio que se detalló?",
        answer: [
          "Si: Si desea discriminar IVA y sumarlo al Precio elija esa opción. Permitirá que la Parte que facture el Servicio adicione el IVA correspondiente al Precio.",
          "No: No se incoporará el IVA al Precio de Servicio. La Parte que facture el Servicio no podrá adicionar ni tomarse el IVA de dicha actividad.",
        ],
        type: options,
        options: ["Si", "No"],
        depend: {
          id: 26,
          answer: "Si, (recomendado)",
        },
      },
      {
        id: 30,
        question: "Forma de Pago ¿De qué manera se pagará el Precio?",
        answer: [
          "Elegir la manera en que se pagará el Precio.",
          "Un único pago al finalizar el Servicio: Se contemplará el pago de la totalidad del Precio al finalizar el Contrato",
          "Pagos Mensuales: Automáticamente se dividirá el Precio en la cantidad de meses que dure el Precio.",
          "Cronograma de Pagos: Podrás detallar la manera en que se deberá pagar el Precio del Contrato.",
        ],
        type: options,
        options: [
          "Un único pago al finalizar el Servicio",
          "Pagos Mensuales",
          "Cronograma de Pagos",
        ],
        depend: {
          id: 26,
          answer: "Si, (recomendado)",
        },
      },
      {
        id: 311,
        question: "Detallar el Cronograma de Pagos",
        answer: [
          "Favor de detallar la manera en que se deberá pagar el Precio del Contrato.",
        ],
        type: question,
        doubleDepend: [
          {
            id: 26,
            answer: "Si, (recomendado)",
          },
          {
            id: 30,
            answer: "Cronograma de Pagos",
          },
        ],
      },
    ],
  },

  {
    id: "section6",
    section: "Condiciones Especiales",
    title: "Exclusividad y Jurisdicción",
    type: questions,
    explanation: {
      question: "¿Que vamos a definir?",
      answer: ["Vamos a definir la Exclusividad y Jurisdicción"],
    },
    questions: [
      {
        id: 31,
        question: "¿Desea establecer exclusividad con la Prestadora?",
        answer: [
          "Si las Partes lo desean pueden establecer una exclusividad por la cual la Prestadora no podrá prestar el mismo Servicio a otras personas.",
        ],
        type: options,
        options: ["Si", "No"],
      },
      {
        id: 32,
        question:
          "En caso de conflicto ¿En qué jurisdicción se deberá resolver?",
        answer: [
          "En caso de conflicto , las Partes deberán resolvlo ante un tribunal. Definir en que provincia estará ese tribunal. (Se suele usar la misma provincia donde se celebra el contratos)",
        ],
        type: options,
        options: provincias,
      },
    ],
  },
  {
    id: "section7",
    section: "Multa",
    title: "Multa en caso de incumplimiento",
    type: questions,
    explanation: {
      question: "Que multa debo establecer?",
      answer: [
        "En caso de incumplimiento se puede establecer una multa en dinero como sanción. En caso que no se quiera incorporar una Multa, el Acuerdo preevé la indeminzación establecida por ley para este tipo de casos.",
      ],
    },
    questions: [
      {
        question:
          "En caso de incumplimiento se podrá establecer una Multa dineriaria por día ¿Desea incorporarla?",
        id: 33,
        type: options,
        options: ["Si", "No"],
      },
      {
        id: 34,
        question:
          "¿Qué Multa en dinero se quiere imponer? Recuerde que la misma se aplicará por día.",
        info:
          "El monto total de la multa resultará de multiplicar los días en falta por el monto que pongas a continuación.",
        type: money,
        depend: {
          id: 33,
          answer: "Si",
        },
      },
    ],
  },
];

export const ContratoDeServicios = {
  name: "Contrato de Servicios",
  codeName: "contratoDeServicios",
  question: "¿Qué es un Contrato de Servicios?",
  definition: [
    "Es el contrato mediante el cual una persona, llamada el prestador de servicios, se obliga con respecto a otra, llamada Contratante, a realizar una serie de servicios a cambio de un precio. El pago del contrato es dirigido al cumplimiento de metas, horas, objetivos, proyectos, etc.",
    "Hay contrato de servicios cuando la obligación de hacer consiste en realizar cierta actividad independiente de su eficacia o resultado (por ejemplo: asesoramiento profesional, contratar un arquitecto, un abogado).",
  ],
  firmas: [
    [
      { id: 4, section: "section2" },
      { id: 9, section: "section2" },
    ],
    [
      { id: 14, section: "section3" },
      { id: 19, section: "section3" },
    ],
  ],
  price: 999.99,
  size: "10 Páginas",
  lastRevision: "Agosto 2021",
  sections: ContratoDeServiciosSections,
  url: "contrato-de-servicios",
  video: {
    image: "Servicios.jpg",
  },
  useCases: {
    good: [
      "Contratar los servicios de una agencia de marketing",
      "Contratar los servicios de un arquitecto para el desarrollo de un proyecto",
      "Contratar un asesosoría legal, contable, de consultoría",
    ],
    bad: [
      "Contratar un empleado en relación de dependencia (En blanco)",
      "Contratar una empresa constructora para que realize una obra determinada",
      "Contratar a alguien para que pinte una casa (resultado predeterminado)",
    ],
  },
};
