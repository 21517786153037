import React, { useEffect, useState } from "react";
import "../styles.css";
import "./styles.css";

const Card = ({ item, handleClick, preSetter, answersAreSet }) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    // this bit of code checks whether the item was already selected to mark it as so.
    if (!answersAreSet) {
      preSetter(setSelected, item);
    }
  }, [preSetter, item, answersAreSet]);
  return (
    <div
      onClick={() => {
        setSelected((prev) => !prev);
        handleClick(item);
      }}
      className={` multiple-options-option-card purple multiple-options-option-card-${selected}`}
    >
      {!selected ? (
        <i className="far fa-square multiple-options-square primary"></i>
      ) : (
        <i className="far fa-check-square multiple-options-check primary"></i>
      )}
      {item}
    </div>
  );
};

const MultipleOptions = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
}) => {
  const [answersAreSet, setAnswersAreSet] = useState(false);
  //this state checks wether answers are already set to previous answers or not
  const [answersArray, setAnswersArray] = useState(currentAnswer || []);
  useEffect(() => {
    if (!answersAreSet && !currentAnswer) {
      onChange({
        [sectionId]: {
          ...currentSectionAnswers,
          [question.id]: {
            question: question.question,
            answer: [],
          },
        },
      });
    }
  }, [currentSectionAnswers]);

  // this function will be passed to each option to check wether it was checked or not
  const preSetter = (func, item) => {
    if (!answersAreSet && currentAnswer && currentAnswer.includes(item)) {
      func(true);
    }
  };

  // this sets redux and session state answers
  const reduxAnswerSetter = (newArray) => {
    onChange({
      [sectionId]: {
        ...currentSectionAnswers,
        [question.id]: {
          question: question.question,
          answer: newArray,
        },
      },
    });
  };

  //this method handles clicks

  const handleClick = (newItem) => {
    setAnswersAreSet(true);
    const preHandleArray = currentAnswer || [];
    if (!answersArray.includes(newItem)) {
      reduxAnswerSetter([...preHandleArray, newItem]);
      setAnswersArray([...preHandleArray, newItem]);
    } else {
      const newArray = preHandleArray;
      const index = newArray.indexOf(newItem);
      if (index > -1) {
        newArray.splice(index, 1);
        reduxAnswerSetter([...newArray]);
        setAnswersArray([...newArray]);
      }
    }
  };
  return (
    <div>
      {question.options.map((e) => {
        return (
          <Card
            item={e}
            handleClick={handleClick}
            preSetter={preSetter}
            answersAreSet={answersAreSet}
            key={e}
          />
        );
      })}
    </div>
  );
};

export default MultipleOptions;
