import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Stack, Typography } from "@mui/material";
import { colorPallette } from "../../../Common/theme";

interface TutorialVideoProps {
  title?: string;
  icon?: any;
  src: string;
  message?: string;
}

export const TutorialVideo: React.FC<TutorialVideoProps> = ({
  title,
  icon,
  src,
  message,
}) => {
  return (
    <Stack flex={1} sx={{ alignItems: "flex-start" }}>
      <Typography variant="h6">
        <FontAwesomeIcon
          icon={faPlayCircle}
          style={{ color: colorPallette.primary }}
          className="mr1"
        />
        {title}
      </Typography>
      <Box mt={2}>{message}</Box>
      <Box
        sx={{
          position: "relative",
          paddingBottom: "64.94708994708994%",
          mt: 5,
          height: "100%",
          width: "100%",
        }}
      >
        <iframe
          src={src}
          allowFullScreen
          title="Tutorial Video"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        ></iframe>
      </Box>
    </Stack>
  );
};
