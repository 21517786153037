import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface EditorData {
  document: any;
  shouldESign: boolean;
}

const initialState: EditorData = {
  document: null,
  shouldESign: false,
};

export const editorDataSlice = createSlice({
  name: "editorData",
  initialState,
  reducers: {
    setEditorDocument: (state, action: PayloadAction<any>) => {
      state.document = action.payload;
    },
    setShouldESign: (state, action: PayloadAction<any>) => {
      state.shouldESign = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEditorDocument, setShouldESign } = editorDataSlice.actions;

export default editorDataSlice.reducer;
