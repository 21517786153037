import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

const SectionRedirect = () => {
  let history = useHistory();
  let { id } = useParams() || undefined;

  useEffect(() => {
    if (sessionStorage.sessionUrl && sessionStorage.currentSection && id) {
      const sessionCurrentSection = JSON.parse(sessionStorage.currentSection);
      const sessionUrl = sessionStorage.sessionUrl;
      const newSection = sessionCurrentSection + 1;
      if (sessionCurrentSection === parseInt(id)) {
        sessionStorage.setItem("currentSection", newSection);
        history.push(`/${sessionUrl}`);
      } else {
        history.push(`/${sessionUrl}`);
      }
      return;
    } else {
      history.push("/document-selector");
      return;
    }
  });
  return <div style={{ marginTop: 300 }}>Procesando Respuestas</div>;
};

export default SectionRedirect;
