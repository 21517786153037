import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogOutButton = ({ className = "" }) => {
  const { logout } = useAuth0();

  return (
    <div
      onClick={() => {
        logout();
      }}
      className={className}
    >
      Log Out
    </div>
  );
};

export default LogOutButton;
