import React, { useEffect, useState } from "react";
import IconButton from "../../../../uiComponents/IconButton/IconButton";
import { connect, useDispatch } from "react-redux";
import { setModal } from "../../../../redux/modal/modal.actions";

import "../styles.css";
import SignatureStatusTable from "../SignatureStatusTable";
import ESignatureTable from "../../../../components/Tables/ESignature/ESignatureTable.component";
import FoldersPage from "../../DocumentsManager/FoldersPage/FoldersPage";
import AllSignaturesPage from "../AllSignaturesPage/AllSignaturesPage";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../../../Utils/modalMethods";
import { APIGetWithError } from "../../../../Services/authenticated";
import { setElectronicSignatures } from "../../../../redux/slices/masterData";

const NewElectronicSignatureScreen = ({
  documents,
  setModal,
  setEsignDocumentData,
}) => {
  const [selectedPage, setSelectedPage] = useState(1);
  const { getAccessTokenSilently } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (sessionStorage.getItem("esignatureSection")) {
      const sessionPage = sessionStorage.getItem("esignatureSection");
      const parsedPage = JSON.parse(sessionPage);
      setSelectedPage(parsedPage);
    }
  }, []);

  const setSessionStoragePage = (page) => {
    window.sessionStorage.setItem("esignatureSection", page);
  };

  const getSignatures = async () => {
    const accessToken = await getAccessTokenSilently();

    try {
      setLoading(true);
      const res = await APIGetWithError(
        "/electronic-signature/get-all-signatures-view-only",
        accessToken
      );
      if (res && res.signatures) {
        dispatch(setElectronicSignatures(res.signatures));
      } else {
        setAppError();
      }
    } catch (error) {
      setAppError();
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {selectedPage === 1 && (
        <IconButton
          label="Cargar y firmar documento"
          icon="fas fa-cloud-upload-alt"
          className="electronic-signature-upload-button b-primary-hover"
          color={"secondary"}
          onClick={() => {
            setModal({
              uploadFileModal: {
                show: true,
              },
            });
          }}
        />
      )}
      {selectedPage === 4 && (
        <IconButton
          label="Actualizar"
          icon="fa fa-refresh"
          className="electronic-signature-upload-button b-primary-hover"
          color={"secondary"}
          onClick={() => getSignatures()}
        />
      )}
      <div>
        <p className="dashboard-message">
          <i className="fas fa-play secondary margin-r" />
          {selectedPage === 1 && (
            <>
              Mostrando{" "}
              <span className=" document-manager-length">
                {documents ? documents.length : 0}{" "}
              </span>{" "}
              Documentos
            </>
          )}
          {selectedPage === 2 && (
            <>En progreso y firmados en detalle (Visualización de a 5 firmas)</>
          )}
          {selectedPage === 3 && <>Nueva firma por carpetas </>}
          {selectedPage === 4 && (
            <>Todas las firmas en progreso y buscar firmas </>
          )}
        </p>
      </div>
      <div className="electronic-signature-action-box">
        <div className="electronic-signature-page-toggle-box">
          <div
            className={`electronic-signature-page-toggle electronic-signature-page-toggle-1 ${
              selectedPage === 1
                ? "electronic-signature-page-toggle-selected"
                : "electronic-signature-page-toggle-not-selected"
            }`}
            onClick={() => {
              setSelectedPage(1);
              setSessionStoragePage(1);
            }}
          >
            Nueva Firma
          </div>
          <div
            className={`electronic-signature-page-toggle electronic-signature-page-toggle-2 ${
              selectedPage === 4
                ? "electronic-signature-page-toggle-selected"
                : "electronic-signature-page-toggle-not-selected"
            }`}
            onClick={() => {
              setSelectedPage(4);
              setSessionStoragePage(4);
            }}
          >
            En Progreso
          </div>
        </div>
      </div>
      <div
        className="electronic-signature-action-box"
        style={{ marginTop: 20 }}
      >
        <div className="electronic-signature-page-toggle-box">
          <div
            className={`electronic-signature-page-toggle electronic-signature-page-toggle-1 ${
              selectedPage === 3
                ? "electronic-signature-page-toggle-selected"
                : "electronic-signature-page-toggle-not-selected"
            }`}
            onClick={() => {
              setSelectedPage(3);
              setSessionStoragePage(3);
            }}
          >
            Nueva Firma por Carpeta
          </div>
          {/* <div
            className={`electronic-signature-page-toggle electronic-signature-page-toggle-2 ${
              selectedPage === 4
                ? "electronic-signature-page-toggle-selected"
                : "electronic-signature-page-toggle-not-selected"
            }`}
            onClick={() => {
              setSelectedPage(2);
              setSessionStoragePage(2);
            }}
          >
            En Progreso / Detalle
          </div> */}
        </div>
      </div>

      {documents && selectedPage === 1 && (
        <ESignatureTable
          documents={documents}
          setEsignDocumentData={setEsignDocumentData}
        />
      )}
      {documents && selectedPage === 2 && <SignatureStatusTable />}
      {documents && selectedPage === 3 && (
        <FoldersPage
          isSignatureScreen
          documents={documents}
          setEsignDocumentData={setEsignDocumentData}
        />
      )}
      {documents && selectedPage === 4 && (
        <AllSignaturesPage getSignatures={getSignatures} />
      )}
    </>
  );
};

const mapDispatchToProps = {
  setModal,
};

export default connect(null, mapDispatchToProps)(NewElectronicSignatureScreen);
