import React, { useState, useEffect } from "react";
import Button from "../uiComponents/Button";
import "./styles.css";
import * as Questions from "../data/questionsExporter";
import * as Documents from "../Contracts/contractExporter";
import { APIGetProd, APIPostProd } from "../Services/authenticated";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingScreen from "../screens/LoadingScreen";

const files = [
  {
    questionsName: "TestQuestions1",
    docName: "TestDoc1",
    documentData: Documents.Test1,
    questionData: Questions.Test1,
  },
  {
    questionsName: "TestQuestions2",
    docName: "TestDoc2",
    documentData: Documents.Test2,
    questionData: Questions.Test2,
  },
  {
    questionsName: "TestQuestions3",
    docName: "TestDoc3",
    documentData: Documents.Test3,
    questionData: Questions.Test3,
  },
  {
    questionsName: "TestQuestions4",
    docName: "TestDoc4",
    documentData: Documents.Test4,
    questionData: Questions.Test4,
  },
  {
    questionsName: "TestQuestions5",
    docName: "TestDoc5",
    documentData: Documents.Test5,
    questionData: Questions.Test5,
  },
  {
    questionsName: "TestQuestions6",
    docName: "TestDoc6",
    documentData: Documents.Test6,
    questionData: Questions.Test6,
  },
  {
    questionsName: "TestQuestions7",
    docName: "TestDoc7",
    documentData: Documents.Test7,
    questionData: Questions.Test7,
  },
];

const DataUploader = () => {
  const [loading, setLoading] = useState(true);
  const [companyId, setCompanyId] = useState("");
  const [documentName, setDocumentName] = useState("");
  const [documentName1, setDocumentName1] = useState("");
  const [documentName2, setDocumentName2] = useState("");
  const [selectedQuestionData, setSelectedQuestionData] = useState("");
  const [selectedDocumentData, setSelectedDocumentData] = useState("");
  const [password, setPassword] = useState("");
  const [companies, setCompanies] = useState([]);

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const getAllCompanies = async () => {
    const accesToken = await getAccessTokenSilently();
    const allCompanies = await APIGetProd(
      "/companies/get-all-companies",
      accesToken
    );
    setCompanies(allCompanies);
    setLoading(false);
  };

  useEffect(() => {
    getAllCompanies();
  }, []);

  const upload = async () => {
    if (
      selectedDocumentData.trim() === "" ||
      selectedQuestionData.trim() === "" ||
      documentName.trim() === "" ||
      documentName1.trim() === "" ||
      documentName2.trim() === "" ||
      companyId.trim() === "" ||
      password.trim() === ""
    ) {
      alert("Llenar todos los campos");
      return;
    }
    setLoading(true);
    const finalQuestionData = files.find(
      (e) => e.questionsName === selectedQuestionData
    );

    const finalDocumentData = files.find(
      (e) => e.docName === selectedDocumentData
    );

    const finalCompanyData = companies.find((e) => e.companyId === companyId);

    const now = Date.now();

    const params = {
      documentName,
      documentName1,
      documentName2,
      questionData: finalQuestionData.questionData,
      documentData: finalDocumentData.documentData,
      date: now.toString(),
      companyId: finalCompanyData.companyId,
      companyName: finalCompanyData.companyName,
      password,
    };

    const accesToken = await getAccessTokenSilently();
    const uploadData = await APIPostProd(
      "/company-docs/create-company-document",
      accesToken,
      params
    );

    if (uploadData.saved) {
      alert("guardado correctamente");
    }

    setLoading(false);
  };

  return (
    <div className="data-uploader-wrapper b-tertiary">
      <h2>Data Uploader</h2>
      {loading && <LoadingScreen />}
      {!loading && isAuthenticated && (
        <div className="data-uploader-card b-white">
          <form style={{ width: "100%" }}>
            <div className="data-uploader-field">
              <label>Preguntas:</label>
              <select
                required
                onChange={(e) => setSelectedQuestionData(e.target.value)}
              >
                <option value="">Seleccionar</option>
                {files.map((data, index) => {
                  return (
                    <option value={data.questionsName} key={index}>
                      {data.questionsName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="data-uploader-field">
              <label>Documento:</label>
              <select
                required
                onChange={(e) => setSelectedDocumentData(e.target.value)}
              >
                <option value="">Seleccionar</option>
                {files.map((data, index) => {
                  return (
                    <option value={data.docName} key={index}>
                      {data.docName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="data-uploader-field">
              <label>Nombre del Documento:</label>
              <input
                type="text"
                onChange={(e) => setDocumentName(e.target.value)}
                value={documentName}
              />
            </div>
            <div className="data-uploader-field">
              <label>Nombre parte regular:</label>
              <input
                type="text"
                onChange={(e) => setDocumentName1(e.target.value)}
                value={documentName1}
              />
            </div>
            <div className="data-uploader-field">
              <label>Nombre parte negrita:</label>
              <input
                type="text"
                onChange={(e) => setDocumentName2(e.target.value)}
                value={documentName2}
              />
            </div>
            <div className="data-uploader-field">
              <label>Empresa:</label>
              <select required onChange={(e) => setCompanyId(e.target.value)}>
                <option value="">Seleccionar</option>
                {companies &&
                  companies.map((com, index) => {
                    return (
                      <option value={com.companyId} key={index}>
                        {com.companyName}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="data-uploader-field" style={{ marginTop: 70 }}>
              <label>Password:</label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <Button
              label={"Cargar Data"}
              style={{ width: "100%", marginTop: 40 }}
              onClick={upload}
            />
          </form>
        </div>
      )}
    </div>
  );
};

export default DataUploader;
