import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
} from "./types.js";

export const ComodatoDoc = [
  [
    {
      type: title,
      text: "CONTRATO DE COMODATO – VIVIENDA FAMILIAR",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: false,
    },
    {
      type: bold,
      text: "Contrato de Comodato ",
      depend: false,
      notBe: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text:
        "(en adelante, el “Contrato”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "El presente ",
      depend: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: bold,
      text: "Contrato de Comodato ",
      depend: false,
    },
    {
      type: text,
      text:
        '(en adelante, el "Contrato") se celebra en la ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:',
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Comodante",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[4].answer}, ${answers.section2[5].answer} N° ${answers.section2[6].answer}, con domicilio sito en ${answers.section2[7].answer}, ${answers.section2[8].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Comodante",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[9].answer}, ${answers.section2[10].answer} N° ${answers.section2[11].answer}, con domicilio sito en ${answers.section2[12].answer}, ${answers.section2[13].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Comodataria",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[14].answer}, ${answers.section3[15].answer} N° ${answers.section3[16].answer}, con domicilio sito en ${answers.section3[17].answer}, ${answers.section3[18].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Comodataria",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[19].answer}, ${answers.section3[20].answer} N° ${answers.section3[21].answer}, con domicilio sito en ${answers.section3[22].answer}, ${answers.section3[23].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Comodante y la Comodataria podrán ser denominadas conjuntamente como las “Partes” e individualmente como la “Parte”.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes acuerdan que el presente Contrato se regirá por los siguientes términos y condiciones:",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Cláusula Primera: Definiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: '"CCyCN": Código Civil y Comercial de la Nación.',
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "“Destino”: tiene el significado asignado la Cláusula Cuarta.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "“Domicilio”: significa la respectiva dirección de cada Parte indica en el encabezamiento del presente Contrato o aquella otra dirección que la respectiva Parte informare a la otra Parte de conformidad a la Cláusula Décima.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        '"Inmueble": significa el bien inmueble sito en ${answers.section4[26].answer}, ${answers.section4[25].answer}, provincia de ${answers.section4[24].answer}, República Argentina. ',
      depend: false,
    },
    {
      type: textArray,
      text: "El Inmueble objeto del presente Contrato incluye {{array}}.",
      question: { section: "section4", number: 27 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: text,
      text:
        '"Inmueble": significa el bien inmueble sito en ${answers.section4[26].answer}, ${answers.section4[24].answer}, República Argentina. ',
      depend: true,
      question: {
        section: "section4",
        number: 24,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: textArray,
      text: "El Inmueble objeto del presente Contrato incluye {{array}}.",
      question: { section: "section4", number: 27 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: text,
      text:
        '"Mobiliario": significa las cosas muebles no registrables que se encuentran en el Inmueble para uso residencial temporal objeto del presente Contrato. ',
      depend: false,
    },
    {
      type: text,
      text:
        "Su inventario se detalla a continuación: ${answers.section4[29].answer}.",
      depend: true,
      question: {
        section: "section4",
        number: 28,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: textArray,
      text:
        '"Servicios": significa cualquiera de los siguientes servicios habilitados y disponibles en el Inmueble en la fecha de inicio de la Vigencia, a saber: {{array}}.',
      question: { section: "section4", number: 125 },
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text:
        "“Vigencia”: tiene el significado establecido en la Cláusula Tercera.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEGUNDA: Objeto.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El Comodante da en comodato a la Comodataria el Inmueble durante la Vigencia establecida en el presente Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA TERCERA: Vigencia del Contrato. Prórroga.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente comodato se pacta por el plazo de ${answers.section5[33].answer} ${answers.section5[321].answer} a contar desde ",
      depend: false,
    },
    {
      type: text,
      text: "la fecha que se indica en el encabezado ",
      notBe: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 34,
        answer: "Otra posterior",
      },
    },
    {
      type: text,
      text: "el ${answers.section5[35].answer}",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section5",
        number: 34,
        answer: "Otra posterior",
      },
    },
    {
      type: text,
      text:
        "(en adelante, la “Vigencia”). Ninguna Parte se obligará a alegar, alegará, ni permitirá que se alegue en contra de la otra Parte la reconducción tácita de este Contrato. Por lo que cumplida la Vigencia del mismo el vencimiento del Contrato operará de pleno derecho, sin necesidad de notificación o requerimiento alguno de parte del Comodante, fecha en la cual deberá, la Comodataria, restituir el Inmueble totalmente desocupado y libre en el estado en que lo recibió -excepto por los deterioros provenientes del mero transcurso del tiempo y el uso regular del mismo-, siendo este plazo, único, definitivo, perentorio y sin prórroga de ninguna clase.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En o antes de alcanzada la fecha vencimiento de la Vigencia establecida en el presente Contrato, las Partes de común acuerdo y por escrito podrán prorrogar el plazo del presente Contrato por períodos iguales al establecido en la Vigencia o las que las Partes por escrito acuerden.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA CUARTA: Destino del Inmueble. Inspección del Comodante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes establecen que el Inmueble que se afecta al presente Contrato se encuentra destinado exclusivamente a vivienda familiar (en adelante, el “Destino”). El Comodante se reserva el derecho de inspeccionar el Inmueble para comprobar el destino y estado de conservación del mismo, para lo cual deberá comunicar dicha intención la Comodataria en un plazo no menor a cuarenta y ocho (48) horas o con suficiente tiempo de anticipación. Si el Inmueble tuviese reglamento de propiedad horizontal, la Comodataria será exclusiva y absolutamente responsable por el cumplimiento del mismo.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA QUINTA: Impuestos. Servicios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "Será a cargo de la Comodataria el pago de los Servicios. ",
      depend: false,
    },
    {
      type: text,
      text:
        "Complementariamente, deberá abonar el impuesto municipal aplicable que grave el uso del bien Inmueble, como por ejemplo el impuesto al alumbrado, barrido y limpieza (ABL), incluyendo expresamente cualquier avalúo que se pudiera realizar, y los impuestos -actuales o futuros- que pudieran crearse, siempre y cuando graven específicamente al uso del bien Inmueble. ",
      depend: true,
      stopRemoval: true,
      question: {
        section: "section6",
        number: 38,
        answer: "Sí",
      },
    },
    {
      type: text,
      text:
        "Los pagos deberán efectuarse durante la Vigencia del Contrato y hasta tanto no devolviere el Inmueble en legal forma al Comodante. Cada pago deberá efectuarse antes de que se produjera su respectivo vencimiento, debiendo entregar al Comodante el comprobante original de haberlo efectuado y recibiendo de este constancia de esa entrega.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SEXTA: Prohibiciones.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Queda prohibido a la Comodataria introducir mejoras y/o modificaciones y/o nuevas construcciones en el Inmueble sin autorización dada por escrito por el Comodante. La infracción a lo establecido en esta cláusula, además de ser suficiente causal de desalojo, dará derecho al Comodante para reclamar la restitución del Inmueble en el estado en que se encuentre a la fecha de este documento, así como al pago de los daños y perjuicios que se le haya ocasionado.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes acuerdan que la Comodataria no podrá tener animales domésticos en el Inmueble durante la Vigencia del Contrato.",
      includes: ["Tener Mascotas"],
      question: {
        section: "section6",
        number: 36,
      },
    },
  ],
  [
    {
      type: text,
      text:
        "La Comodataria no permitirá que se enciendan y/o fumen cigarrillos, cigarro y/o productos similares (de hoja, naturales y/o electrónicos) en cualquier ambiente cerrado del Inmueble.",
      includes: ["Fumar dentro del inmueble"],
      question: {
        section: "section6",
        number: 36,
      },
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA SÉPTIMA: Responsabilidad.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Comodataria manifiesta que será la única y exclusiva responsable por la totalidad de las actividades que se realicen en el Inmueble, tanto si fuese en materia comercial, como civil, laboral, impositiva y/o cualquiera actividad que se realice en el Inmueble objeto del presente Contrato. En consecuencia, la Comodataria se obliga a mantener indemne al Comodante por cualquier reclamo que este reciba como consecuencia de dicha actividad.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA OCTAVA: Cesión.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "El presente Contrato es intransferible, no pudiendo la Comodataria transferir, ceder, subcomodatar, alquilar, permutar y/o prestar el Inmueble, en todo o en parte, bajo sanción de desalojo, sin la expresa autorización previa por escrito del Comodante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA NOVENA: Extinción del Contrato.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "9.1 La Comodataria podrá, de manera unilateral, extinguir el presente Contrato notificando al Comodante con una antelación no menor de tres (3) días, sin necesidad de causa alguna.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "9.2 El Comodante podrá extinguir el presente Contrato y solicitar la restitución anticipada del Inmueble si se configurase alguno de los siguientes supuestos, a saber:",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "La Comodataria incumple con cualquiera de los términos y condiciones establecidos en el presente Contrato;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "La Comodataria no usa el Inmueble según el Destino determinado en el presente Contrato;",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "De conformidad con el art. 1539 del CCyCN, el Comodante necesitase el Inmueble en razón de una circunstancia imprevista y urgente; y",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text: "El Inmueble se destruyera y/o la Comodataria muriera.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En caso que operare alguno de los supuestos establecidos en la presente cláusula 9.2, y en caso que corresponda, el Comodante deberá dar aviso previo a la Comodataria, con una antelación mínima de tres (3) días.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMA: Domicilios.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Cada Parte constituye domicilio en su Domicilio exclusivamente a los fines del presente Contrato. La Parte que cambiare su Domicilio informará la nueva dirección, que deberá encontrarse en la República Argentina, a la otra Parte mediante una notificación fehaciente dirigida al Domicilio de esta última.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las notificaciones que se realicen entre las Partes, así como otras comunicaciones y notificaciones prejudiciales y/o judiciales derivadas de este Contrato, se tendrán por válidamente cursadas al Domicilio de la Parte destinataria hasta que la Parte remitente sea informada del nuevo Domicilio de la Parte destinataria mediante una notificación fehaciente dirigida al Domicilio de la Parte remitente.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CLÁUSULA DÉCIMO PRIMERA: Ley Aplicable. Jurisdicción.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Este Contrato se regirá e interpretará de conformidad con las leyes de la República Argentina. Para cualquier cuestión judicial emergente de este Contrato, las Partes se someten a la jurisdicción de los Tribunales Nacionales Ordinarios en lo Civil con sede en ${answers.section6[37].answer}, renunciando a cualquier otro fuero y/o jurisdicción que pudiere corresponderles.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes firman dos (2) ejemplares de un mismo tenor y a un solo efecto.",
      depend: false,
    },
  ],
];
