import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { setAppError, setLoading } from "../../Utils/modalMethods";
import Select from "../../uiComponents/Select/Select";
import {
  APIGetWithError,
  APIPostWithError,
} from "../../Services/authenticated";
import { useSelector } from "react-redux";

type Company = {
  value: string;
  label: string;
};

const CompanyChanger = () => {
  const [companies, setCompanies] = useState<Company[]>([]);

  const reduxUser = useSelector((state: any) => state.masterData.user);

  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  const getAllCompanies = async () => {
    const accesToken = await getAccessTokenSilently();
    const allCompanies = await APIGetWithError(
      "/companies/get-all-companies",
      accesToken
    );
    if (allCompanies && allCompanies.length > 0) {
      const companyOptions = allCompanies.map((comp: any) => ({
        value: comp.companyId,
        label: `${comp.companyId} ${comp.companyName}`,
      }))
      .sort((a: { value: number; label: string }, b: { value: number; label: string }) => a.value - b.value);
      
      setCompanies(companyOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = async (e: Company) => {
    try {
      setLoading(true);
      const accesToken = await getAccessTokenSilently();
      const res = await APIPostWithError("/users/update-user", accesToken, {
        companyId: e.value,
      });
      if (res && res.userUpdated === true) {
        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 1000);
      } else {
        setAppError();
      }
    } catch {
      setAppError();
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {isAuthenticated && user && reduxUser && reduxUser.internalUser && (
        <div
          style={{
            width: 300,
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-75%)",
            zIndex: 9999,
          }}
        >
          {companies.length > 0 && (
            <Select
              options={companies}
              placeholder={"Seleccione su compañía"}
              onChange={onChange}
            />
          )}
        </div>
      )}
    </>
  );
};

export default CompanyChanger;
