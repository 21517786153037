import {
  Box,
  Typography,
  IconButton as MUIButton,
  Button,
} from "@mui/material";
import Modal from "../Modal";
import IconButton from "../../IconButton/IconButton";
import { useAtomValue, useSetAtom } from "jotai";
import {
  confirmationModalAtom,
  initialConfirmationModalState,
} from "../../../jotai/atoms.jotai";

const JotaiConfirmModal: React.FC = () => {
  const {
    message,
    subMessage,
    onClose,
    onConfirm,
    disclaimer = null,
  } = useAtomValue(confirmationModalAtom);

  const setConfirmationAtom = useSetAtom(confirmationModalAtom);

  const handleClose = () => {
    onClose && onClose();
    setConfirmationAtom(initialConfirmationModalState);
  };

  return (
    <Modal>
      <Box
        sx={{
          position: "absolute",
          top: "10%",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 9999,
          backgroundColor: "white",
          width: 600,
          borderRadius: 1,
          padding: 3,
          paddingTop: 5,
          minHeight: 250,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Button
          onClick={handleClose}
          sx={{
            fontSize: "1.4rem",
            color: "rgb(170, 170, 228)",
            position: "absolute",
            top: 20,
            right: 20,
            "&:hover": {
              color: "rgb(107, 107, 158)",
            },
          }}
        >
          <i className="far fa-window-close" />
        </Button>

        <Typography
          variant="h5"
          sx={{
            pt: 2,
            fontWeight: "bold",
            textAlign: "center",
            fontSize: "1.5rem",
          }}
        >
          {message}
        </Typography>

        <Typography
          variant="body1"
          sx={{
            margin: "20px",
            textAlign: "center",
          }}
        >
          {subMessage}
        </Typography>

        {disclaimer && (
          <Typography
            variant="body2"
            sx={{
              color: "grey",
              fontSize: "0.8rem",
              textAlign: "center",
            }}
          >
            {disclaimer}
          </Typography>
        )}

        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            padding: "0 10%",
          }}
        >
          <IconButton
            label="No"
            color="secondary"
            icon="far fa-times-circle"
            onClick={handleClose}
          />
          <IconButton
            label="Si"
            color="secondary"
            icon="far fa-check-circle"
            onClick={onConfirm}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default JotaiConfirmModal;
