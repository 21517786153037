import React from "react";

import "./styles.css";
import { useSelector } from "react-redux";

const DashboardSummary = ({
  user,
  companyDocumentsLength,
  documentsLength,
  spaceUsage,
}) => {
  const { company } = useSelector((state) => state.masterData);

  const calculateWidth = (space) => {
    const Mb = Math.round(spaceUsage / 10000) / 100;
    const toLimit = Mb / 1000;
    if (toLimit > 0.1) return toLimit;
    return 0.1;
  };

  return (
    <div>
      {company && (
        <div>
          <div className="dashboard-summary">
            <div className="dashboard-summary-box ">
              <h4 className="primary">{company.companyName}</h4>
              <p>Empresa</p>
            </div>
            <div className="dashboard-summary-box ">
              <h4 className="primary">{documentsLength || "0"}</h4>
              <p>Documentos Generados</p>
            </div>
            <div className="dashboard-summary-box ">
              <h4 className="primary">{companyDocumentsLength || "0"}</h4>
              <p>Plantillas Automatizadas</p>
            </div>
            <div className="dashboard-summary-box cloud-space-used ">
              <p className="margin-v">
                Espacio Cloud utilizado: <br />
                <span className="primary">
                  {spaceUsage ? Math.round(spaceUsage / 10000) / 100 : 0} MB /
                  1000 MB
                  <div
                    className="dashboard-summary-line-back"
                    style={{ width: 200, marginTop: 12 }}
                  >
                    <div
                      className="dashboard-summary-line-inner b-primary"
                      style={{ width: 200 * calculateWidth(spaceUsage) }}
                    ></div>
                  </div>
                </span>
              </p>
            </div>
          </div>
          {/* <div className='documets-manager-divider-2 center b-white'></div> */}
        </div>
      )}
    </div>
  );
};

export default DashboardSummary;
