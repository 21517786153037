import { Box, Button, Stack } from "@mui/material";
import React from "react";
import { Question } from "../../Types/Question.types";
import { Section } from "./Section";

type Props = {
  formWatch: any;
  sectionFormHookData: any;
  handleFilterSection: (section: number, isSelected: boolean) => void;
  selectedSections: number[];
  resetFilters: () => void;
};

const FormSections: React.FC<Props> = ({
  formWatch,
  sectionFormHookData,
  handleFilterSection,
  selectedSections,
  resetFilters,
}) => {
  const { control, watch } = sectionFormHookData;

  const sections =
    formWatch?.questions
      ?.map((question: Question) => Number(question.section))
      .sort((a: number, b: number) => a - b)
      .filter(
        (value: number, index: number, self: any) =>
          self.indexOf(value) === index
      ) || [];

  return (
    <Stack sx={{ width: "380px" }} alignItems={"center"} className="b-white">
      <Box mt={5} mb={1}>
        Secciones:
      </Box>
      <Box mt={1} width={"90%"}>
        {sections.map((section: number) => (
          <Section
            key={section}
            control={control}
            section={section}
            watch={watch}
            handleFilterSection={handleFilterSection}
            isSelected={selectedSections.includes(section)}
          />
        ))}
      </Box>
      <Box mt={2}>
        <Button onClick={resetFilters} variant="contained" color="primary">
          Resetear Filtros
        </Button>
      </Box>
    </Stack>
  );
};

export default FormSections;
