import React, { useEffect, useState } from "react";
import "../styles.css";
import numbersToLetters from "../../../Utils/numbersToLetters";

const Pesos = ({
  question,
  onChange,
  currentAnswer,
  sectionId,
  currentSectionAnswers,
  currentAnswerObject,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentCurrency, setCurrentCurrency] = useState("");
  const [currentNumber, setCurrentNumber] = useState("0");
  const [currentFull, setCurrentFull] = useState("");
  const [currentDecimals, setCurrentDecimals] = useState("");

  const answerNormalizer = (value) => {
    let valueNormalized = typeof value === "string" ? value.split("") : [];
    let newValue = valueNormalized.filter((val) => /[\d|]/.test(val)).join("");
    return newValue;
  };

  const addLetters = (num, thisCurrency = null) => {
    const parsedNum = parseFloat(num);
    const letters = numbersToLetters(parsedNum);
    const numberWithComma = num.replace(".", ",");
    let final = "";
    if (thisCurrency === "Pesos Argentinos") {
      final = `${letters} ($ ${numberWithComma})`;
    } else if (thisCurrency === "Dólares Estadounidenses") {
      final = `${letters} (USD ${numberWithComma})`;
    } else {
      final = `${letters} (${numberWithComma})`;
    }
    return final;
  };

  useEffect(() => {
    if (
      currentAnswer &&
      answerNormalizer(currentAnswer) !== "" &&
      answerNormalizer(currentAnswer) !== " "
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }

    if (
      currentAnswerObject &&
      currentAnswerObject.currency &&
      currentAnswerObject.currency !== ""
    ) {
      setCurrentCurrency(currentAnswerObject.currency);
    }

    if (
      currentAnswerObject &&
      currentAnswerObject.currentFull &&
      currentAnswerObject.currentFull !== ""
    ) {
      setCurrentFull(currentAnswerObject.currentFull);
    }

    if (
      currentAnswerObject &&
      currentAnswerObject.currentDecimals &&
      currentAnswerObject.currentDecimals !== ""
    ) {
      setCurrentDecimals(currentAnswerObject.currentDecimals);
    }
  }, [currentAnswer, currentAnswerObject]);

  return (
    <div className="question-format-form  question-format-money-div">
      <input
        required
        type="text"
        value={answerNormalizer(currentFull)}
        className="question-format-input-money"
        placeholder="Numero Entero"
        onChange={(e) => {
          setCurrentCurrency("");
          let valueNormalized =
            typeof e.target.value === "string" ? e.target.value.split("") : [];
          let newValue = valueNormalized
            .filter((val) => /[\d]/.test(val))
            .join("");
          if (newValue.length > 9) {
            const oldValue = newValue;
            newValue = currentFull;
          }
          setCurrentFull(newValue);
          setCurrentNumber(`${newValue}${currentDecimals}`);
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: addLetters(`${newValue}${currentDecimals}`),
                currency: "",
                currentFull: newValue,
                currentDecimals: currentDecimals,
              },
            },
          });
        }}
      ></input>
      <input
        className="question-format-input-decimals"
        placeholder="Centavos"
        value={answerNormalizer(currentDecimals)}
        onChange={(e) => {
          setCurrentCurrency("");
          let valueNormalized =
            typeof e.target.value === "string" ? e.target.value.split("") : [];
          let newValue = valueNormalized
            .filter((val) => /[\d]/.test(val))
            .join("");
          if (newValue.length > 0) {
            const oldValue = newValue;
            newValue = `.${newValue}`;
          }
          if (newValue.length > 3) {
            const oldValue = newValue;
            newValue = currentDecimals;
          }
          setCurrentDecimals(newValue);
          setCurrentNumber(`${currentFull}${newValue}`);
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                question: question.question,
                answer: addLetters(`${currentFull}${newValue}`),
                currency: "",
                currentFull: currentFull,
                currentDecimals: newValue,
              },
            },
          });
        }}
      ></input>
      <select
        disabled={isDisabled}
        required
        value={currentCurrency}
        className="question-format-input-currency"
        onChange={(e) => {
          setCurrentCurrency(e.target.value);
          const withLetters = addLetters(currentNumber);
          onChange({
            [sectionId]: {
              ...currentSectionAnswers,
              [question.id]: {
                ...currentAnswerObject,
                answer: ` ${e.target.value} ${withLetters}`,
                currency: e.target.value,
              },
            },
          });
        }}
      >
        <option value="">Moneda</option>
        <option value={"Pesos Argentinos"}>Pesos Argentinos</option>;
      </select>
    </div>
  );
};

export default Pesos;
