import React from "react";
import { Typography, Container, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

const DocxNotCompatibleMessage: React.FC = () => {
  const history = useHistory();

  return (
    <Container sx={{ backgroundColor: "white", borderRadius: "10px", padding: "50px" }}>
      <h1 style={{ textAlign: "center" }}>Descargue el documento</h1>
      <Typography
        mt={3}
        variant="h6"
        component="h3"
        align="center"
        gutterBottom
      >
        Para editar este documento descárguelo y edítelo en Word en su
        computadora
      </Typography>
      <Typography variant="body1" color="slategrey" sx={{ opacity: 0.5 }} mt={10} textAlign="center">
        *Este documento no es compatible con el editor online. Le recomendamos
        editarlo en su computadora y volver a subirlo para firmarlo
        electrónicamente.
      </Typography>
      <Button
        onClick={() => {
          history.push("/dashboard/finished");
        }}
        variant="contained"
        className="center"
        sx={{ px: 4, mt: 5 }}
      >
        Volver
      </Button>
    </Container>
  );
};

export default DocxNotCompatibleMessage;
