export const documents = [
  {
    title: "Contrato de Confidencialidad",
    title1: "Contrato",
    title2: "de Confidencialidad",
    to: "nda",
  },
  {
    title: "Contrato de Servicios",
    title1: "Contrato",
    title2: "de Servicios",
    to: "contrato-de-servicios",
  },
  {
    title: "Contrato de Trabajo",
    title1: "Contrato",
    title2: "de Trabajo",
    to: "contrato-de-trabajo",
  },
  {
    title: "Contrato de Alquiler",
    title1: "Contrato de",
    title2: "Alquiler (Nueva Ley)",
    to: "contrato-de-alquiler",
  },
  {
    title: "Contrato de Alquiler (Vieja Ley)",
    title1: "Contrato de",
    title2: "Alquiler (Vieja Ley)",
    to: "contrato-de-alquiler-vieja-ley",
  },
  {
    title: "Contrato de Alquiler Temporal",
    title1: "Contrato de",
    title2: "Alquiler Temporal",
    to: "contrato-de-alquiler-temporal",
  },
  {
    title: "Contrato de Préstamo de Dinero",
    title1: "Contrato de",
    title2: "Préstamo de Dinero",
    to: "contrato-de-prestamo-de-dinero",
  },
  {
    title: "Comodato de Vivienda Familiar",
    title1: "Comodato de",
    title2: "Vivienda Familiar",
    to: "comodato",
  },
  {
    title: "Reserva de Locación",
    title1: "Reserva de",
    title2: "Locación",
    to: "reserva-de-locacion",
  },
  {
    title: "Pagaré",
    title1: " ",
    title2: "Pagaré",
    to: "pagare",
    price: "$1.399",
  },
  {
    title: "Recibo",
    title1: "",
    title2: "Recibo",
    to: "recibo",
    price: "$1.399",
  },
];
