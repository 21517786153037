import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import "./Entries.styles.css";

export type TableAction = {
  name: string;
  onClick?: any;
  icon?: any;
  color?: string;
  onClickWithParams?: any;
  onClickAsync?: any;
  onClickAsyncWithUser?: any;
  getIconFunc?: any;
};

type Props = {
  actions: TableAction[];
  rowData: any;
};

const TableActions: React.FC<Props> = ({ actions, rowData }) => {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();

  const getAction = async (action: TableAction) => {
    if (action.onClick) {
      action.onClick();
      return;
    }
    if (action.onClickWithParams) {
      action.onClickWithParams(rowData);
      return;
    }
    if (action.onClickAsync && isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      await action.onClickAsync(rowData, accessToken);
    }
    if (action.onClickAsyncWithUser && isAuthenticated) {
      const accessToken = await getAccessTokenSilently();
      await action.onClickAsyncWithUser(rowData, accessToken, user);
    }
  };

  return (
    <>
      <div className="table-entry-action-wrapper">
        {actions.map((action: TableAction) => (
          <>
            <div className="table-entry-action-icon-wrapper">
              <FontAwesomeIcon
                icon={
                  action.getIconFunc ? action.getIconFunc(rowData) : action.icon
                }
                className={`${
                  action.color ? action.color : "primary"
                } table-entry-action-icon`}
                onClick={() => getAction(action)}
              />
              <div className="table-entry-action-name">{action.name}</div>
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default TableActions;
