import Modal from "../Modal";
import IconButton from "../../IconButton/IconButton";
import Loader from "react-loader-spinner";

import "./styles.css";

type Props = {
  disclaimer?: string | null;
  message: string;
  subMessage: string;
  onClose?: () => void;
  onConfirm?: () => void;
  loading?: boolean;
  fileName?: string | null;
};

const ConfirmationModal: React.FC<Props> = ({
  message,
  subMessage,
  onClose,
  onConfirm,
  loading = false,
  disclaimer = null,
  fileName = null,
}) => {
  return (
    <Modal>
      <div className="confirmation-modal">
        <i
          className="far fa-window-close confirmation-modal-close"
          onClick={onClose}
        ></i>
        <p className="confimation-modal-message">{message}</p>
        <p className="confimation-modal-sub-message">{subMessage}</p>
        {fileName && <p className="primary bold margin-b">{fileName}</p>}
        {disclaimer && (
          <p className="confimation-modal-disclaimer">{disclaimer}</p>
        )}
        {!loading && (
          <div className="confirmation-modal-button-box">
            <IconButton
              label="No"
              color="secondary"
              icon="far fa-times-circle"
              onClick={onClose}
            />
            <IconButton
              label="Si"
              color="secondary"
              icon="far fa-check-circle"
              onClick={onConfirm}
            />
          </div>
        )}{" "}
        {loading && (
          <Loader
            type="Bars"
            color="#e8eeff"
            height={60}
            width={60}
            // className="margin-v"
          />
        )}
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
