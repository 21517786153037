import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
  dateWithoutSpace,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const ReservaDeLocacionSections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración de la Reserva de Locación",
    type: questions,
    explanation: {
      question: "Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma la Reserva de Locación.",
      ],
    },
    questions: [
      {
        id: 1,
        question: "¿En qué Provincia se celebra la Reserva de Locación?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question: "¿En que Ciudad/Localidad se celebra la Reserva de Locación?",
        info: "Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se celebra la Reserva de Locación?",
        type: dateWithoutSpace,
      },
    ],
  },
  {
    id: "section2",
    section: "Propietario",
    title:
      "Indicar los datos del Propietario que dará en Alquiler su Inmueble.",
    type: person,
    explanation: {
      question: "¿Quién es Propietario de la Reserva de Locación?",
      answer: [
        "El Propietario es el dueño del Inmueble que lo da en alquiler y recibe a cambio un monto en dinero a cambio, sujeto a la aprobación de la Reserva de Locación.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 4,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 5,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 6,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 7,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 8,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 9,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 10,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 11,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 12,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 13,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section3",
    section: "Ofertante",
    title:
      "Indicar los datos de la Ofertante que se encuentra interesado en alquilar el Inmueble.",
    type: person,
    explanation: {
      question: "¿Quién es la Ofertante de la Reserva de Locación?",
      answer: [
        "La Ofertante es quien se encuentra interesado en alquilar el Inmueble del Propietario y pagará un alquiler a cambio.",
        "Todo ello sujeto a los términos de la Reserva de Locación.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person2",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 14,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              question: "Tipo de Documento:",
              id: 15,
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 16,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 17,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 18,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 19,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 20,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 21,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 22,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 23,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section4",
    section: "Inmobiliaria",
    title:
      "Indicar los datos de la Inmobiliaria que participa de la Reserva de Locación.",
    type: person,
    explanation: {
      question: "¿Quién es la Inmobiliaria de la Reserva de Locación?",
      answer: [
        "La Inmobiliaria es quien intermedia entre el Ofertante y el Propietario en el alquiler del Inmueble.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person2",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 140,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              question: "Tipo de Documento:",
              id: 142,
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 143,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 144,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 145,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Jurídica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 146,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ej.: Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 147,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 148,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 149,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 150,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section5",
    section: "Inmueble",
    title:
      "Detallar el Inmueble que se alquilará y es objeto de la Reserva de Locación",
    type: questions,
    explanation: {
      question: "¿Qué es el Inmueble?",
      answer: [
        "El inmueble puede ser de cualquier tipo apto para ser usado como vivienda (departamento, PH, casa en un barrio privado o abierto, country, etc.) y de cualquier tamaño, condición (nuevo o usado) con o sin cochera y/o amoblado o desamoblado.",
      ],
    },
    questions: [
      {
        id: 24,
        question: "¿En qué Provincia se ubica el Inmueble?:",
        type: options,
        options: provincias,
      },
      {
        id: 25,
        question: "¿En qué Ciudad/Localidad se encuentra el Inmueble?",
        answer: ["Ushuaia, Ciudad de Córdoba, Localidad de Tigre, etc."],
        type: question,
        notBe: {
          id: 24,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },

      {
        id: 26,
        question:
          "¿Cuál es la úbicación exacta del Inmueble? (Calle, Número, Departamento, Piso y Unidad)",
        answer: [
          'Si es un Departamento o PH, por ej: Beruti 3574, Piso 3, dpto "A"',
          "Si es una Casa, Lote, etc., por ej: Ramallo 3571, Beccar; ó Blanco Encalada 1200, Barrio Laguna de la Luna, Lote 3525",
        ],
        type: question,
      },
      {
        id: 125,
        question: "¿Con que servicios cuenta el inmueble?",
        type: multipleOptions,
        info:
          "Esto NO implica que vayan a ser pagados por el dueño del inmueble",
        options: [
          "Agua corriente",
          "Electricidad",
          "Gas de red",
          "Telefonía fija",
          "Televisión por cable",
          "Internet",
        ],
      },
      {
        id: 27,
        question: "¿El Inmueble cuenta con Expensas? ¿Quién las pagará?",
        type: options,
        options: [
          "Sí, las pagará el Ofertante",
          "Sí, las pagará el Propietario",
          "Sí, se pagará en proporciones iguales entre el Propietario y Ofertante",
          "Sí, se definirá en el Contrato quien las paga",
          "No, no hay expensas en el Inmueble",
        ],
      },
      {
        id: 28,
        question: "¿El inmueble incluye cochera y/o mobiliario y/o baulera?",
        type: multipleOptions,
        options: ["Cochera", "Baulera", "Mobiliario"],
      },
    ],
  },
  {
    id: "section6",
    section: "Contrato a firmar",
    title: "Características Principales del Contrato a firmar",
    type: questions,
    explanation: {
      question:
        "¿Qué son las Características Principales del Contrato a Firmar?",
      answer: [
        "Se definirá el Precio, la Forma de Pago y la Duración, entre otros.",
        "El Precio del Alquiler es el dinero que el Inquilino le pagará al Propietario.",
        "La Forma de Pago es la manera que se pagará el Precio del Alquiler.",
      ],
    },
    questions: [
      {
        id: 29,
        question: "¿Cúal será el precio del Valor Locativo?",
        answer: [
          "Establecer si se establecerá un precio de inicio y luego se ajustará por el Índice de Contratos de Locación (ICL) publicado por el Banco Central de la República Argentina o",
          "Si se establecerá un cronograma con los valores locativos y su vigencia durante el Contrato. Por ej.: 24.000 primeros seis meses, 30.000 los siguentes seis meses, etc.",
        ],
        type: options,
        options: [
          "Se establece un precio y luego se ajusta por BCRA",
          "Cronograma de Valores Locativos",
        ],
      },
      {
        id: 30,
        question: "Establecer el monto del Precio de Inicio del Alquiler",
        answer: ["Por ej.: $48.000, U$S 500, etc."],
        type: money,
        depend: {
          id: 29,
          answer: "Se establece un precio y luego se ajusta por BCRA",
        },
      },
      {
        id: 31,
        question: "Establecer el cronograma de los Valores Locativos",
        answer: [
          "Por ej.: 24.000 primeros seis meses, 30.000 los siguentes seis meses, etc.",
        ],
        type: question,
        depend: {
          id: 29,
          answer: "Cronograma de Valores Locativos",
        },
      },
      {
        id: 32,
        question: "¿Cuánto tiempo durará el Contrato de alquiler?",
        answer: ["Por ejemplo: 3 meses, 3 años, 4 años."],
        type: question,
      },
      {
        id: 33,
        question: "¿Cuándo iniciará el Contrato de Alquiler?",
        answer: [
          "Determinar la fecha en la que el futuro Inquilino podrá ingresar en el Inmueble e iniciará la relación locativa.",
        ],
        type: dateWithoutSpace,
      },
      {
        id: 34,
        question: "¿Cuándo se FIRMARÁ el Contrato de Alquiler?",
        answer: [
          "Determinar la fecha en la que el Oferente y el Propietario firmarán el Contrato de Alquiler.",
        ],
        type: dateWithoutSpace,
      },
      {
        id: 35,
        question: "¿Qué destino se le asignará al Inmueble?",
        answer: [
          "Se debe definir que destino tendrá el Inmueble: de vivienda, uso comercial, oficina u otro.",
          'En caso que eliga la opción "Otro" deberá indicarlo en la pregunta siguiente.',
        ],
        type: options,
        options: ["Vivienda", "Uso comercial", "Oficina", "Otro"],
      },
      {
        id: 36,
        question: "¿Cuál será ese destino que se le asignará al Inmueble?",
        answer: ["Detallar el destino que se le asignará al Inmueble"],
        type: question,
        depend: {
          id: 35,
          answer: "Otro",
        },
      },
      {
        id: 39,
        question: "¿De que manera se pagará el Precio del alquiler?",
        answer: ["Definir si se pagará a mes vencido o mes adelantado."],
        type: options,
        options: ["A mes vencido", "A mes adelantado"],
      },
      {
        id: 40,
        question: "Definir entre que días será pagadero el precio del Alquiler",
        answer: [
          "Definir entre que días deberá el Inquilino pagar el precio del alquiler",
        ],
        type: options,
        options: ["día 1 y 5 de cada mes", "día 1 y 10 de cada mes", "Otro"],
      },
      {
        id: 41,
        question:
          "Definir entre que días será pagadero el precio del Alquiler distinto de las opciones anteriores",
        answer: [
          "Establecer únicamente los días. De la siguiente manera: día 10 y 15 de cada mes, día 1 y 10 de cada año, etc.",
        ],
        type: question,
        depend: {
          id: 40,
          answer: "Otro",
        },
      },
      {
        id: 37,
        question:
          "Al momento de firma del Contrato de Alquiler ¿El Oferente deberá realizar un Depósito en Garantía?",
        type: options,
        options: ["Sí", "No"],
      },
      {
        id: 38,
        question: "¿Cuál será el monto del depósito en garantía?",
        type: money,
        depend: {
          id: 37,
          answer: "Sí",
        },
      },
    ],
  },
  {
    id: "section7",
    section: "Garante y Garantía",
    title:
      "En caso de haber, definir el Garante y la Garantía que se establecerán en el Contrato de Locación.",
    type: questions,
    explanation: {
      question: [
        "¿Qué es una Garantía? ¿Qué tipo de Garantías hay? ¿Qué es un Garante?",
      ],
      answer: [
        "Garantía: Una Garantía es instrumento jurídico que se emplea para asegurar el cumplimiento de una obligación, en este caso para el cumplimiento de las obligaciones del Contrato de Alquiler.",
        "El Garante (una tercera persona) garantizará las obligaciones que tiene el Inquilino en el Contrato.",
        "La Ley prevé distintos tipos de Garantías para los Contratos de Locación, a saber: Título de propiedad inmueble; Aval bancario; Seguro de caución; Garantía de fianza o fiador solidario; y/o Garantía personal del locatario, (Recibo de sueldo, Certificado de ingresos o cualquier otro medio fehaciente).",
      ],
    },
    questions: [
      {
        id: 51,
        question:
          "¿Va a haber un Garante (una tercera persona) que garantize que el Contrato se va a cumplir?",
        answer: [
          'En caso que se opté por "Sí, y sus datos se establecerán en esta Reserva", deberá indicar los datos de esta persona a continuación.',
          'En caso que se opté por "Sí, pero sus datos serán individualizados en el Contrato", los datos del Garante se indicaran posteriormente en el Contrato de Alquiler que se firme, no en la presente Rerserva de Locación.',
        ],
        type: options,
        options: [
          "Sí, y sus datos se establecerán en esta Reserva",
          "Sí, pero sus datos serán individualizados en el Contrato",
          "No, no habrá Garante",
        ],
      },
      {
        id: 511,
        question:
          "Detallar los datos del Garante ¿Es una persona física o una persona jurídica?",
        type: options,
        options: ["Persona Física", "Persona Jurídica"],
        depend: {
          id: 51,
          answer: "Sí, y sus datos se establecerán en esta Reserva",
        },
      },
      {
        id: 512,
        question: "Nombres y Apellidos Completos:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 513,
        question: "Tipo de Documento:",
        type: options,
        options: ["DNI", "Pasaporte", "Cédula de Identidad"],
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 514,
        question: "Número de Documento:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 515,
        question: "Domicilio Completo:",
        info: "(Calle, Piso, Departamento, Ciudad)",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 516,
        question: "Provincia:",
        type: options,
        options: provincias,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Física",
          },
        ],
      },
      {
        id: 517,
        question: "Denominación Social (incluyendo tipo societario):",
        info: "Por ej.: Zapatillas Pampeanas S.R.L, o Perez Hermanos S.A.",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 518,
        question: "Tipo de identificacion:",
        type: options,
        options: ["CUIT"],
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 519,
        question: "Número de CUIT:",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 520,
        question: "Domicilio Completo de la sede principal de la entidad:",
        info: "(Calle, Piso, Departamento, Ciudad)",
        type: question,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 521,
        question: "Provincia:",
        type: options,
        options: provincias,
        doubleDepend: [
          {
            id: 51,
            answer: "Sí, y sus datos se establecerán en esta Reserva",
          },
          {
            id: 511,
            answer: "Persona Jurídica",
          },
        ],
      },
      {
        id: 52,
        question:
          "Al momento de la firma del Contrato de Alquiler ¿Va a ver una Garantía del Ofertante para asegurar el Contrato de Alquiler?",
        answer: [
          'La Garantía deberá ser del Inquilino. En caso que se opté por "Sí", deberá elegir cuál presenta entre las opciones que le daremos.',
          'En caso que opté por "No", no lo agregaremos a la Reserva.',
        ],
        type: options,
        options: ["Sí", "No"],
      },
      {
        id: 53,
        question: "¿Que Garantía se va a dar?",
        answer: [
          "Elegir entre las siguientes opciones. Una vez impreso el Contrato Ud. deberá acompañar una copia de la Garantia elegida al final del Contrato.",
        ],
        type: options,
        depend: {
          id: 52,
          answer: "Sí",
        },
        options: [
          "Título de propiedad Inmueble",
          "Aval bancario",
          "Seguro de Caución",
          "Recibo de sueldo",
          "Certificado de Ingresos",
          "Pagaré",
        ],
      },
    ],
  },
  {
    id: "section8",
    section: "Dinero en Reserva. Honorarios",
    title:
      "Detallar el dinero en reserva que entrega el Ofertante y los Honorarios de la Inmobiliaria",
    type: questions,
    explanation: {
      question: "¿Qué es el Dinero en Reserva?",
      answer: [
        "Es el dinero objeto de la Reserva de Locación que se entrega para reservar un Inmueble y se encuentra sujeto al cumplimiento de los términos de la Reserva.",
      ],
    },
    questions: [
      {
        id: 54,
        question:
          "¿Cuál es el monto de Dinero que el Ofertante entrega en Reserva?",
        type: money,
      },
      {
        id: 55,
        question:
          "¿Cuál es el monto de los Honorarios que recibirá la Inmobiliaria?",
        type: money,
      },
    ],
  },
];
export const ReservaDeLocacion = {
  name: "Reserva de Locación",
  codeName: "reservaDeLocacion",
  question: "¿Qué es una Reserva de Locación?",
  definition: [
    "La Reserva de Locación se utiliza para que una persona (Ofertante) reserve un Inmueble para la posterior firma de un Contrato de Locación con el Propietario del mismo. Se suele entregar una suma de dinero para asegurar la Reserva. La firma el Contrato de Locación queda sujeto a aprobación del Propietario del Inmueble. Suele ser un documento utilizado por las Inmobiliarias.",
  ],
  firmas: [
    [
      { id: 4, section: "section2" },
      { id: 9, section: "section2" },
    ],
    [
      { id: 14, section: "section3" },
      { id: 19, section: "section3" },
    ],
    [
      { id: 512, section: "section7" },
      { id: 517, section: "section7" },
    ],
  ],
  price: 999.99,
  size: "9-12 Páginas",
  lastRevision: "Septiembre 2021",
  sections: ReservaDeLocacionSections,
  url: "reserva-de-locacion",
  video: null,
  useCases: {
    good: [
      "Reservar el alquiler de un departamento por un plazo mayor a tres (3) años",
      "Reservar el alquiler de un departamento para un contrato temporal (menos de 3 meses)",
      "Establecer los honorarios de la Inmobiliaria que participó en el proceso",
    ],
    bad: [
      "Alquilar una casa/departamento para turismo temporal",
      "Alquilar un inmueble por aplicaciones como Airbnb o similar",
      "Reserva la compra de un inmueble",
    ],
  },
};
