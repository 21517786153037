import { APIPost } from "../Services/authenticated";
import { saveAs } from "file-saver";

export const downloadWordS3 = async (document, accesToken) => {
  try {
    const thisDocument = await APIPost("/doc-ref/downlad-doc-s3", accesToken, {
      documentPath: document.filePath,
    });
    const thisData = thisDocument.Body.data;

    var blob = new Blob([new Uint8Array(thisData)], {
      type: "application/octet-binary",
    });

    let mimeType = "doc/docx";
    let extension = "docx";

    if (document.format === "doc") {
      extension = "doc";
    }

    if (document.format === "pdf") {
      mimeType = "application/pdf";
      extension = "pdf";
    }

    if (document.format === "xls") {
      mimeType = "application/vnd.ms-excel";
      extension = "xls";
    }

    if (document.format === "xlsx") {
      mimeType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      extension = "xlsx";
    }
    if (document.format === "png") {
      mimeType = "image/png";
      extension = "png";
    }

    if (document.format === "jpeg") {
      mimeType = "image/jpeg";
      extension = "jpeg";
    }

    if (document.format === "jpg") {
      mimeType = "image/jpeg";
      extension = "jpg";
    }

    if (document.format === "heic") {
      mimeType = "image/heic";
      extension = "jpg";
    }
    if (document.format === "ppt") {
      mimeType = "application/vnd.ms-powerpoint";
      extension = "ppt";
    }
    if (document.format === "pptx") {
      mimeType =
        "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      extension = "pptx";
    }

    const file = new File([blob], "File name", { type: mimeType });
    saveAs(file, `${document.fileName}.${extension}`);
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const downloadWordFromS3andReturn = async (document, accesToken) => {
  try {
    const thisDocument = await APIPost("/doc-ref/downlad-doc-s3", accesToken, {
      documentPath: document.filePath,
    });
    const thisData = thisDocument.Body.data;

    var blob = new Blob([new Uint8Array(thisData)], {
      type: "application/octet-binary",
    });

    let mimeType = "doc/docx";

    const file = new File([blob], "File name", { type: mimeType });
    return file;
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export default null;
