import { Stack } from "@mui/material";
import { cardStyle } from "../../Common/theme";

interface TutorialVideoProps {
  src: string;
}

export const VideoTour: React.FC<TutorialVideoProps> = ({ src }) => {
  return (
    <Stack flex={1}>
      <iframe
        src={src}
        allowFullScreen
        title="Tutorial Video"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          borderRadius: "8px",
          ...cardStyle.dark,
        }}
      ></iframe>
    </Stack>
  );
};
