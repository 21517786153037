import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
  dependIncludeDouble,
  catastro,
  percentage,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";
import { bancos } from "./bancos";

export const TestSections = [
  {
    id: "section1",
    section: "Tiempo, Lugar",
    title: "Partes del documento",
    type: questions,
    explanation: {
      question: "¿Partes?",
      answer: ["los sujetos que intervienen en la operación."],
    },
    questions: [
      {
        id: 5,
        question: "¿Habrá uno (1), dos (2) o (3) Inquilinos/Locatarios?",
        info: "Definir si habrá 1, 2, 3 inquilinos. Si hay mas, completar con los 3 y luego agregar el resto en el EDITOR.",
        type: options,
        options: ["1", "2", "3"],
      },

      {
        id: 6,
        question: "Detallar Nombre y Apellido del INQUILINO",
        type: question,
      },
      {
        id: 7,
        question: "Número de Telefono del INQUILINO",
        type: question,
      },
      {
        id: 8,
        question: "Domicilio Electrónico (E-mail) del INQUILINO",
        type: question,
      },

      // ACA ARRANCA PREGUNTAS PARA EL 2DO INQUILINO
      {
        id: 9,
        question: "Detallar Nombre y Apellido del SEGUNDO INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "2",
        },
      },
      {
        id: 10,
        question: "Número de Telefono del SEGUNDO INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "2",
        },
      },
      {
        id: 11,
        question: "Domicilio Electrónico (E-mail) del SEGUNDO INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "2",
        },
      },

      // ACA ARRANCA PREGUNTAS PARA EL 3ER INQUILINO
      {
        id: 9,
        question: "Detallar Nombre y Apellido del SEGUNDO INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "3",
        },
      },
      {
        id: 10,
        question: "Número de Telefono del SEGUNDO INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "3",
        },
      },
      {
        id: 11,
        question: "Domicilio Electrónico (E-mail) del SEGUNDO INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "3",
        },
      },
      {
        id: 12,
        question: "Detallar Nombre y Apellido del TERCER INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "3",
        },
      },
      {
        id: 13,
        question: "Número de Telefono del TERCER INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "3",
        },
      },
      {
        id: 14,
        question: "Domicilio Electrónico (E-mail) del TERCER INQUILINO",
        type: question,
        depend: {
          id: 5,
          answer: "3",
        },
      },
      /////////////////////GARANTE
      {
        id: 15,
        question: "¿hay GARANTE?",
        type: options,
        options: ["Sí", "No"],
      },
      {
        id: 16,
        question: "¿Habrá uno (1), dos (2) GARANTES?",
        type: options,
        options: ["1", "2"],
        depend: {
          id: 15,
          answer: "Sí",
        },
      },

      {
        id: 17,
        question: "Detallar Nombre y Apellido del GARANTE",
        type: question,
        depend: {
          id: 15,
          answer: "Sí",
        },
      },
      {
        id: 18,
        question: "Número de Telefono del GARANTE",
        type: question,
        depend: {
          id: 15,
          answer: "Sí",
        },
      },
      {
        id: 19,
        question: "Domicilio Electrónico (E-mail) del GARANTE",
        type: question,
        depend: {
          id: 15,
          answer: "Sí",
        },
      },

      // ACA ARRANCA PREGUNTAS PARA EL 2DO INQUILINO
      {
        id: 20,
        question: "Detallar Nombre y Apellido del SEGUNDO GARANTE",
        type: question,
        depend: {
          id: 16,
          answer: "2",
        },
      },
      {
        id: 21,
        question: "Número de Telefono del SEGUNDO GARANTE",
        type: question,
        depend: {
          id: 16,
          answer: "2",
        },
      },
      {
        id: 22,
        question: "Domicilio Electrónico (E-mail) del SEGUNDO GARANTE",
        type: question,
        depend: {
          id: 16,
          answer: "2",
        },
      },
      /////////PROPIETARIO
      {
        id: 23,
        question: "¿Habrá uno (1), dos (2) PROPIETARIOS?",
        info: "Definir si habrá 1, 2, 3 inquilinos. Si hay mas, completar con los 3 y luego agregar el resto en el EDITOR.",
        type: options,
        options: ["1", "2"],
      },

      {
        id: 24,
        question: "Detallar Nombre y Apellido del PROPIETARIO",
        type: question,
      },
      {
        id: 25,
        question: "Número de Telefono del PROPIETARIO",
        type: question,
      },
      {
        id: 26,
        question: "Domicilio Electrónico (E-mail) del PROPIETARIO",
        type: question,
      },

      // ACA ARRANCA PREGUNTAS PARA EL 2DO PROPIETARIO
      {
        id: 27,
        question: "Detallar Nombre y Apellido del SEGUNDO PROPIETARIO",
        type: question,
        depend: {
          id: 23,
          answer: "2",
        },
      },
      {
        id: 28,
        question: "Número de Telefono del SEGUNDO PROPIETARIO",
        type: question,
        depend: {
          id: 23,
          answer: "2",
        },
      },
      {
        id: 29,
        question: "Domicilio Electrónico (E-mail) del SEGUNDO PROPIETARIO",
        type: question,
        depend: {
          id: 23,
          answer: "2",
        },
      },
    ],
  },
  {
    id: "section2",
    section: "Detalles del Inmueble",
    title: "Indicar los detalles del Inmueble involucrado",
    type: questions,
    questions: [
      {
        id: 10,
        question: "¿Qué tipo de Inmueble se Alquila?",
        type: options,
        options: [
          "Casa",
          "Casa en barrio cerrado",
          "Casa en country",
          "Departamento",
          "PH",
        ],
      },
      {
        id: 11,
        question: "¿En qué Provincia se encuentra el Inmueble?",
        type: options,
        options: provincias,
      },
      {
        id: 12,
        question:
          "Calle donde se encuentra el Inmueble (Calle, Altura y Ciudad/Localidad)",
        answer: [
          "Detallar frente a qué calle se encuentra (Calle, Altura y Ciudad/Localidad)",
          "Por ejemplo: Sargento Díaz 301, Tigre; San Martín 234, San Isidro",
          "En caso de Barrio Cerrado o Country, la dirección del Country/Barrio Cerrado",
        ],
        type: question,
      },
      {
        id: 13,
        question: "Detallar Nombre del Barrio Cerrado",
        type: question,
        depend: {
          id: 10,
          answer: "Casa en barrio cerrado",
        },
      },
      {
        id: 14,
        question: "Detallar Nombre del Country",
        type: question,
        depend: {
          id: 10,
          answer: "Casa en country",
        },
      },
      {
        id: 15,
        question: "Detallar Número de Lote",
        type: question,
        depend: {
          id: 10,
          answer: "Casa en country",
        },
      },
      {
        id: 16,
        question: "Detallar Número de Lote",
        type: question,
        depend: {
          id: 10,
          answer: "Casa en barrio cerrado",
        },
      },
      {
        id: 17,
        question: "Detallar Unidad Funcional",
        type: question,
        depend: {
          id: 10,
          answer: "Departamento",
        },
      },
      {
        id: 18,
        question: "Detallar Piso",
        type: question,
        depend: {
          id: 10,
          answer: "Departamento",
        },
      },
    ],
  },

  {
    id: "section3",
    section: "Detalles de la Transacción",
    title: "Indicar los detalles de la Transacción",
    type: questions,
    questions: [
      {
        id: 1,
        question: "¿En qué fecha se celebra el Contrato de Locación?",
        info: "Si se firmo el documento, completar las preguntas con relación al Contrato. Si no se firmo continuar sin contestar.",
        type: date,
      },
      {
        id: 2,
        question: "¿En qué fecha FINALIZA el Contrato de Locación?",
        info: "Si se firmo el documento, completar las preguntas con relación al Contrato. Si no se firmo continuar sin contestar.",
        type: date,
      },
      {
        id: 3,
        question:
          "Definir cada cuanto tiempo se actualizará el precio del Alquiler del Inmueble",
        answer: ["Detallar el plazo de indexación del precio del alquiler"],
        type: numberOf,
        fixedValue: "meses",
        fixedValueSingular: "mes",
      },

      {
        id: 301,
        question: "¿Cuando se abonará el pago del alquiler?",
        info: "Rellenar la siguiente frase: El alquiler se abonará del ......RELLENAR..... de cada mes. EJEMPLO: 1 al 5, 5 al 10...",
        type: question,
      },
      {
        id: 302,
        question: "¿Cómo se realizará el pago del alquiler?",
        type: options,
        options: ["en efectivo", "mediante trasnferencia/depósito bancario"],
      },
      {
        id: 303,
        question:
          "Indicar donde dirección donde se deberá entregar el efectivo:",
        type: question,
        depend: {
          id: 302,
          answer: "en efectivo",
        },
      },
      {
        id: 304,
        question: "Indicar datos bancarios",
        info: "CA........., CBU......... CUIT.......... BANCO.........",
        type: question,
        depend: {
          id: 302,
          answer: "mediante trasnferencia/depósito bancario",
        },
      },

      {
        id: 4,
        question: "Indicar el monto del depósito de garantía",
        type: money,
      },

      {
        id: 30,
        question: "Nombre de la Administración del edificio",
        type: question,
      },
      {
        id: 31,
        question: "Télefono de la Administración del edificio",
        type: question,
      },
      {
        id: 32,
        question: "Correo eléctronico de la Administración del edificio",
        type: question,
      },
      {
        id: 33,
        question: "Página y enlace de acceso",
        type: question,
      },

      {
        id: 34,
        question: "Empresa proveedora de luz del inmueble",
        type: options,
        options: ["EDENOR", "EDESUR"],
      },
      {
        id: 35,
        question: "Número de Cliente:",
        type: question,
      },
      {
        id: 36,
        question: "Número de medidor:",
        type: question,
      },
      {
        id: 37,
        question: "AYSA: Número de CUENTA SERVICIOS:",
        type: question,
      },
      {
        id: 38,
        question: "ABL/AGIP: Número de PARTIDA:",
        type: question,
      },
      {
        id: 39,
        question: "ABL/AGIP: DÍGITO VERIFICADOR:",
        type: question,
      },
      {
        id: 40,
        question: "METROGAS: Número de CLIENTE:",
        type: question,
      },
      {
        id: 41,
        question: "Los Impuestos y Servicios:",
        type: options,
        options: [
          "Viene boleta independiente",
          "Viene incluida en las expensas",
        ],
      },
    ],
  },
];

export const Test2 = {
  name: "Instrucciones para controlar los pagos y servicios",
  codeName: "Test2",
  question: "Instrucciones para controlar los pagos y servicios",
  definition: [
    "Cada Unidad locada tiene sus particularidades a la hora de pagar y controlar servicios. Este documento intenta facilitar la información de cada uno de las unidades.",
  ],
  sections: TestSections,
  url: "test2",

  removeHeaderAndFooter: false,
};
