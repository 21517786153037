import React from 'react';
import { setDetails } from '../../../../../redux/fileDetail/fileDetail.actions';
import { connect } from 'react-redux';

import './styles.css';

const DetailField = ({
  field,
  fieldName,
  edit,
  editable,
  setDetails,
  logicalName,
  details,
}) => {
  return (
    <div>
      <div className='detail-field-wrapper'>
        <i className='far fa-play-circle secondary margin-r'></i>
        <div>
          {fieldName}:{' '}
          {!edit && (
            <span>
              {field ? (
                <span className='bold'>{field}</span>
              ) : (
                <span className='detail-field-undefined'>Sin Definir</span>
              )}
            </span>
          )}
        </div>
      </div>
      {edit && editable && (
        <input
          type='text'
          className='detail-field-input'
          maxlength='50'
          defaultValue={details[logicalName] ? details[logicalName] : field}
          onChange={(e) => {
            setDetails({ [logicalName]: e.target.value });
          }}
        />
      )}
      {edit && !editable && (
        <p className='detail-field-not-editable'>
          {field}{' '}
          <span className='detail-field-not-remark'>- No editable </span>
        </p>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  setDetails,
};

const mapStateToProps = (state) => {
  return {
    details: state.details,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailField);
