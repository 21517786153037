import React from 'react';
import { connect } from 'react-redux';
import { select } from '../../../../../../data/types';
import CheckSection from '../../../MainSection/CheckSection';
import './styles.css';

const DocumentSection = ({
  name,
  number,
  currentSection,
  onClick,
  answers,
  checkSection,
}) => {
  const selected =
    currentSection === number ? 'document-section-selected-div' : null;
  const checkSectionIsSelected = checkSection
    ? 'document-section-selected-div'
    : null;

  const isClickable = currentSection > number;
  return (
    <div
      className={`document-section-text-div ${selected} ${checkSectionIsSelected} document-section-hoverer-${isClickable}`}
      onClick={onClick}
    >
      <div className='document-section-text'>
        {number > 0 && `${number}.`} {name}
      </div>
      <div>
        {isClickable && (
          <i className='far fa-check-circle document-section-check primary'></i>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    answers: state.answers,
  };
};

export default connect(mapStateToProps)(DocumentSection);
