import { Box, Stack, Typography } from "@mui/material";
import { colorPallette } from "../../Common/theme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

const clients = [
  {
    name: "Eduardo Stulich",
    businessName: "Soldati Propiedades",
    photo: "/images/eduardo-sol.png",
    logo: "https://andes-photos.s3.us-east-2.amazonaws.com/soldati-company-logo.png",
    opinion:
      "Nuestros Agentes asociados aman la aplicación, por la velocidad y facilidad con la que generan los documentos. A su vez, es muy ágil la interacción con los desarrolladores al momento de hacer correcciones necesarias a los documentos .",
  },

  {
    name: "Sofia Derudder - Senior Legal Manager",
    businessName: "Flechabus",
    photo: "/images/SofiaFlechabus.jpeg",
    logo: "https://andes-photos.s3.us-east-2.amazonaws.com/flecha-bus-company-logo.png",
    opinion:
      "La implementación de AndesDocs en el Grupo Flechabus nos permitió optimizar nuestros procesos y ahorrar costos en la gestión y firma de contratos con nuestros agentes a lo largo y ancho del país. Nuestro departamento de legales y comerciales utilizan a diario AndeDocs. La herramienta es intuitiva, ágil y potente, recomiendo su uso.",
  },
];

export const ClientsCarousel: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
  };

  return (
    <Stack
      sx={{
        width: "80%",
        gap: 5,
        pb: 10,
        pt: 5,
        // overflow: "hidden",
        position: "relative",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          px: 10,
        }}
      >
        <Box>
          <Typography
            component="span"
            variant="h4"
            gutterBottom
            fontWeight="bold"
            mb={3}
            sx={{
              fontSize: { xs: "1.8rem", sm: "2rem" },
              width: "100vw !important",
              wordBreak: "break-word",
              whiteSpace: "no-wrap",
              overflow: "hidden",
            }}
          >
            <Box
              component="span"
              sx={{
                color: colorPallette.primary,
                marginRight: "10px",
                fontSize: { xs: "1.5rem", sm: "1.8rem" },
                display: { xs: "none", sm: "inline" },
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
            </Box>
            Casos de{" "}
            <span
              style={{
                color: colorPallette.primary,
              }}
            >
              éxito
            </span>
          </Typography>
        </Box>
      </Box>
      <Box maxWidth={"100%"}>
        <Slider {...settings}>
          {clients.map((client, index) => (
            <Box
              key={index}
              gap={2}
              sx={{
                alignItems: "center !important",
                display: "flex !important",
                flexDirection: "column",
                py: 5,
                "@media (max-width: 600px)": {
                  py: 2,
                },
              }}
            >
              <Typography
                fontWeight="regular"
                maxWidth="800px"
                sx={{ fontSize: "1.1rem" }}
              >
                "{client.opinion}"
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  pt: 5,
                  justifyContent: "space-between",
                  flexDirection: { xs: "column", sm: "row" },
                  width: "500px",
                  maxWidth: "90%",
                }}
              >
                <Stack
                  sx={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component={"img"}
                    sx={{
                      width: "100px",
                      borderRadius: "5%",
                      mr: 3,
                    }}
                    src={process.env.PUBLIC_URL + client.photo}
                  />
                  <Stack sx={{ textAlign: "left" }}>
                    <Typography fontWeight={"bold"}>{client.name}</Typography>
                    <Typography color={colorPallette.primary}>
                      {client.businessName}
                    </Typography>
                  </Stack>
                </Stack>
                <Box
                  component={"img"}
                  sx={{
                    width: "120px",
                    borderRadius: "5%",
                  }}
                  src={process.env.PUBLIC_URL + client.logo}
                />
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Stack>
  );
};
