import React, { useEffect } from "react";
import Loader from "react-loader-spinner";

const LoadingScreen = () => {
  return (
    <div style={{textAlign: 'center'}}>
      <div style={{ marginTop: 150 }}>
        <Loader
          type="Bars"
          color="#e8eeff"
          height={60}
          width={60}
          className="margin-v"
        />
        <span className="f-medium primary margin-v">Cargando ...</span>
      </div>
    </div>
  );
};

export default LoadingScreen;
