import {
  title,
  text,
  bold,
  bullet,
  subtitle,
  firma,
  textArray,
} from "./types.js";

export const ReservaDeLocacionDoc = [
  [
    {
      type: title,
      text: "Reserva de Locación",
      depend: false,
      stopRemoval: false,
    },
  ],
  [
    {
      type: text,
      text: "La presente ",
      depend: false,
    },
    {
      type: bold,
      text: "Reserva de Locación ",
      depend: false,
      notBe: true,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: text,
      text:
        "(en adelante, la “Reserva”) se celebra en ${answers.section1[2].answer}, provincia de ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "La presente ",
      depend: true,
      stopRemoval: false,
      question: {
        section: "section1",
        number: 1,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: bold,
      text: "Reserva de Locación ",
      depend: false,
    },
    {
      type: text,
      text:
        '(en adelante, la "Reserva") se celebra en la ${answers.section1[1].answer}, República Argentina al ${answers.section1[3].answer}, entre las siguientes partes:',
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Propietario",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[4].answer}, ${answers.section2[5].answer} N° ${answers.section2[6].answer}, con domicilio sito en ${answers.section2[7].answer}, ${answers.section2[8].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Propietario",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section2[9].answer}, ${answers.section2[10].answer} N° ${answers.section2[11].answer}, con domicilio sito en ${answers.section2[12].answer}, ${answers.section2[13].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Ofertante",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[14].answer}, ${answers.section3[15].answer} N° ${answers.section3[16].answer}, con domicilio sito en ${answers.section3[17].answer}, ${answers.section3[18].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Ofertante",
      depend: false,
    },
    {
      type: text,
      text:
        ": ${answers.section3[19].answer}, ${answers.section3[20].answer} N° ${answers.section3[21].answer}, con domicilio sito en ${answers.section3[22].answer}, ${answers.section3[23].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Inmobiliaria",
      depend: true,
      question: {
        section: "section4",
        number: "person",
        answer: "Persona Física",
      },
    },
    {
      type: text,
      text:
        ": ${answers.section4[140].answer}, ${answers.section4[142].answer} N° ${answers.section4[143].answer}, con domicilio sito en ${answers.section4[144].answer}, ${answers.section4[145].answer}, República Argentina.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "Inmobiliaria",
      depend: true,
      question: {
        section: "section4",
        number: "person",
        answer: "Persona Jurídica",
      },
    },
    {
      type: text,
      text:
        ": ${answers.section4[146].answer}, ${answers.section4[147].answer} N° ${answers.section4[148].answer}, con domicilio sito en ${answers.section4[149].answer}, ${answers.section4[150].answer}, República Argentina, representada legalmente por el abajo firmante.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La Ofertante, el Propietario y la Inmobiliaria podrán ser denominadas conjuntamente como las “Partes” e individualmente como la “Parte”.",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "CONSIDERANDO",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Que la Ofertante se encuentra interesada en alquilar el inmueble sito en ${answers.section5[25].answer}, ${answers.section5[24].answer}, ${answers.section5[26].answer} (en adelante, el “Inmueble”) que se encuentra bajo dominio del Propietario, en los términos y condiciones que se establecen en la presente Reserva. ",
      notBe: true,
      question: {
        section: "section5",
        number: 24,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: textArray,
      text: "El Inmueble objeto del presente Contrato incluye {{array}}.",
      question: { section: "section5", number: 28 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(i)",
      text:
        "Que la Ofertante se encuentra interesada en alquilar el inmueble sito en ${answers.section5[26].answer}, ${answers.section5[24].answer} (en adelante, el “Inmueble”) que se encuentra bajo dominio del Propietario, en los términos y condiciones que se establecen en la presente Reserva. ",
      depend: true,
      question: {
        section: "section5",
        number: 24,
        answer: "Ciudad Autónoma de Buenos Aires",
      },
    },
    {
      type: textArray,
      text: "El Inmueble objeto del presente Contrato incluye {{array}}.",
      question: { section: "section5", number: 28 },
      stopRemoval: true,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(ii)",
      text:
        "Que el Propietario ha autorizado a la Inmobiliaria a actuar como intermediaria entre este y el Ofertante.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iii)",
      text:
        "Que la Inmobiliaria ha identificado y contactado a la Ofertante que se encuentra interesada en alquilar el Inmueble, quedando la suscripción del contrato de locación sujeto a la aprobación del Propietario de los términos y condiciones que regulará su relación locativa y, que la presente Reserva, se encarga de poner en manifiesto.",
      depend: false,
    },
  ],
  [
    {
      type: bullet,
      bullet: "(iv)",
      text:
        "Que en caso que el Ofertante y el Propietario perfeccionen una relación locativa, el Ofertante deberá abonar los honorarios a la Inmobiliaria según se establece a continuación.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "Es por todo ello, que las Partes se obligan según lo dispuesto a continuación:",
      depend: false,
    },
  ],
  [
    {
      type: bold,
      text: "PRIMERO: Objeto de la Reserva.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "En el presente acto la Ofertante entrega a la Inmobiliaria, y está a su vez la recibe, la suma de ${answers.section8[54].answer} (en adelante, el “Dinero en Reserva”) en concepto de reserva de locación del Inmueble. Las Partes acuerdan que el presente documento es recibo suficiente por el monto desembolsado.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "La presente reserva de locación del Inmueble se realiza “ad-referéndum” del Propietario, es decir, sujeto a su entera aprobación. Las Partes acuerdan que si el Propietario presta su consentimiento, la presente Reserva quedará firme y el Ofertante y el Propietario deberán suscribir un contrato de locación (en adelante, el “Contrato”) sujeto a los siguientes términos, a saber:",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "- Valor Locativo del Contrato: ${answers.section6[31].answer}.",
      depend: true,
      question: {
        section: "section6",
        number: 29,
        answer: "Cronograma de Valores Locativos",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Valor Locativo del Contrato: La suma de ${answers.section6[30].answer}, la que se ajustará anualmente según el Índice de Contratos de Locación (ICL) elaborado y publicado mensualmente por el Banco Central de la República Argentina.",
      depend: true,
      question: {
        section: "section6",
        number: 29,
        answer: "Se establece un precio y luego se ajusta por BCRA",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Forma de pago del Valor Locativo: ${answers.section6[39].answer}, pagadero entre el ${answers.section6[40].answer}.",
      notBe: true,
      question: {
        section: "section6",
        number: 40,
        answer: "Otro",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Forma de pago del Valor Locativo: ${answers.section6[39].answer}, pagadero entre el ${answers.section6[41].answer}.",
      depend: true,
      question: {
        section: "section6",
        number: 40,
        answer: "Otro",
      },
    },
  ],
  [
    {
      type: text,
      text: "- Duración del Contrato: ${answers.section6[32].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "- Fecha de Inicio del Contrato: ${answers.section6[33].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text: "- Fecha de Firma del Contrato: ${answers.section6[34].answer}.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "- El Inmueble será destinado para: ${answers.section6[35].answer}.",
      notBe: true,
      question: {
        section: "section6",
        number: 35,
        answer: "Otro",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- El Inmueble será destinado para: ${answers.section6[36].answer}.",
      depend: true,
      question: {
        section: "section6",
        number: 35,
        answer: "Otro",
      },
    },
  ],
  [
    {
      type: textArray,
      text:
        "- Servicios: Será a cargo del Ofertante el pago de los siguientes servicios: {{array}}.",
      depend: false,
      question: { section: "section5", number: 125 },
    },
  ],
  [
    {
      type: text,
      text:
        "- Expensas: Será a cargo del Ofertante el pago de las expensas ordinarias.",
      depend: true,
      question: {
        section: "section5",
        number: 27,
        answer: "Sí, las pagará el Ofertante",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Expensas: Será a cargo del Propietario el pago de las expensas ordinarias.",
      depend: true,
      question: {
        section: "section5",
        number: 27,
        answer: "Sí, las pagará el Propietario",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Expensas: Las expensas ordinarias se pagarán en proporciones iguales entre el Propietario y el Ofertante.",
      depend: true,
      question: {
        section: "section5",
        number: 27,
        answer:
          "Sí, se pagará en proporciones iguales entre el Propietario y Ofertante",
      },
    },
  ],
  [
    {
      type: text,
      text: "- Expensas: Se definirá en el Contrato.",
      depend: true,
      question: {
        section: "section5",
        number: 27,
        answer: "Sí, se definirá en el Contrato quien las paga",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Depósito en Garantía: se establecerá en un monto de ${answers.section6[38].answer}.",
      depend: true,
      question: {
        section: "section6",
        number: 37,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Garante: El Garante será individualizado en el Contrato y quedará sujeto a la previa aprobación del Propietario.",
      depend: true,
      question: {
        section: "section7",
        number: 51,
        answer: "Sí, pero sus datos serán individualizados en el Contrato",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Garante: ${answers.section7[512].answer}, ${answers.section7[513].answer} N° ${answers.section7[514].answer}, con domicilio sito en ${answers.section7[515].answer}, ${answers.section7[516].answer}, República Argentina.",
      depend: true,
      question: {
        section: "section7",
        number: 511,
        answer: "Persona Física",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "- Garante: ${answers.section7[517].answer}, ${answers.section7[518].answer} N° ${answers.section7[519].answer}, con domicilio sito en ${answers.section7[520].answer}, ${answers.section7[521].answer}, República Argentina.",
      depend: true,
      question: {
        section: "section7",
        number: 511,
        answer: "Persona Jurídica",
      },
    },
  ],
  [
    {
      type: text,
      text: "- Garantía: La Garantía será un ${answers.section7[53].answer}.",
      depend: true,
      question: {
        section: "section7",
        number: 52,
        answer: "Sí",
      },
    },
  ],
  [
    {
      type: bold,
      text: "SEGUNDO: Condiciones de la Reserva.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.1 La presente Reserva tendrá vigencia desde la fecha establecida en el encabezado hasta la Fecha de Firma del Contrato –la que se encuentra determinada en el artículo primero anterior- (en adelante, la “Vigencia de la Reserva”). Una vez vencida la Vigencia de la Reserva sin que mediare consentimiento del Propietario para suscribir el Contrato, la Inmobiliaria deberá reintegrar el Dinero en Reserva a la Ofertante, renunciando está ultima a cualquier tipo de reclamo y/o solicitud de intereses y/o indemnización por el monto y tipo que fuese.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.2 Durante la Vigencia de la Reserva el Propietario no podrá reservar, ni tampoco alquilar el Inmueble a terceras personas. Si el Propietario incumpliese con lo establecido en el presente párrafo y/o con alguna de las obligaciones establecidas en la presente Reserva, deberá reintegrar al Ofertante el Dinero en Reserva más otro monto igual en concepto de única y total indemnización.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.3 Si el Ofertante no suscribiera el Contrato y/o incumpliera con alguna de las obligaciones establecidas en la presente Reserva durante la Vigencia de la Reserva, no podrá exigir la devolución del Dinero en Reserva, ni solicitar compensación y/o indemnización de ningún tipo y deberá abonar los montos correspondientes a los gastos notariales, en caso que se hubiesen generado.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.4 Si el Ofertante y el Propietario efectivamente firmaran el Contrato, el Dinero en Reserva se tomará a cuenta del depósito en garantía o del valor locativo del primer mes o de los Honorarios de la Inmobiliaria (tal como se define en 2.5), según así lo acuerden oportunamente las Partes.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.5 En la Fecha de Firma del Contrato, el Oferente abonará a la Inmobiliaria la suma de equivalente a ${answers.section8[55].answer} en concepto de honorarios (en adelante, los “Honorarios de la Inmobiliaria”).",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.6 Los gastos que se generasen para la obtención de informes de dominio e inhibición estarán a cargo del Ofertante y podrán, según las Partes así lo acuerden, descontarse del Dinero en Reserva.",
      depend: false,
    },
  ],
  [
    {
      type: text,
      text:
        "2.7 La Partes acuerdan que la Propietaria se reserva el derecho de rechazar el garante y/o la garantía propuesta/as por el Ofertante y solicitar presente nuevos sujetos a su conformidad. ",
      depend: false,
    },
    {
      type: text,
      text:
        "Dentro de los cinco (5) días de firmada la presente Reserva, el Ofertante deberá presentar copia de DNI y los datos de quién será el Garante del Contrato para evaluación del Propietario y la Inmobiliaria.",
      notBe: true,
      stopRemoval: true,
      question: {
        section: "section7",
        number: 51,
        answer: "No, no habrá Garante",
      },
    },
  ],
  [
    {
      type: text,
      text:
        "Las Partes firman tres (3) ejemplares de un mismo tenor y a un solo efecto.",
      depend: false,
    },
  ],
];
