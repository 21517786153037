// src/form-component/FormInputText.tsx
import { Controller } from "react-hook-form";
import styles from "./InputForm.module.css";
import { Box, Stack } from "@mui/material";
import FormLabel from "./FormLabel";
import { useState } from "react";

type Props = {
  name: string;
  control: any;
  label?: string;
  smLabel?: boolean;
  required?: boolean;
  defaultValue?: string;
  info?: string[] | "";
  smallText?: boolean;
};

const FormTextarea: React.FC<Props> = ({
  name,
  control,
  label,
  smLabel,
  required,
  defaultValue,
  info,
  smallText,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <>
      <FormLabel
        required={required}
        label={label}
        smLabel={smLabel}
        info={info}
        isFocused={isFocused}
      />
      {label && smLabel && <Box className={styles.textSm}>{label}</Box>}

      <Box className={styles.wrapper}>
        <Controller
          name={name}
          control={control}
          rules={{
            required: {
              value: required || false,
              message: "Por favor, complete este campo",
            },
          }}
          defaultValue={defaultValue}
          render={({
            field: { onChange, value },
            fieldState: { error },
            formState,
          }) => (
            <Stack
              width={"100%"}
              textAlign={"left"}
              sx={{
                "& textarea": {
                  fontSize: smallText ? "13px !important" : "17.6px",
                },
              }}
            >
              <textarea
                value={value}
                className={styles.textarea}
                style={
                  error && {
                    borderColor: "red",
                    backgroundColor: "#fef7f6",
                    fontSize: smallText ? "8px !important" : "17.6px",
                  }
                }
                maxLength={9000}
                onChange={onChange}
                defaultValue={value}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
              />
              <Box className="red" mt={-2} mb={2} ml={1}>
                {error?.message && error.message}
              </Box>
            </Stack>
          )}
        />
      </Box>
    </>
  );
};

export default FormTextarea;
