import { theme } from "../Common/theme";
import { Dependency } from "../Types/Question.types";
import {
  processCatastro,
  processMoney,
  processPercentage,
  processSheetsDataBase,
} from "./questionTypeProcessor";

function replaceLastCommaWithY(inputString: string): string {
  const lastCommaIndex = inputString.lastIndexOf(",");
  if (lastCommaIndex === -1) {
    // If no comma is found, return the original string
    return inputString;
  }
  const stringWithY =
    inputString.substring(0, lastCommaIndex) +
    " y" +
    inputString.substring(lastCommaIndex + 1);
  return stringWithY;
}

export const processAnswer = (
  answer:
    | string
    | {
        type: string;
        [key: string]: any;
      }
): string => {
  if (typeof answer === "string") return answer;
  if (answer.type === "multipleOptions") {
    const processedString = Object.keys(answer)
      .filter((key) => key !== "type" && answer[key] === true) // Fixed comparison
      .join(", ");

    return replaceLastCommaWithY(processedString);
  }
  if (answer.type === "money") {
    return processMoney(answer);
  }
  if (answer.type === "percentage") {
    return processPercentage(answer);
  }
  if (answer.type === "sheetsDataBase") {
    return processSheetsDataBase(answer);
  }
  if (answer.type === "catastro") {
    return processCatastro(answer);
  }
  return "ERROR";
};

export const processAnswerForDependency = (
  answer:
    | undefined
    | string
    | {
        type: string;
        [key: string]: any;
      }
): string[] => {
  if (!answer) return [];
  if (typeof answer === "string") return [answer];
  if (answer.type === "multipleOptions") {
    const processedStringArray = Object.keys(answer).filter(
      (key) => key !== "type" && answer[key] === true
    ); // Fixed comparison

    return processedStringArray;
  }
  if (answer.type === "money") {
    return [processMoney(answer)];
  }
  if (answer.type === "percentage") {
    return [processPercentage(answer)];
  }
  if (answer.type === "sheetsDataBase") {
    return [processSheetsDataBase(answer)];
  }
  if (answer.type === "catastro") {
    return [processCatastro(answer)];
  }
  return ["ERROR"];
};

export const processText = (text: string) => {
  if (text.includes("[") || text.includes("{")) {
    return (
      <>
        {text
          .replaceAll("[", "#[")
          .replaceAll("]", "]#")
          .replaceAll("{", "#{")
          .replaceAll("}", "}#")
          .split("#")
          .map((t: string) => {
            if (t.includes("[")) {
              return <b>{t.replaceAll("[", "").replaceAll("]", "")}</b>;
            }
            if (t.includes("{")) {
              try {
                const companyData = JSON.parse(
                  window.localStorage.getItem("companyData") || "{}"
                );

                const currentAnswers = JSON.parse(
                  window.localStorage.getItem("generator-form-answers") || "{}"
                );

                const answers = { companyData, ...currentAnswers };
                const str: any = t.slice(0, -1).substring(1);
                if (str.includes(".")) {
                  const split = str.split(".");
                  if (answers[split[0]] && answers[split[0]][split[1]])
                    return (
                      <span style={{ color: theme.palette.primary.main }}>
                        {processAnswer(answers[split[0]][split[1]])}
                      </span>
                    );
                }
                if (answers && answers[str])
                  return (
                    <span style={{ color: theme.palette.primary.main }}>
                      {processAnswer(answers[str])}
                    </span>
                  );
                return <span>{"___________________"}</span>;
              } catch {
                return t;
              }
            }
            return t;
          })}
      </>
    );
  }
  return <>{text}</>;
};

export const processDependencies = (dependencies?: Dependency[]) => {
  const answers = JSON.parse(
    window.localStorage.getItem("generator-form-answers") || "{}"
  );

  if (!dependencies || dependencies.length === 0) return true;

  let shouldRender = true;

  if (dependencies) {
    dependencies.forEach((dependency: Dependency) => {
      const { type, values, id } = dependency;

      const valuesToCheck = String(values).toLowerCase().split(";");

      if (answers[id] === undefined) shouldRender = false;

      if (answers[id]) {
        const processedAnswer = processAnswerForDependency(answers[id]).map(
          (ans) => ans.toLowerCase()
        );

        if (type === "exclude") {
          processedAnswer.forEach((ans) => {
            if (valuesToCheck.includes(String(ans))) {
              shouldRender = false;
            }
          });
        }
        if (type === "contain any") {
          shouldRender = false;
          processedAnswer.forEach((ans) => {
            if (valuesToCheck.includes(String(ans))) {
              shouldRender = true;
            }
          });
        }
        if (type === "exact") {
          if (valuesToCheck[0] !== processedAnswer[0]) {
            shouldRender = false;
          }
        }
        if (type === "contain all") {
          valuesToCheck.forEach((val) => {
            if (!processedAnswer.includes(String(val))) {
              shouldRender = false;
            }
          });
        }
      }
      // if (type === "contain all") {
      //   if (valuesToCheck[0] !== answers[id]) {
      //     shouldRender = false;
      //   }
      // }
    });
  }
  return shouldRender;
};
