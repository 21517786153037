import {
  questions,
  path,
  options,
  select,
  question,
  date,
  person,
  number,
  numberOf,
  pathQuestion,
  multipleOptions,
  textarea,
  money,
} from "./types";

import currency from "./currency";
import { provincias } from "./provincias";

export const NDASections = [
  {
    id: "section1",
    section: "Tiempo y Lugar",
    title: "Tiempo y Lugar de Celebración del Contrato",
    type: questions,
    explanation: {
      question: "¿Que es el tiempo y el lugar?",
      answer: [
        "El tiempo y el lugar hace referencia al lugar y al momento en donde se firma el Contrato.",
      ],
    },
    questions: [
      {
        id: 1,
        question: "¿En qué provincia se celebra el Contrato?",
        type: options,
        options: provincias,
      },
      {
        id: 2,
        question: "¿En que ciudad se celebra el Contrato?",
        info:
          "Si arriba elegistes Ciudad de Buenos Aires, respondé lo mismo en esta pregunta.",
        type: question,
        notBe: {
          id: 1,
          answer: "Ciudad Autónoma de Buenos Aires",
        },
      },
      {
        id: 3,
        question: "¿En que fecha se celebra el Contrato?",
        type: date,
      },
    ],
  },
  {
    id: "section2",
    section: "Primer Persona",
    title: "Indicar los datos de la Primera Parte que participa del Contrato.",
    type: person,
    explanation: {
      question: "¿Quien es la primera persona del Contrato?",
      answer: [
        "En esta seccion hacemos referencia a la primer persona del Contrato, la persona que va a estar obligada a mantener en secreto lo que se le comunique.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person1",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 4,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 5,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 6,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 7,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 8,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Juridica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 9,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ejemplo Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 10,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 11,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 12,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 13,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section3",
    section: "Segunda Persona",
    title: "Indicar los datos de la Segunda Parte que participa del Contrato.",
    type: person,
    explanation: {
      question: "¿Quien es la segunda persona del Contrato?",
      answer: [
        "En esta seccion hacemos referencia a la segunda persona del Contrato, la persona que va a pedirle a la primera mantener en secreto lo que se le comunique.",
      ],
    },
    pathQuestion: {
      question: "¿Persona física o jurídica?",
      id: "person2",
      type: select,
      options: [
        {
          name: "Persona Física",
          description: "Una persona real, humana.",
          image: "girl.png",
          questions: [
            {
              id: 14,
              question: "Nombres y Apellidos Completos:",
              type: question,
            },
            {
              id: 15,
              question: "Tipo de Documento:",
              type: options,
              options: ["DNI", "Pasaporte", "Cédula de Identidad"],
            },
            {
              id: 16,
              question: "Número de Documento:",
              type: question,
            },
            {
              id: 17,
              question: "Domicilio Completo:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 18,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
        {
          name: "Persona Juridica",
          description:
            "Es un ente jurídico. Por ej: Sociedades, Asociaciones y Fundaciones",
          image: "srl.png",
          questions: [
            {
              id: 19,
              question: "Denominación Social (incluyendo tipo societario):",
              info:
                "Por ejemplo Zapatillas pampeanas S.R.L, o Perez Hermanos S.A.",
              type: question,
            },
            {
              id: 20,
              question: "Tipo de identificacion:",
              type: options,
              options: ["CUIT"],
            },
            {
              id: 21,
              question: "Número de CUIT:",
              type: question,
            },
            {
              id: 22,
              question:
                "Domicilio Completo de la sede principal de la entidad:",
              info: "(Calle, Piso, Departamento, Ciudad)",
              type: question,
            },
            {
              id: 23,
              question: "Provincia:",
              type: options,
              options: provincias,
            },
          ],
        },
      ],
    },
  },
  {
    id: "section4",
    section: "Información Confidencial",
    title: "Información Confidencial",
    type: questions,
    explanation: {
      question: "¿Qué es la Información Confidencial? ¿Qué es el Motivo?",
      answer: [
        "La Información Confidencial es la información privada que una o más personas y/o entidades deciden compartir con otras para utilizar en un proyecto y/o actividad.",
        "El Motivo o el Proyecto es la razón por la cual las Partes comparten cierta Información Confidencial.",
      ],
    },
    questions: [
      {
        id: 24,
        question:
          "¿Cúal es el motivo y/o proyecto por el cual se celebra el Contrato de Confidencialidad?",
        info:
          "Por favor complete detalladamente el motivo y/o el proyecto razón del intercambio de Información Confidencial. Por ej: Desarrollo de una start-up, Formación de nueva sociedad con próposito específico, Fusión de dos empresas, etc.",
        type: question,
      },
      {
        id: 25,
        question:
          "¿Donde se detallará la Información Confidencial que se acompaña en el Contrato?",
        answer: [
          "En un anexo: si la información confidencial que se compartirá se detallará en un anexo. En caso de elegir esta opción el Contrato deberás agregar una hoja de Anexo que detalle la información confidencial.",
          "En el texto: si la información confidencial que se compartirá se detallará en el texto del Contrato.",
        ],
        type: options,
        options: ["En un Anexo del Contrato", "En el Texto del Contrato"],
      },
      {
        id: 26,
        question: "Detallar la Información Confidencial que se entregará.",
        info:
          "Por ej: Detalle de nuevo proyecto inmobiliario, Proyecciones financieras para ejercicio 2022, Croquis Proyecto Minero en San Juan, etc.",
        type: question,
        depend: {
          id: 25,
          answer: "En el Texto del Contrato",
        },
      },
    ],
  },
  {
    id: "section5",
    section: "Condiciones Especiales",
    title: "Duración del Contrato",
    type: questions,
    explanation: {
      question: "Cuanto durará el Contrato?",
      answer: [
        "La duración del Contrato indicará por cuanto tiempo se deberá guardar reserva de la información Confidencial entregada y cuanto tiempo durará el Contrato.",
      ],
    },
    questions: [
      {
        id: 27,
        question: "¿Por cuántos meses tendrá vigencia el Contrato?",
        info: "(indicar en números)",
        answer: [
          "Por cuanto tiempo las Partes no podrán revelar la Información Confidencial que les fue entregada.",
        ],
        type: numberOf,
        fixedValue: "meses",
        fixedValueSingular: "mes",
      },
      {
        id: 28,
        question:
          "En caso de conflicto ¿En que jurisdicción se deberá resolver?",
        answer: [
          "En caso de conflicto por la interpretación, ejecución y/o incumplimiento del Contrato, las Partes deberán resolver el conflicto ante un tribunal competente en la jurisdicción que hayan establecido. (Se suele indicar la misma provincia donde se celebra el Contrato)",
        ],
        type: options,
        options: provincias,
      },
    ],
  },
  {
    id: "section6",
    section: "Multa",
    title: "Multa en caso de incumplimiento",
    type: questions,
    explanation: {
      question: "Que multa debo establecer?",
      answer: [
        "En caso de incumplimiento se puede establecer una multa en dinero como sanción. En caso que no se quiera incorporar una Multa, el Contrato preevé la indeminzación establecida por ley para este tipo de casos.",
      ],
    },
    questions: [
      {
        question:
          "En caso de incumplimiento del Contrato se podrá establecer una Multa en dinero ¿Desea incorporarla?",
        id: 29,
        type: options,
        options: ["Sí", "No"],
      },
      {
        id: 30,
        question: "¿De cuanto dinero será la Multa que se quiere imponer? ",
        type: money,
        depend: {
          id: 29,
          answer: "Sí",
        },
      },
    ],
  },
];

export const NDA = {
  name: "Contrato de Confidencialidad",
  codeName: "nda",
  question: "¿Qué es un Contrato de Confidencialidad?",
  definition: [
    "El Contrato de Confidencialidad se utiliza para mantener bajo reserva –confidencialidad- cierta información privada que una o más personas y/o entidades deciden compartir con otra/s persona/s y/o entidad/es para utilizar en un proyecto o una actividad. La persona y/o entidad que la recibió tiene el deber de no revelarla y de no usarla inapropiadamente en su propio interés.",
    "AndesDocs pone a disposición este Contrato de Confidencialidad de tipo bilateral que sirve para que dos o más personas y/o entidades, mantengan bajo reserva cierta información confidencial de cada una que intercambian para evaluar un determinado proyecto que ejecutarán y cuyo resultado será aprovechado por ambas.",
  ],
  firmas: [
    [
      { id: 4, section: "section2" },
      { id: 9, section: "section2" },
    ],
    [
      { id: 14, section: "section3" },
      { id: 19, section: "section3" },
    ],
  ],
  price: 999.99,
  size: "4 Páginas",
  lastRevision: "Agosto 2021",
  sections: NDASections,
  url: "nda",
  video: {
    image: "Nda.jpg",
  },
  useCases: {
    good: [
      "Compartir información confidencial sobre un proyecto",
      "Exigir se mantenga reserva/confidencialidad sobre cierta información a otra persona/entidad",
      "Asegurar la confidencialidad de cierto bloque de datos (Reportes, informes, pendrives, etc.)",
    ],
    bad: [
      "Exigir se mantenga reserva/confidencialidad sobre información de conocimiento público",
      "Exigir se mantenga reserva/confidencialidad sobre información que no es propia",
    ],
  },
};
