import { combineReducers } from "redux";
import answerReducer from "./answers/answers.reducer";
import detailsReducer from "./fileDetail/fileDetail.reducer";
import modalReducer from "./modal/modal.reducer";
import masterDataReducer from "./slices/masterData";
import currentDocumentDataSlice from "./slices/currentDocumentDataSlice";
import editorDataReducer from "./slices/EditorDataSlice";
import triggersReducer from "./slices/triggers";
import commonComponentsSlice from "./slices/commonComponentsSlice";

const rootReducer = combineReducers(
  {
    answers: answerReducer,
    details: detailsReducer,
    modal: modalReducer,
    masterData: masterDataReducer,
    currentDocumentData: currentDocumentDataSlice,
    editorData: editorDataReducer,
    triggers: triggersReducer,
    commonComponents: commonComponentsSlice,
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export default rootReducer;
