import React, { useEffect, useState } from "react";
import { APIGet } from "../../Services/authenticated";
import DocumentCreator from "../DocumentCreator";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

const CompanyDocumentCreator = () => {
  const [documentQuestionData, setDocumentQuestionData] = useState(null);
  const [showDocumentCreator, setShowDocumentCreator] = useState(false);

  useEffect(() => {
    const docData = sessionStorage.getItem("currentCompanyQuestionData");
    const parsed = JSON.parse(docData);
    setDocumentQuestionData(parsed);
    setShowDocumentCreator(true);
  }, []);

  return (
    <div>
      {showDocumentCreator && documentQuestionData && (
        <DocumentCreator data={documentQuestionData} />
      )}
    </div>
  );
};

export default CompanyDocumentCreator;
